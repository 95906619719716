import React, {useState} from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import CloudDownload from '@material-ui/icons/CloudDownload'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import AttachmentImage from './AttachmentImage'
import AttachmentFile from './AttachmentFile'
import Tracker from './Tracker'
import URL from './URL'
import Poll from './Poll'
import {Session,fileDownload,postDelete} from '../../shared/globals'

const StyledIconButton = withStyles({
  root: {
    fontSize: 12,
    boxShadow: 'none',
    color:'#DDDFE2',
    '&:hover': {
      color: "grey",
    }
  },
})(IconButton);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Attachments = ({user,attachments}) => {
  const [dialogDeleteOpen,setDialogDeleteOpen] = useState(false)
  const [currentAttachment,setCurrentAttachment] = useState(null)

  const deleteAttachmentDialog = (attachment) => {
    setDialogDeleteOpen(true)
    setCurrentAttachment(attachment)
  }
  const deleteAttachmentConfirm = (confirm) => {
    if(confirm){
      postDelete(user.uid,currentAttachment)
    }
    setDialogDeleteOpen(false)
  }

  return (
    <div style={{display:'flex',flexDirection:'column'}}>
    {
      _.map(_.orderBy(attachments,['attachmentOrder'],['asc']),(attachment)=>{
        return (
          <div key={attachment.id} style={{ position:'relative',display:'flex',flexDirection:'column',padding: 8, marginTop:8, backgroundColor: '#FFFFFF', border: '1px solid #DDDFE2', borderRadius: 8}}>
            <Attachment user={user} attachment={attachment} />
              <div style={{position:'absolute',bottom:attachment.postType==='attachment_url'?8:0,right:attachment.postType==='attachment_url'?8:0}}>
                {
                  attachment.postType!=='attachment_file'?null:
                  <StyledIconButton size='small' onClick={()=>fileDownload(user.uid,attachment)}>
                    <CloudDownload style={{fontSize:20}}/>
                  </StyledIconButton>
                }
                {
                  attachment.createdById !== Session.uid ? null :
                  <StyledIconButton size='small' onClick={()=>deleteAttachmentDialog(attachment)}>
                    <DeleteOutline style={{fontSize:20}}/>
                  </StyledIconButton>
                }
              </div>
          </div>
        )
      })
    }
    <Dialog fullWidth={true} maxWidth={'xs'}
      open={dialogDeleteOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={()=>setDialogDeleteOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Delete this item?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Once deleted this item cannot be recovered.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>deleteAttachmentConfirm(false)} color="primary">
          Disagree
        </Button>
        <Button onClick={()=>deleteAttachmentConfirm(true)} color="primary">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  )
}
const Attachment = ({user,attachment,refreshParentState}) => {
  switch (attachment.postType){
    case 'attachment_file':
      return attachmentFile(user,attachment)
    case 'attachment_tracker':
      return <Tracker user={user} attachment={attachment} />
    case 'attachment_url':
      return <URL user={user} attachment={attachment} />
    case 'attachment_poll':
      return <Poll user={user} attachment={attachment} />
    default:
      console.log('attachment.postType',attachment.postType)
      return null
  }
}

const attachmentFile = (user,attachment) => {
  switch (attachment.attachmentType){
    case 'image/png': case 'image/jpeg': case 'image/gif':
      return <AttachmentImage user={user} attachment={attachment} />
    case 'video/mp4':
      return (
        <video controls style={{border:'solid 1px grey',width:200,height:200,cursor:'pointer'}}>
          <source src={attachment.attachmentURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    case 'audio/mpeg':
      return (
        <audio controls style={{border:'solid 1px grey',width:200,height:200,cursor:'pointer'}}>
          <source src={attachment.attachmentURL} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )
    case 'application/pdf':
      return <AttachmentFile user={user} attachment={attachment} />
    default:
      console.log('attachment.attachmentType',attachment.attachmentType)
      return null
  }
}
