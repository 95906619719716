import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Session, adminOpenTab, markupMessage } from '../../shared/globals'
import { DialogCases } from '../dialogs'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

const localStyles = {
  root: {
    boxSizing: 'border-box',
    marginTop: 8,
    display:'flex',
    flexDirection:'column',
  },
}

const AdminCases = ({defaultToggleDrawer,searchStr}) => {
  const [, refreshState] = useState()
  const [dialogOpen,setDialogOpen] = useState('')
  const [toggleDrawer, setToggleHeader] = useState(defaultToggleDrawer)
  const [adminDoc,setAdminDoc] = useState({})
  const [currentCase, setCurrentCase] = useState('')

  const dialogShow = (dialog,phone) => {
    setAdminDoc(phone)
    setDialogOpen(dialog)
  }

  const dialogHide = () => {
    setAdminDoc({})
    setDialogOpen('')
  }

  const refresh = () => {
    refreshState(Date.now())
  }

  const renderCaret = () => {
    if (toggleDrawer === true) {
      return <ArrowDropDown />
    } else {
      return <ArrowDropUp />
    }
  }

  const userFullName = (uid) => {
    if(uid) {
      return _.trim(`${_.get(Session,`users.${uid}.profile.firstName`,'')} ${_.get(Session,`users.${uid}.profile.lastName`,'')}`)
    }
  }

  Session.refreshAdminCases=refresh

  if (Session.auth && _.get(Session,'userClaims.isAdmin',false)) {
    let filteredList = _.filter(Session.adminDocs,{docType:'case'})
    if(_.get(Session,'filters.createdBy',true)){
      filteredList = _.filter(filteredList,(doc)=>{
        return _.get(doc,'assignedTo','') === Session.uid || (_.get(doc,'assignedTo','') === '' && _.get(doc,'createdById','') === Session.uid)
        // return _.indexOf(_.get(doc,'assignedTo',[]),Session.uid) >= 0
      })
    }
    if(_.get(Session,'filters.open',true)){
      filteredList = _.filter(filteredList,(doc)=>{
        return _.get(doc,'status','') !== 'Closed'
      })
    }
    if(searchStr.length>0 || searchStr>0){
      filteredList = _.filter(filteredList,{userUid:_.get(Session,`userCount.C${searchStr}`,'ZZZ')})
    }
    if(filteredList.length===0) return null
    return (
      <div style={localStyles.root}>
        <div className='tabHeader' style={{height: '48px', fontSize:'16px',fontWeight:'bold',display:'flex',flexDirection:'row',whiteSpace: 'nowrap',alignItems:'center', backgroundColor: '#D3D3D3', borderRadius: '4px', paddingLeft: '10px', paddingRight: '10px', marginBottom: '2px', justifyContent: 'space-between', boxShadow: '0 2px 4px 0 rgba(80,80,80,0.5)', cursor: 'pointer'}} onClick={(e) =>{e.preventDefault(); setToggleHeader(!toggleDrawer)}}>
          <div className='tabHeaderText'>
            Cases
          </div>
          <div className='caretArrow'>
            { renderCaret() }
          </div>
        </div>
        <div id='caseList' style={{display: toggleDrawer === false ? 'none' : ''}}>
          {
            _.map(filteredList,(adminCase)=>{
              return (
                <div key={`CASE-${adminCase.id}`} onClick={()=>{setCurrentCase(adminCase);adminOpenTab(adminCase.userUid);dialogShow('DialogCases',adminCase)}} style={{cursor:'pointer',backgroundColor: currentCase === adminCase ? '#BFE3E8' : '#F5F5F5', boxShadow: '0 2px 4px 0 rgba(80,80,80,0.5)', borderRadius:4, marginTop:'6px', padding:'8px', display:'flex',flexDirection:'column'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div id='patientName' style={{marginTop: '2px', fontWeight: 'bold', fontSize: '12px'}}>
                      { userFullName(adminCase.userUid) }
                    </div>
                    <div id='priorityStatus' style={{marginTop: '2px', fontWeight: 'bold', fontSize: '12px'}}>
                      {_.get(adminCase,'priority','Low')} / {_.get(adminCase,'status','Open')}
                    </div>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div title="Assigned To" style={{color: '#504D4D', fontSize: '12px', marginTop: '6px', textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {userFullName(adminCase.assignedTo)}
                    </div>
                    <div title="Due Date" style={{fontWeight: adminCase.epoc < Date.now() ? 'bold':'normal',color: adminCase.epoc < Date.now() ? '#f50057':'#504D4D', fontSize: '12px', marginTop: '6px', textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {moment(adminCase.epoc).format('MM/DD/YYYY')}
                    </div>
                  </div>
                  <div style={{fontSize: '14px', marginTop: '6px',maxHeight:100, maxWidth:'100%',
                    textOverflow: 'ellipsis',
                    // whiteSpace: 'nowrap',
                    overflow:'hidden'}}>
                    {markupMessage('ZZZ',adminCase.message)}
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div title="Created By" style={{color: '#504D4D', fontSize: '12px', marginTop: '6px', textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {adminCase.createdByName}
                    </div>
                    <div title="Created Date" style={{color: '#504D4D', fontSize: '12px', marginTop: '6px', textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {moment(adminCase.createdDateEPOC).format('MM/DD/YYYY')}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        {dialogOpen!=='DialogCases'?null:<DialogCases open={dialogOpen==='DialogCases'} dialogParentHide={dialogHide} post={{id:adminDoc.postId}} adminDoc={adminDoc} user={{uid:adminDoc.userUid}} isDisabled={true}/>}
      </div>
    )
  } else {
    return null
  }
}
export default AdminCases
