import React, { useState } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles';
import 'emoji-mart/css/emoji-mart.css'
import data from "emoji-mart/data/facebook";
import { Emoji, NimblePicker } from 'emoji-mart'

//MATERIAL DESIGN IMPORTS
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase'
import Button from '@material-ui/core/Button'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import PhotoCameraOutlined from '@material-ui/icons/PhotoCameraOutlined'
import MicNone from '@material-ui/icons/MicNone'
import Public from '@material-ui/icons/Public'
import AttachFile from '@material-ui/icons/AttachFile'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import List from '@material-ui/icons/List'
import PollOutlined from '@material-ui/icons/PollOutlined'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'

import { Session, fileSize, allowedFileTypes, postComment, } from '../shared/globals'
import { DialogURL, DialogPoll, DialogTracker } from './dialogs'

const localStyles = {
  action: {flex:1,marginLeft:12,textOverflow: 'ellipsis',whiteSpace: 'nowrap',fontSize:14}
}
const StyledInputBase = withStyles({
  root: { fontSize: 13, letterSpacing: '-0.07px', lineHeight: '17px' },
})(InputBase);
const StyledThreadInput = withStyles({
  root: { fontSize: 15, letterSpacing: '-0.07px', lineHeight: '17px', width: '100%', maxlength: '999', },
})(InputBase);
const StyledButton = withStyles({
  root: {
    fontSize: 13,
    backgroundColor: 'rgb(245, 246, 247)',
    boxShadow: 'none',
    border: "1px solid #DDDFE2",
    textTransform: 'capitalize',
    borderRadius:16,
    margin:8,
    flex:1,
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start',
    // alignItems:'center',
    overflow:'hidden',
  },
  label:{
    overflow:'hidden',
  }
})(Button)
const LightTooltip = withStyles((theme: Theme) => ({
  popper: {
    zIndex:500,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    borderRadius: '3px',
    cursor: 'pointer',
    '& .feedbackItem': {
      height: '22px',
      paddingLeft: '2px',
      paddingRight: '2px',
      '&:hover': {
        background: "rgb(221, 223, 226)",
      }
    }
  },
}))(Tooltip);

export const ThreadPoster = ({user}) => {
  const [comment, updateComment] = useState('')
  const [, refreshState] = useState()
  const [open, setOpen] = useState(false)
  const [emojiList, toggleEmojiList] = useState(false)
  const addEmoji = (e) => {
    toggleEmojiList(false)
    if (e.unified.length <= 5) {
      let emojiPic = String.fromCodePoint(`0x${e.unified}`)
      updateComment(comment + emojiPic)
    } else {
      let sym = e.unified.split('-')
      let codesArray = []
      sym.forEach((el) => codesArray.push('0x' + el))
      let emojiPic = String.fromCodePoint(...codesArray)
      updateComment(comment + emojiPic)
    }
  }
  const addTempFiles = (files) => {
    _.map(files, (file, index) => {
      tempFiles.push({
        file: file,
        valid:
          file.size <= 50 * 1024 * 1024 &&
          _.indexOf(allowedFileTypes, file.type) >= 0
      })
    })
    refreshState(Date.now())
  }
  const [tempFiles, setTempFiles] = useState([])
  const [urlList,setURLList] = useState([])
  const [pollList,setPollList] = useState([])
  const [trackerList,setTrackerList] = useState([])
  const [dialogOpen,setDialogOpen] = useState('')
  const dialogShow = (dialog) => {
    setDialogOpen(dialog)
  }
  const dialogHide = () => {
    setDialogOpen('')
  }
  const postSuccess = () => {
    updateComment('')
    setOpen(false)
    // Session.posterModal(false)
    setURLList([])
    setPollList([])
    setTrackerList([])
    setTempFiles([])
  }
  const minimizeThreadPoster = () => {
    toggleEmojiList(false)
    if(dialogOpen) return
    if(open) {
      setOpen(false)
      // Session.posterModal(false)
    }
  }
  const maximizeThreadPoster = () => {
    if(dialogOpen) return
    if(!open){
      setOpen(true)
      // Session.posterModal(true)
    }
  }

  const styleThreadPoster = {
    cursor:'pointer',
    backgroundColor: 'white',
    zIndex:open?99:0,
    height: !open ? 200 : (_.size(urlList) + _.size(tempFiles) + _.size(pollList) + _.size(trackerList) > 0 ? 'auto':350),
    transition: 'height 0.25s ease-out',
    overflow:'hidden',
    border: "1px solid #8CB2D1",
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    borderRadius: 8,
    boxShadow: 'rgb(143, 154, 164) -1px 5px 9px'
  }
  return (
    <ClickAwayListener onClickAway={minimizeThreadPoster}>
      <div id="ThreadPoster" onClick={maximizeThreadPoster}
        style={styleThreadPoster}>
        <div style={{backgroundColor: '#8CB2D1', height: '34px'}} className="inputHeader">
          <div className="headerText" style={{paddingLeft: '10px', paddingTop: '7px', paddingBottom: '7px', height: '23px', fontWeight: 'bold', fontSize: '14px',color:'white', letterSpacing: '0.65px', lineHeight: '20px'}}>
            Create a New Topic
          </div>
        </div>
        <div className="userInfo" style={{display: 'flex', flexDirection: 'row', backgroundColor: 'white'}}>
          <div className="userAvatar" style={{padding: '10px 14px 29px'}}>
            <img style={{ width: 36, height: 36, borderRadius: 99,border:'solid 2px black',padding:4}} alt={''} src={_.get(Session.fbUser,'profileIcon','')}/>
          </div>
          <div className="userInput" style={{position: 'relative', height: open ? '100px' : '50px', width: '100%', paddingTop: '17px', paddingRight: '8px'}}>
            <StyledThreadInput id="outlined-textarea" inputProps={{ maxLength: 999 }} rows={5} rowsMax={5} value={comment} multiline placeholder='Ask anything...' onChange={(e) => { updateComment(e.target.value) }}/>
          </div>
        </div>
        <div id="commentEmojiButton" style={{ marginRight: '10px', cursor: 'pointer', display: open ? 'flex' : 'none', justifyContent: 'flex-end', paddingRight: '5px' }}>
          <Emoji emoji={{ id: 'grinning'}} size={20} onClick={() => toggleEmojiList(!emojiList)}/>
        </div>
        <NimblePicker style={{position: 'absolute', zIndex: 200, marginLeft: '170px', marginTop: '180px', display: emojiList ? '' : 'none'}} set='messenger' data={data} onSelect={addEmoji}/>
        <div style={{backgroundColor: 'white'}}>
          <hr style={{width: '95%', borderTop: '0.1px solid #DADDE0'}} />
        </div>
        <div className="attachmentButtons" style={{display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}>
          <div className="attachmentButtons" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', backgroundColor: 'white'}}>
            <StyledButton variant="outlined" size="small" component='label' color="default" aria-label="Add">
              <AttachFile />
              <div style={localStyles.action}>Attach File</div>
              <input multiple={true} type="file" style={{ display: 'none' }} onChange={(event) => addTempFiles(event.target.files)} />
            </StyledButton>
            <StyledButton variant="outlined" size="small" component='label' color="default" aria-label="Add">
              <PhotoCameraOutlined />
              <div style={localStyles.action}>Take Picture</div>
              <input type="file" accept="image/*" capture="camera" style={{ display: 'none' }} onChange={(event) => addTempFiles(event.target.files)}/>
            </StyledButton>
            <StyledButton variant="outlined" size="small" component='label' color="default" aria-label="Add">
              <MicNone/>
              <div style={localStyles.action}>Add Recording</div>
              <input type="file" accept="video/*" capture="camcorder" style={{ display: 'none' }} onChange={(event) => addTempFiles(event.target.files)}/>
            </StyledButton>
          </div>
          <div className="attachmentButtons" style={{display: open?'flex':'none', flexDirection: 'row', justifyContent: 'space-around', backgroundColor: 'white'}}>
            <StyledButton variant="outlined" size="small" component='label' color="default" aria-label="Add" onClick={()=>dialogShow('DialogTracker')}>
              <List/>
              <div style={localStyles.action}>Tracker</div>
            </StyledButton>
            <StyledButton variant="outlined" size="small" component='label' color="default" aria-label="Add" onClick={()=>dialogShow('DialogURL')}>
              <Public />
              <div style={localStyles.action}>Website</div>
            </StyledButton>
            {
              !_.get(Session,'userClaims.isAdmin',false) ? null :
              <StyledButton variant="outlined" size="small" component='label' color="default" aria-label="Add" onClick={()=>dialogShow('DialogPoll')}>
                <PollOutlined />
                <div style={localStyles.action}>Poll / Question</div>
              </StyledButton>
            }
          </div>
        </div>
        <hr style={{display: open ? '' : 'none', width: '100%', borderTop: '0.1px solid #DADDE0'}} />
        {
          !open?null:
          <PostSubmit user={user} comment={comment} thread={null} postSuccess={postSuccess} attachments={{urlList,pollList,tempFiles,trackerList}} />
        }
        <DialogURL open={dialogOpen==='DialogURL'} dialogParentHide={dialogHide} urlList={urlList}/>
        <DialogPoll open={dialogOpen==='DialogPoll'} dialogParentHide={dialogHide} pollList={pollList}/>
        {
          dialogOpen!=='DialogTracker'?null:
          <DialogTracker open={dialogOpen==='DialogTracker'} dialogParentHide={dialogHide} trackerList={trackerList}/>
        }
      </div>
    </ClickAwayListener>
  )
}

export const CommentPoster = ({ user, thread }) => {
  const [comment, updateComment] = useState('')
  const [, refreshState] = useState()
  const [busy,setBusy] = useState(false)
  const [open, setOpen] = useState(false)
  const [emojiList, toggleEmojiList] = useState(false)
  const addEmoji = (e) => {
    toggleEmojiList(false)
    if (e.unified.length <= 5) {
      let emojiPic = String.fromCodePoint(`0x${e.unified}`)
      updateComment(comment + emojiPic)
    } else {
      let sym = e.unified.split('-')
      let codesArray = []
      sym.forEach((el) => codesArray.push('0x' + el))
      let emojiPic = String.fromCodePoint(...codesArray)
      updateComment(comment + emojiPic)
    }
  }
  const addTempFiles = (files) => {
    _.map(files, (file, index) => {
      tempFiles.push({
        file: file,
        valid:
          file.size <= 50 * 1024 * 1024 &&
          _.indexOf(allowedFileTypes, file.type) >= 0
      })
    })
    refreshState(Date.now())
  }
  const [tempFiles, setTempFiles] = useState([])
  const [urlList,setURLList] = useState([])
  const [pollList,setPollList] = useState([])
  const [trackerList,setTrackerList] = useState([])
  const [dialogOpen,setDialogOpen] = useState('')
  const dialogShow = (dialog) => {
    setOpen(false)
    setDialogOpen(dialog)
  }
  const dialogHide = () => {
    setDialogOpen('')
  }
  const handleTooltipClose = () => {
    setOpen(false)
    toggleEmojiList(false)
  }
  const handleTooltipOpen = () => {
    setOpen(true)
  }
  const postSuccess = () => {
    setBusy(false)
    updateComment('')
    setURLList([])
    setPollList([])
    setTrackerList([])
    setTempFiles([])
    Session.refs[`THREADCOMMENT-${thread.id}`].scrollIntoView({behavior: 'smooth',block: 'end',inline: 'nearest'})
  }
  return (
    <div className="newComment" style={{marginTop: '7px'}} ref={(ref) => (Session.refs[`THREADCOMMENT-${thread.id}`] = ref)}>
      <div className="commentContents" style={{display: 'flex', padding: '5px 16px 0 10px', flexDirection: 'row'}}>
        {
          user.uid==='PUBLICBLOG' ? null :
          <div className="commentUserAvatar">
            <img style={{ width: 24, height: 24, borderRadius: 99,border:'solid 1.25px black',padding:3}} alt={''} src={_.get(Session.fbUser,'profileIcon','')}/>
          </div>
        }
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div id="inputTextField" style={{position: 'relative',marginLeft: '9px', paddingLeft: '8px', marginRight: '2px', display:'flex', flexDirection: 'column', backgroundColor: '#F5F6F7', borderRadius: '8px', width: '100%', border: '1px solid #DDDFE2', marginBottom: '10px'}}>
            <StyledInputBase id="outlined-textarea" inputProps={{ maxLength: 999 }} value={comment} multiline placeholder='Write a comment...' onChange={(e) => { updateComment(e.target.value) }} onKeyPress=
              {
                (event) => {
                  if(event.key === 'Enter' && _.trim(comment).length===0){
                    event.preventDefault()
                    event.stopPropagation()
                  }
                  if(event.key === 'Enter' && (_.trim(comment).length>0 || _.size(tempFiles) > 0 || _.size(urlList) > 0 || _.size(pollList) > 0) && !busy) {
                    event.preventDefault()
                    event.stopPropagation()
                    setBusy(true)
                    postComment(user.uid,_.trim(comment),thread,_.cloneDeep({urlList,pollList,tempFiles,trackerList}))
                      .then((postRef)=>{
                        // Session.refs[`THREADCOMMENT-${thread.id}`].scrollIntoView({behavior: 'smooth',block: 'end',inline: 'nearest'})
                      })
                      .catch((error)=>{
                        console.log('ERROR',error)
                      })
                    postSuccess()
                  }
                }
              }/>
            {
              user.uid==='PUBLICBLOG' ? null :
              <div id='inputActions' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <div id="commentEmojiButton" style={{ marginTop: '4px', marginRight: '5px', cursor: 'pointer' }}>
                  <Emoji emoji={{ id: 'grinning'}} size={20} onClick={() => toggleEmojiList(!emojiList)}/>
                </div>
                <NimblePicker style={{position: 'absolute', zIndex: 200, marginTop: '25px', display: emojiList ? '' : 'none'}} set='messenger' data={data} onSelect={addEmoji}/>
                <div>
                  <input id="icon-button-file" multiple={true} type="file" style={{ color: 'white', display: 'none' }} onChange={(event) => addTempFiles(event.target.files)} />
                  <label htmlFor="icon-button-file">
                    <IconButton size="small" component='span'>
                      <AttachFile />
                    </IconButton>
                  </label>
                </div>
                <div>
                  <input id="icon-button-file2" type="file" accept="image/*" capture="camera" style={{ color: 'white', display: 'none' }} onChange={(event) => addTempFiles(event.target.files)}/>
                  <label htmlFor="icon-button-file2">
                    <IconButton size="small" component='span'>
                      <PhotoCameraOutlined />
                    </IconButton>
                  </label>
                </div>
                <div>
                  <input id="icon-button-file3" type="file" accept="video/*" capture="camcorder" style={{ color: 'white', display: 'none' }} onChange={(event) => addTempFiles(event.target.files)}/>
                  <label htmlFor="icon-button-file3">
                    <IconButton size="small" component='span'>
                      <MicNone />
                    </IconButton>
                  </label>
                </div>
                <div id="feedbackButton" style={{cursor: 'pointer', verticalAlign: 'center', fontSize: 'inherit'}}>
                    <LightTooltip PopperProps={{ disablePortal: true }} onClose={handleTooltipClose} open={open} disableFocusListener disableHoverListener disableTouchListener placement='bottom' interactive title={
                      <React.Fragment>
                        <div id='feedbackItemList' style={{ display: 'flex', flexDirection: 'column', width: 240, letterSpacing: '-0.07px' }}>
                          <div className="feedbackItem" id='feedbackItemRemove' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} onClick={()=>dialogShow('DialogTracker')}>
                            <List />
                            <div id='moreOptionsEdit' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                              Tracker
                            </div>
                          </div>
                          <div className="feedbackItem" id='addEvent' style={{ color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '3px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}} onClick={()=>dialogShow('DialogURL')}>
                            <Public />
                            <div id='moreOptionsEvent' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                              Website
                            </div>
                          </div>
                          {
                            !_.get(Session,'userClaims.isAdmin',false) ? null :
                            <div className="feedbackItem" id='addEvent' style={{ color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '3px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}} onClick={()=>dialogShow('DialogPoll')}>
                              <PollOutlined />
                              <div id='moreOptionsEvent' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                                Poll / Question
                              </div>
                            </div>
                          }
                        </div>
                      </React.Fragment>
                    }>
                      <IconButton size='small' onClick={handleTooltipOpen}>
                        <MoreHoriz/>
                      </IconButton>
                    </LightTooltip>
                  </div>
              </div>
            }
          </div>
        </ClickAwayListener>
      </div>
      <PostSubmit user={user} comment={comment} thread={thread} postSuccess={postSuccess} attachments={{urlList,pollList,tempFiles,trackerList}} />
      <DialogURL open={dialogOpen==='DialogURL'} dialogParentHide={dialogHide} urlList={urlList}/>
      <DialogPoll open={dialogOpen==='DialogPoll'} dialogParentHide={dialogHide} pollList={pollList}/>
      {
        dialogOpen!=='DialogTracker'?null:
        <DialogTracker open={dialogOpen==='DialogTracker'} dialogParentHide={dialogHide} trackerList={trackerList}/>
      }
    </div>
  )
}

const PostSubmit = ({user,comment,thread,postSuccess,attachments}) => {
  const [,refreshState] = useState()
  const [busy,setBusy] = useState(false)

  if(thread && !(comment.length>0 || _.size(attachments.tempFiles)>0 || _.size(attachments.urlList)>0 || _.size(attachments.pollList)>0 || _.size(attachments.trackerList)>0)){
    return null
  }

  return (
    <div id='submitButtonArea' style={{display: 'flex', flexDirection: 'column',padding:4}}>
      {
        _.map(attachments.urlList, (url, index)=>{
          return (
            <div key={`URL-${index}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 4}}>
              <Public />
              <div style={{flex:1,overflowWrap:'break-word'}}>
                {url}
              </div>
              <RemoveCircle
                onClick={() => {
                  attachments.urlList.splice(index,1)
                  refreshState(Date.now())
                }}
                style={{ fontSize: 14, color: 'red', cursor: 'pointer' }}
              />
            </div>
          )
        })
      }
      {
        _.map(attachments.pollList, (poll, index)=>{
          return (
            <div key={`POLL-${index}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 4}}>
              <PollOutlined />
              <div style={{flex:1,overflowWrap:'break-word',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{flex:1}}>{poll.question}</div>
                <div style={{flex:2,display:'flex',flexDirection:'row'}}>
                  {
                    _.map(poll.options,(option,index)=>{
                      return <Fab key={`OPTION-${index}`} title={option} style={{ flex:1, textAlign:'left',textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden',margin:4,padding:2,maxHeight:14,borderRadius:16,backgroundColor: '#03DAC4',color: 'white',fontWeight:'bold',fontSize:12}} aria-label="Add" >{option}</Fab>
                    })
                  }
                </div>
              </div>
              <RemoveCircle
                onClick={() => {
                  attachments.pollList.splice(index,1)
                  refreshState(Date.now())
                }}
                style={{ fontSize: 14, color: 'red', cursor: 'pointer' }}
              />
            </div>
          )
        })
      }
      {
        _.map(attachments.trackerList, (tracker, index)=>{
          return (
            <div key={`TRACKER-${index}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 4}}>
              <List />
              <div style={{flex:1,overflowWrap:'break-word'}}>
                {tracker}
              </div>
              <RemoveCircle
                onClick={() => {
                  attachments.trackerList.splice(index,1)
                  refreshState(Date.now())
                }}
                style={{ fontSize: 14, color: 'red', cursor: 'pointer' }}
              />
            </div>
          )
        })
      }
      {_.map(attachments.tempFiles, (file, index) => {
        if (!file.valid) {
          console.log(file.file.type)
        }
        return (
          <div key={`FILE-${index}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 4}}>
            <AttachFile />
            <div style={{flex:1,overflowWrap:'break-word'}}>
              {file.file.name} {fileSize(file.file.size)}
              {file.valid ? null : (
                <span style={{color: 'red', fontSize: 'x-small', fontWeight: 'bold'}}>
              {' '}
                  (This file will not be uploaded)
            </span>
              )}
            </div>
            <RemoveCircle
              onClick={() => {
                attachments.tempFiles.splice(index,1)
                refreshState(Date.now())
              }}
              style={{ fontSize: 14, color: 'red', cursor: 'pointer' }}
            />
          </div>
        )
      })}
      <Button
        id="submit-comment"
        type="submit"
        color='primary'
        variant="contained"
        fullWidth
        style={{backgroundColor: (_.trim(comment).length + _.size(attachments.tempFiles) + _.size(attachments.urlList) + _.size(attachments.pollList) + _.size(attachments.trackerList) === 0) || busy ? '#F5F5F7' : 'rgb(81, 183, 197)'}}
        disabled={(_.trim(comment).length + _.size(attachments.tempFiles) + _.size(attachments.urlList) + _.size(attachments.pollList) + _.size(attachments.trackerList) === 0) || busy}
        onClick={() => {
          if(!busy){
            setBusy(true)
            postComment(user.uid,_.trim(comment),thread,_.cloneDeep(attachments))
              .then((postRef)=>{
              })
              .catch((error)=>{
                console.log('ERROR',error)
              })
            postSuccess()
            setBusy(false)
          }
        }}
      >
        {thread?'POST COMMENT':'CREATE POST'}
      </Button>
    </div>
  )
}
