import React, {useState} from 'react'
import _ from 'lodash'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined'
import Paper from '@material-ui/core/Paper'
import Slider from '@material-ui/lab/Slider'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Button from '@material-ui/core/Button'

import {Session,postUpdateTracker} from '../../shared/globals'

const useStyles = makeStyles({
  root: {
    flex:1,
    // width: 300,
    // maxWidth:'90%',
    padding: 24,
    // paddingRight:48,
    minHeight:30,
    display:'flex',
    flexDirection:'row',
    alignItems:'flex-end',
    position:'relative',
    marginRight:16,
  },
  slider: {
    padding: '22px 0px',
  },
  thumbIcon: {
    borderRadius: '50%',
  },
})

const StyledSlider = withStyles({
  thumbIconWrapper: {
    backgroundColor: '#fff',
  },
  thumb: {
    height: '50px',
    width: '50px',
    boxSizing:'border-box',
    backgroundColor: 'transparent',
    '&$focused, &:hover': {
      boxShadow: `inset 0px 0px 0px ${25}px ${fade('#2B8CA6', 0.16)}`,
      boxSizing:'border-box',
      backgroundColor: 'transparent',
    },
    '&$activated': {
      boxShadow: `0px 0px 0px ${8 * 1.5}px ${fade('#2B8CA6', 0.16)}`,
    },
    '&$jumped': {
      boxShadow: `0px 0px 0px ${8 * 1.5}px ${fade('#2B8CA6', 0.16)}`,
    },
  },
  track: {
    backgroundColor: '#2B8CA6',
    height: 8,
  },
  trackAfter: {
    backgroundColor: '#d0d7dc',
  },
  focused: {},
  activated: {},
  jumped: {},
})(Slider)

const Tracker = ({user,attachment,refreshParentState}) => {
  const classes = useStyles()
  const [,refreshState] = useState()
  const [backup,setBackup] = useState(_.cloneDeep(attachment.attachmentData))
  const [editMode,setEditMode] = useState(!_.get(attachment,'attachmentData.isAnswered',false))

  const handleChange = (event, newValue) => {
    if(!editMode || (_.get(Session,'userClaims.isAdmin',false) && user.uid !== Session.uid)) return
    _.set(attachment,'attachmentData.value',newValue)
    refreshState(Date.now())
  }
  const handleReset = () => {
    if(!editMode) return
    setEditMode(!_.get(attachment,'attachmentData.isAnswered',false))
    _.set(attachment,'attachmentData',_.cloneDeep(backup))
    refreshState(Date.now())
  }
  const handleEdit = (e) => {
    setBackup(_.cloneDeep(attachment.attachmentData))
    _.set(attachment,'attachmentData.value',null)
    setEditMode(true)
  }
  const handleSave = (e) => {
    if(!editMode) return
    postUpdateTracker(user.uid,attachment,{
      isAnswered:true,
      value:attachment.attachmentData.value,
    })
    setEditMode(false)
    e.preventDefault()
    e.stopPropagation()
  }
  return (
    <div style={{flex:1}}>
      <ClickAwayListener onClickAway={handleReset}>
        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
          <div style={{flex:1,display:'flex',flexDirection:'column'}}>
            <div style={{marginBottom:8}}>{attachment.attachmentName}</div>
            <Paper className={classes.root}>
              <StyledSlider
                min={0} max={10} step={1} aria-labelledby="label"
                classes={{ thumbIconWrapper: classes.thumbIconWrapper }}
                value={_.isNull(attachment.attachmentData.value)?5:_.get(attachment,'attachmentData.value',5)} onChange={handleChange}
                thumb={
                  <div style={{ position:'relative',display:'flex' }}>
                    <LocationOnOutlined style={{ color: '#2B8CA6',fontSize:'50px',marginTop:'-50%' }} />
                    <div style={{minWidth:18,position:'absolute',backgroundColor:'white',borderRadius:8,color:'black',fontWeight:'bold',fontSize:16,left: '50%',top: '-10%', transform: 'translate(-50%,-50%)'}}>{_.get(attachment,'attachmentData.value','')}</div>
                  </div>
                }
               />
               <div style={{}}>
                 <div style={{width:60,marginLeft:16}}>
                 {
                   (_.get(Session,'userClaims.isAdmin',false) && user.uid !== Session.uid) || _.isNull(attachment.attachmentData.value) ? null :
                   editMode ?
                   <Button style={{border:'solid 1px #DDDFE2',padding:2,backgroundColor:'white',color:'#757d8a',fontWeight:500,fontSize:12}} onClick={handleSave}>
                     Save
                   </Button>
                   :
                   <Button style={{border:'solid 1px #DDDFE2',padding:2,backgroundColor:'white',color:'#757d8a',fontWeight:500,fontSize:12}} onClick={handleEdit} >
                     Reset
                   </Button>
                  //  <Tooltip title={'Save'} placement="top">
                  //    <StyledIconButton size='small' onClick={handleSave} >
                  //      <CheckCircleOutlined style={{fontSize:30}}/>
                  //    </StyledIconButton>
                  //  </Tooltip>
                  //  :
                  //  <Tooltip title={'Reset'} placement="top">
                  //    <StyledIconButton size='small' onClick={handleEdit} >
                  //      <RemoveCircleOutline style={{fontSize:30}}/>
                  //    </StyledIconButton>
                  //  </Tooltip>
                 }
               </div>
             </div>
            </Paper>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default Tracker
