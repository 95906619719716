import React, {useState} from 'react'
import _ from 'lodash'
import firebase from 'firebase'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Checkbox from '@material-ui/core/Checkbox'

import { Session, userUpdate } from '../../shared/globals'
import Responsive from 'react-responsive'

const Mobile = props => <Responsive {...props} maxWidth={499} />;
const Default = props => <Responsive {...props} minWidth={500} />;

const DialogAccept = ({ match,open,user,dialogParentHide }) => {
  return (
    <div>
      <Mobile>
        <MobileView match={match} open={open} user={user} dialogParentHide={dialogParentHide} />
      </Mobile>
      <Default>
        <WebView match={match} open={open} user={user} dialogParentHide={dialogParentHide} />
      </Default>
    </div>
  )
}
const localStyles = {
  dialogHolder:{
    backgroundColor:'#007A8A',
    padding:16,
    borderRadius:8,
    minWidth:400,
    maxWidth:600,
    minHeight:400,
    maxHeight:700,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
  },
  dialogContent:{
    flex:1,
    backgroundColor:'white',
    borderRadius:8,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.5)',
    fontWeight:200,
    display:'flex',
    padding:32,
    flexDirection:'column',
  },
}
const WebView = ({open,user,dialogParentHide}) => {
  const [privacy,setPrivacy] = useState(_.get(user,'acceptPrivacy',false))
  const [terms,setTerms] = useState(_.get(user,'acceptTerms',false))
  const dialogHide = () => {
    dialogParentHide()
  }
  return (
    <Dialog key={'WS'} fullWidth={true} maxWidth={'xs'} open={true} disableBackdropClick={true} disableEscapeKeyDown={true} onClose={dialogHide} PaperProps={{ style: localStyles.dialogHolder }}>
      <div style={localStyles.dialogContent}>
        <div style={{flex:1}}></div>
        <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
          <div style={{color:'#007A8A',fontSize:36,fontWeight:200}}>Please review and accept our Terms & Conditions</div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div id='markCheckBox' style={{cursor:'pointer',marginTop:16, fontSize: 20, fontWeight: 'bold', verticalAlign: 'center'}}>
              <Checkbox checked={terms} onChange={(e) => {
                if(!terms){
                  window.open('/terms', '_blank', 'top=250,left=250,width=500,height=500')
                }
                setTerms(!terms)
                }}
              />
              ACCEPT
            </div>
          </div>
        </div>
        <div style={{flex:1}}></div>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
          <Button style={{visibility:'hidden',marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}}>
            Back
          </Button>
          <Button style={{marginLeft:8,backgroundColor:!terms?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
            disabled={!terms}
            onClick={()=>{
              if(Session.userStatusDatabaseRef){
                Session.userStatusDatabaseRef.set({
                  presenceState: 'online',
                  presenceLastChanged: firebase.database.ServerValue.TIMESTAMP,
                })
              }
              userUpdate(user.uid,{
                presenceState: 'online',
                acceptTerms: terms,
                lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                lastActivityAction:`Accepted T&C`
              })
            }}>
            Continue
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
const MobileView = ({open,user,dialogParentHide}) => {
  const [privacy,setPrivacy] = useState(_.get(user,'acceptPrivacy',false))
  const [terms,setTerms] = useState(_.get(user,'acceptTerms',false))
  const dialogHide = () => {
    dialogParentHide()
  }
  return (
    <Dialog key={'WS'} fullWidth={true} maxWidth={'xs'} open={true} disableBackdropClick={true} disableEscapeKeyDown={true} onClose={dialogHide} PaperProps={{ style: localStyles.dialogHolder }}>
      <div style={localStyles.dialogContent}>
        <div style={{flex:1}}></div>
        <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
          <div style={{color:'#007A8A',fontSize:36,fontWeight:200}}>Please review and accept our Terms & Conditions</div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div id='markCheckBox' style={{cursor:'pointer',marginTop:16, fontSize: 20, fontWeight: 'bold', verticalAlign: 'center'}}>
              <Checkbox checked={terms} onChange={(e) => {
                setTerms(!terms)
                }}
              />
              ACCEPT
            </div>
          </div>
          <a href="/terms" target="_blank">Click here to view Terms & Conditions</a>
        </div>
        <div style={{flex:1}}></div>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
          <Button style={{visibility:'hidden',marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}}>
            Back
          </Button>
          <Button style={{marginLeft:8,backgroundColor:!terms?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
            disabled={!terms}
            onClick={()=>{
              if(Session.userStatusDatabaseRef){
                Session.userStatusDatabaseRef.set({
                  presenceState: 'online',
                  presenceLastChanged: firebase.database.ServerValue.TIMESTAMP,
                })
              }
              userUpdate(user.uid,{
                presenceState: 'online',
                acceptTerms: terms,
                lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                lastActivityAction:`Accepted T&C`
              })
            }}>
            Continue
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
export default DialogAccept
