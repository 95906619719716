import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export const DialogURL = ({open,urlList,dialogParentHide}) => {
  const [url,setURL] = useState('')
  const dialogHide = () => {
    setURL('')
    dialogParentHide()
  }
  const dialogUpdate = () => {
    if(url.match(/\bhttps?:\/\/\S+/gi)){
      urlList.push(url.match(/\bhttps?:\/\/\S+/gi)[0])
    }
    dialogHide()
  }

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogHide} aria-labelledby="form-dialog-title">
        <DialogTitle>Add Website URL to Post</DialogTitle>
        {
          // <DialogContentText>This allows you to save your favorite websites for future use, or some might be sent to you from your advocates</DialogContentText>
        }
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Make sure to enter a full website url.<br/>
            It must start with "HTTP://" or "HTTPS://"
          </DialogContentText>
          <TextField
            autoFocus fullWidth margin="normal" variant="outlined" inputProps={{ maxLength: 250 }} InputLabelProps={{ shrink: true }}
            label="Website URL"
            placeholder="https://www.cancer.chat"
            value={url}
            onChange={(e)=>setURL(e.target.value)}
            error={!url.match(/\bhttps?:\/\/\S+/gi)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogHide} color="primary">
            CANCEL
          </Button>
          <Button onClick={dialogUpdate} color="primary" disabled={!url.match(/\bhttps?:\/\/\S+/gi)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
  )
}
export default DialogURL
