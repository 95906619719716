import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Session, postUpdate } from '../../shared/globals'

export const DialogEditMessage = ({open,post,user,dialogParentHide}) => {
  const [message,setMessage] = useState(post.message)
  const dialogHide = () => {
    setMessage('')
    dialogParentHide()
  }
  const dialogUpdate = () => {
    postUpdate(user.uid,post,message).then(()=>{
      dialogHide()
    }).catch((error)=>{
      console.log('DialogEditMessage:error',error)
      dialogHide()
    })
  }

  if(post.createdById !== Session.uid) return null
  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogHide} aria-labelledby="form-dialog-title">
      <DialogTitle>Update Message</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Message"
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={3}
          rowsMax={10}
          inputProps={{ maxLength: 999 }}
          onChange={(e)=>setMessage(e.target.value)}
          value={message}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogHide} color="primary">
          CANCEL
        </Button>
        <Button onClick={dialogUpdate} color="primary" disabled={message.length === 0}>
          UPDATE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogEditMessage
