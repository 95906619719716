import React, { useState } from 'react'
import _ from 'lodash'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { userLogin, userFacebookLogin, userGoogleLogin, userRegister, userSendPasswordResetEmail } from '../shared/globals.js'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    facebookButton: {
      backgroundColor: '#1877f2',
      color: 'white',
      fontWeight: 'bold',
      marginBottom: '10px',
      '&:hover': {
        backgroundColor: '#1877f2',
      }
    },
    googleButton: {
      color: 'black',
      backgroundColor: 'white',
      fontWeight: 'bold',
      '&:hover:': {
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 3px #ccc'
      }
    },
  }),
)

const StyledTextField = withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: '4px',
    '& label.Mui-focused': {
      color: '#6BC1F6',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#6BC1F6',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#979797',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6BC1F6',
      },
    },
  },
})(TextField);

const localStyles = {
  root: { padding: 16, flex:1 ,display:'flex',flexDirection:'column',alignItems:'center'},
  loginModal: {paddingTop: '14px', paddingBottom: '14px', backgroundColor: '#6BC1F6', width:'50%',minWidth:300, maxWidth: 500, display:'flex', flexDirection:'column', marginTop:'20px', height: 'auto', borderRadius: '4px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)'}
}

const Login = ({ history,login }) => {
  const [email, setEmail] = useState('')
  const [busy, setBusy] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [signUp,setSignUp] = useState(!login)
  const classes = useStyles()

  const isDisabled = () => {
    return !((signUp && password===confirmPassword && _.trim(email).length>0 && _.trim(password).length>0) || (!signUp && _.trim(email).length>0 && _.trim(password).length>0))
  }

  const headerTitle = () => {
    return signUp ? 'Sign Up' : 'Log in'
  }

  return (
    <div style={localStyles.root}>
      <div style={localStyles.loginModal}>
        <div id='loginHeaderAndToggle' style={{paddingLeft: '15px', paddingRight: '15px', justifyContent: 'flex-start'}}>
          <div id='loginHeader' style={{ marginTop: '5px', height: '35px', color: '#FFFFFF', fontSize: '30px', fontWeight: 'bold', letterSpacing: '1.4px', lineHeight: '35px'}}>
            {headerTitle()}
          </div>
          <div id='signUpToggle' onClick={()=>setSignUp(!signUp)} style={{ cursor: 'pointer', marginTop: '5px', textDecoration: 'underline', color: '#4E6CB4'}}>
            {signUp ? 'Already have an account?' : `No Account? Sign Up`}
          </div>
        </div>
        <div id='userFields' style={{marginTop: '15px', paddingTop: '10px', paddingBottom: '25px', paddingLeft: '15px', paddingRight: '15px', backgroundColor: '#F7F7F7'}}>
          <StyledTextField autoFocus variant='outlined' margin='normal' required fullWidth id='email'
            label='Email Address' autoComplete='email'
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            />
          <StyledTextField variant='outlined' margin='normal' required fullWidth name='password' type='password' id='password'
            label='Password' autoComplete='current-password'
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            />
          {
            !signUp ? null :
            <StyledTextField variant='outlined' margin='normal' required fullWidth name='confirmPassword' type='password' id='confirmPassword'
              label='Confirm Password' autoComplete='current-password'
              value={confirmPassword}
              onChange={(e)=>setConfirmPassword(e.target.value)}
              />
          }
          <br/>
          <Button style={{ marginTop: '10px', height: '50px', color: isDisabled() ? '#FFFFFF' : '#FFFFFF', fontSize: '19px', backgroundColor: isDisabled() ? 'rgba(107, 193, 246, 0.29)' : '#6BC1F6' }} fullWidth variant='contained'
            disabled={isDisabled()}
            onClick={() => {
              if(busy) return
              setBusy(true)
              if(!signUp){
                userLogin(email, password)
                  .then(() => {
                    setErrorMessage('')
                  })
                  .catch(({code,message}) => {
                    console.log('error',code,message)
                    setBusy(false)
                    setErrorMessage(message)
                  })
              }else{
                userRegister(email, password)
                  .then(() => {
                    setErrorMessage('')
                  })
                  .catch(({code,message}) => {
                    console.log('error',code,message)
                    setBusy(false)
                    setErrorMessage(message)
                  })
              }
            }}>
            {headerTitle()}
          </Button>
          <div style={{paddingTop:16,color:'red'}}>
            {errorMessage}
          </div>
        </div>
        {
          /*
          <div id='socialMediaButtons' style={{paddingLeft: '15px', paddingRight: '15px'}}>
            <div id='separatorText' style={{marginTop: '35px', marginBottom: '35px', textAlign: 'center', fontSize: '30px', height: '30px'}}>
              OR
            </div>
            <Button fullWidth variant='contained' onClick={() => userFacebookLogin()} className={classes.facebookButton}>
              <img src={require('../shared/assets/fb_logo.png')} alt="" style={{height: '25px'}} />
              Continue with Facebook
            </Button>
            <Button fullWidth variant='outlined' onClick={() => userGoogleLogin()} className={classes.googleButton}>
              <img src={require('../shared/assets/google_logo.png')} alt="" style={{height: '25px'}} />
              Continue with Google
            </Button>
          </div>
          */
        }
        <br/>
        <br/>
      </div>
      <div style={{marginTop: '8px', display: 'flex', justifyContent: 'flex-end'}}>
        <div style={{textAlign: 'right', cursor:'pointer', textDecoration: 'underline', color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px'}} onClick={()=>userSendPasswordResetEmail()}>
          {!signUp ? 'Forgot password?' : null}
        </div>
      </div>
    </div>
  )
}
export default Login
