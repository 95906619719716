import React, { useState } from 'react';
import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton';
import PhoneOutlined from '@material-ui/icons/PhoneOutlined'

import { Session } from '../../shared/globals'
import { DialogPhoneLog } from '../dialogs'

const PhoneBadge = ({ user, post }) => {
  const [dialogOpen,setDialogOpen] = useState('')
  const dialogHide = () => {
    setDialogOpen('')
  }

  if(_.filter(_.get(Session,'adminDocs',{}),{postId:post.id,docType:'phone'}).length===0 || !_.get(Session,'userClaims.isAdmin',false)){
    return null
  }else{
    return (
        <div id="feedbackButton" style={{cursor: 'pointer', verticalAlign: 'center', fontSize: 'inherit'}}>
          <IconButton size='small' onClick={()=>setDialogOpen('DialogPhoneLog')}>
            <PhoneOutlined style={{fontSize:16,cursor:'pointer'}} />
          </IconButton>
          {dialogOpen!=='DialogPhoneLog'?null:<DialogPhoneLog open={dialogOpen==='DialogPhoneLog'} dialogParentHide={dialogHide} post={post} adminDoc={_.get(_.filter(Session.adminDocs,{postId:post.id,docType:'phone'}),'[0]',{id:null})} user={user} isDisabled={true}/>}
        </div>
      )
  }
}

export default PhoneBadge
