import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Session } from '../shared/globals'
import { Link } from 'react-router-dom'

const Public = () => {
  return (
    <div style={{ flex:1 ,display:'flex',flexDirection:'column',alignItems:'center' }}>
      <div ref={(ref) => (Session.refs['public1'] = ref)} style={{ width: '100%', height: '450px'}}>
        <div style={{display: 'flex', flexDirection: 'row', padding: '70px 90px'}}>
          <div id='textContents' style={{display: 'flex', flexDirection: 'column', marginRight: '30px'}}>
            <div id='itemHeader' style={{ color: '#4E6CB4', fontSize: '43px', letterSpacing: '1.37px', lineHeight: '50px' }}>
              For everything your doctors can't help you with
            </div>
            <div id='itemBody' style={{minWidth: '363px', color: '#4E6CB4', marginTop: '20px', fontSize: '20px', letterSpacing: '1.03px', lineHeight: '24px'}}>
              And this is a short paragraph that explains what we do with a few details and that's it.
            </div>
            <div id='itemButton'>
            </div>
            <Link to='/login'>
              <button style={{marginTop: '99px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '116px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
                Join Now
              </button>
            </Link>
          </div>
          <div id='itemPhoto' style={{marginTop: '10px', zIndex: '200'}}>
            <img src={require('../shared/assets/lightbulb.png')} alt="" style={{ height: '440px', width: '440px' }} />
          </div>
        </div>
      </div>
      <div ref={(ref) => (Session.refs['public2'] = ref)} style={{ height: 'auto', backgroundColor: '#FDAAAA' }}>
        <div style={{display: 'flex', flexDirection: 'row', padding: '70px 90px'}}>
          <div id='itemPhoto'>
            <img src={require('../shared/assets/market_chat.png')} alt="" style={{ height: '350px', width: '200px' }} />
          </div>
          <div id='textContents' style={{display: 'flex', flexDirection: 'column', marginLeft: '50px', marginTop: '70px'}}>
            <div id='itemHeader' style={{ color: '#4E6CB4', fontSize: '43px', letterSpacing: '1.37px', lineHeight: '50px' }}>
              A familiar way to share your experience
            </div>
            <div id='itemBody' style={{color: '#4E6CB4', marginTop: '23px', fontSize: '20px', letterSpacing: '1.03px', lineHeight: '24px'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
            <div id='actionButtons' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '20px'}}>
              <Link to='/blog'>
                <button style={{marginRight: '20px', marginTop: '99px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '161px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
                  SEE THE BLOG
                </button>
              </Link>
              <button style={{marginTop: '99px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '116px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
                Join Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref={(ref) => (Session.refs['public3'] = ref)} style={{ height: '510px', padding: '70px' }}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <div id='valueProp1' style={{display: 'flex', flexDirection: 'column', padding: '10px', minWidth: '300px', maxWidth: '300px', marginRight: '100px'}}>
            <div id='valueImage' style={{padding: '5px', alignSelf: 'center'}}>
              <img src={require('../shared/assets/customer_support.png')} alt="" style={{ height: '270px', width: '250px' }} />
            </div>
            <div id='valueHeader' style={{marginTop: '8px', marginBottom: '8px', height: '50px', color: '#4E6CB4', fontSize: '38px', letterSpacing: '1.21px', lineHeight: '44px', textAlign: 'center'}}>
              Life Coaching
            </div>
            <div id='valueBody' style={{color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px', textAlign: 'center'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
          </div>
          <div id='valueProp2' style={{display: 'flex', flexDirection: 'column', padding: '10px', minWidth: '300px', maxWidth: '300px'}}>
            <div id='valueImage' style={{padding: '5px', alignSelf: 'center'}}>
              <img src={require('../shared/assets/zenpicture.png')} alt="" style={{ height: '270px', width: '250px' }} />
            </div>
            <div id='valueHeader' style={{marginTop: '8px', marginBottom: '8px', height: '50px', color: '#4E6CB4', fontSize: '38px', letterSpacing: '1.21px', lineHeight: '44px', textAlign: 'center'}}>
              No Cost
            </div>
            <div id='valueBody' style={{color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px', textAlign: 'center'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
          </div>
        </div>
        <div id='actionButtonCentered' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <button style={{marginTop: '30px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '116px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
            Join Now
          </button>
        </div>
      </div>
      <div ref={(ref) => (Session.refs['public4'] = ref)} style={{ height: '427px', backgroundColor: '#F0E3F6', padding: '70px' }}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <blockquote style={{fontSize: '26px', fontStyle: 'italic', letterSpacing: '1.34px', lineHeight: '30px', textAlign: 'center', color: '#4E6CB4'}}>
            "Social confirmation quote from a RC Patient. It really helped me a lot and I discovered new ways to deal with my cancer"
          </blockquote>
          <div id='quoteAuthor' style={{display: 'flex', justifyContent: 'flex-end', height: '33px', color: '#4E6CB4', fontSize: '25px', letterSpacing: '1.34px', lineHeight: '29px', fontWeight: 'bold'}}>
            Robin Patient
          </div>
        </div>
      </div>
      <div id='stockPeopleSection' style={{width: '100%', height: '250px'}}>
        <img src={require('../shared/assets/stock_people.png')} alt="" style={{ width: '100%' }} />
      </div>
      <div ref={(ref) => (Session.refs['public5'] = ref)} style={{backgroundColor: '#F5F6F7', height: '427px', width: '100%', padding: '50px' }}>
        <div id='sectionHeader' style={{textAlign: 'center', color: '#4E6CB4', justifyContent: 'center', fontSize: '38px', letterSpacing: '1.21px', lineHeight: '44px', height: '50px'}}>
          First value prop
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <div id='valueBody' style={{padding: '10px', minWidth: '300px', maxWidth: '300px', color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px', textAlign: 'center'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
            <div id='valueBody' style={{padding: '10px', minWidth: '300px', maxWidth: '300px', color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px', textAlign: 'center'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
        </div>
        <div id='actionButtons' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <button style={{marginTop: '99px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '116px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
            Join Now
          </button>
        </div>
      </div>
      <div id='footer' style={{ height: '600px'}}>
        <div id='footerContents'>
          Footer
        </div>
      </div>
    </div>
  )
}
export default Public
