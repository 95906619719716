import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import Tooltip from '@material-ui/core/Tooltip'

import { Session, userLogout, debounceUserProfileIcon } from '../shared/globals'

const localStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    zIndex:99,
    backgroundColor: '#6BC1F6',
    alignItems: 'center',
    maxHeight: 80,
    padding: '8px 12px',
    justifyContent:'space-between'
  },
  logoHolder: {
    boxSizing: 'border-box',
    minWidth: '20%',
    maxWidth: '20%',
    color: 'white',
    cursor:'pointer',
  },
  topNav: {
    flex:1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  topNavItem: {
    marginRight: 16,
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
   }
}
const AfterHours = () => {
  return (
    <div style={{display:'flex',flexDirection:'row',backgroundColor:'#FF74D8',color:'white',fontSize:14,fontWeight:600,alignItems:'center',justifyContent:'center',maxHeight:80,padding:8}}>
      Expanding to 24/7 coverage soon! For now expect responses from M-F 9AM to 9PM ET
    </div>
  )
}
const Header = ({ history }) => {
  if(_.get(history,`location.pathname`,'/').split('/')[1] === 'employer' || _.get(history,`location.pathname`,'/').split('/')[1] === 'broker'){
    return null
  }
  if (Session.auth && Session.fbUser) {
    const currentDay = moment().tz('America/New_York').day()
    const currentHour = moment().tz('America/New_York').hour()
    const userName = _.get(Session.oauthProfile, 'profile.given_name') || _.get(Session.oauthProfile, 'profile.first_name') || _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName','')
    return (
      <div style={{display:'flex',flexDirection:'column'}}>
        <div style={localStyles.root}>
          <div style={localStyles.logoHolder} onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            history.push('/login')
            }}>
            <img
              src={require('../shared/assets/rc/RobinCare_logo_menuBar.png')}
              alt=""
              style={{ maxWidth: '100%' }}
            />
          </div>
          <div style={localStyles.topNav}>
            <div style={localStyles.topNavItem} onClick={() => history.push('/')}>
              {
                _.get(Session,'userClaims.isAdmin',false) ?
                <Tooltip title={'Sign Out'} placement="top">
                  <img src={_.get(Session.fbUser,'profileIcon','')} alt="" onError={()=>debounceUserProfileIcon(Session.fbUser)}
                    style={{ maxHeight: 20, backgroundColor: 'white', border: 'solid 1px black', padding: 2, borderRadius: 99 }}
                    onClick={(e) =>
                      userLogout()
                        .then(() => {
                          history.push('/login')
                        })
                        .catch((e) => {})
                    }
                  />
                </Tooltip>
                :
                <Tooltip title={'Goto Profile'} placement="top">
                  <div onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    history.push('/profile')
                  }}>
                    <img src={_.get(Session.fbUser,'profileIcon','')} alt="" onError={()=>debounceUserProfileIcon(Session.fbUser)}
                      style={{ maxHeight: 20, backgroundColor: 'white', border: 'solid 1px black', padding: 2, borderRadius: 99 }}
                    />
                  </div>
                </Tooltip>
              }
            </div>
            <div>{userName}</div>
          </div>
        </div>
        {
          // _.get(Session,'userClaims.isAdmin',false) ? null :
          // currentDay === 0 || currentDay === 6 ? <AfterHours /> :
          // currentHour < 9 || currentHour >= 21 ? <AfterHours /> : null
        }
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#6BC1F6',
          alignItems: 'center',
          maxHeight: 80,
          padding: '8px 12px'
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            minWidth: '20%',
            maxWidth: '20%',
            color: 'white'
          }}
        >
          <img
            src={require('../shared/assets/rc/RobinCare_logo_menuBar.png')}
            alt=""
            style={{ maxWidth:'100%',cursor:'pointer' }}
            onClick={() => history.push('/')}
          />
        </div>
        <div style={localStyles.topNav}>
          {
            /*
            <div style={{...localStyles.topNavItem, color: _.get(history,`location.pathname`,'/').split('/')[1] === 'public' ? '#14177D':'white'}}
               onClick={() => history.push('/')}>
              Home
            </div>
            <div style={{...localStyles.topNavItem, color: _.get(history,`location.pathname`,'/').split('/')[1] === 'blog' ? '#14177D':'white'}}
              onClick={() => history.push('/blog')}
            >
              Blog
            </div>
            <div style={{...localStyles.topNavItem, color: _.get(history,`location.pathname`,'/').split('/')[1] === 'login' || _.get(history,`location.pathname`,'/').split('/')[1] === 'signup' ? '#14177D':'white'}}
              onClick={() => history.push('/login')}
            >
              Sign In
            </div>
            */
          }
        </div>
      </div>
    )
  }
}
export default Header
