import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import CreatableSelect from 'react-select/creatable'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DatePicker } from '@material-ui/pickers'

import { Session, adminDocCreate } from '../../shared/globals'

export const DialogCases = ({open,adminDoc,post,user,dialogParentHide,isDisabled}) => {
  const [tags, setTags]=useState(_.map(_.get(adminDoc,'tags',[]),(tag)=>{return {value:tag.value,label:tag.value}}))
  const [,refreshState] = useState()
  const [profile,] = useState(_.get(Session,`users.${user.uid}.profile`,{}))
  const [message, setMessage] = useState(_.get(Session,`adminDocs.${adminDoc.id}.message`,''))
  const [disabled,setDisabled] = useState(isDisabled)
  const [assignedTo,setAssignedTo] = useState(_.get(adminDoc,'assignedTo',''))
  const [priority,setPriority] = useState(_.get(adminDoc,'priority','Low'))
  const [status,setStatus] = useState(_.get(adminDoc,'status','Open'))
  const [eventDateTime,setEventDateTime] = useState(moment(_.get(adminDoc,'epoc',new Date())).toDate())
  const [assignToList,] = useState(_.filter(_.map(Session.users,({profile})=>{
    if(profile && profile.type === 'Admin'){
      const {userCount,uid,firstName,lastName} = profile
      return {userCount,uid,firstName,lastName}
    }else{
      return null
    }
  }),(admin) => {return admin}))

  const dialogHide = () => {
    setMessage('')
    dialogParentHide()
  }
  const dialogUpdate = () => {
    adminDoc.message=message
    adminDoc.tags=tags
    adminDoc.assignedTo=assignedTo
    adminDoc.assignedToName=_.trim(`${_.get(Session,`users.${assignedTo}.profile.firstName`,'')} ${_.get(Session,`users.${assignedTo}.profile.lastName`,'')}`)
    adminDoc.priority=priority
    adminDoc.status=status
    adminDoc.dateString = moment(eventDateTime.ts).toString()
    adminDoc.epoc = moment(eventDateTime.ts).valueOf()
    if(adminDoc.id){
      adminDocCreate(user.uid,adminDoc).then(()=>{
        dialogHide()
      })
    }else{
      adminDoc.postId=post.id
      adminDoc.docType='case'
      adminDocCreate(user.uid,adminDoc).then(()=>{
        dialogHide()
      })
    }
  }

  if(!_.get(Session,'userClaims.isAdmin',false)) return null
  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogHide} aria-labelledby="form-dialog-title">
      <DialogTitle>Case Request</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {`${profile.firstName} ${profile.lastName} (${profile.userCount})`}
        </DialogContentText>
        <TextField fullWidth margin="normal" variant="outlined" label="Assigned To" select disabled={disabled} value={assignedTo || ''} onChange={(e) => setAssignedTo(e.target.value)} >
          {
            _.map(assignToList,(adminUser)=>{
              return <MenuItem key={adminUser.uid} value={adminUser.uid}>{adminUser.firstName} {adminUser.lastName}</MenuItem>
            })
          }
        </TextField>
        <DatePicker fullWidth inputVariant="outlined" showTodayButton disabled={disabled} label="Due Date"
          onClose={()=>{refreshState(Date.now())}}
          value={eventDateTime}
          onChange={setEventDateTime}
          />
        <TextField fullWidth margin="normal" variant="outlined" label="Priority" select disabled={disabled} value={priority || ''} onChange={(e) => setPriority(e.target.value)} >
          {
            _.map(['Low','Medium','High'],(priorityLevel)=>{
              return <MenuItem key={priorityLevel} value={priorityLevel}>{priorityLevel}</MenuItem>
            })
          }
        </TextField>
        <TextField fullWidth margin="normal" variant="outlined" label="Status" select disabled={disabled} value={status || ''} onChange={(e) => setStatus(e.target.value)} >
          {
            _.map(['Open','Pending','Closed'],(statusSTR)=>{
              return <MenuItem key={statusSTR} value={statusSTR}>{statusSTR}</MenuItem>
            })
          }
        </TextField>
        <TextField autoFocus fullWidth margin="normal" variant="outlined" multiline
          label="Case Details" rows={5} rowsMax={10} inputProps={{ maxLength: 2000 }}
          onChange={(e)=>setMessage(e.target.value)} disabled={disabled}
          value={message}
        />
        {
          (2000-message.length > 500) ? null :
          <div style={{fontSize:12,fontColor:'grey',marginBottom:16}}>{2000 - message.length} characters left</div>
        }
        <CreatableSelect isMulti isDisabled={disabled}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          placeholder={'Select Tags'}
          value={tags}
          onChange={(newValue)=>setTags(newValue)}
          options={Session.groupedOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogHide} color="primary">
          { disabled ? 'CLOSE' : 'CANCEL' }
        </Button>
        <Button onClick={disabled ? ()=>setDisabled(false) : dialogUpdate } color="primary">
          { disabled ? 'EDIT' : 'UPDATE' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogCases
