import React, { useState } from 'react';
import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton';
import Today from '@material-ui/icons/Today'

import {  DialogEventDetails } from './dialogs'

const CalendarBadge = ({ user, post }) => {
  const [dialogOpen,setDialogOpen] = useState('')
  const dialogHide = () => {
    setDialogOpen('')
  }

  if(!_.has(post,'eventDetails.epoc')){
    return null
  }else{
    return (
        <div id="feedbackButton" style={{cursor: 'pointer', verticalAlign: 'center', fontSize: 'inherit'}}>
          <IconButton size='small' onClick={()=>setDialogOpen('DialogEventDetails')}>
            <Today style={{fontSize:16,cursor:'pointer'}} />
          </IconButton>
          {dialogOpen!=='DialogEventDetails'?null:<DialogEventDetails open={dialogOpen==='DialogEventDetails'} dialogParentHide={dialogHide} post={post} user={user}/>}
        </div>
      )
  }
}

export default CalendarBadge
