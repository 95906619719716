import React, { useState } from 'react';
import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton';
import LocalOfferOutlined from '@material-ui/icons/LocalOfferOutlined'

import { Session } from '../../shared/globals'
import { DialogTags } from '../dialogs'

const TagsBadge = ({ user, post }) => {
  const [dialogOpen,setDialogOpen] = useState('')
  const dialogHide = () => {
    setDialogOpen('')
  }

  if(!_.get(Session,'userClaims.isAdmin',false) || (_.get(post,'tags',[]).length === 0 && post.createdById !== user.uid)){
    return null
  }else{
    let styleTagBadge = {fontSize:16,cursor:'pointer'}
    if(_.get(post,'tags',[]).length === 0 && post.createdById === user.uid){
      styleTagBadge = {fontSize:16,cursor:'pointer',color:'red'}
    }
    return (
        <div id="feedbackButton" style={{cursor: 'pointer', verticalAlign: 'center', fontSize: 'inherit'}}>
          <IconButton size='small' onClick={()=>setDialogOpen('DialogTags')}>
            <LocalOfferOutlined style={styleTagBadge} />
          </IconButton>
          {dialogOpen!=='DialogTags'?null:<DialogTags open={dialogOpen==='DialogTags'} dialogParentHide={dialogHide} post={post} user={user}/>}
        </div>
      )
  }
}

export default TagsBadge
