import React, { useState } from 'react'
import _ from 'lodash'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import {Session,postUpdatePoll} from '../../shared/globals'

const Poll = ({user,attachment}) => {
  const [dialogOpen,setDialogOpen] = useState(false)
  const {question,options,response,isAnswered} = attachment.attachmentData
  const postPollAnswer = (option) => {
    postUpdatePoll(user.uid,attachment,option).then(()=>{
    }).catch((error)=>{
    })
    setDialogOpen(false)
  }
  return (
    <div style={{width:'90%',flex:1}}>
      <div style={{margin:4,fontSize:16,fontWeight:'bold'}}>{question}</div>
      {
        isAnswered?
        <Button onClick={()=>setDialogOpen(attachment.createdById!==Session.uid || user.uid===Session.uid)} fullWidth margin="normal" variant="outlined" style={{margin:4}} >
          {response}
        </Button>
         :
        <div style={{display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
          {
            _.map(options,(option,index)=>{
              return (
                <Button key={`OPTION-${index}`} onClick={()=>postPollAnswer(option)} fullWidth margin="normal" variant="outlined" style={{margin:4}} >
                  {option}
                </Button>
              )
            })
          }
        </div>
      }
      <Dialog fullWidth={true} maxWidth={'xs'} open={dialogOpen} onClose={()=>setDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>Change Poll Selection</DialogTitle>
        <DialogContent>
          <div>{question}</div>
          <div style={{display:'flex',flexDirection:'column'}}>
            {
              _.map(options,(option,index)=>{
                return (
                  <Button key={`OPTION-${index}`} onClick={()=>postPollAnswer(option)} style={{margin:4}} fullWidth margin="normal" variant="outlined" >
                    {option}
                  </Button>
                )
              })
            }
            <Button style={{flex:1,margin:4,marginTop:16,padding:4,border:'solid 2px black',borderRadius:8,fontSize:16,fontWeight:'bold',color:'black'}}  onClick={()=>postPollAnswer('')} color="primary">
              RESET
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Poll
