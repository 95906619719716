import React, { useState } from 'react'
import _ from 'lodash'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import MoreVert from '@material-ui/icons/MoreVert'
import Clear from '@material-ui/icons/Clear'

import { Session, userLogout } from '../shared/globals'
import Responsive from 'react-responsive'

const Mobile = props => <Responsive {...props} maxWidth={Session.auth ? 699 : 499} />;
const Default = props => <Responsive {...props} minWidth={Session.auth ? 700 : 500} />;

const LeftNav = ({ match,user, history }) => (
  <div>
    <Mobile>
      <LeftNavWeb match={match} user={user} history={history} isMobile={true} />
    </Mobile>
    <Default>
      <LeftNavWeb match={match} user={user} history={history} isMobile={false} />
    </Default>
  </div>
);
export default LeftNav

const localStyles = {
  root: {
    boxSizing: 'border-box',
    minWidth: 300,
    maxWidth: 300,
    marginTop: '10px',
    marginLeft: '10px',
    position:'relative',
  },
  node: {
    display: 'flex',
    margin: '8px 20px 8px 8px',
    paddingLeft: '8px',
    paddingRight: 8,
    height: '32px',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  },
  node2: {
    display: 'flex',
    margin: 12,
    marginLeft: 32,
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  }
}

const LeftNavWeb = ({ user, history, isMobile }) => {
  const [navItem, selectNavItem] = useState(_.get(history,`location.pathname`,'/').split('/')[1] === '' ? 'home' : _.get(history,`location.pathname`,'/').split('/')[1])
  const [drawerOpen,setDrawerOpen] = useState(!isMobile)
  const [drawerWidth,setDrawerWidth] = useState(0)
  if(_.get(history,`location.pathname`,'/').split('/')[1] === '' && navItem === 'home'){
  }else if(_.get(history,`location.pathname`,'/').split('/')[1] !== navItem){
    selectNavItem(_.get(history,`location.pathname`,'/').split('/')[1] === '' ? 'home' : _.get(history,`location.pathname`,'/').split('/')[1])
  }
  const nodeClick = (navDest, url) => {
    if (navItem !== navDest) {
      selectNavItem(navDest)
      history.push(url)
    }
  }
  const nodeClickScroll = (navDest, url, refId) => {
    if (navItem === navDest && Session.refs[refId]) {
      Session.refs[refId].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    } else {
      history.push(url)
      selectNavItem(navDest)
    }
  }
  const renderArrow = (navDest) => {
    if (navItem === navDest) {
      return <ArrowDropUp />
    } else {
      return <ArrowDropDown />
    }
  }

  const navButtonStyle = (current) => {
    if(navItem === current) {
      return {
        ...localStyles.node,
        borderRadius:2,
        backgroundColor: '#f1f1f2',
        // margin:4,
      }
    } else {
      return {
        ...localStyles.node,
        // margin:4,
      }
    }
  }

  if (Session.auth && user) {
    const userEventList = _.orderBy(_.filter(_.get(Session,`posts.${user.uid}`,{}),(post)=>{return _.has(post,'eventDetails.epoc')}),['createdDate'],['desc'])
    const userArchivedThreadList = _.filter(_.get(Session,`posts.${user.uid}`,{}),{isThread:true,isArchived:true})
    const userPollsList = _.filter(_.get(Session,`posts.${user.uid}`,{}),{attachmentType:'poll'})
    const userTrackers = _.filter(_.get(Session,`posts.${user.uid}`,{}),{attachmentType:'tracker'})
    const userFiles = _.filter(_.get(Session,`posts.${user.uid}`,{}),{postType:'attachment_file'})
    const userWebsites = _.filter(_.get(Session,`posts.${user.uid}`,{}),{isAttachment:true,attachmentType:'url'})
    if(isMobile && !drawerOpen){
      return (
        <div onClick={()=>setDrawerOpen(!drawerOpen)} style={{borderRight:'solid 1px black',cursor:'pointer',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <MoreVert />
        </div>
      )
    }
    if(isMobile && drawerWidth === 0){
      setTimeout(()=>setDrawerWidth(325), 100);
    }
    if(isMobile && drawerWidth === 1){
      setTimeout(()=>{
        setDrawerOpen(!drawerOpen)
        setDrawerWidth(0)
      }, 450);
    }
    let stylesMobileRoot = {}
    if(isMobile){
      stylesMobileRoot={position:'absolute',border:'solid 1px black',width:drawerWidth,overflow:'hidden',backgroundColor:'#F5F5F7',zIndex:2,transition: 'width .5s'}
    }
    return (
      <div style={stylesMobileRoot}>
        <div style={localStyles.root}>
          {
            !isMobile ? null :
            <div onClick={()=>setDrawerWidth(1)} style={{position:'absolute',top:0,right:0,cursor:'pointer'}}>
              <Clear />
            </div>
          }
          <div style={navButtonStyle('home')} onClick={(e) => nodeClick('home', '/')}>
            <div>Home</div>
          </div>
          <div style={navButtonStyle('profile')} onClick={(e) => nodeClickScroll('profile', '/profile', 'profile0')} >
            <div style={{ flex: 3 }}>Profile</div>
            <div style={{}}>{renderArrow('profile')}</div>
          </div>
          {!['profile','settings'].includes(navItem) ? null : (
            <div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#generalDetails', 'generalDetails')} >
                <div style={{ flex: 1 }}>General Information</div>
              </div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#contactDetails', 'contactDetails')} >
                <div style={{ flex: 1 }}>Contact Information</div>
              </div>
              {
                _.get(user,'type','')!=='Caregiver'? null :
                <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#patientDetails', 'patientDetails')} >
                  <div style={{ flex: 1 }}>Patient Details</div>
                </div>
              }
              {
                _.get(user,'type','')!=='Patient'  && _.get(user,'type','')!=='Caregiver'? null :
                <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#cancerDetails', 'cancerDetails')} >
                  <div style={{ flex: 1 }}>Cancer Details</div>
                </div>
              }
              { //USER EVENT LIST
                userEventList.length === 0 ? null :
                <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#eventList', 'eventList')} >
                  <div style={{ flex: 1 }}>Event List</div>
                </div>
              }
              { //USER EVENT LIST
                userArchivedThreadList.length === 0 ? null :
                <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#archivedThreads', 'archivedThreads')} >
                  <div style={{ flex: 1 }}>Archived Threads</div>
                </div>
              }
              { //USER EVENT LIST
                userPollsList.length === 0 ? null :
                <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#pollsList', 'pollsList')} >
                  <div style={{ flex: 1 }}>Polls List</div>
                </div>
              }
              { //USER EVENT LIST
                userTrackers.length === 0 ? null :
                <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#trackerList', 'trackerList')} >
                  <div style={{ flex: 1 }}>Tracker List</div>
                </div>
              }
              { //USER EVENT LIST
                userFiles.length === 0 ? null :
                <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#fileList', 'fileList')} >
                  <div style={{ flex: 1 }}>File List</div>
                </div>
              }
              { //USER EVENT LIST
                userWebsites.length === 0 ? null :
                <div style={localStyles.node2} onClick={(e) => nodeClickScroll('profile', '/profile#savedWebsites', 'savedWebsites')} >
                  <div style={{ flex: 1 }}>Saved Websites</div>
                </div>
              }
            </div>
          )}
          <div style={navButtonStyle('settings')} onClick={(e) => nodeClick('settings', '/settings')} >
            <div style={{ flex: 1 }}>Settings</div>
          </div>
          {
            /*
            <div style={navButtonStyle('public')} onClick={(e) => nodeClick('public', '/public/0')} >
              <div style={{ flex: 3 }}>About</div>
              <div style={{}}>{renderArrow('public')}</div>
            </div>
            */
          }
          {navItem !== 'public' ? null : (
            <div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('public', '/public/0/#whatWeDo', 'whatWeDo')} >
                <div style={{ flex: 1 }}>What We Do</div>
              </div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('public', '/public/0/#builtForYou', 'builtForYou')} >
                <div style={{ flex: 1 }}>Built For You</div>
              </div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('public', '/public/0/#quickAndEasy', 'quickAndEasy')} >
                <div style={{ flex: 1 }}>Quick and Easy</div>
              </div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('public', '/public/0/#testimonials', 'testimonials')} >
                <div style={{ flex: 1 }}>Testimonials</div>
              </div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('public', '/public/0/#howWeHelp', 'howWeHelp')} >
                <div style={{ flex: 1 }}>How We Help</div>
              </div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('public', '/public/0/#legal', 'legal')} >
                <div style={{ flex: 1 }}>Privacy Policy</div>
              </div>
              <div style={localStyles.node2} onClick={(e) => nodeClickScroll('public', '/public/0/#legal', 'legal')} >
                <div style={{ flex: 1 }}>Terms & Conditions</div>
              </div>
            </div>
          )}
          <div style={{display:'flex',flexDirection:'center',alignItems:'center',justifyContent:'center',paddingRight:24,paddingLeft:8}}>
            <div style={{border:'solid .5px #cfcff6',height:0,width:'100%'}}></div>
          </div>
          <div
            style={localStyles.node}
            onClick={(e) =>
              userLogout()
                .then(() => {
                  history.push('/login')
                })
                .catch((e) => {})
            }
          >
            <div style={{ flex: 3 }}>Sign Out</div>
            <div style={{ flex: 1 }} />
          </div>
        </div>
      </div>
    )
  } else {
    if (navItem !== 'home') {
      selectNavItem('home')
    }
    return null
  }
}
