import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Session, adminOpenTab, adminDocCreate } from '../../shared/globals'
import { DialogTasks } from '../dialogs'

import Checkbox from '@material-ui/core/Checkbox'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

const localStyles = {
  root: {
    boxSizing: 'border-box',
    marginTop: 8,
    display:'flex',
    flexDirection:'column',
  },
}

const AdminTasks = ({defaultToggleDrawer,searchStr}) => {
  const [, refreshState] = useState()
  const [dialogOpen,setDialogOpen] = useState('')
  const [toggleDrawer, setToggleHeader] = useState(defaultToggleDrawer)
  const [currentTask, setCurrentTask] = useState('')
  const [adminDoc,setAdminDoc] = useState({})

  const dialogShow = (dialog,task) => {
    setAdminDoc(task)
    setDialogOpen(dialog)
  }

  const dialogHide = () => {
    setAdminDoc({})
    setDialogOpen('')
  }

  const refresh = () => {
    refreshState(Date.now())
  }

  const renderCaret = () => {
    if (toggleDrawer === true) {
      return <ArrowDropDown />
    } else {
      return <ArrowDropUp />
    }
  }

  const userFullName = (uid) => {
    if(uid) {
      return `${Session.users[uid].profile.firstName} ${Session.users[uid].profile.lastName}`
    }
  }

  Session.refreshAdminTasks=refresh
  if (Session.auth && _.get(Session,'userClaims.isAdmin',false)) {
    let filteredList = _.filter(Session.adminDocs,{docType:'task'})
    if(_.get(Session,'filters.createdBy',true)){
      filteredList = _.filter(filteredList,{createdById:Session.uid})
    }
    if(_.get(Session,'filters.open',true)){
      filteredList = _.filter(filteredList,(doc)=>{
        return !_.get(doc,'done',false) &&  !_.get(doc,'archived',false)
      })
    }
    if(searchStr.length>0 || searchStr>0){
      filteredList = _.filter(filteredList,{userUid:_.get(Session,`userCount.C${searchStr}`,'ZZZ')})
    }
    if(filteredList.length===0) return null
    return (
      <div style={localStyles.root}>
        <div className='tabHeader' style={{height: '48px', fontSize:'16px',fontWeight:'bold',display:'flex',flexDirection:'row',whiteSpace: 'nowrap',alignItems:'center', backgroundColor: '#D3D3D3', borderRadius: '4px', paddingLeft: '10px', paddingRight: '10px', marginBottom: '2px', justifyContent: 'space-between', boxShadow: '0 2px 4px 0 rgba(80,80,80,0.5)', cursor: 'pointer'}} onClick={(e) =>{e.preventDefault(); setToggleHeader(!toggleDrawer)}}>
          <div className='tabHeaderText'>
            Tasks
          </div>
          <div className='caretArrow'>
            { renderCaret() }
          </div>
        </div>
        <div id='taskList' style={{display: toggleDrawer === false ? 'none' : ''}}>
          {
            _.map(filteredList,(task)=>{
              return (
                <div key={`TASK-${task.id}`} style={{backgroundColor: currentTask === task ? '#BFE3E8' : '#F5F5F5', boxShadow: '0 2px 4px 0 rgba(80,80,80,0.5)', borderRadius:4, marginTop:'6px', padding:'8px', display:'flex',flexDirection:'column'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div id='editButton' onClick={()=>{setCurrentTask(task);adminOpenTab(task.userUid);dialogShow('DialogTasks',task)}} style={{cursor:'pointer', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'center', paddingTop: '10px', paddingBottom: '3px', textDecoration: 'underline'}}>
                      EDIT
                    </div>
                    <div id='markCheckBox' style={{cursor:'pointer', fontSize: '12px', fontWeight: 'bold', verticalAlign: 'center'}}>
                      MARK DONE
                      <Checkbox checked={task.done || false} onChange={(e) => {
                          e.preventDefault()
                          adminDocCreate(task.uid,{ id: task.id, done: !task.done })
                        }}
                      />
                    </div>
                  </div>
                  <div id='patientName' style={{marginTop: '2px', fontWeight: 'bold', fontSize: '12px'}}>
                    { userFullName(task.userUid) }
                  </div>
                  <div style={{fontSize: '14px', marginTop: '6px', textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                    {task.message}
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{color: '#504D4D', fontSize: '12px', marginTop: '6px', textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {task.createdByName}
                    </div>
                    <div style={{color: '#504D4D', fontSize: '12px', marginTop: '6px', textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {moment(task.createdDateEPOC).format('MM/DD/YYYY')}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        {dialogOpen!=='DialogTasks'?null:<DialogTasks open={dialogOpen==='DialogTasks'} dialogParentHide={dialogHide} post={{id:adminDoc.postId}} adminDoc={adminDoc} user={{uid:adminDoc.userUid}} isDisabled={true}/>}
      </div>
    )
  } else {
    return null
  }
}
export default AdminTasks
