import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css'
import { Session } from '../shared/globals'
import Responsive from 'react-responsive'
import MarketingFooter from './MarketingFooter'

const Mobile = props => <Responsive {...props} maxWidth={Session.auth ? 1099 : 799} />;
const Default = props => <Responsive {...props} minWidth={Session.auth ? 1100 : 800} />;

const MarketingPage = ({ match }) => (
  <div style={{width:'100%'}}>
    <Mobile>
      <MobileView match={match} />
    </Mobile>
    <Default>
      <WebView match={match} />
    </Default>
  </div>
);
export default MarketingPage

const blockQuotes = [
  "Thanks for making the drudgery of cancer easier and lighter. You are all treasures to me! I am managing side effects much better thanks to y’all.",
  "You all are incredible; I really appreciate everything you do. You all are a wonderful line of hope",
  "I hope I get the chance to let everyone know how AMAZING you & everyone else has been!",
  "It was helpful to have support during these past months. It helped make more sense of my thoughts, concerns and questions.",
  "I love and appreciate you all and would have been lost without you.",
  "You’re the best. My personal cheerleader! I’m so grateful.",
  "I admire you for what you are doing and I wish you great success with this. You are making things so much easier for me, and that allows me to focus on my family instead of the cancer.",
  "The questions are exactly what I needed. Thank you for the additional resources. I feel much better prepared."
]

const chatImageAssets = ['real_laptop.png','iStock-872191096.jpg','marketing_chat_color.png']
const laptopImageAssets = ['real_man.png','iStock-1019963246.jpg','marketing_laptop_color.png']


const MobileView = ({ match }) => {
  let imageStyles = {borderRadius:32,boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)'}
  if(match.params.id < 2){
    imageStyles={}
  }
  return (
    <div id='marketingMainContainer' style={{width: '100%', overflow: 'scroll'}}>
      <div id='marketingWhiteContainer' style={{ backgroundColor: 'white', paddingTop: '50px', flex:1 ,display:'flex',flexDirection:'column',alignItems:'center',paddingLeft:24,paddingRight:24 }}>
        <div id='marketingSubContainer' style={{ maxWidth: '100%', minWidth: '350px' }}>
          <div id='marketingSection1' ref={(ref)=>_.set(Session,'refs.whatWeDo',ref)}>
            <div className='sectionHeaderText' style={{width: '75%', color: '#14177D', fontSize: '36px', fontWeight: 'bold', lineHeight: '45px'}}>
              Expert cancer guidance at your fingertips.
            </div>
            <div className='sectionBodyText' style={{marginTop: '20px', width: '75%', color: '#14177D', fontWeight: 600, fontSize: '20px', lineHeight: '26px', letterSpacing: '1.03px' }}>
              Chat with our team of oncology navigators whenever you need support.
            </div>
          </div>
          <div id='marketingSection2' style={{marginTop: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Link to='/signup' id='joinNowButton' style={{textDecoration: 'none', cursor: 'pointer', height: '60px', width: '200px', borderRadius: '30px', background: 'linear-gradient(270deg, #78FFFC 0%, #00CFD1 100%)', boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)', visibility: Session.auth ? 'hidden': 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '16px', fontWeight: '900', letterSpacing: '2.5px'}}>
                JOIN NOW
            </Link>
          </div>
          <div id='illustrationChat' style={{paddingTop: '20px'}}>
            <img alt="" style={{width: '100%',...imageStyles}} src={require(`../shared/assets/${chatImageAssets[match.params.id]}`)}/>
          </div>
          <div id='marketingSection3' ref={(ref)=>_.set(Session,'refs.builtForYou',ref)} style={{marginTop: '150px'}}>
            <div id='sectionThreeHeader' style={{width: '75%', color: '#14177D', fontSize: '36px', fontWeight: 'bold', lineHeight: '45px'}}>
              Built for you
            </div>
            <div id='sectionThreeBody' style={{marginTop: '30px', display: 'flex', flexDirection: 'column'}}>
              <div id='rowItem1' style={{flexGrow: 1, flexBasis: 0, wordBreak: 'break-word', display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                <div id='itemImage1' style={{height: '80px', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <img alt="" style={{width: '90px', height: '80px'}}  src={require('../shared/assets/marketing_row_item1.png')}/>
                </div>
                <div className='rowItemHeader' style={{textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                  Comprehensive
                </div>
                <div className='rowItemBody' style={{color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                  From diagnosis to survivorship, our team has you covered.
                </div>
              </div>
              <div id='rowItem2' style={{flexGrow: 1, flexBasis: 0, wordBreak: 'break-word', display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                <div id='itemImage2' style={{height: '80px', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <img alt="" style={{width: '80px', height: '70px'}} src={require('../shared/assets/marketing_row_item2.png')}/>
                </div>
                <div className='rowItemHeader' style={{textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                  No Cost
                </div>
                <div className='rowItemBody' style={{color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                  Chat with us as much as you want for free.
                </div>
              </div>
              <div id='rowItem3' style={{flexGrow: 1, flexBasis: 0, wordBreak: 'break-word', display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                <div id='itemImage3' style={{height: '80px', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <img alt="" style={{width: '100px', height: '60px'}} src={require('../shared/assets/marketing_row_item3.png')}/>
                </div>
                <div className='rowItemHeader' style={{textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                  Personalized
                </div>
                <div className='rowItemBody' style={{color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                  Responsive to your unique needs.
                </div>
              </div>
              <div id='rowItem4' style={{flexGrow: 1, flexBasis: 0, wordBreak: 'break-word', display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                <div id='itemImage4' style={{height: '80px', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <img alt="" style={{width: '70px', height: '70px'}} src={require('../shared/assets/marketing_row_item4.png')}/>
                </div>
                <div className='rowItemHeader' style={{textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                  Secure
                </div>
                <div className='rowItemBody' style={{color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                  Built using industry leading security protection.
                </div>
              </div>
            </div>
          </div>
          <div id='marketingSection4' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '140px'}}>
            <Link to='/blog' id='blogButton' style={{textDecoration: 'none', cursor: 'pointer', height: '60px', paddingLeft: '50px', paddingRight: '50px', borderRadius: '30px', background: 'linear-gradient(270deg, #78FFFC 0%, #00CFD1 100%)', boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)',  visibility: Session.auth ? 'hidden': 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '16px', fontWeight: '900', letterSpacing: '2.5px'}}>
              CHECK OUT THE BLOG
            </Link>
          </div>
          <div id='marketingSection5' ref={(ref)=>_.set(Session,'refs.quickAndEasy',ref)} style={{marginTop: '180px'}}>
            <div className='sectionHeaderText' style={{width: '75%', color: '#14177D', fontSize: '36px', fontWeight: 'bold', lineHeight: '45px'}}>
              Robin Care is the easiest way to get confidential support.
            </div>
            <div className='sectionBodyText' style={{marginTop: '20px', width: '75%', color: '#14177D', fontWeight: 600, fontSize: '20px', lineHeight: '26px', letterSpacing: '1.03px' }}>
              Getting started is easy. Create your free account and start sharing your experience with our team in under a minute.
            </div>
          </div>
          <div id='marketingSection6' style={{marginTop: '60px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '100px', paddingBottom: '135px'}}>
            <Link to='/signup' id='joinNowButton' style={{textDecoration: 'none', cursor: 'pointer', height: '60px', width: '200px', borderRadius: '30px', background: 'linear-gradient(270deg, #78FFFC 0%, #00CFD1 100%)', boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)',  visibility: Session.auth ? 'hidden': 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '16px', fontWeight: '900', letterSpacing: '2.5px'}}>
              JOIN NOW
            </Link>
          </div>
          <div id='ladyLaptopPhoto' style={{paddingTop: '30px'}}>
            <img alt="" style={{width: '100%',...imageStyles}} src={require(`../shared/assets/${laptopImageAssets[match.params.id]}`)}/>
          </div>
          <div id='marketingBlueContainer' ref={(ref)=>_.set(Session,'refs.testimonials',ref)} style={{marginLeft:-24,marginRight:-24, background: '#C6F3F4',display:'flex',alignItems:'center',justifyContent:'center'}}>
              <Slider>
                {
                  blockQuotes.map((quote,index) =>
                    <div key={index} style={{display:'flex',alignItems:'center'}}>
                      <div id='quote' style={{paddingLeft: 60,paddingRight:60 }}>
                        <div id='quoteText' style={{fontSize: '20px', color: '#14177D', fontStyle: 'italic', fontWeight: 'bold', letterSpacing: '1.3px'}}>
                          "{quote}"
                        </div>
                        <div id='quoteOwner' style={{textAlign: 'right', fontSize: '20px', color: '#14177D', letterSpacing: '1.5px', fontWeight: 'bold', paddingTop: '30px'}}>
                          Robin Care Patient
                        </div>
                      </div>
                    </div>
                  )}
              </Slider>
          </div>
          <div id='marketingWhiteContainer' ref={(ref)=>_.set(Session,'refs.howWeHelp',ref)} style={{ backgroundColor: 'white', paddingTop: '30px', flex:1 ,display:'flex',flexDirection:'column',alignItems:'center' }}>
            <div id='marketingSubContainer' style={{ maxWidth: '100%', minWidth: '350px', paddingTop: '150px', paddingBottom: '150px'}}>
              <div id='containerHeader' style={{color: '#212A7C', letterSpacing: '1.15px', fontSize: '36px', fontWeight: 'bold'}}>
                How we help
              </div>
              <div id='helpItems' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: '155px', paddingRight: '155px'}}>
                <div className='helpItem' style={{marginTop: '50px', display: 'flex', flexDirection: 'row'}}>
                  <div className='itemImage'>
                    <img alt="" src={require('../shared/assets/help_item_1.png')}/>
                  </div>
                  <div className='itemText' style={{marginLeft: '80px'}}>
                    <div className='helpItemHeader' style={{fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                      Clinical Questions
                    </div>
                    <div className='helpItemBody' style={{marginTop: '10px', color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                      From understanding your diagnosis to prepping for appointments, our clinical experts are here to answer your toughest questions.
                    </div>
                  </div>
                </div>
                <div className='helpItem' style={{marginTop: '50px', display: 'flex', flexDirection: 'row'}}>
                  <div className='itemImage'>
                    <img alt="" src={require('../shared/assets/help_item_2.png')}/>
                  </div>
                  <div className='itemText' style={{marginLeft: '80px'}}>
                    <div className='helpItemHeader' style={{fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                      Emotional Support
                    </div>
                    <div className='helpItemBody' style={{marginTop: '10px', color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                      Finding a person who gets it isn’t easy. Let our team help.
                    </div>
                  </div>
                </div>
                <div className='helpItem' style={{marginTop: '50px', display: 'flex', flexDirection: 'row'}}>
                  <div className='itemImage'>
                    <img alt="" src={require('../shared/assets/help_item_3.png')}/>
                  </div>
                  <div className='itemText' style={{marginLeft: '80px'}}>
                    <div className='helpItemHeader' style={{fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                      Administrative Burden
                    </div>
                    <div className='helpItemBody' style={{marginTop: '10px', color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                      From managing insurance bills to figuring out transportation, our team is here to make the day-to-day easier.
                    </div>
                  </div>
                </div>
                <Link to='/signup' id='joinNowButton' style={{textDecoration: 'none', marginTop: '50px', cursor: 'pointer', height: '60px', width: '200px', borderRadius: '30px', background: 'linear-gradient(270deg, #78FFFC 0%, #00CFD1 100%)', boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)',  visibility: Session.auth ? 'hidden': 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '16px', fontWeight: '900', letterSpacing: '2.5px'}}>
                  JOIN NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MarketingFooter />
    </div>
  )
}
const WebView = ({ match }) => {
  let imageStyles = {borderRadius:32,boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)'}
  if(match.params.id < 2){
    imageStyles={}
  }
  return (
    <div id='marketingMainContainer' style={{width: '100%', overflow: 'scroll'}}>
      <div id='marketingWhiteContainer' style={{ backgroundColor: 'white', paddingTop: '50px', flex:1 ,display:'flex',flexDirection:'column',alignItems:'center',paddingLeft:24,paddingRight:24 }}>
        <div id='marketingSubContainer' style={{ maxWidth: '900px', minWidth: '600px' }}>
          <div id='marketingSection1' ref={(ref)=>_.set(Session,'refs.whatWeDo',ref)}>
            <div className='sectionHeaderText' style={{width: '50%', color: '#14177D', fontSize: '36px', fontWeight: 'bold', lineHeight: '45px'}}>
              Expert cancer guidance at your fingertips.
            </div>
            <div className='sectionBodyText' style={{marginTop: '20px', width: '50%', color: '#14177D', fontWeight: 600, fontSize: '20px', lineHeight: '26px', letterSpacing: '1.03px' }}>
              Chat with our team of oncology navigators whenever you need support.
            </div>
          </div>
          <div id='marketingSection2' style={{marginTop: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Link to='/signup' id='joinNowButton' style={{textDecoration: 'none', cursor: 'pointer', height: '60px', width: '200px', borderRadius: '30px', background: 'linear-gradient(270deg, #78FFFC 0%, #00CFD1 100%)', boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)', visibility: Session.auth ? 'hidden': 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '16px', fontWeight: '900', letterSpacing: '2.5px'}}>
                JOIN NOW
            </Link>
            <div id='illustrationChat' style={{paddingTop: '20px'}}>
              <img alt="" style={{height: '380px',...imageStyles}} src={require(`../shared/assets/${chatImageAssets[match.params.id]}`)}/>
            </div>
          </div>
          <div id='marketingSection3' ref={(ref)=>_.set(Session,'refs.builtForYou',ref)} style={{marginTop: '150px'}}>
            <div id='sectionThreeHeader' style={{width: '50%', color: '#14177D', fontSize: '36px', fontWeight: 'bold', lineHeight: '45px'}}>
              Built for you
            </div>
            <div id='sectionThreeBody' style={{marginTop: '30px', display: 'flex', flexDirection: 'row'}}>
              <div id='rowItem1' style={{flexGrow: 1, flexBasis: 0, wordBreak: 'break-word', display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                <div id='itemImage1' style={{height: '80px', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <img alt="" style={{width: '90px', height: '80px'}}  src={require('../shared/assets/marketing_row_item1.png')}/>
                </div>
                <div className='rowItemHeader' style={{textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                  Comprehensive
                </div>
                <div className='rowItemBody' style={{color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                  From diagnosis to survivorship, our team has you covered.
                </div>
              </div>
              <div id='rowItem2' style={{flexGrow: 1, flexBasis: 0, wordBreak: 'break-word', display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                <div id='itemImage2' style={{height: '80px', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <img alt="" style={{width: '80px', height: '70px'}} src={require('../shared/assets/marketing_row_item2.png')}/>
                </div>
                <div className='rowItemHeader' style={{textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                  No Cost
                </div>
                <div className='rowItemBody' style={{color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                  Chat with us as much as you want for free.
                </div>
              </div>
              <div id='rowItem3' style={{flexGrow: 1, flexBasis: 0, wordBreak: 'break-word', display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                <div id='itemImage3' style={{height: '80px', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <img alt="" style={{width: '100px', height: '60px'}} src={require('../shared/assets/marketing_row_item3.png')}/>
                </div>
                <div className='rowItemHeader' style={{textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                  Personalized
                </div>
                <div className='rowItemBody' style={{color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                  Responsive to your unique needs.
                </div>
              </div>
              <div id='rowItem4' style={{flexGrow: 1, flexBasis: 0, wordBreak: 'break-word', display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                <div id='itemImage4' style={{height: '80px', marginBottom: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <img alt="" style={{width: '70px', height: '70px'}} src={require('../shared/assets/marketing_row_item4.png')}/>
                </div>
                <div className='rowItemHeader' style={{textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                  Secure
                </div>
                <div className='rowItemBody' style={{color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                  Built using industry leading security protection.
                </div>
              </div>
            </div>
          </div>
          <div id='marketingSection4' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '140px'}}>
            <Link to='/blog' id='blogButton' style={{textDecoration: 'none', cursor: 'pointer', height: '60px', paddingLeft: '90px', paddingRight: '90px', borderRadius: '30px', background: 'linear-gradient(270deg, #78FFFC 0%, #00CFD1 100%)', boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)',  visibility: Session.auth ? 'hidden': 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '16px', fontWeight: '900', letterSpacing: '2.5px'}}>
              CHECK OUT THE BLOG
            </Link>
          </div>
          <div id='marketingSection5' ref={(ref)=>_.set(Session,'refs.quickAndEasy',ref)} style={{marginTop: '180px'}}>
            <div className='sectionHeaderText' style={{width: '50%', color: '#14177D', fontSize: '36px', fontWeight: 'bold', lineHeight: '45px'}}>
              Robin Care is the easiest way to get confidential support.
            </div>
            <div className='sectionBodyText' style={{marginTop: '20px', width: '66%', color: '#14177D', fontWeight: 600, fontSize: '20px', lineHeight: '26px', letterSpacing: '1.03px' }}>
              Getting started is easy. Create your free account and start sharing your experience with our team in under a minute.
            </div>
          </div>
          <div id='marketingSection6' style={{marginTop: '60px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '100px', paddingBottom: '135px'}}>
            <Link to='/signup' id='joinNowButton' style={{textDecoration: 'none', cursor: 'pointer', height: '60px', width: '200px', borderRadius: '30px', background: 'linear-gradient(270deg, #78FFFC 0%, #00CFD1 100%)', boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)',  visibility: Session.auth ? 'hidden': 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '16px', fontWeight: '900', letterSpacing: '2.5px'}}>
              JOIN NOW
            </Link>
            <div id='ladyLaptopPhoto' style={{paddingTop: '30px'}}>
              <img alt="" style={{height: '380px',...imageStyles}} src={require(`../shared/assets/${laptopImageAssets[match.params.id]}`)}/>
            </div>
          </div>
          <div id='marketingBlueContainer' ref={(ref)=>_.set(Session,'refs.testimonials',ref)} style={{ background: '#C6F3F4', height: '500px'}}>
              <Slider>
                {
                  blockQuotes.map((quote,index) =>
                    <div key={index}>
                      <div id='quote' style={{padding: '150px' }}>
                        <div id='quoteText' style={{fontSize: '26px', color: '#14177D', fontStyle: 'italic', fontWeight: 'bold', letterSpacing: '1.3px'}}>
                          "{quote}"
                        </div>
                        <div id='quoteOwner' style={{textAlign: 'right', fontSize: '26px', color: '#14177D', letterSpacing: '1.5px', fontWeight: 'bold', paddingTop: '30px'}}>
                          Robin Care Patient
                        </div>
                      </div>
                    </div>
                  )}
              </Slider>
          </div>
          <div id='marketingWhiteContainer' ref={(ref)=>_.set(Session,'refs.howWeHelp',ref)} style={{ backgroundColor: 'white', paddingTop: '30px', flex:1 ,display:'flex',flexDirection:'column',alignItems:'center' }}>
            <div id='marketingSubContainer' style={{ maxWidth: '900px', minWidth: '600px', paddingTop: '150px', paddingBottom: '150px'}}>
              <div id='containerHeader' style={{color: '#212A7C', letterSpacing: '1.15px', fontSize: '36px', fontWeight: 'bold'}}>
                How we help
              </div>
              <div id='helpItems' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: '155px', paddingRight: '155px'}}>
                <div className='helpItem' style={{marginTop: '50px', display: 'flex', flexDirection: 'row'}}>
                  <div className='itemImage'>
                    <img alt="" src={require('../shared/assets/help_item_1.png')}/>
                  </div>
                  <div className='itemText' style={{marginLeft: '80px'}}>
                    <div className='helpItemHeader' style={{fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                      Clinical Questions
                    </div>
                    <div className='helpItemBody' style={{marginTop: '10px', color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                      From understanding your diagnosis to prepping for appointments, our clinical experts are here to answer your toughest questions.
                    </div>
                  </div>
                </div>
                <div className='helpItem' style={{marginTop: '50px', display: 'flex', flexDirection: 'row'}}>
                  <div className='itemImage'>
                    <img alt="" src={require('../shared/assets/help_item_2.png')}/>
                  </div>
                  <div className='itemText' style={{marginLeft: '80px'}}>
                    <div className='helpItemHeader' style={{fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                      Emotional Support
                    </div>
                    <div className='helpItemBody' style={{marginTop: '10px', color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                      Finding a person who gets it isn’t easy. Let our team help.
                    </div>
                  </div>
                </div>
                <div className='helpItem' style={{marginTop: '50px', display: 'flex', flexDirection: 'row'}}>
                  <div className='itemImage'>
                    <img alt="" src={require('../shared/assets/help_item_3.png')}/>
                  </div>
                  <div className='itemText' style={{marginLeft: '80px'}}>
                    <div className='helpItemHeader' style={{fontSize: '18px', fontWeight: 'bold', color: '#212A7C', letterSpacing: '1.03px', marginBottom: '10px'}}>
                      Administrative Burden
                    </div>
                    <div className='helpItemBody' style={{marginTop: '10px', color: '#212A7C', fontSize: '15px', letterSpacing: '0.99px'}}>
                      From managing insurance bills to figuring out transportation, our team is here to make the day-to-day easier.
                    </div>
                  </div>
                </div>
                <Link to='/signup' id='joinNowButton' style={{textDecoration: 'none', marginTop: '50px', cursor: 'pointer', height: '60px', width: '200px', borderRadius: '30px', background: 'linear-gradient(270deg, #78FFFC 0%, #00CFD1 100%)', boxShadow: '0 2px 4px 0 rgba(20,23,125,0.5)',  visibility: Session.auth ? 'hidden': 'visible', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'white', fontSize: '16px', fontWeight: '900', letterSpacing: '2.5px'}}>
                  JOIN NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MarketingFooter />
    </div>
  )
}
