import React, { useState } from 'react'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/icons/List'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close'

import { Session, fbListenerAdminDocs, userUpdate } from '../../shared/globals'
import AdminNotes from './AdminNotes'
import AdminCases from './AdminCases'
import AdminPhoneLogs from './AdminPhoneLogs'
import AdminTasks from './AdminTasks'

const AdminSection = () => {
  const [, refreshState] = useState()
  const [listToggle, setListToggle] = useState(false)
  const [searchStr,setSearchStr] = useState('')

  const refresh = (userCount) => {
    if(userCount){
      setSearchStr(userCount)
    }else{
      refreshState(Date.now())
    }
  }
  const setFilters = (e,filterName) => {
    _.set(Session,`filters.${filterName}`,!_.get(Session,`filters.${filterName}`,true))
    refreshState(Date.now())
    userUpdate(Session.uid,{filters:_.cloneDeep(Session.filters)})
  }

  Session.refreshAdminSection=refresh
  if (!Session.fbListenerAdminDocs && _.get(Session,'userClaims.isAdmin',false)) {
    fbListenerAdminDocs()
  }
  if (Session.auth && _.get(Session,'userClaims.isAdmin',false)) {
    const {filters} = Session
    return (
      <div style={{minWidth:275,maxWidth:275,display:'flex',flexDirection:'column', backgroundColor: '#E6E6E6', borderRadius: '4px'}}>
        <div id='adminHeader' style={{padding:8,borderBottom:'solid 1px #D3D3D3'}}>
          <Grid container direction="row" alignItems="center" style={{cursor:'pointer'}} onClick={()=>setListToggle(!listToggle)}>
            <Grid xs={6} item container direction="row" justify='flex-start'>
              <List />
              <Grid item style={{paddingTop: '2px', paddingBottom: '2px', marginLeft: '6px'}}>
                Admin
              </Grid>
            </Grid>
          </Grid>
          {
            !listToggle ? null :
            <div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox checked={_.get(filters,'createdBy',true)} onChange={(e)=>setFilters(e,'createdBy')}/>
                Only show my items
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox checked={_.get(filters,'open',true)} onChange={(e)=>setFilters(e,'open')}/>
                Only show open items
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox checked={_.get(filters,'notes',true)} onChange={(e)=>setFilters(e,'notes')}/>
                Notes
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox checked={_.get(filters,'cases',true)} onChange={(e)=>setFilters(e,'cases')}/>
                Cases
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox checked={_.get(filters,'phones',true)} onChange={(e)=>setFilters(e,'phones')}/>
                Communication Log
              </div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Checkbox checked={_.get(filters,'tasks',true)} onChange={(e)=>setFilters(e,'tasks')}/>
                Tasks
              </div>
            </div>
          }
          <div style={{paddingTop:8}}>
            <TextField fullWidth margin="dense" variant="outlined" style={{backgroundColor:'white', borderRadius: 3, boxSizing: 'border-box'}}
              placeholder="Client ID" InputProps={{ maxLength: 10,endAdornment: (
                searchStr==='' ? null :
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="Clear" onClick={()=>setSearchStr('')} >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ), }} InputLabelProps={{ shrink: true }}
              onChange={(e)=>setSearchStr(e.target.value)}
              value={searchStr}
            />
          </div>
        </div>
        <div style={{display:'flex',flexDirection:'column',overflowY:'scroll', backgroundColor: '#E6E6E6', padding: 16}}>
          <Grid spacing={2} container direction="column" justify="space-around" id='adminContents'>
            {
              _.get(filters,'notes',true) ? <Grid xs item><AdminNotes defaultToggleDrawer={true} searchStr={searchStr} /></Grid> : null
            }
            {
              _.get(filters,'cases',true) ? <Grid xs item><AdminCases defaultToggleDrawer={true} searchStr={searchStr} /></Grid> : null
            }
            {
              _.get(filters,'phones',true) ? <Grid xs item><AdminPhoneLogs defaultToggleDrawer={true} searchStr={searchStr} /></Grid> : null
            }
            {
              _.get(filters,'tasks',true) ? <Grid xs item><AdminTasks defaultToggleDrawer={true} searchStr={searchStr} /></Grid> : null
            }
          </Grid>
        </div>
      </div>
    )
  } else {
    return null
  }
}
export default AdminSection
