import React from 'react'

import {fileDownload} from '../../shared/globals'

const AttachmentImage = ({user,attachment}) => {
  return (
    <img src={attachment.attachmentURL} alt={''} style={{border:'solid 1px grey',width:200,cursor:'pointer'}} onClick={()=>fileDownload(user.uid,attachment)}/>
  )
}
export default AttachmentImage
