import React, { useState } from 'react'
import _ from 'lodash'

import TextField from '@material-ui/core/TextField'

import { Session, refreshHome } from '../shared/globals'

const SearchBox = ({uid}) => {
  const [searchStr, setSearchStr] = useState(_.get(Session,`searchStr.${uid}`,''))
  const updateSearchText = (e) => {
    setSearchStr(e.target.value)
    _.set(Session,`searchStr.${uid}`,e.target.value)
    _.set(Session,`searchPostIdArray.${uid}`,
      _.union(
        _.without(_.map(_.get(Session,`posts.${uid}`,{}),(post)=>{if(_.toLower(post.message).indexOf(_.toLower(e.target.value))>-1){return post.id}}),undefined),
        _.without(_.map(_.get(Session,`posts.${uid}`,{}),(post)=>{if(_.toLower(post.message).indexOf(_.toLower(e.target.value))>-1){return post.threadId}}),undefined)
      )
    )
    refreshHome(uid)
  }

  if(!uid) return null
  return (
    <TextField
      id="navSearchBox"
      type="text"
      margin='dense'
      placeholder="Search Messages"
      variant="outlined"
      value={searchStr}
      style={{ backgroundColor: 'white', borderRadius: 4 }}
      onChange={updateSearchText}
      fullWidth
      InputLabelProps={{ shrink: true }}
      inputProps={{
        maxLength: 254
      }}
    />
  )
}
export default SearchBox
