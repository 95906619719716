import React from 'react'
import _ from 'lodash'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Session, postDelete } from '../../shared/globals'

export const DialogDeletePost = ({open,post,user,dialogParentHide}) => {
  const dialogHide = () => {
    dialogParentHide()
  }
  const dialogUpdate = () => {
    postDelete(user.uid,post)
    dialogHide()
  }

  if(!(post.createdById === 'PUBLICBLOG' || post.createdById === Session.uid || (post.isComment && post.createdById === Session.uid) || (post.isThread && user.uid === Session.uid) || (post.isThread && _.get(Session,'userClaims.isAdmin',false)))) return null
  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} disableBackdropClick={true} disableEscapeKeyDown={true} onClose={dialogHide} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" >
      <DialogTitle id="alert-dialog-slide-title">{post.isThread?'Archive this thread?':'Delete this comment?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {post.isThread?'You can unarchive this thread by navigating to your profile under the archived section':'Once deleted this item cannot be recovered.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogHide} color="primary">
          {post.isThread?'Cancel':'Disagree'}
        </Button>
        <Button onClick={dialogUpdate} color="primary">
          {post.isThread?'Archive':'Agree'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogDeletePost
