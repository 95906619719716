import React, { useState } from 'react'
import _ from 'lodash'
import CreatableSelect from 'react-select/creatable'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Session, postUpdateTags } from '../../shared/globals'
export const groupedOptions = [
  {
    label: 'Care Coordination',
    options: [
      { value: 'CARE-Scheduling', parentTag: 'CARE', label: 'Scheduling' },
      {
        value: 'CARE-AppointmentPrep',
        parentTag: 'CARE',
        label: 'Appointment Prep'
      },
      {
        value: 'CARE-MedicalRecords',
        parentTag: 'CARE',
        label: 'Medical Records'
      },
      {
        value: 'CARE-CareTeamManagement',
        parentTag: 'CARE',
        label: 'Care Team Management'
      },
      {
        value: 'CARE-ProviderSelection',
        parentTag: 'CARE',
        label: 'Provider Selection'
      },
      {
        value: 'CARE-ResourceNavigation',
        parentTag: 'CARE',
        label: 'Resource Navigation'
      },
      { value: 'CARE-Other', parentTag: 'CARE', label: 'Care Other' }
    ]
  },
  {
    label: 'Medical Education & Symptom Management',
    options: [
      { value: 'MEDICAL-Diagnosis', parentTag: 'MEDICAL', label: 'Diagnosis' },
      { value: 'MEDICAL-Treatment', parentTag: 'MEDICAL', label: 'Treatment' },
      {
        value: 'MEDICAL-Medications',
        parentTag: 'MEDICAL',
        label: 'Medications'
      },
      {
        value: 'MEDICAL-AppointmentInterpretation',
        parentTag: 'MEDICAL',
        label: 'Appointment Interpretation'
      },
      {
        value: 'MEDICAL-Comorbidity',
        parentTag: 'MEDICAL',
        label: 'Comorbidity'
      },
      { value: 'MEDICAL-Symptoms', parentTag: 'MEDICAL', label: 'Symptoms' },
      { value: 'MEDICAL-Update', parentTag: 'MEDICAL', label: 'Update' },
      { value: 'MEDICAL-Other', parentTag: 'MEDICAL', label: 'Meical Other' }
    ]
  },
  {
    label: 'Finance and Logistics',
    options: [
      {
        value: 'FINANCE-MealCoordination',
        parentTag: 'FINANCE',
        label: 'Meal Coordination'
      },
      {
        value: 'FINANCE-FinancialAssistance',
        parentTag: 'FINANCE',
        label: 'Financial Assistance'
      },
      {
        value: 'FINANCE-BillReview',
        parentTag: 'FINANCE',
        label: 'Bill Review'
      },
      {
        value: 'FINANCE-GrantIDAndApplication',
        parentTag: 'FINANCE',
        label: 'Grant ID and Application'
      },
      {
        value: 'FINANCE-Transportation',
        parentTag: 'FINANCE',
        label: 'Transportation'
      },
      { value: 'FINANCE-ChildCare', parentTag: 'FINANCE', label: 'Child Care' },
      { value: 'FINANCE-HouseCare', parentTag: 'FINANCE', label: 'House Care' },
      { value: 'FINANCE-Clothing', parentTag: 'FINANCE', label: 'Clothing' },
      {
        value: 'FINANCE-WigsProstheses',
        parentTag: 'FINANCE',
        label: 'Wigs/Prostheses'
      },
      { value: 'FINANCE-Other', parentTag: 'FINANCE', label: 'Finance Other' }
    ]
  },
  {
    label: 'Wellbeing',
    options: [
      {
        value: 'WELLBEING-Nutrition',
        parentTag: 'WELLBEING',
        label: 'Nutrition'
      },
      { value: 'WELLBEING-Sleep', parentTag: 'WELLBEING', label: 'Sleep' },
      { value: 'WELLBEING-Weight', parentTag: 'WELLBEING', label: 'Weight' },
      {
        value: 'WELLBEING-RelationshipManagement',
        parentTag: 'WELLBEING',
        label: 'Relationship Management'
      },
      { value: 'WELLBEING-General', parentTag: 'WELLBEING', label: 'General' },
      {
        value: 'WELLBEING-Identity',
        parentTag: 'WELLBEING',
        label: 'Identity'
      },
      {
        value: 'WELLBEING-Other',
        parentTag: 'WELLBEING',
        label: 'Wellbeing Other'
      }
    ]
  },
  {
    label: 'Emotional',
    options: [
      { value: 'EMOTIONAL-Alone', parentTag: 'EMOTIONAL', label: 'Alone' },
      { value: 'EMOTIONAL-Anxious', parentTag: 'EMOTIONAL', label: 'Anxious' },
      { value: 'EMOTIONAL-Death', parentTag: 'EMOTIONAL', label: 'Death' },
      {
        value: 'EMOTIONAL-Depressed',
        parentTag: 'EMOTIONAL',
        label: 'Depressed'
      },
      { value: 'EMOTIONAL-General', parentTag: 'EMOTIONAL', label: 'General' },
      { value: 'EMOTIONAL-Lonely', parentTag: 'EMOTIONAL', label: 'Lonely' },
      { value: 'EMOTIONAL-Sad', parentTag: 'EMOTIONAL', label: 'Sad' },
      { value: 'EMOTIONAL-Therapy', parentTag: 'EMOTIONAL', label: 'Therapy' },
      {
        value: 'EMOTIONAL-EmotionalSupport',
        parentTag: 'EMOTIONAL',
        label: 'Emotional Support'
      },
      {
        value: 'EMOTIONAL-BodyImage',
        parentTag: 'EMOTIONAL',
        label: 'Body Image'
      },
      {
        value: 'EMOTIONAL-Other',
        parentTag: 'EMOTIONAL',
        label: 'Emotional Other'
      }
    ]
  },
  {
    label: 'Benefits Navigation',
    options: [
      { value: 'BENEFITS-Benefits', parentTag: 'BENEFITS', label: 'Benefits' },
      { value: 'BENEFITS-Leaves', parentTag: 'BENEFITS', label: 'Leaves' },
      {
        value: 'BENEFITS-ReturnToWork',
        parentTag: 'BENEFITS',
        label: 'Return to Work'
      },
      {
        value: 'BENEFITS-EmployerRelations',
        parentTag: 'BENEFITS',
        label: 'Employer Relations'
      },
      {
        value: 'BENEFITS-WorkersComp',
        parentTag: 'BENEFITS',
        label: 'Workers Comp'
      },
      {
        value: 'BENEFITS-SecondOpinion',
        parentTag: 'BENEFITS',
        label: 'Second Opinion'
      },
      {
        value: 'BENEFITS-Other',
        parentTag: 'BENEFITS',
        label: 'Benefits Other'
      }
    ]
  },
  {
    label: 'Administrative',
    options: [
      {
        value: 'ADMINISTRATIVE-HowTo',
        parentTag: 'ADMINISTRATIVE',
        label: 'How-To'
      },
      {
        value: 'ADMINISTRATIVE-ExplainServices',
        parentTag: 'ADMINISTRATIVE',
        label: 'Explain Services'
      },
      {
        value: 'ADMINISTRATIVE-Feedback',
        parentTag: 'ADMINISTRATIVE',
        label: 'Feedback'
      },
      {
        value: 'ADMINISTRATIVE-Complaints',
        parentTag: 'ADMINISTRATIVE',
        label: 'Complaints'
      },
      {
        value: 'ADMINISTRATIVE-GiftBox',
        parentTag: 'ADMINISTRATIVE',
        label: 'Gift Box'
      },
      {
        value: 'ADMINISTRATIVE-ThankYou',
        parentTag: 'ADMINISTRATIVE',
        label: 'Thank You'
      },
      {
        value: 'ADMINISTRATIVE-Billable',
        parentTag: 'ADMINISTRATIVE',
        label: 'Billable'
      },
      {
        value: 'ADMINISTRATIVE-Other',
        parentTag: 'ADMINISTRATIVE',
        label: 'Administrative Other'
      }
    ]
  },
  {
    label: 'App/Tech',
    options: [
      { value: 'APPTECH-TechnicalSupport', parentTag: 'APPTECH', label: 'Technical Support' },
      { value: 'APPTECH-Password', parentTag: 'APPTECH', label: 'Password' },
      { value: 'APPTECH-Other', parentTag: 'APPTECH', label: 'App/Tech Other' }
    ]
  },
  {
    label: 'Referrals',
    options: [
      { value: 'REFERRALS-TrueRX', parentTag: 'REFERRALS', label: 'TrueRX' },
      { value: 'REFERRALS-GrandRounds', parentTag: 'REFERRALS', label: 'Grand Rounds' },
      { value: 'REFERRALS-Other', parentTag: 'REFERRALS', label: 'Referrals Other' }
    ]
  },
  {
    label: 'Expected Outcomes',
    options: [
      {label:'ER Visit Avoided',value:'ROI-ER Visit Avoided',parentTag:'ROI'},
      {label:'Improved Appointment Compliance',value:'ROI-Improved Appointment Compliance',parentTag:'ROI'},
      {label:'Quality of Life Improved',value:'ROI-Quality of Life Improved',parentTag:'ROI'},
      {label:'Anxiety Improved',value:'ROI-Anxiety Improved',parentTag:'ROI'},
      {label:'Financial Barrier Resolved',value:'ROI-Financial Barrier Resolved',parentTag:'ROI'},
      {label:'Improved Clinical Outcomes',value:'ROI-Improved Clinical Outcomes',parentTag:'ROI'},
      {label:'Treatment Plan Compliant',value:'ROI-Treatment Plan Compliant',parentTag:'ROI'},
      {label:'Education for Informed Decision Making',value:'ROI-Education for Informed Decision Making',parentTag:'ROI'},
      {label:'Improved Timeliness to Treatment',value:'ROI-Improved Timeliness to Treatment',parentTag:'ROI'},
      {label:'Clinical Low',value:'ROI-Clinical Low',parentTag:'ROI'},
      {label:'Clinical Medium',value:'ROI-Clinical Medium',parentTag:'ROI'},
      {label:'Clinical High',value:'ROI-Clinical High',parentTag:'ROI'},
      {label:'Logisical Low',value:'ROI-Logisical Low',parentTag:'ROI'},
      {label:'Logisical Medium',value:'ROI-Logisical Medium',parentTag:'ROI'},
      {label:'Logisical High',value:'ROI-Logisical High',parentTag:'ROI'},
      {label:'Emotional Low',value:'ROI-Emotional Low',parentTag:'ROI'},
      {label:'Emotional Medium',value:'ROI-Emotional Medium',parentTag:'ROI'},
      {label:'Emotional High',value:'ROI-Emotional High',parentTag:'ROI'},
    ]
  },

]

export const DialogTags = ({open,post,user,dialogParentHide}) => {
  const [tags, setTags]=useState(_.map(_.get(post,'tags',[]),(tag)=>{return {value:tag,label:tag}}))

  const dialogHide = () => {
    setTags([])
    dialogParentHide()
  }
  const dialogUpdate = () => {
    postUpdateTags(user.uid,post,tags).then(()=>{
      dialogHide()
    }).catch((error)=>{
      console.log('tagCommentUpdate:error',error)
      dialogHide()
    })
  }

  if(!_.get(Session,'userClaims.isAdmin',false)) return null
  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogHide} aria-labelledby="form-dialog-title">
      <DialogTitle>Tag Post</DialogTitle>
      <DialogContent>
        <CreatableSelect isMulti autoFocus
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          placeholder={'Select Tags'}
          value={tags}
          onChange={(newValue)=>setTags(newValue)}
          options={groupedOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogHide} color="primary">
          CANCEL
        </Button>
        <Button onClick={dialogUpdate} color="primary">
          UPDATE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogTags
