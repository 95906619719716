import React, { useState } from 'react'
import NotificationToggle from "./NotificationToggle"
import Button from '@material-ui/core/Button'
import { userUpdate } from '../shared/globals'
import _ from "lodash";

const Settings = ({ user }) => {
  const [, refreshState] = useState(Date.now())
  const [notificationDayFrequency, updateNotificationDayFrequency] = useState(_.get(user, 'notificationDayFrequency', 7) || false)

  const updateUserField = (field, value) => {
    _.set(user,field,value)
    userUpdate(user.uid,{[field]:typeof _.get(user,field,'') === 'string'?_.trim(_.get(user,field,'')):_.get(user,field,'')}).then(()=>{
      refreshState(Date.now())
    })
    // _.set(user,field,_.trim(_.get(user,field,'')))
  }

  const toggleItemValue = (selectedValue) => {
    if(selectedValue !== notificationDayFrequency) {
      updateNotificationDayFrequency(selectedValue)
      updateUserField('notificationDayFrequency', selectedValue)
    }
  }

  return (
    <div id='settingsContainer' style={{minWidth: '500px', maxWidth: '500px', marginTop: '15px', paddingBottom: '20px'}}>
      <div className='sectionHeader' style={{border: '1px solid #51B7C5', height: '37px', backgroundColor: '#51B7C5', borderRadius: '5px 5px 0 0', paddingLeft: '12px'}}>
        <div className='sectionHeaderText' style={{color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '18px', letterSpacing: '0.65px'}}>
          Notification Settings
        </div>
      </div>
      <div className='sectionBody' style={{backgroundColor: '#FFFFFF', borderLeft: '1px solid #E9E9E9', borderRight: '1px solid #E9E9E9', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '12px', paddingRight: '12px'}}>
        <div className='sectionSubHeader' style={{fontSize: '16px', letterSpacing: '0.75px', lineHeight: '19px', color: '#020202'}}>
          Emails
        </div>
        <div className='sectionSubBody'>
          <NotificationToggle user={user} title={ 'New Item' } field={'notifyOnNewPost'} updateUserField={updateUserField} />
          <NotificationToggle user={user} title={ 'New answer in post' } field={'notifyOnNewPostAnswer'} updateUserField={updateUserField} />
          <NotificationToggle user={user} title={ 'Reminder event - 1 hour before' } field={'notifyOnReminderEvent'} updateUserField={updateUserField} />
          <NotificationToggle user={user} title={ 'Robin Care News' } field={'notifyOnNewNews'} updateUserField={updateUserField} />
          <div id='activityDigest' style={{marginTop: '13px', marginBottom: '8px', fontSize: '14px', letterSpacing: '0.65px'}}>
            Activity Digest email every:
            <div id='digestFrequency' style={{marginTop: '10px', marginBottom: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
              <Button onClick={() => toggleItemValue(1)} variant='contained' style={{backgroundColor: notificationDayFrequency === 1 ? '#13BB21' : '', width: '125px'}}size='small'>
                day
              </Button>
              <Button onClick={() => toggleItemValue(3)} variant='contained' style={{backgroundColor: notificationDayFrequency === 3 ? '#13BB21' : '', width: '125px'}}size='small'>
                3 days
              </Button>
              <Button onClick={() => toggleItemValue(7)} variant='contained' style={{backgroundColor: notificationDayFrequency === 7 ? '#13BB21' : '', width: '125px'}}size='small'>
                week
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className='sectionBody' style={{backgroundColor: '#FFFFFF', borderBottom: '1px solid #E9E9E9', borderLeft: '1px solid #E9E9E9', borderRight: '1px solid #E9E9E9', borderRadius: '0 0 5px 5px', paddingTop: '10px', paddingBottom: '15px', marginBottom: '25px', paddingLeft: '12px', paddingRight: '12px'}}>
        <div className='sectionSubHeader' style={{fontSize: '16px', letterSpacing: '0.75px', lineHeight: '19px', color: '#020202'}}>
          Desktop Notifications
        </div>
        <div className='sectionSubBody'>
          <NotificationToggle user={user} title={ 'New Post' } field={'desktopNotifyOnNewPost'} updateUserField={updateUserField} />
          <NotificationToggle user={user} title={ 'New answer in post' } field={'desktopNotifyOnNewPostAnswer'} updateUserField={updateUserField} />
        </div>
      </div>
    </div>
  )
}
export default Settings
