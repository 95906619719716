import React from 'react'
import Responsive from 'react-responsive'
import MarketingFooter from './MarketingFooter'
import { Session } from '../shared/globals'

const Mobile = props => <Responsive {...props} maxWidth={Session.auth ? 1099 : 799} />;
const Default = props => <Responsive {...props} minWidth={Session.auth ? 1100 : 800} />;

const MarketingPrivacy = ({ match }) => (
  <div style={{width:'100%'}}>
    <Mobile>
      <MobileView match={match} />
    </Mobile>
    <Default>
      <WebView match={match} />
    </Default>
  </div>
);
export default MarketingPrivacy

const MobileView = () => {
  return (
    <div style={{width: '100%', overflow: 'scroll'}}>
      <div id='marketingWhiteContainer' style={{ backgroundColor: 'white', paddingTop: '50px', flex:1 ,display:'flex',flexDirection:'column',alignItems:'center',paddingLeft:24,paddingRight:24 }}>
        <div id='marketingSubContainer' style={{ maxWidth: '100%', minWidth: '350px' }}>
          <p>Privacy Policy</p>
          <p>Robin Care, Inc</p>
          <p>Last Updated: June 26, 2019</p>
          <p>THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION AND OTHER INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW THIS PRIVACY POLICY CAREFULLY.</p>
          <p>
            This Privacy Policy, together with our Terms of Use (the “Terms of Use”), form the Agreement by which you may access or use the websites located at www.robincare.com, www.getrobincare.com, other related websites and mobile applications (collectively, the “Site”). Through these sites, Robin Care, Inc. (“Robin Care”) operates various online services that enable members (“Members”) to receive cancer support services from healthcare assistants located in the U.S. This Online Privacy Policy (“Privacy Policy’) explains Robin Care’s privacy practices that apply to the services (collectively, the “Services”) we provide through our Sites.
          </p>
          <p>
            This policy applies to information we collect from you orally, in e-mail and/or text, and from other electronic communications between you and Robin Care and its health assistants employed or under contract with Robin Care, whether or not through mobile and desktop applications you download, and when you interact with our advertising and applications on third-party websites and services (if those applications or advertising include links to this Privacy Policy). All capitalized terms used herein without definition shall have the respective meanings ascribed thereto in the Terms of Use.
          </p>
          <p>
            We do not sell, share, rent or trade the information we have collected about you, including Personal Information (as such term is hereinafter defined), other than as disclosed within this Privacy Policy or at the time you provide Your Information.
          </p>
          <ul>
            <li style={{listStyleType:'upper-roman'}}>Collection of Your Information:<ul>
              <li style={{listStyleType:'upper-alpha'}}>
                <u>Information Collected</u>: In order to provide you with the Services, Robin Care will collect certain categories of personal information, including, without limitation, information that could reasonably be used to identify you personally (e.g., such as your name, e-mail address, birthdate, mobile number and credit card) (“Personal Information“). Your health or medical information may be collected and used by Robin Care to enable the provision of the Services to you (“Health Information”). This information may be collected at various times and from various sources, including, but not limited to, when you register to use the Robin Care website, when you subscribe to use the Services, and when you request certain Services. Additionally, Robin Care may collect information about your internet connection, the equipment you use to access our website and application. Robin Care may also automatically collect information through cookies, web beacons, embedded scripts and other tracking technologies as you navigate through the website and from your usage of any application, your computer, mobile or other device used to access the Services (any, a “Device“). All of the information collected by Robin Care about you, including Health Information and Personal Information shall be collectively referred to in this Policy as “Your Information”.
              </li>
            </ul></li>
            <li style={{listStyleType:'upper-roman'}}>How May Robin Care Use and Disclose Your Information<ul>
              <li style={{listStyleType:'upper-alpha'}}>
                <u>Use of Your Information</u>: Your Information is collected to provide you with a positive experience when using the Services. In addition to using your information for the purposes described above:
                  <ul>
                    <li style={{listStyleType:'disc'}}><u>For Services.</u> We may use or disclose Your Information to provide you with the Services, and so may disclose it to, without limitation, nurses, doctors or other employees, subcontractors or agents personnel who are involved in providing you healthcare as part of the Services.</li>
                    <li style={{listStyleType:'disc'}}><u>For Payment.</u> We may use and disclose Your Information so that the Services we provide may be billed to and payment may be collected from you, an insurance company or a third party (if applicable). You may restrict disclosures by us of medical information to your health plan regarding services you paid for yourself in full.</li>
                    <li style={{listStyleType:'disc'}}><u>For Our Operations.</u> We may use and disclose Your Information to facilitate our operations. For example, we may do this by monitoring which features of the Services are used the most, identifying your address, phone number and current location. We may share your information, including Your Information with our parent, subsidiaries and affiliates, for internal and operational reasons. We may also use Your Information for troubleshooting and in some cases, marketing purposes. In addition, we may use Your Information:
                      <ul>
                        <li style={{listStyleType:'circle'}}>To review your history of Services;</li>
                        <li style={{listStyleType:'circle'}}>To determine if you have any restrictions on any type of Service that may be provided;</li>
                        <li style={{listStyleType:'circle'}}>To recommend certain products and/or Services;</li>
                        <li style={{listStyleType:'circle'}}>To provide you with information or services or process transactions that you have requested or agreed to receive, including to send you electronic newsletters, or to provide you with special offers or promotional materials on behalf of us or third parties;</li>
                        <li style={{listStyleType:'circle'}}>To process your registration with the Services, including verifying your information is active and valid;</li>
                        <li style={{listStyleType:'circle'}}>To improve the Services or our services, to customize your experience with the Services, or to serve you specific content that is most relevant to you;</li>
                        <li style={{listStyleType:'circle'}}>To enable you to participate in a variety of the Services’ features;</li>
                        <li style={{listStyleType:'circle'}}>To contact you with regard to your use of the Services and, in our discretion, changes to the Services and/or the Services’ policies;</li>
                        <li style={{listStyleType:'circle'}}>For internal business purposes; and</li>
                        <li style={{listStyleType:'circle'}}>For inclusion in our data analytics.</li>
                      </ul>
                    </li>
                    <li style={{listStyleType:'disc'}}><u>Appointment Reminders.</u> We may use and disclose Your Information to contact you as a reminder that you have an appointment with a Robin Care Health Assistant.</li>
                    <li style={{listStyleType:'disc'}}><u>Treatment Alternatives.</u> We may use and disclose medical information to tell you about, or recommend, possible Service or care options that may be of interest to you.</li>
                    <li style={{listStyleType:'disc'}}><u>Benefits and Services.</u> We may use and disclose medical information to tell you about benefits or services that may be of interest to you.</li>
                    <li style={{listStyleType:'disc'}}><u>Contractors and Agents.</u> In certain cases, we will provide your information to contractors, agents and other parties who need the information in order to perform a service for us, such as providing you with Services or obtaining payment for Services or carrying out business operations. Another example is that we may share your information with an insurance company, law firm or risk management organization in order to maintain professional advice about how to manage risk and legal liability, including insurance or legal claims. However, you should know that in these situations, we require third parties to provide us with assurances that they will safeguard your information.</li>
                    <li style={{listStyleType:'disc'}}><u>Use of Your Information for Service Related Information.</u> We will send you service-related announcements on those occasions when it is necessary to do so. For instance, if our Services are temporarily suspended for maintenance, we might send you an email. Generally, you may not opt-out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account.</li>
                  </ul>
              </li>
              <li style={{listStyleType:'upper-alpha'}}>
                <u>Disclosure of Information to Other Third Parties.</u>
                  <ul>
                    <li style={{listStyleType:'disc'}}><u>Disclosure of Non Personally Identifiable Information</u>: Robin Care may share non-personally identifiable (or “de-identified”) information, such as aggregated user statistics and log data, with third parties for industry analysis, demographic profiling, to deliver targeted advertising about other products or services, or for other business purposes.</li>
                    <li style={{listStyleType:'disc'}}><u>Agreeing to Receive Information from Third Parties</u>: You may be presented with an opportunity to receive information and/or marketing offers directly from third parties. For example, we may use Your Information to enable us to push to you advertisements from our advertisers’ for their target audiences. Even though we do not disclose your personal information to our advertisers for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria. We do not share your Your Information with third parties for those third parties’ direct marketing purposes to you unless you consent to such sharing at the time you provide Your Information. If you do agree to have Your Information shared, Your Information will be disclosed to such third parties and all information you disclose will be subject to the privacy policy and practices of such third parties. We are not responsible for the privacy policies and practices of such third parties and, therefore, you should review the privacy policies and practices of such third parties prior to agreeing to receive such information from them. If you later decide that you no longer want to receive communication from a third party, you will need to contact that third party directly.</li>
                    <li style={{listStyleType:'disc'}}><u>Administrative and Legal Reasons for Disclosure of Information</u>: We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. Thus, we may access, use, preserve, transfer and disclose your information (including, Your Information), including disclosure to third parties such as government or law enforcement officials or private parties as we reasonably determine is necessary and appropriate:
                      <ul>
                        <li style={{listStyleType:'circle'}}>To satisfy any applicable law, regulation, subpoenas, governmental requests or legal process court order, warrant, summons or similar process (We may release Your Information to authorized federal officials for intelligence, counterintelligence, and other national security activities authorized by law);</li>
                        <li style={{listStyleType:'circle'}}>To defend lawsuits and disputes arising from the Agreement;</li>
                        <li style={{listStyleType:'circle'}}>To protect and/or defend the Agreement or other policies applicable to any online and mobile Services, including investigation of potential violations thereof;</li>
                        <li style={{listStyleType:'circle'}}>To protect the safety, rights, property or security of Robin Care, our Services or any third party;</li>
                        <li style={{listStyleType:'circle'}}>To protect the safety of the public for any reason;</li>
                        <li style={{listStyleType:'circle'}}>To identify or locate a suspect, fugitive, material witness, or missing person;</li>
                        <li style={{listStyleType:'circle'}}>To detect, prevent or otherwise address fraud, security or technical issues; and</li>
                        <li style={{listStyleType:'circle'}}>To prevent or stop activity we may consider to be, or to pose a risk of being, an illegal, unethical, criminal or legally actionable activity.</li>
                      </ul>
                    </li>
                  </ul>
              </li>
            </ul></li>
            <p>
              We may use IP address or other Device Identifiers, to identify users, and may do so in cooperation with third parties such as copyright owners, internet service providers, wireless service providers and/or law enforcement agencies, including disclosing such information to third parties, all in our discretion. Such disclosures may be carried out without notice to you.
            </p>
            <ul>
              <li style={{listStyleType:'disc'}}><u>Reasons for Disclosure of Your Information</u>: We also reserve the right to disclose and transfer all such information:
                <ul>
                  <li style={{listStyleType:'circle'}}>To public health reporting and other governmental healthcare oversight activities. Your health information may be disclosed to public health agencies as required by law.</li>
                  <li style={{listStyleType:'circle'}}>In connection with a corporate merger, consolidation, restructuring, the sale of substantially all of the outstanding stock and/or assets or other corporate change, including, during the course of any due diligence process;</li>
                  <li style={{listStyleType:'circle'}}>To comply with any court order, law or legal process, including to respond to any government or regulatory request;</li>
                  <li style={{listStyleType:'circle'}}>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Robin Care our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
                </ul>
              </li>
            </ul>
            <li style={{listStyleType:'upper-roman'}}>Your Rights<ul>
              <li style={{listStyleType:'upper-alpha'}}>
                You may have certain rights under the state and federal privacy standards. These include:
                <ul>
                  <li style={{listStyleType:'disc'}}><u>Right to Request Restrictions.</u> You have the right to request restrictions on our uses and disclosures of Your Information. However, we are not required to agree to your request unless the disclosure is to a health plan, Your Information pertains solely to health care items or services for which you have paid the bill in full, and the disclosure is not otherwise required by law.</li>
                  <li style={{listStyleType:'disc'}}><u>Alternative Communication Means.</u> You have the right to reasonably request to receive confidential communications of Your Information by alternative means or at alternative locations.</li>
                  <li style={{listStyleType:'disc'}}><u>Right to Inspect a Copy.</u> The right to inspect and copy your Health Information by submitting a written request detailing what information you want access to, whether you want to inspect it or get a copy of it, and if you want a copy, your preferred form and format. We will provide copies in your requested form and format if it is readily producible, or we will provide you with an alternative format you find acceptable. We will charge a reasonable fee which covers our costs for labor, supplies, postage, and if requested and agreed to in advance, the cost of preparing an explanation or summary, as allowed by applicable law. We may deny your request under limited circumstances;</li>
                  <li style={{listStyleType:'disc'}}><u>Right to Amend.</u> The right to amend or submit corrections to your Health Information by submitting a written request including the reasons you believe the information is incorrect or incomplete. We are not required to change your Health Information and will provide you with information regarding our denial of such requested amendment. If we deny your request, you may submit a written statement of your disagreement with that decision, and we may, in turn, prepare a written rebuttal;</li>
                  <li style={{listStyleType:'disc'}}><u>Right to an Accounting.</u> The right to receive an accounting of how and to whom your protected health information has been disclosed; provided, however, we are not required to provide to you an accounting of disclosures made for the purposes of treatment, payment, health care operations, information provided directly to you, information provided pursuant to your written authorization, and certain government functions; and</li>
                  <li style={{listStyleType:'disc'}}><u>Printed Copy.</u> The right to receive a printed copy of this notice.</li>
                </ul>
              </li>
            </ul></li>
            <li style={{listStyleType:'upper-roman'}}>Right to Revise Privacy Policy.<ul>
              <li style={{listStyleType:'upper-alpha'}}>
                You may have certain rights under the state and federal privacy standards. These include:
                <ul>
                  <li style={{listStyleType:'disc'}}><u>Right to Change or Amend Privacy Policy.</u> Robin Care has the right to amend its Privacy Policy at any time without notice to you by posting the revised Privacy Policy on this Website. You agree that you are bound by those changes by continuing to use the Website.</li>
                </ul>
              </li>
            </ul></li>
            <li style={{listStyleType:'upper-roman'}}>Your Information After Cancelling Account.
              <p>
                You are responsible for maintaining the accuracy of the information you submit to Robin Care, such as your contact information provided as part of account registration. If Your Information changes, or if you no longer desire to use the Services, you may correct, delete inaccuracies, or amend information by making the change on our member information page or by contacting us. We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable. Robin Care will retain Your Information for as long as your account with the Services is active and as needed to provide you services. Even after your account is terminated, we will retain your Personal Information as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to cancellation of an account (such as addressing chargebacks from your credit card companies), investigate or prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reason. After a period of time, your data may be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our Services effectively, but our use of the anonymized data will be solely for analytic purposes.
              </p>
            </li>
            <li style={{listStyleType:'upper-roman'}}>Complaints.
              <p>
                If you believe your privacy rights have been violated, you may file a complaint with Robin Care or with the Secretary of the Department of Health and Human Services. To file a complaint with Robin Care, please contact Robin Care at privacy@getrobincare.com or via US postal mail at the following address:
              </p>
            </li>
          </ul>
          <p>
            Robin Care, Inc.<br/>
            5540 Centerview Dr<br/>
            Ste 204 #14572<br/>
            Raleigh, North Carolina 27606
          </p>
        </div>
      </div>
      <MarketingFooter />
    </div>
  )
}
const WebView = () => {
  return (
    <div style={{width: '100%', overflow: 'scroll'}}>
      <div id='marketingWhiteContainer' style={{ backgroundColor: 'white', paddingTop: '50px', flex:1 ,display:'flex',flexDirection:'column',alignItems:'center',paddingLeft:24,paddingRight:24 }}>
        <div id='marketingSubContainer' style={{ maxWidth: '900px', minWidth: '600px' }}>
          <p>Privacy Policy</p>
          <p>Robin Care, Inc</p>
          <p>Last Updated: June 26, 2019</p>
          <p>THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION AND OTHER INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW THIS PRIVACY POLICY CAREFULLY.</p>
          <p>
            This Privacy Policy, together with our Terms of Use (the “Terms of Use”), form the Agreement by which you may access or use the websites located at www.robincare.com, www.getrobincare.com, other related websites and mobile applications (collectively, the “Site”). Through these sites, Robin Care, Inc. (“Robin Care”) operates various online services that enable members (“Members”) to receive cancer support services from healthcare assistants located in the U.S. This Online Privacy Policy (“Privacy Policy’) explains Robin Care’s privacy practices that apply to the services (collectively, the “Services”) we provide through our Sites.
          </p>
          <p>
            This policy applies to information we collect from you orally, in e-mail and/or text, and from other electronic communications between you and Robin Care and its health assistants employed or under contract with Robin Care, whether or not through mobile and desktop applications you download, and when you interact with our advertising and applications on third-party websites and services (if those applications or advertising include links to this Privacy Policy). All capitalized terms used herein without definition shall have the respective meanings ascribed thereto in the Terms of Use.
          </p>
          <p>
            We do not sell, share, rent or trade the information we have collected about you, including Personal Information (as such term is hereinafter defined), other than as disclosed within this Privacy Policy or at the time you provide Your Information.
          </p>
          <ul>
            <li style={{listStyleType:'upper-roman'}}>Collection of Your Information:<ul>
              <li style={{listStyleType:'upper-alpha'}}>
                <u>Information Collected</u>: In order to provide you with the Services, Robin Care will collect certain categories of personal information, including, without limitation, information that could reasonably be used to identify you personally (e.g., such as your name, e-mail address, birthdate, mobile number and credit card) (“Personal Information“). Your health or medical information may be collected and used by Robin Care to enable the provision of the Services to you (“Health Information”). This information may be collected at various times and from various sources, including, but not limited to, when you register to use the Robin Care website, when you subscribe to use the Services, and when you request certain Services. Additionally, Robin Care may collect information about your internet connection, the equipment you use to access our website and application. Robin Care may also automatically collect information through cookies, web beacons, embedded scripts and other tracking technologies as you navigate through the website and from your usage of any application, your computer, mobile or other device used to access the Services (any, a “Device“). All of the information collected by Robin Care about you, including Health Information and Personal Information shall be collectively referred to in this Policy as “Your Information”.
              </li>
            </ul></li>
            <li style={{listStyleType:'upper-roman'}}>How May Robin Care Use and Disclose Your Information<ul>
              <li style={{listStyleType:'upper-alpha'}}>
                <u>Use of Your Information</u>: Your Information is collected to provide you with a positive experience when using the Services. In addition to using your information for the purposes described above:
                  <ul>
                    <li style={{listStyleType:'disc'}}><u>For Services.</u> We may use or disclose Your Information to provide you with the Services, and so may disclose it to, without limitation, nurses, doctors or other employees, subcontractors or agents personnel who are involved in providing you healthcare as part of the Services.</li>
                    <li style={{listStyleType:'disc'}}><u>For Payment.</u> We may use and disclose Your Information so that the Services we provide may be billed to and payment may be collected from you, an insurance company or a third party (if applicable). You may restrict disclosures by us of medical information to your health plan regarding services you paid for yourself in full.</li>
                    <li style={{listStyleType:'disc'}}><u>For Our Operations.</u> We may use and disclose Your Information to facilitate our operations. For example, we may do this by monitoring which features of the Services are used the most, identifying your address, phone number and current location. We may share your information, including Your Information with our parent, subsidiaries and affiliates, for internal and operational reasons. We may also use Your Information for troubleshooting and in some cases, marketing purposes. In addition, we may use Your Information:
                      <ul>
                        <li style={{listStyleType:'circle'}}>To review your history of Services;</li>
                        <li style={{listStyleType:'circle'}}>To determine if you have any restrictions on any type of Service that may be provided;</li>
                        <li style={{listStyleType:'circle'}}>To recommend certain products and/or Services;</li>
                        <li style={{listStyleType:'circle'}}>To provide you with information or services or process transactions that you have requested or agreed to receive, including to send you electronic newsletters, or to provide you with special offers or promotional materials on behalf of us or third parties;</li>
                        <li style={{listStyleType:'circle'}}>To process your registration with the Services, including verifying your information is active and valid;</li>
                        <li style={{listStyleType:'circle'}}>To improve the Services or our services, to customize your experience with the Services, or to serve you specific content that is most relevant to you;</li>
                        <li style={{listStyleType:'circle'}}>To enable you to participate in a variety of the Services’ features;</li>
                        <li style={{listStyleType:'circle'}}>To contact you with regard to your use of the Services and, in our discretion, changes to the Services and/or the Services’ policies;</li>
                        <li style={{listStyleType:'circle'}}>For internal business purposes; and</li>
                        <li style={{listStyleType:'circle'}}>For inclusion in our data analytics.</li>
                      </ul>
                    </li>
                    <li style={{listStyleType:'disc'}}><u>Appointment Reminders.</u> We may use and disclose Your Information to contact you as a reminder that you have an appointment with a Robin Care Health Assistant.</li>
                    <li style={{listStyleType:'disc'}}><u>Treatment Alternatives.</u> We may use and disclose medical information to tell you about, or recommend, possible Service or care options that may be of interest to you.</li>
                    <li style={{listStyleType:'disc'}}><u>Benefits and Services.</u> We may use and disclose medical information to tell you about benefits or services that may be of interest to you.</li>
                    <li style={{listStyleType:'disc'}}><u>Contractors and Agents.</u> In certain cases, we will provide your information to contractors, agents and other parties who need the information in order to perform a service for us, such as providing you with Services or obtaining payment for Services or carrying out business operations. Another example is that we may share your information with an insurance company, law firm or risk management organization in order to maintain professional advice about how to manage risk and legal liability, including insurance or legal claims. However, you should know that in these situations, we require third parties to provide us with assurances that they will safeguard your information.</li>
                    <li style={{listStyleType:'disc'}}><u>Use of Your Information for Service Related Information.</u> We will send you service-related announcements on those occasions when it is necessary to do so. For instance, if our Services are temporarily suspended for maintenance, we might send you an email. Generally, you may not opt-out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account.</li>
                  </ul>
              </li>
              <li style={{listStyleType:'upper-alpha'}}>
                <u>Disclosure of Information to Other Third Parties.</u>
                  <ul>
                    <li style={{listStyleType:'disc'}}><u>Disclosure of Non Personally Identifiable Information</u>: Robin Care may share non-personally identifiable (or “de-identified”) information, such as aggregated user statistics and log data, with third parties for industry analysis, demographic profiling, to deliver targeted advertising about other products or services, or for other business purposes.</li>
                    <li style={{listStyleType:'disc'}}><u>Agreeing to Receive Information from Third Parties</u>: You may be presented with an opportunity to receive information and/or marketing offers directly from third parties. For example, we may use Your Information to enable us to push to you advertisements from our advertisers’ for their target audiences. Even though we do not disclose your personal information to our advertisers for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria. We do not share your Your Information with third parties for those third parties’ direct marketing purposes to you unless you consent to such sharing at the time you provide Your Information. If you do agree to have Your Information shared, Your Information will be disclosed to such third parties and all information you disclose will be subject to the privacy policy and practices of such third parties. We are not responsible for the privacy policies and practices of such third parties and, therefore, you should review the privacy policies and practices of such third parties prior to agreeing to receive such information from them. If you later decide that you no longer want to receive communication from a third party, you will need to contact that third party directly.</li>
                    <li style={{listStyleType:'disc'}}><u>Administrative and Legal Reasons for Disclosure of Information</u>: We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. Thus, we may access, use, preserve, transfer and disclose your information (including, Your Information), including disclosure to third parties such as government or law enforcement officials or private parties as we reasonably determine is necessary and appropriate:
                      <ul>
                        <li style={{listStyleType:'circle'}}>To satisfy any applicable law, regulation, subpoenas, governmental requests or legal process court order, warrant, summons or similar process (We may release Your Information to authorized federal officials for intelligence, counterintelligence, and other national security activities authorized by law);</li>
                        <li style={{listStyleType:'circle'}}>To defend lawsuits and disputes arising from the Agreement;</li>
                        <li style={{listStyleType:'circle'}}>To protect and/or defend the Agreement or other policies applicable to any online and mobile Services, including investigation of potential violations thereof;</li>
                        <li style={{listStyleType:'circle'}}>To protect the safety, rights, property or security of Robin Care, our Services or any third party;</li>
                        <li style={{listStyleType:'circle'}}>To protect the safety of the public for any reason;</li>
                        <li style={{listStyleType:'circle'}}>To identify or locate a suspect, fugitive, material witness, or missing person;</li>
                        <li style={{listStyleType:'circle'}}>To detect, prevent or otherwise address fraud, security or technical issues; and</li>
                        <li style={{listStyleType:'circle'}}>To prevent or stop activity we may consider to be, or to pose a risk of being, an illegal, unethical, criminal or legally actionable activity.</li>
                      </ul>
                    </li>
                  </ul>
              </li>
            </ul></li>
            <p>
              We may use IP address or other Device Identifiers, to identify users, and may do so in cooperation with third parties such as copyright owners, internet service providers, wireless service providers and/or law enforcement agencies, including disclosing such information to third parties, all in our discretion. Such disclosures may be carried out without notice to you.
            </p>
            <ul>
              <li style={{listStyleType:'disc'}}><u>Reasons for Disclosure of Your Information</u>: We also reserve the right to disclose and transfer all such information:
                <ul>
                  <li style={{listStyleType:'circle'}}>To public health reporting and other governmental healthcare oversight activities. Your health information may be disclosed to public health agencies as required by law.</li>
                  <li style={{listStyleType:'circle'}}>In connection with a corporate merger, consolidation, restructuring, the sale of substantially all of the outstanding stock and/or assets or other corporate change, including, during the course of any due diligence process;</li>
                  <li style={{listStyleType:'circle'}}>To comply with any court order, law or legal process, including to respond to any government or regulatory request;</li>
                  <li style={{listStyleType:'circle'}}>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Robin Care our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
                </ul>
              </li>
            </ul>
            <li style={{listStyleType:'upper-roman'}}>Your Rights<ul>
              <li style={{listStyleType:'upper-alpha'}}>
                You may have certain rights under the state and federal privacy standards. These include:
                <ul>
                  <li style={{listStyleType:'disc'}}><u>Right to Request Restrictions.</u> You have the right to request restrictions on our uses and disclosures of Your Information. However, we are not required to agree to your request unless the disclosure is to a health plan, Your Information pertains solely to health care items or services for which you have paid the bill in full, and the disclosure is not otherwise required by law.</li>
                  <li style={{listStyleType:'disc'}}><u>Alternative Communication Means.</u> You have the right to reasonably request to receive confidential communications of Your Information by alternative means or at alternative locations.</li>
                  <li style={{listStyleType:'disc'}}><u>Right to Inspect a Copy.</u> The right to inspect and copy your Health Information by submitting a written request detailing what information you want access to, whether you want to inspect it or get a copy of it, and if you want a copy, your preferred form and format. We will provide copies in your requested form and format if it is readily producible, or we will provide you with an alternative format you find acceptable. We will charge a reasonable fee which covers our costs for labor, supplies, postage, and if requested and agreed to in advance, the cost of preparing an explanation or summary, as allowed by applicable law. We may deny your request under limited circumstances;</li>
                  <li style={{listStyleType:'disc'}}><u>Right to Amend.</u> The right to amend or submit corrections to your Health Information by submitting a written request including the reasons you believe the information is incorrect or incomplete. We are not required to change your Health Information and will provide you with information regarding our denial of such requested amendment. If we deny your request, you may submit a written statement of your disagreement with that decision, and we may, in turn, prepare a written rebuttal;</li>
                  <li style={{listStyleType:'disc'}}><u>Right to an Accounting.</u> The right to receive an accounting of how and to whom your protected health information has been disclosed; provided, however, we are not required to provide to you an accounting of disclosures made for the purposes of treatment, payment, health care operations, information provided directly to you, information provided pursuant to your written authorization, and certain government functions; and</li>
                  <li style={{listStyleType:'disc'}}><u>Printed Copy.</u> The right to receive a printed copy of this notice.</li>
                </ul>
              </li>
            </ul></li>
            <li style={{listStyleType:'upper-roman'}}>Right to Revise Privacy Policy.<ul>
              <li style={{listStyleType:'upper-alpha'}}>
                You may have certain rights under the state and federal privacy standards. These include:
                <ul>
                  <li style={{listStyleType:'disc'}}><u>Right to Change or Amend Privacy Policy.</u> Robin Care has the right to amend its Privacy Policy at any time without notice to you by posting the revised Privacy Policy on this Website. You agree that you are bound by those changes by continuing to use the Website.</li>
                </ul>
              </li>
            </ul></li>
            <li style={{listStyleType:'upper-roman'}}>Your Information After Cancelling Account.
              <p>
                You are responsible for maintaining the accuracy of the information you submit to Robin Care, such as your contact information provided as part of account registration. If Your Information changes, or if you no longer desire to use the Services, you may correct, delete inaccuracies, or amend information by making the change on our member information page or by contacting us. We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable. Robin Care will retain Your Information for as long as your account with the Services is active and as needed to provide you services. Even after your account is terminated, we will retain your Personal Information as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to cancellation of an account (such as addressing chargebacks from your credit card companies), investigate or prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reason. After a period of time, your data may be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our Services effectively, but our use of the anonymized data will be solely for analytic purposes.
              </p>
            </li>
            <li style={{listStyleType:'upper-roman'}}>Complaints.
              <p>
                If you believe your privacy rights have been violated, you may file a complaint with Robin Care or with the Secretary of the Department of Health and Human Services. To file a complaint with Robin Care, please contact Robin Care at privacy@getrobincare.com or via US postal mail at the following address:
              </p>
            </li>
          </ul>
          <p>
            Robin Care, Inc.<br/>
            5540 Centerview Dr<br/>
            Ste 204 #14572<br/>
            Raleigh, North Carolina 27606
          </p>
        </div>
      </div>
      <MarketingFooter />
    </div>
  )
}
