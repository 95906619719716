import React, { useState } from 'react'
import _ from 'lodash'

import Tooltip from '@material-ui/core/Tooltip'
import Close from '@material-ui/icons/Close'
import { Session, adminOpenTab } from '../../shared/globals'
import UserList from './UserList'
import Home from '../Home'
import AdminSection from './AdminSection'

const AdminPanel = () => {
  const [,refreshState] = useState()
  const refresh = () => {
    refreshState(Date.now())
  }

  const closeTab = (index) => {
    Session.userTabs.splice(index,1)
    refresh()
  }

  Session.refreshAdminPanel=refresh
// console.log('RENDER ADMINPANEL')
  if (Session.auth && _.get(Session,'userClaims.isAdmin',false)) {
    return (
      <div key={'ADMINPANEL'} style={{backgroundColor: '#F1F1F1', display: 'flex', flexDirection: 'row', flex: 1, overflow: 'hidden', paddingTop: '20px'}}>
        <UserList />
        <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 8, paddingRight: 8}}>
          <div id='userTabs' style={{display: Session.userTabs.length < 3 ? 'none' : 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '12px', border: '1px solid #DDDDDD', backgroundColor: '#E6E6E6', borderRadius: '4px', overflowX:'scroll',padding:4}}>
            {
              _.map(Session.userTabs,(uid,index)=>{
                if(index < 2) return null
                if(!_.get(Session,`users.${uid}.profile`,null)) return null
                return (
                  <Tooltip key={`USERS-${uid}`} title={''} placement="top">
                    <div className='outerUserTab' style={{height: '40px', margin:4,color:'white',padding:'8px',boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',backgroundColor:true?'#ECECEC':'#FF7391',borderRadius:4,cursor:'pointer',alignItems: 'center', display: 'flex'}}>
                      <div className='innerUserTab' style={{height: '33px', display:'flex', flexDirection:'row', backgroundColor: '#fff5e0', borderRadius: '4px', padding: '4px'}}>
                        <Tooltip title="Close" placement="top">
                          <div style={{marginLeft: '5px', display: 'flex', flexDirection: 'row', alignItems: 'center',color:'#D6D6D6'}} onClick={() => { closeTab(index) }}>
                            <Close/>
                          </div>
                        </Tooltip>
                        <div id='patientIdAndName' onClick={()=>adminOpenTab(uid)} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <div id='patientId' style={{fontWeight: 'bold', fontSize: '13px', color:'#504D4D'}}>
                            {_.get(Session,`users.${uid}.profile.userCount`,'')}
                          </div>
                          <div id='patientFullName' style={{marginLeft: '7px', color: '#504D4D', fontSize: '14px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            {_.startCase(_.get(Session,`users.${uid}.profile.firstName`,''))} {
                              _.trim(_.get(Session,`users.${uid}.profile.nickname`,'')).length > 0 && _.trim(_.get(Session,`users.${uid}.profile.nickname`,'')) !== _.trim(_.get(Session,`users.${uid}.profile.firstName`,'')) ?
                              `[${_.startCase(_.get(Session,`users.${uid}.profile.nickname`,''))}]` : null
                            } {_.startCase(_.get(Session,`users.${uid}.profile.lastName`,''))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                )
              })
            }
          </div>
          <div id='userPanel' style={{flex:1,display: 'flex', flexDirection: 'row', overflow: 'hidden', overflowX:'scroll'}}>
            {
              _.map(Session.userTabs,(uid,index)=>{
                if(index > 1) return null
                if(!_.get(Session,`users.${uid}.profile`,null)) return null
                return (
                  <Home key={`USERTAB-${uid}`} uid={uid} index={index} closeTab={closeTab} />
                )
              })
            }
          </div>
        </div>
        <div style={{flex:1}}></div>
        <AdminSection />
      </div>
    )
  } else {
    return null
  }
}
export default AdminPanel
