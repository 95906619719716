import React, { useState } from 'react';
import _ from 'lodash'

import Tooltip from '@material-ui/core/Tooltip'
import { withStyles, Theme } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Edit from '@material-ui/icons/Edit'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ArchiveOutlined from '@material-ui/icons/ArchiveOutlined'
import LocalOfferOutlined from '@material-ui/icons/LocalOfferOutlined'
import Today from '@material-ui/icons/Today'
import CollectionsBookmarkOutlined from '@material-ui/icons/CollectionsBookmarkOutlined'
import PhoneOutlined from '@material-ui/icons/PhoneOutlined'

import { Session } from '../shared/globals'
import { DialogEditMessage, DialogEventDetails, DialogTags, DialogDeletePost, DialogNotes, DialogPhoneLog } from './dialogs'

const LightTooltip = withStyles((theme: Theme) => ({
  popper: {
    zIndex:500,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    borderRadius: '3px',
    cursor: 'pointer',
    '& .feedbackItem': {
      height: '22px',
      paddingLeft: '2px',
      paddingRight: '2px',
      '&:hover': {
        background: "rgb(221, 223, 226)",
      }
    }
  },
}))(Tooltip);
const styles = () => ({
  root: {
    boxSizing: 'border-box',
    border:'solid 10px red'
  },
});

const PostActions = ({ user,post,classes }) => {
  const [open, setOpen] = useState(false)
  const [dialogOpen,setDialogOpen] = useState('')
  const dialogHide = () => {
    setOpen(false)
    setDialogOpen('')
  }
  if(user.uid==='PUBLICBLOG') return null
  return (
    <ClickAwayListener onClickAway={()=>setOpen(false)}>
      <div id="feedbackButton" style={{cursor: 'pointer', verticalAlign: 'center', fontSize: 'inherit'}}>
        <LightTooltip PopperProps={{ disablePortal: true }} onClose={()=>setOpen(false)} open={open} disableFocusListener disableHoverListener disableTouchListener placement='bottom' interactive title={
          <React.Fragment>
            <div id='feedbackItemList' style={{ display: 'flex', flexDirection: 'column', width: 240, letterSpacing: '-0.07px' }}>
              {
                !_.get(Session,'userClaims.isAdmin',false) ? null :
                <div className="feedbackItem" id='LocalOfferOutlined' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} onClick={()=>setDialogOpen('DialogTags')}>
                  <LocalOfferOutlined fontSize="small"/>
                  <div id='moreOptionsEdit' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                    {`Tag this message`}
                  </div>
                </div>
              }
              {
                !_.get(Session,'userClaims.isAdmin',false) ? null :
                <div className="feedbackItem" id='CollectionsBookmarkOutlined' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} onClick={()=>setDialogOpen('DialogNotes')}>
                  <CollectionsBookmarkOutlined fontSize="small" />
                  <div id='moreOptionsEdit' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                    {`Create Sticky Note`}
                  </div>
                </div>
              }
              {
                !_.get(Session,'userClaims.isAdmin',false) ? null :
                <div className="feedbackItem" id='PhoneOutlined' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} onClick={()=>setDialogOpen('DialogPhoneLog')}>
                  <PhoneOutlined fontSize="small" />
                  <div id='moreOptionsEdit' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                    {`Create Communication Log`}
                  </div>
                </div>
              }
              {
                post.createdById !== Session.uid ? null :
                <div className="feedbackItem" id='feedbackItemRemove' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }} onClick={()=>setDialogOpen('DialogEditMessage')}>
                  <Edit fontSize="small"/>
                  <div id='moreOptionsEdit' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                    {`Edit ${post.isThread ? 'Thread' : 'Comment'}`}
                  </div>
                </div>
              }
              {
                post.createdById === 'PUBLICBLOG' || post.createdById === Session.uid || (post.isComment && post.createdById === Session.uid) || (post.isThread && user.uid === Session.uid) || (post.isThread && _.get(Session,'userClaims.isAdmin',false)) ?
                <div className="feedbackItem" id='deletePost' style={{ color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '3px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}} onClick={()=>setDialogOpen('DialogDeletePost')}>
                  {post.isThread?<ArchiveOutlined fontSize='small'/>:<DeleteOutline fontSize='small'/>}
                  <div id='moreOptionsEvent' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                    {post.isThread?'Archive this thread':'Delete this comment'}
                  </div>
                </div>
                : null
              }
              <div className="feedbackItem" id='addEvent' style={{ color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '3px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}} onClick={()=>setDialogOpen('DialogEventDetails')}>
                <Today fontSize='small'/>
                <div id='moreOptionsEvent' style={{ marginLeft: '5px', color: '#616770', lineHeight: '17px', fontSize: '13px', paddingTop: '2px'}}>
                  {_.has(post,'eventDetails.epoc')?'Edit Event or Reminder':'Add Event or Reminder'}
                </div>
              </div>
            </div>
          </React.Fragment>
        }>
          <IconButton size='small' onClick={()=>setOpen(true)}>
            <MoreHoriz/>
          </IconButton>
        </LightTooltip>
        {dialogOpen!=='DialogPhoneLog'?null:<DialogPhoneLog open={dialogOpen==='DialogPhoneLog'} dialogParentHide={dialogHide} post={post} adminDoc={_.get(_.filter(Session.adminDocs,{postId:post.id,docType:'phone'}),'[0]',{id:null})} user={user}/>}
        {dialogOpen!=='DialogNotes'?null:<DialogNotes open={dialogOpen==='DialogNotes'} dialogParentHide={dialogHide} post={post} adminDoc={_.get(_.filter(Session.adminDocs,{postId:post.id,docType:'note'}),'[0]',{id:null})} user={user}/>}
        {dialogOpen!=='DialogTags'?null:<DialogTags open={dialogOpen==='DialogTags'} dialogParentHide={dialogHide} post={post} user={user}/>}
        {dialogOpen!=='DialogEditMessage'?null:<DialogEditMessage open={dialogOpen==='DialogEditMessage'} dialogParentHide={dialogHide} post={post} user={user}/>}
        {dialogOpen!=='DialogEventDetails'?null:<DialogEventDetails open={dialogOpen==='DialogEventDetails'} dialogParentHide={dialogHide} post={post} user={user}/>}
        {dialogOpen!=='DialogDeletePost'?null:<DialogDeletePost open={dialogOpen==='DialogDeletePost'} dialogParentHide={dialogHide} post={post} user={user}/>}
      </div>
    </ClickAwayListener>
  )
}
export default withStyles(styles)(PostActions)
