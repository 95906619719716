import React from 'react'
import Responsive from 'react-responsive'
import MarketingFooter from './MarketingFooter'
import { Session } from '../shared/globals'

const Mobile = props => <Responsive {...props} maxWidth={Session.auth ? 1099 : 799} />;
const Default = props => <Responsive {...props} minWidth={Session.auth ? 1100 : 800} />;

const MarketingTerms = ({ match }) => (
  <div style={{width:'100%'}}>
    <Mobile>
      <MobileView match={match} />
    </Mobile>
    <Default>
      <WebView match={match} />
    </Default>
  </div>
);
export default MarketingTerms

const MobileView = () => {
  return (
    <div style={{width: '100%', overflow: 'scroll'}}>
      <div id='marketingWhiteContainer' style={{ backgroundColor: 'white', paddingTop: '50px', flex:1 ,display:'flex',flexDirection:'column',alignItems:'center',paddingLeft:24,paddingRight:24 }}>
        <div id='marketingSubContainer' style={{ maxWidth: '100%', minWidth: '350px' }}>
          <p>Robin Care, Inc. Terms of Use</p>
          <p>IF YOU HAVE A MEDICAL EMERGENCY, IMMEDIATELY CALL 911 TO GET PROMPT MEDICAL ATTENTION.</p>
          <p>
            IT IS VERY IMPORTANT THAT YOU READ THESE TERMS OF USE (“TERMS OF USE”) VERY CAREFULLY. These Terms of Use govern your use of our online interfaces and properties (e.g., websites and mobile applications) owned and controlled by Robin Care, Inc. (hereinafter “us” or “Robin Care”), including the www.getrobincare.com and www.robincare.com websites (the “Site”) and the services available to users through the Site, including services provided by health assistants employed or under contract with Robin Care (“Robin Care Care Advocates”) to you or a member of your family (collectively, the “Services”). Your compliance with these Terms of Use is a condition to your use of the Site. If you do not agree to be bound by the Terms of Use, promptly exit this Site. Please also consult our Privacy Policy for a description of our privacy practices and policies, including how we collect and handle your personal health information.
          </p>
          <p>
            YOU UNDERSTAND AND AGREE THAT ROBIN CARE SOLELY PROVIDES THE SERVICES AND DOES NOT PROVIDE MEDICAL CARE, MENTAL HEALTH SERVICES OR OTHER PROFESSIONAL MEDICAL SERVICES. YOUR INTERACTIONS WITH THE ROBIN CARE CARE ADVOCATES VIA THE SERVICES ARE NOT INTENDED TO TAKE THE PLACE OF YOUR PRACTITIONER-PATIENT RELATIONSHIP WITH YOUR REGULAR HEALTH CARE PRACTITIONERS. ROBIN CARE DISCLAIMS ANY AND ALL LIABILITY FOR ANY ADVICE OBTAINED FROM A ROBIN CARE CARE ADVOCATE VIA THE SERVICES OR FOR ANY OTHER INFORMATION OBTAINED ON THE SITE. YOU ACKNOWLEDGE THAT YOUR RELIANCE ON INFORMATION PROVIDED BY ROBIN CARE CARE ADVOCATES VIA THE SERVICES IS SOLELY AT YOUR OWN RISK AND YOU ASSUME FULL RESPONSIBILITY FOR ALL RISK ASSOCIATED HEREWITH.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Ownership Of The Site And Related Materials</u></li></ul>
          <p>
            All pages within this Site and any material made available for download are the property of Robin Care, or its licensors or suppliers, as applicable. The Site is protected by United States and international copyright and trademark laws.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Electronic Communications</u></li></ul>
          <p>
            When you use any Robin Care Service, or send e-mails, text messages, and other communications from your desktop or mobile device to Robin Care, you are communicating with us electronically. You hereby consent to receive communications from us electronically. You agree that (a) all agreements and consents can be signed electronically and (b) all notices, disclosures, and other communications that Robin Care provides to you electronically satisfy any legal requirement that such notices and other communications be in writing.
          </p>
          <p>
            In addition, all information received by you from Robin Care via e-mails, text messages, and other communications is transmitted over a medium which is beyond Robin Care’s control and jurisdiction. Accordingly, Robin Care assumes no responsibility for, or relating to, delay, failure, interruption or corruption of any data or other information transmitted in connection with use of this Site or sites accessed through this Site.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Site Access, Security and Restrictions; Passwords</u></li></ul>
          <p>
            If you create a user account for the Robin Care Site, you agree to complete the registration process by providing current, complete, and accurate information as required by Robin Care. You are responsible for all activities that occur under your account. In the event access to the Site or a portion thereof is limited requiring a user ID and password (“Protected Areas”), you agree to access Protected Areas using only your user ID and password as provided to you by Robin Care. You agree to protect the confidentiality of your user ID and password, and not to share or disclose your user ID or password to any third party. You agree that you are fully responsible for all activity occurring under your user ID. Your access to the Site may be revoked by Robin Care at any time with or without cause.
          </p>
          <p>
            You are prohibited from violating or attempting to violate the security of the Site, including, without limitation, (a) accessing data not intended for such user or logging onto a server or an account which the user is not authorized to access; or (b) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; or (c) accessing or using the Site or any portion thereof without authorization, in violation of these Terms of Use or in violation of applicable law. If you are under 18, you may use the Robin Care Services only with involvement of a parent or guardian.
          </p>
          <p>
            You may not use any scraper, crawler, spider, robot or other automated means of any kind to access or copy data on the Site, deep-link to any feature or content on the Site, bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Site.
          </p>
          <p>
            Violations of system or network security may result in civil or criminal liability. Robin Care will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this Site or any activity being conducted on this Site.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>No Medical Advice or Services</u></li></ul>
          <p>
            The content of the Site and the Services, including without limitation, text, copy, audio, video, photographs, illustrations, graphics and other visuals, is for informational purposes only and does not constitute professional medical advice, diagnosis, treatment or recommendations of any kind. You should always seek the advice of your qualified heath care professionals with any questions or concerns you may have regarding your individual needs and any medical conditions. All information provided by Robin Care with respect to the Services or in connection with any communications supported by Robin Care, including but not limited to communications with Robin Care Care Advocates, is intended to be for general informational purposes only. The Site and Services are not a substitute for professional medical diagnosis or treatment. Reliance on any information appearing on the Site, whether provided by Robin Care, its content providers, medical experts, clients, visitors to the Site or others, is solely at your own risk.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Communication and Access to Cancer Care Plan.</u></li></ul>
          <p>
            In connection with the Services, you consent and agree that Robin Care and the Robin Care Care Advocates may have access, to the extent applicable, from time to time, to your Cancer Care Plan in order to provide the Services to you. Robin Care recognizes your privacy and, in accordance with the Privacy Notice, will not release information to anyone without your written authorization or as required or permitted by law. Moreover, you understand and agree a Robin Care Care Advocate may communicate on your behalf with your health care practitioner and may involve the communication of your medical information, both via text or orally, to physicians and other health care practitioners located in other parts of the state/jurisdiction or outside of the state/jurisdiction. You agree that Robin Care Care Advocates may initiate contact with you, from time to time, via e-mail or orally via telephone, to inform you about the Services as well as other health-related products or services related to the Services.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>License And Access</u></li></ul>
          <p>
            Subject to your compliance with these Terms of Use and your payment of any applicable fees, Robin Care or its content providers grant you a limited, non-exclusive, non-transferable, non-sub-licensable license to access and make personal and non-commercial use of the Robin Care Services. This license does not include any resale or commercial use of any Robin Care Service, or its contents; any collection and use of any service listings, descriptions, or prices; any derivative use of any Robin Care Service or its contents; any downloading, copying, or other use of account information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and extraction tools. All rights not expressly granted to you in these Terms of Use are reserved and retained by Robin Care or its licensors, suppliers, publishers, rights holders, or other content providers. No Robin Care Service, nor any part of any Robin Care Service, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Robin Care. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Robin Care without express written consent. You may not use any meta tags or any other “hidden text” utilizing Robin Care’s name or trademarks without the express written consent of Robin Care. You may not misuse the Robin Care Services. You may use the Robin Care Services only as permitted by law. The licenses granted by Robin Care terminate if you do not comply with these Terms of Use.
          </p>
          <p>
            The Contents of the Site, including without limitation the files, documents, text, photographs, images, audio, and video, and any materials accessed through or made available for use or download through this Site (“Content”) may not be copied, distributed, modified, reproduced, published or used, in whole or in part, except for purposes authorized or approved in writing by Robin Care. You may not frame or utilize framing techniques to enclose, or deep linking to, any name, trademarks, service marks, logo, Content or other proprietary information (including; images, text, page layout, or form) of Robin Care without our express written consent.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Accuracy and Integrity of Information</u></li></ul>
          <p>
            Although Robin Care attempts to ensure the integrity and accurateness of the Site, it makes no representations, warranties or guarantees whatsoever as to the correctness or accuracy of the Site and Content thereon. It is possible that the Site could include typographical errors, inaccuracies or other errors, and that unauthorized additions, deletions and alterations could be made to the Site by third parties. In the event that an inaccuracy arises, please inform Robin Care so that it can be corrected. Information contained on the Site may be changed or updated without notice. Additionally, Robin Care shall have no responsibility or liability for information or Content posted to the Site from any non-Robin Care affiliated third party.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Links to Other Sites</u></li></ul>
          <p>
            Robin Care makes no representations whatsoever about any other website that you may access through this Site. When you access a non-Robin Care site, please understand that it is independent from Robin Care, and that Robin Care has no control over the Content on that website. In addition, a link to a non-Robin Care website does not mean that Robin Care endorses or accepts any responsibility for the Content, or the use, of the linked site. It is up to you to take precautions to ensure that whatever you select for your use or download is free of such items as viruses, worms, Trojan horses, and other items of a destructive nature. If you decide to access any of the third party sites linked to this Site, you do this entirely at your own risk.?
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>User Information</u></li></ul>
          <p>
            If you submit, upload, post or transmit any health information, medical history, conditions, problems, symptoms, personal information, consent forms, agreements, requests, comments, ideas, suggestions, information, files, videos, images or other materials to us or our Site (“User Information”), you agree not to provide any User Information that (1) is false, inaccurate, defamatory, abusive, libelous, unlawful, obscene, threatening, harassing, fraudulent, pornographic, or harmful, or that could encourage criminal or unethical behavior, (2) violates or infringes the privacy, copyright, trademark, trade dress, trade secrets or intellectual property rights of any person or entity, or (3) contains or transmits a virus or any other harmful component. You agree not to contact other site users through unsolicited e-mail, telephone calls, mailings or any other method of communication. You represent and warrant to Robin Care and its Robin Care Care Advocates that you have the legal right and authorization to provide all User Information to Robin Care and its Robin Care Care Advocates for use as set forth herein and required by Robin Care and the Robin Care Care Advocates.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Claims of Copyright Infringement</u></li></ul>
          <p>
            Robin Care disclaims any responsibility or liability for copyrighted materials posted on the Site. If you believe that your work has been copied in a manner that constitutes copyright infringement, please follow the procedures set forth below.
          </p>
          <p>
            Robin Care respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act (“DMCA”), Robin Care will respond promptly to notices of alleged infringement that are reported to Robin Care’s Designated Copyright Agent, identified below.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Notices of Alleged Infringement for Content Made Available on the Site</u></li></ul>
          <p>
            If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through our Site by sending us a notice (“Notice”) complying with the following requirements.
          </p>
          <ul>
            <li style={{listStyleType:'decimal'}}>Identify the copyrighted works that you claim have been infringed.</li>
            <li style={{listStyleType:'decimal'}}>Identify the material or link you claim is infringing (or the subject of infringing activity) and that access to which is to be disabled, including at a minimum, if applicable, the URL of the link shown on the Site where such material may be found.</li>
            <li style={{listStyleType:'decimal'}}>Provide your mailing address, telephone number, and, if available, email address.</li>
            <li style={{listStyleType:'decimal'}}>Include both of the following statements in the body of the Notice:<ul>
              <li style={{listStyleType:'none'}}>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).”</li>
              <li style={{listStyleType:'none'}}>“I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”</li>
            </ul></li>
            <li style={{listStyleType:'decimal'}}>Provide your full legal name and your electronic or physical signature.</li>
          </ul>
          <p>
            Deliver this Notice, with all items completed, to legal@getrobincare.com
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Disclaimer of Warranties</u></li></ul>
          <p>
            ROBIN CARE DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SITE WILL BE CORRECTED. THIS SITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR ANY SITE-RELATED SERVICE, IS PROVIDED “AS IS,” WITH ALL FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY OF INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT. ROBIN CARE DOES NOT WARRANT THE ACCURACY, COMPLETENESS OR TIMELINESS OF THE INFORMATION OBTAINED THROUGH THE SITE.
          </p>
          <p>
            YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE, SITE-RELATED SERVICES, AND LINKED WEBSITES. ROBIN CARE DOES NOT WARRANT THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS, TROJAN HORSES OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING PROCEDURES SUFFICIENT TO SATISFY YOUR NEEDS FOR DATA BACK UP AND SECURITY.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Limitation of Liability Regarding Use of Site</u></li></ul>
          <p>
            ROBIN CARE AND ANY THIRD PARTIES MENTIONED ON THIS SITE ARE NEITHER RESPONSIBLE NOR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, SITE-RELATED SERVICES, CONTENT OR INFORMATION CONTAINED WITHIN THE SITE, AND/OR ANY LINKED WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE, SITE-RELATED SERVICES, AND/OR LINKED WEBSITES IS TO STOP USING THE SITE AND/OR THOSE SERVICES. TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, THE MAXIMUM LIABILITY OF ROBIN CARE TO YOU WITH RESPECT TO YOUR USE OF THIS SITE IS $1000 (ONE THOUSAND DOLLARS).
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Dispute Resolution</u></li></ul>
          <p>
            Robin Care will try work in good faith to resolve any issue you have with Site, including services ordered or purchased through the Site, if you bring that issue to the attention of our customer service department. However, Robin Care realizes that there may be rare cases where we may not be able to resolve an issue to a customer’s satisfaction. In the event we cannot resolve a dispute between us, you agree that all matters related to any use or access of the Site, the Services or any other Robin Care site will be governed by the laws of the State of California, without regard to its conflicts of laws rules. You hereby waive any objections to such jurisdiction or venue. Any claims or controversies arising out of this Agreement, and/or the related use and access of the Site, the Services or any other Robin Care site, shall be exclusively in the state courts of California.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Force Majeure</u></li></ul>
          <p>
            We will not be deemed to be in breach of these terms or liable for any breach of these terms or our privacy policy due to any event or occurrence beyond our reasonable control, including without limitation, acts of God, terrorism, war, invasion, failures of any public networks, electrical shortages, earthquakes or floods, civil disorder, strikes, fire or other disaster.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Indemnity</u></li></ul>
          <p>
            You agree to defend, indemnify and hold Robin Care harmless from and against all third party claims, damages and expenses (including reasonable attorneys’ fees) against or incurred by Robin Care arising out of your breach of these Terms of Use or violation of applicable law, or access by anyone accessing the Site using your user ID and password. Notwithstanding the foregoing or anything to the contrary herein, Robin Care shall only exercise its rights in this Section after addressing applicable claims with Your employer or health plan should you be accessing the Site or Services through your employer or health plan.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Adverse Technical Events.</u></li></ul>
          <p>
            All information is transmitted over a medium which is beyond our control and jurisdiction. Accordingly, Robin Care assumes no responsibility for, or relating to, delay, failure, interruption or corruption of any data or other information transmitted in connection with use of this Site
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Right to Modify Revisions; General</u></li></ul>
          <p>
            Robin Care reserves the right to modify these Terms of Use at any time, effective upon posting. Any use of this website after such changes will be deemed an acceptance of those changes. You agree to review the Terms of Use each time you access this Site so that you may be aware of any changes to these Terms. Robin Care uses Google Analytics, a third-party tracking service, which uses cookies to track non-personal identifiable information about our visitors to our main site in the aggregate to capture usage and volume statistics. Robin Care has no access to or control over these cookies. The companion Privacy Policy covers the use of cookies by Robin Care only and does not cover the use of cookies by any third-party. Robin Care reserves the right, in its sole discretion, to terminate your access to all or part of this Site, with or without cause, and with or without notice. In the event that any of the Terms of Use are held by a court or other tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary so that these Terms of Use shall otherwise remain in full force and effect. These Terms of Use constitute the entire agreement between Robin Care and you pertaining to the subject matter hereof. In its sole discretion, Robin Care may from time-to-time revise these Terms of Use by updating this posting. You should, therefore, periodically visit this page to review the current Terms of Use, so you are aware of any such revisions to which you are bound. Certain provisions of these Terms of Use may be superseded by expressly designated legal notices or terms located on particular pages within this Site.
          </p>
          <p>Revised 15 October 2018</p>
        </div>
      </div>
      <MarketingFooter />
    </div>
  )
}
const WebView = () => {
  return (
    <div style={{width: '100%', overflow: 'scroll'}}>
      <div id='marketingWhiteContainer' style={{ backgroundColor: 'white', paddingTop: '50px', flex:1 ,display:'flex',flexDirection:'column',alignItems:'center',paddingLeft:24,paddingRight:24 }}>
        <div id='marketingSubContainer' style={{ maxWidth: '900px', minWidth: '600px' }}>
          <p>Robin Care, Inc. Terms of Use</p>
          <p>IF YOU HAVE A MEDICAL EMERGENCY, IMMEDIATELY CALL 911 TO GET PROMPT MEDICAL ATTENTION.</p>
          <p>
            IT IS VERY IMPORTANT THAT YOU READ THESE TERMS OF USE (“TERMS OF USE”) VERY CAREFULLY. These Terms of Use govern your use of our online interfaces and properties (e.g., websites and mobile applications) owned and controlled by Robin Care, Inc. (hereinafter “us” or “Robin Care”), including the www.getrobincare.com and www.robincare.com websites (the “Site”) and the services available to users through the Site, including services provided by health assistants employed or under contract with Robin Care (“Robin Care Care Advocates”) to you or a member of your family (collectively, the “Services”). Your compliance with these Terms of Use is a condition to your use of the Site. If you do not agree to be bound by the Terms of Use, promptly exit this Site. Please also consult our Privacy Policy for a description of our privacy practices and policies, including how we collect and handle your personal health information.
          </p>
          <p>
            YOU UNDERSTAND AND AGREE THAT ROBIN CARE SOLELY PROVIDES THE SERVICES AND DOES NOT PROVIDE MEDICAL CARE, MENTAL HEALTH SERVICES OR OTHER PROFESSIONAL MEDICAL SERVICES. YOUR INTERACTIONS WITH THE ROBIN CARE CARE ADVOCATES VIA THE SERVICES ARE NOT INTENDED TO TAKE THE PLACE OF YOUR PRACTITIONER-PATIENT RELATIONSHIP WITH YOUR REGULAR HEALTH CARE PRACTITIONERS. ROBIN CARE DISCLAIMS ANY AND ALL LIABILITY FOR ANY ADVICE OBTAINED FROM A ROBIN CARE CARE ADVOCATE VIA THE SERVICES OR FOR ANY OTHER INFORMATION OBTAINED ON THE SITE. YOU ACKNOWLEDGE THAT YOUR RELIANCE ON INFORMATION PROVIDED BY ROBIN CARE CARE ADVOCATES VIA THE SERVICES IS SOLELY AT YOUR OWN RISK AND YOU ASSUME FULL RESPONSIBILITY FOR ALL RISK ASSOCIATED HEREWITH.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Ownership Of The Site And Related Materials</u></li></ul>
          <p>
            All pages within this Site and any material made available for download are the property of Robin Care, or its licensors or suppliers, as applicable. The Site is protected by United States and international copyright and trademark laws.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Electronic Communications</u></li></ul>
          <p>
            When you use any Robin Care Service, or send e-mails, text messages, and other communications from your desktop or mobile device to Robin Care, you are communicating with us electronically. You hereby consent to receive communications from us electronically. You agree that (a) all agreements and consents can be signed electronically and (b) all notices, disclosures, and other communications that Robin Care provides to you electronically satisfy any legal requirement that such notices and other communications be in writing.
          </p>
          <p>
            In addition, all information received by you from Robin Care via e-mails, text messages, and other communications is transmitted over a medium which is beyond Robin Care’s control and jurisdiction. Accordingly, Robin Care assumes no responsibility for, or relating to, delay, failure, interruption or corruption of any data or other information transmitted in connection with use of this Site or sites accessed through this Site.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Site Access, Security and Restrictions; Passwords</u></li></ul>
          <p>
            If you create a user account for the Robin Care Site, you agree to complete the registration process by providing current, complete, and accurate information as required by Robin Care. You are responsible for all activities that occur under your account. In the event access to the Site or a portion thereof is limited requiring a user ID and password (“Protected Areas”), you agree to access Protected Areas using only your user ID and password as provided to you by Robin Care. You agree to protect the confidentiality of your user ID and password, and not to share or disclose your user ID or password to any third party. You agree that you are fully responsible for all activity occurring under your user ID. Your access to the Site may be revoked by Robin Care at any time with or without cause.
          </p>
          <p>
            You are prohibited from violating or attempting to violate the security of the Site, including, without limitation, (a) accessing data not intended for such user or logging onto a server or an account which the user is not authorized to access; or (b) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; or (c) accessing or using the Site or any portion thereof without authorization, in violation of these Terms of Use or in violation of applicable law. If you are under 18, you may use the Robin Care Services only with involvement of a parent or guardian.
          </p>
          <p>
            You may not use any scraper, crawler, spider, robot or other automated means of any kind to access or copy data on the Site, deep-link to any feature or content on the Site, bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Site.
          </p>
          <p>
            Violations of system or network security may result in civil or criminal liability. Robin Care will investigate occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of this Site or any activity being conducted on this Site.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>No Medical Advice or Services</u></li></ul>
          <p>
            The content of the Site and the Services, including without limitation, text, copy, audio, video, photographs, illustrations, graphics and other visuals, is for informational purposes only and does not constitute professional medical advice, diagnosis, treatment or recommendations of any kind. You should always seek the advice of your qualified heath care professionals with any questions or concerns you may have regarding your individual needs and any medical conditions. All information provided by Robin Care with respect to the Services or in connection with any communications supported by Robin Care, including but not limited to communications with Robin Care Care Advocates, is intended to be for general informational purposes only. The Site and Services are not a substitute for professional medical diagnosis or treatment. Reliance on any information appearing on the Site, whether provided by Robin Care, its content providers, medical experts, clients, visitors to the Site or others, is solely at your own risk.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Communication and Access to Cancer Care Plan.</u></li></ul>
          <p>
            In connection with the Services, you consent and agree that Robin Care and the Robin Care Care Advocates may have access, to the extent applicable, from time to time, to your Cancer Care Plan in order to provide the Services to you. Robin Care recognizes your privacy and, in accordance with the Privacy Notice, will not release information to anyone without your written authorization or as required or permitted by law. Moreover, you understand and agree a Robin Care Care Advocate may communicate on your behalf with your health care practitioner and may involve the communication of your medical information, both via text or orally, to physicians and other health care practitioners located in other parts of the state/jurisdiction or outside of the state/jurisdiction. You agree that Robin Care Care Advocates may initiate contact with you, from time to time, via e-mail or orally via telephone, to inform you about the Services as well as other health-related products or services related to the Services.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>License And Access</u></li></ul>
          <p>
            Subject to your compliance with these Terms of Use and your payment of any applicable fees, Robin Care or its content providers grant you a limited, non-exclusive, non-transferable, non-sub-licensable license to access and make personal and non-commercial use of the Robin Care Services. This license does not include any resale or commercial use of any Robin Care Service, or its contents; any collection and use of any service listings, descriptions, or prices; any derivative use of any Robin Care Service or its contents; any downloading, copying, or other use of account information for the benefit of any third party; or any use of data mining, robots, or similar data gathering and extraction tools. All rights not expressly granted to you in these Terms of Use are reserved and retained by Robin Care or its licensors, suppliers, publishers, rights holders, or other content providers. No Robin Care Service, nor any part of any Robin Care Service, may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Robin Care. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Robin Care without express written consent. You may not use any meta tags or any other “hidden text” utilizing Robin Care’s name or trademarks without the express written consent of Robin Care. You may not misuse the Robin Care Services. You may use the Robin Care Services only as permitted by law. The licenses granted by Robin Care terminate if you do not comply with these Terms of Use.
          </p>
          <p>
            The Contents of the Site, including without limitation the files, documents, text, photographs, images, audio, and video, and any materials accessed through or made available for use or download through this Site (“Content”) may not be copied, distributed, modified, reproduced, published or used, in whole or in part, except for purposes authorized or approved in writing by Robin Care. You may not frame or utilize framing techniques to enclose, or deep linking to, any name, trademarks, service marks, logo, Content or other proprietary information (including; images, text, page layout, or form) of Robin Care without our express written consent.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Accuracy and Integrity of Information</u></li></ul>
          <p>
            Although Robin Care attempts to ensure the integrity and accurateness of the Site, it makes no representations, warranties or guarantees whatsoever as to the correctness or accuracy of the Site and Content thereon. It is possible that the Site could include typographical errors, inaccuracies or other errors, and that unauthorized additions, deletions and alterations could be made to the Site by third parties. In the event that an inaccuracy arises, please inform Robin Care so that it can be corrected. Information contained on the Site may be changed or updated without notice. Additionally, Robin Care shall have no responsibility or liability for information or Content posted to the Site from any non-Robin Care affiliated third party.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Links to Other Sites</u></li></ul>
          <p>
            Robin Care makes no representations whatsoever about any other website that you may access through this Site. When you access a non-Robin Care site, please understand that it is independent from Robin Care, and that Robin Care has no control over the Content on that website. In addition, a link to a non-Robin Care website does not mean that Robin Care endorses or accepts any responsibility for the Content, or the use, of the linked site. It is up to you to take precautions to ensure that whatever you select for your use or download is free of such items as viruses, worms, Trojan horses, and other items of a destructive nature. If you decide to access any of the third party sites linked to this Site, you do this entirely at your own risk.?
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>User Information</u></li></ul>
          <p>
            If you submit, upload, post or transmit any health information, medical history, conditions, problems, symptoms, personal information, consent forms, agreements, requests, comments, ideas, suggestions, information, files, videos, images or other materials to us or our Site (“User Information”), you agree not to provide any User Information that (1) is false, inaccurate, defamatory, abusive, libelous, unlawful, obscene, threatening, harassing, fraudulent, pornographic, or harmful, or that could encourage criminal or unethical behavior, (2) violates or infringes the privacy, copyright, trademark, trade dress, trade secrets or intellectual property rights of any person or entity, or (3) contains or transmits a virus or any other harmful component. You agree not to contact other site users through unsolicited e-mail, telephone calls, mailings or any other method of communication. You represent and warrant to Robin Care and its Robin Care Care Advocates that you have the legal right and authorization to provide all User Information to Robin Care and its Robin Care Care Advocates for use as set forth herein and required by Robin Care and the Robin Care Care Advocates.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Claims of Copyright Infringement</u></li></ul>
          <p>
            Robin Care disclaims any responsibility or liability for copyrighted materials posted on the Site. If you believe that your work has been copied in a manner that constitutes copyright infringement, please follow the procedures set forth below.
          </p>
          <p>
            Robin Care respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act (“DMCA”), Robin Care will respond promptly to notices of alleged infringement that are reported to Robin Care’s Designated Copyright Agent, identified below.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Notices of Alleged Infringement for Content Made Available on the Site</u></li></ul>
          <p>
            If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through our Site by sending us a notice (“Notice”) complying with the following requirements.
          </p>
          <ul>
            <li style={{listStyleType:'decimal'}}>Identify the copyrighted works that you claim have been infringed.</li>
            <li style={{listStyleType:'decimal'}}>Identify the material or link you claim is infringing (or the subject of infringing activity) and that access to which is to be disabled, including at a minimum, if applicable, the URL of the link shown on the Site where such material may be found.</li>
            <li style={{listStyleType:'decimal'}}>Provide your mailing address, telephone number, and, if available, email address.</li>
            <li style={{listStyleType:'decimal'}}>Include both of the following statements in the body of the Notice:<ul>
              <li style={{listStyleType:'none'}}>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).”</li>
              <li style={{listStyleType:'none'}}>“I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”</li>
            </ul></li>
            <li style={{listStyleType:'decimal'}}>Provide your full legal name and your electronic or physical signature.</li>
          </ul>
          <p>
            Deliver this Notice, with all items completed, to legal@getrobincare.com
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Disclaimer of Warranties</u></li></ul>
          <p>
            ROBIN CARE DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SITE WILL BE CORRECTED. THIS SITE, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR ANY SITE-RELATED SERVICE, IS PROVIDED “AS IS,” WITH ALL FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY OF INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT. ROBIN CARE DOES NOT WARRANT THE ACCURACY, COMPLETENESS OR TIMELINESS OF THE INFORMATION OBTAINED THROUGH THE SITE.
          </p>
          <p>
            YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE, SITE-RELATED SERVICES, AND LINKED WEBSITES. ROBIN CARE DOES NOT WARRANT THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS, TROJAN HORSES OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING PROCEDURES SUFFICIENT TO SATISFY YOUR NEEDS FOR DATA BACK UP AND SECURITY.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Limitation of Liability Regarding Use of Site</u></li></ul>
          <p>
            ROBIN CARE AND ANY THIRD PARTIES MENTIONED ON THIS SITE ARE NEITHER RESPONSIBLE NOR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, SITE-RELATED SERVICES, CONTENT OR INFORMATION CONTAINED WITHIN THE SITE, AND/OR ANY LINKED WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE, SITE-RELATED SERVICES, AND/OR LINKED WEBSITES IS TO STOP USING THE SITE AND/OR THOSE SERVICES. TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, THE MAXIMUM LIABILITY OF ROBIN CARE TO YOU WITH RESPECT TO YOUR USE OF THIS SITE IS $1000 (ONE THOUSAND DOLLARS).
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Dispute Resolution</u></li></ul>
          <p>
            Robin Care will try work in good faith to resolve any issue you have with Site, including services ordered or purchased through the Site, if you bring that issue to the attention of our customer service department. However, Robin Care realizes that there may be rare cases where we may not be able to resolve an issue to a customer’s satisfaction. In the event we cannot resolve a dispute between us, you agree that all matters related to any use or access of the Site, the Services or any other Robin Care site will be governed by the laws of the State of California, without regard to its conflicts of laws rules. You hereby waive any objections to such jurisdiction or venue. Any claims or controversies arising out of this Agreement, and/or the related use and access of the Site, the Services or any other Robin Care site, shall be exclusively in the state courts of California.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Force Majeure</u></li></ul>
          <p>
            We will not be deemed to be in breach of these terms or liable for any breach of these terms or our privacy policy due to any event or occurrence beyond our reasonable control, including without limitation, acts of God, terrorism, war, invasion, failures of any public networks, electrical shortages, earthquakes or floods, civil disorder, strikes, fire or other disaster.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Indemnity</u></li></ul>
          <p>
            You agree to defend, indemnify and hold Robin Care harmless from and against all third party claims, damages and expenses (including reasonable attorneys’ fees) against or incurred by Robin Care arising out of your breach of these Terms of Use or violation of applicable law, or access by anyone accessing the Site using your user ID and password. Notwithstanding the foregoing or anything to the contrary herein, Robin Care shall only exercise its rights in this Section after addressing applicable claims with Your employer or health plan should you be accessing the Site or Services through your employer or health plan.
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Adverse Technical Events.</u></li></ul>
          <p>
            All information is transmitted over a medium which is beyond our control and jurisdiction. Accordingly, Robin Care assumes no responsibility for, or relating to, delay, failure, interruption or corruption of any data or other information transmitted in connection with use of this Site
          </p>
          <ul><li style={{listStyleType:'decimal'}}><u>Right to Modify Revisions; General</u></li></ul>
          <p>
            Robin Care reserves the right to modify these Terms of Use at any time, effective upon posting. Any use of this website after such changes will be deemed an acceptance of those changes. You agree to review the Terms of Use each time you access this Site so that you may be aware of any changes to these Terms. Robin Care uses Google Analytics, a third-party tracking service, which uses cookies to track non-personal identifiable information about our visitors to our main site in the aggregate to capture usage and volume statistics. Robin Care has no access to or control over these cookies. The companion Privacy Policy covers the use of cookies by Robin Care only and does not cover the use of cookies by any third-party. Robin Care reserves the right, in its sole discretion, to terminate your access to all or part of this Site, with or without cause, and with or without notice. In the event that any of the Terms of Use are held by a court or other tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary so that these Terms of Use shall otherwise remain in full force and effect. These Terms of Use constitute the entire agreement between Robin Care and you pertaining to the subject matter hereof. In its sole discretion, Robin Care may from time-to-time revise these Terms of Use by updating this posting. You should, therefore, periodically visit this page to review the current Terms of Use, so you are aware of any such revisions to which you are bound. Certain provisions of these Terms of Use may be superseded by expressly designated legal notices or terms located on particular pages within this Site.
          </p>
          <p>Revised 15 October 2018</p>
        </div>
      </div>
      <MarketingFooter />
    </div>
  )
}
