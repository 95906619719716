import _ from "lodash";
import {Emoji} from "emoji-mart";
import React from "react";

const ReactionList = ({ post, size }) => {
  return (
      <div id="reactions" style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
      {
        _.map(post.reactions, (userList,reaction) => {
          if(_.isArray(userList) && userList.length>0){
            return (
              <div key={`${reaction}`} style={{cursor:'pointer',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                {
                  _.map(userList,(user,index)=>{
                    if(index < 3){
                      return (
                        <div key={`${reaction}-${index}`} style={{marginLeft:index>0?-8:0,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                          <Emoji emoji={`${reaction}`} set='messenger' size={size} />
                        </div>
                      )
                    }
                  })
                }
              </div>
            )
          }
        })
      }
    </div>
  )
}

export default ReactionList
