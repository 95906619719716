import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'

import {Session, fbListenerBlog} from '../shared/globals'
import Thread from './Thread'

const localStyles = {
  root: {flex:1,display:'flex',flexDirection:'row',justifyContent:'center'},
  root2: { maxWidth: 500,minWidth:400, flex:1 ,display:'flex',flexDirection:'column'}
}
const Blog = () => {
  const [,refreshState] = useState()
  const [threadCount,setThreadCount] = useState(99)
  const [scrollParentRef,] = useState({})

  if(!Session.fbListenerBlog){
    Session.fbListenerBlog = 'init'
    fbListenerBlog()
  }

  useEffect(()=>{
    _.set(Session,'refreshBlog',()=>refreshState(Date.now()))
    return () => {
      _.unset(Session,'refreshBlog')
    }
  })
  return (
    <div key="blog" style={localStyles.root} ref={(ref) => scrollParentRef.ref=ref }>
      <div key="blogList" style={localStyles.root2}>
        <InfiniteScroll
            pageStart={0}
            loadMore={()=>{
              if(threadCount < _.size(_.get(Session,'blogThreadList.threads',{}))){
                setThreadCount(threadCount+3)
              }
            }}
            hasMore={threadCount < _.size(_.get(Session,'blogThreadList.threads',{}))}
            loader={<div key={0}>Loading ...</div>}
            useWindow={false}
            getScrollParent={() => scrollParentRef.ref}
        >
          {
            _.map(_.orderBy(_.get(Session,'blogThreadList.threads',{}),['createdDate'],['desc']), (thread,index) => {
              if(index < threadCount && !thread.isArchived && thread.id){
                return <Thread key={`${thread.id}`} user={{uid:'PUBLICBLOG'}} thread={thread} />
              }else{
                // console.log(index, threadCount, thread.id)
              }
            })
          }
        </InfiniteScroll>
      </div>
    </div>
  )
}
export default Blog
