import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import firebase from 'firebase'
import InfiniteScroll from 'react-infinite-scroller'
import TimezoneLookup from 'zipcode-to-timezone'
import MomentTimezone from 'moment-timezone'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/icons/List'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'


import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close'
import Refresh from '@material-ui/icons/Refresh'

import { Session, fbListenerUserList, adminOpenTab, userUpdate, debounceUserProfileIcon } from '../../shared/globals'

const localStyles = {
  root: {
    borderRadius: '4px',
    minWidth: '275px',
    maxWidth: '275px',
    display:'flex',
    flexDirection:'column',
    backgroundColor: '#E6E6E6'
  },
  userList: {
    borderRadius: '4px',
    padding: 8,
    paddingTop:0,
    display:'flex',
    flexDirection:'column',
    overflow:'scroll',
    backgroundColor: '#E6E6E6'
  },
}

const UserList = () => {
  const [, refreshState] = useState()
  const [listToggle, setListToggle] = useState(false)
  const [searchStr,setSearchStrVar] = useState('')
  const [scrollParentRef,] = useState({})
  const [userCount,setUserCount] = useState(10)

  const setSearchStr = (tempStr) => {
    // console.log('setSearchStr')
    setSearchStrVar(tempStr)
    setUserCount(10)
    scrollParentRef.userListRef.scrollIntoView(true)
  }
  const refresh = () => {
    // console.log('refresh')
    refreshState(Date.now())
    // setUserCount(10)
    // scrollParentRef.userListRef.scrollIntoView(true)
  }
  const refreshDirect = () => {
    // console.log('refreshDirect')
    refreshState(Date.now())
    setUserCount(10)
    scrollParentRef.userListRef.scrollIntoView(true)
  }
  const setFilters = (filterName,value) => {
    // console.log('setFilters')
    _.set(Session,`filters.userList.${filterName}`,value)
    setUserCount(10)
    scrollParentRef.userListRef.scrollIntoView(true)
    refreshState(Date.now())
    userUpdate(Session.uid,{filters:_.cloneDeep(Session.filters)})
  }
  const resetFilters = (e) => {
    // console.log('resetFilters')
    _.set(Session,`filters.userList`,{})
    setUserCount(10)
    scrollParentRef.userListRef.scrollIntoView(true)
    setListToggle(!listToggle)
    refreshState(Date.now())
    userUpdate(Session.uid,{filters:_.cloneDeep(Session.filters)})
    e.preventDefault()
    e.stopPropagation()
  }
  const clearFilter = (filterName) => {
    // console.log('clearFilter')
    _.set(Session,`filters.userList.${filterName}`,'All')
    setUserCount(10)
    scrollParentRef.userListRef.scrollIntoView(true)
    refreshState(Date.now())
    userUpdate(Session.uid,{filters:_.cloneDeep(Session.filters)})
  }
  Session.refreshUserList=refresh
  if (!Session.fbListenerUserList && _.get(Session,'userClaims.isAdmin',false)) {
    fbListenerUserList()
  }

// console.log(_.filter(Session.users,(user)=>{
//   return searchStr.length===0 || _.startsWith(_.toLower(user.firstName),_.toLower(searchStr)) || _.startsWith(_.toLower(user.lastName),_.toLower(searchStr))
// }))
// console.log('RENDER UserList')
  if (Session.auth && _.get(Session,'userClaims.isAdmin',false)) {
    const {userList: userListFilters} = Session.filters
    let searchedUserList = _.orderBy(
      _.filter(Session.users,({profile},uid)=>{
        return profile && profile.uid===uid &&
          (searchStr.length === 0
            || _.get(profile,'userCount',-1) === parseInt(searchStr)
            || _.startsWith(_.toLower(profile.firstName),_.toLower(searchStr))
            || _.startsWith(_.toLower(profile.email),_.toLower(searchStr))
            || _.startsWith(_.toLower(profile.lastName),_.toLower(searchStr))
            || _.toLower(_.get(profile,'companyName','unknown')) === _.toLower(searchStr)
          )
      }),['profile.lastActivityActionDate'],['desc']
    )
    let orderedFilteredUserList = searchedUserList
    const filterCompanyList = _.uniq(_.map(Session.users,({profile})=>{return _.toLower(profile.companyName) || ''})).sort()
    if(_.get(userListFilters,`companyName`,'').length > 0 && _.get(userListFilters,`companyName`,'') !== 'All'){
      orderedFilteredUserList=_.filter(searchedUserList,({profile})=>{
        return _.toLower(profile.companyName) === _.get(userListFilters,`companyName`,'') ||
        (_.get(profile,'companyName','') === '' && _.get(userListFilters,`companyName`,'') === 'Blank')
      })
    }
    const filterUserTypeList = _.uniq(_.map(Session.users,({profile})=>{return profile.type || ''})).sort()
    if(_.get(userListFilters,`type`,'').length > 0 && _.get(userListFilters,`type`,'') !== 'All'){
      orderedFilteredUserList=_.filter(searchedUserList,({profile})=>{
        return profile.type === _.get(userListFilters,`type`,'')
      })
    }
    const filterUserPresenceList = _.uniq(_.map(Session.users,({profile})=>{return profile.presenceState || ''})).sort()
    if(_.get(userListFilters,`presenceState`,'').length > 0 && _.get(userListFilters,`presenceState`,'') !== 'All'){
      orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
        return profile.presenceState === _.get(userListFilters,`presenceState`,'')
      })
    }
    const filterUserCancerTypeList = _.uniq(_.map(Session.users,({profile})=>{return profile.cancerType || ''})).sort()
    if(_.get(userListFilters,`cancerType`,'').length > 0 && _.get(userListFilters,`cancerType`,'') !== 'All'){
      orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
        return profile.cancerType === _.get(userListFilters,`cancerType`,'') ||
        (_.get(profile,'billable','') === '' && _.get(userListFilters,`billable`,'') === 'Blank')
      })
    }
    const filterUserBillableList = _.uniq(_.map(Session.users,({profile})=>{return profile.billable || ''})).sort()
    if(_.get(userListFilters,`billable`,'').length > 0 && _.get(userListFilters,`billable`,'') !== 'All'){
      orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
        return profile.billable === _.get(userListFilters,`billable`,'') ||
        (_.get(profile,'billable','') === '' && _.get(userListFilters,`billable`,'') === 'Blank')
      })
    }
    const filterUserOnboardingStatusList = ['All','Onboarding','Onboarded','Welcome Screen 1','Welcome Screen 2','Welcome Screen 3','Welcome Screen 4','Welcome Screen 5','Welcome Screen 6','Welcome Screen 10','Welcome Screen 11','Welcome Screen 12','Welcome Screen 20','Welcome Screen 21']
    switch(_.get(userListFilters,`onboardingStatus`,'All')){
      case 'All': break
      case 'Onboarding':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',99) < 99
        })
        break
      case 'Onboarded':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) >= 99
        })
        break
      case 'Welcome Screen 1':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 1
        })
        break
      case 'Welcome Screen 2':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 2
        })
        break
      case 'Welcome Screen 3':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 3
        })
        break
      case 'Welcome Screen 4':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 4
        })
        break
      case 'Welcome Screen 5':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 5
        })
        break
      case 'Welcome Screen 6':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 6
        })
        break
      case 'Welcome Screen 10':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 10
        })
        break
      case 'Welcome Screen 11':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 11
        })
        break
      case 'Welcome Screen 12':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 12
        })
        break
      case 'Welcome Screen 20':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 20
        })
        break
      case 'Welcome Screen 21':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'welcomeScreen',0) === 21
        })
        break
      default: break
    }
    const filterUserTagsList = ['All','Needing Tags']
    switch(_.get(userListFilters,`tags`,'All')){
      case 'All': break
      case 'Needing Tags':
        orderedFilteredUserList=_.filter(orderedFilteredUserList,({profile})=>{
          return _.get(profile,'tagsNeeded',[]).length > 0
        })
        break
      default: break
    }
    const filtersMap = [
      {filterLabel:'Company', filterName:'companyName', filterOptionsList:filterCompanyList,showBlank:true},
      {filterLabel:'User Type', filterName:'type', filterOptionsList:filterUserTypeList},
      {filterLabel:'User Presence Status', filterName:'presenceState', filterOptionsList:filterUserPresenceList},
      {filterLabel:'User Cancer Type', filterName:'cancerType', filterOptionsList:filterUserCancerTypeList},
      {filterLabel:'User Billable Status', filterName:'billable', filterOptionsList:filterUserBillableList, showBlank:true},
      // {filterLabel:'Onboarding Status', filterName:'onboardingStatus', filterOptionsList:filterUserOnboardingStatusList},
      {filterLabel:'Tags', filterName:'tags', filterOptionsList:filterUserTagsList},
      // {filterLabel:'', filterName:'', filterOptionsList:TODO},
      // {filterLabel:'', filterName:'', filterOptionsList:TODO},
      // {filterLabel:'', filterName:'', filterOptionsList:TODO},
    ]
    return (
      <div style={localStyles.root}>
        <div style={{padding:8,paddingBottom:0,display:'flex',flexDirection:'column'}}>
          <Grid container direction="row" alignItems="center" style={{cursor:'pointer'}} onClick={()=>setListToggle(!listToggle)}>
            <Grid item container direction="row" justify='flex-start'>
              <List />
              <Grid item style={{flex:1,paddingTop: '2px', paddingBottom: '2px', marginLeft: '6px'}}>
                Users List
              </Grid>
              {
                !listToggle ? null :
                <Grid style={{fontSize:12,fontWeight:'bold',textAlign:'center',display:'flex',alignItems:'center'}} onClick={resetFilters}>
                  Clear all filters
                </Grid>
              }
            </Grid>
          </Grid>
          {
            !listToggle ? null :
            <div style={{display:'flex',flexDirection:'column'}}>
              {
                _.map(filtersMap,(filter)=>{
                  const {filterLabel,filterName,filterOptionsList} = filter
                  return (
                    <TextField key={`FILTER-${filterName}`} style={{marginTop: '15px'}} fullWidth variant="outlined" margin="dense" select
                      label={filterLabel} value={_.get(userListFilters,filterName,'All')}
                      onChange={(e) => setFilters(filterName,e.target.value)} InputProps={{ endAdornment: (
                        _.get(userListFilters,filterName,'All') === 'All' || _.get(userListFilters,filterName,'All') === '' ? null :
                        <InputAdornment position="end">
                          <IconButton edge="end" aria-label="Clear" onClick={()=>clearFilter(filterName)} >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ), }} InputLabelProps={{ shrink: true }}
                      >
                      {
                        _.map(filterOptionsList,(option)=>{
                          if(option===''){
                            return <MenuItem key={option} value={'All'}>All</MenuItem>
                          }
                        })
                      }
                      {
                        _.map(filterOptionsList,(option)=>{
                          if(option===''){
                            if(_.get(filter,'showBlank',false)){
                              return <MenuItem key={option} value={'Blank'}>(Blank)</MenuItem>
                            }
                          }else{
                            return <MenuItem key={option} value={option}>{_.startCase(option)}</MenuItem>
                          }
                        })
                      }
                    </TextField>
                  )
                })
              }
            </div>
          }
          <TextField fullWidth margin="dense" variant="outlined" style={{backgroundColor:'white', borderRadius: 3, boxSizing: 'border-box'}}
            placeholder="First/Last/Email/CID" InputProps={{ maxLength: 10,endAdornment: (
              searchStr==='' ?
              <InputAdornment position="end">
                <IconButton edge="end" aria-label="Refresh" onClick={refreshDirect} >
                  <Refresh style={{color:'green'}}/>
                </IconButton>
              </InputAdornment>
               :
              <InputAdornment position="end">
                <IconButton edge="end" aria-label="Clear" onClick={()=>setSearchStr('')} >
                  <Close />
                </IconButton>
              </InputAdornment>
            ), }} InputLabelProps={{ shrink: true }}
            onChange={(e)=>setSearchStr(e.target.value)}
            value={searchStr}
          />
        </div>
        <div style={localStyles.userList} ref={(ref) => scrollParentRef.ref=ref }>
          { true?
            <InfiniteScroll
              pageStart={0}
              loadMore={()=>{
                if(userCount < _.size(orderedFilteredUserList)){
                  // console.log('loading more users',userCount,_.size(orderedFilteredUserList))
                  setUserCount(userCount+10)
                }
              }}
              hasMore={userCount < _.size(orderedFilteredUserList)}
              loader={<div key={0}>Loading more users...</div>}
              useWindow={false}
              getScrollParent={() => scrollParentRef.ref}
            >
            <div ref={(ref) => scrollParentRef.userListRef=ref }></div>
            {
              _.map(orderedFilteredUserList, (user,index) => {
                if(index < userCount){
                  return <UserCard key={`USERCARD-${user.profile.uid}`} user={user} index={index} />
                }
              })
            }
            </InfiniteScroll>
            :
            _.map(orderedFilteredUserList,(user,index)=>{
              return <UserCard key={`USERCARD-${user.profile.uid}`} user={user} index={index} />
            })
          }
        </div>
      </div>
    )
  } else {
    return null
  }
}
export default UserList
const UserCard = ({user,index}) => {
  const {profile} = user
  const {uid} = profile
  const timeLookUp = (zipcode) => {
    let timeZone = TimezoneLookup.lookup(zipcode)

    if(timeZone) {
      let currentTime = MomentTimezone(Date.now())
      return `Local Time: ${currentTime.tz(timeZone).format("LT")}`
    } else {
      return "-"
    }
  }
  let backgroundColor = 'white'
  switch (_.indexOf(Session.userTabs,uid)){
    case -1:
      backgroundColor = 'white'
      break
    case 0:
      backgroundColor = '#ffe7b8'//'#C7DFFF'
      break
    case 1:
      backgroundColor = '#ffe7b8'//'#E9C7FF'
      break
    default:
      backgroundColor = '#fff5e0'
      // backgroundColor = '#F2D7D5'
      break
  }
  let userCardStyle = {cursor:'pointer', margin:4,borderRadius:4, display:'flex', flexDirection:'column'}
  if(profile.presenceState==='online'){
    userCardStyle={...userCardStyle,backgroundColor:'#70db70',padding:4}
  }
  if(profile.presenceState==='onboarding'){
    userCardStyle={...userCardStyle,backgroundColor:'#70db70',padding:4}
  }
  // if(Date.now()-profile.lastActivityDate <= 1800000 ){
  //   userCardStyle={...userCardStyle,border:'solid 4px #FF7391'}
  // }
  if(_.get(Session,'userClaims.isAdmin',false) && _.get(profile,'tagsNeeded',[]).length > 0 && _.get(profile,'deactivatedStatus','').length === 0){
    userCardStyle={...userCardStyle,border:'solid 4px #FF7391'}
  }
  if(profile.type==='REMOVED') return null
  return (
    <div key={`USER-${uid}`} style={userCardStyle} onClick={()=>{Session.refreshAdminSection(profile.userCount);adminOpenTab(uid)}}>
      <div className='userHeader' style={{backgroundColor: backgroundColor, borderTop:'solid 0.5px #DDDDDD', borderLeft:'solid 0.5px #DDDDDD', borderRight: 'solid 0.5px #DDDDDD', borderRadius:'4px 4px 0 0', height: 'auto', paddingTop: '2px', paddingBottom: '2px'}}>
        <div className='userHeaderContents' style={{display: 'flex', flexDirection: 'row'}}>
          <div className='avatarImage' style={{paddingTop: '2px', paddingLeft: '5px'}}>
            <img alt="" src={profile.profileIcon} onError={()=>debounceUserProfileIcon(profile)} style={{height: '25px', borderRadius: '50%'}}/>
          </div>
          <div className='userMainInfo' style={{display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: '5px', paddingRight: '5px'}}>
            <div className='idAndDate' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <div className='userId' style={{fontSize: '13px', fontWeight: 'bold'}}>
                {profile.userCount} {profile.type}
              </div>
              <div className='interactionTime' style={{fontSize: '12px'}}>
                {
                  _.get(profile,'lastActivityDate','') !== '' ? moment(_.get(profile,'lastActivityDate',null)).fromNow() : '-'
                }
              </div>
            </div>
            <div className='nameAndIcon' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <div className='userFullName' style={{fontSize: '13px', fontWeight: 'bold'}}>
                {_.startCase(profile.firstName)} {
                  _.trim(_.get(profile,'nickname','')).length > 0 && _.trim(_.get(profile,'nickname','')) !== _.trim(_.get(profile,'firstName','')) ?
                  `[${_.startCase(_.get(profile,'nickname',''))}]` : null
                } {_.startCase(profile.lastName)} {!_.get(profile,'companyName',null)?null:`(${profile.companyName})`}
              </div>
              <div className='engagedIcon'>
                {
                  true?null:
                  <img alt="" src={profile.profileIcon} style={{height: '15px'}}/>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='userBody' style={{backgroundColor: _.get(profile,'welcomeScreen',0) < 99 ? '#F5F5F5':'#F5F5F5', borderLeft:'solid 0.5px #DDDDDD', borderRight: 'solid 0.5px #DDDDDD', borderBottom: 'solid 0.5x #DDDDDD', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '5px', borderRadius: '0 0 3px 3px'}}>
          <div className='cancerTypeAndStage' style={{display: 'flex', flexDirection: 'row', marginTop: '2px', justifyContent: 'space-between'}}>
            <div className='cancerType' style={{fontSize: '13px'}}>
              {profile.cancerType || '-'}
            </div>
            <div className='cancerStage' style={{fontSize: '13px'}}>
              {profile.cancerStage || '-'}
            </div>
          </div>
          <div className='userPhoneNumberAndLocation' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '2px'}}>
            <div className='userPhoneNumber' style={{fontSize: '13px'}}>
              {profile.phoneNumber || '-'}
            </div>
            <div className='userLocation' style={{fontSize: '13px'}}>
              {`${timeLookUp(profile.addressZip)}`}
            </div>
          </div>
          <div className='userEmailAddress' style={{fontSize: '13px'}} title='Personal Email Address'>
            {profile.email}
          </div>
          {
            !_.get(profile,'companyEmail',null) ? null :
            <div className='userCompanyEmail' style={{fontSize: '13px'}} title='Work Email Address'>
              {profile.companyEmail}
            </div>
          }
          {
            !_.get(profile,'companyName',null) ? null :
            <div className='userCompanyName' style={{fontSize: '13px'}} title='Company Name'>
              {profile.companyName}
            </div>
          }
          <div className='lastActivityAction' style={{fontSize: 13,textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}} title='Last user action'>
            {profile.lastActivityAction || ''}
          </div>
          <div className='lastActivityActionDate' style={{fontSize: 13,textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}} title='Last user action date/time'>
            {
              _.get(profile,'lastActivityActionDate','') !== '' ? moment(_.get(profile,'lastActivityActionDate',null)).fromNow() : '-'
            }
          </div>
          {
            _.get(profile,'notificationOnPresence',true) ? null :
            <div className='lastActivityAction' style={{color:'red',fontSize: 13,textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}} title='Last user action'>
              Presence Alert Muted
            </div>
          }
          {
            _.get(profile,'welcomeScreen',0) >= 99 || _.get(profile,'type','') === 'Admin' ? null :
            <div className='welcomeScreen' style={{fontWeight:'bold',color:'#2AB1FF',fontSize: 13,textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}} title='Last user action'>
              Welcome Screen: {_.get(profile,'welcomeScreen',0)}
            </div>
          }
        </div>
    </div>
  )
}
