import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Session } from '../shared/globals'

const MarketingFooter = ({ match }) => {
  return (
    <div ref={(ref)=>_.set(Session,'refs.legal',ref)} style={{padding:8,backgroundColor: '#51B7C5',display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',paddingRight:32}}>
      <div>
        <div style={{marginBottom:4}}>
          <Link to='/privacy-policy' style={{textDecoration: 'none', cursor: 'pointer',color:'white',fontWeight:'bold',fontSize:16}}>
              Privacy Policy
          </Link>
        </div>
        <div>
          <Link to='/terms' style={{textDecoration: 'none', cursor: 'pointer',color:'white',fontWeight:'bold',fontSize:16}}>
              Terms & Conditions
          </Link>
        </div>
      </div>
    </div>
  )
}
export default MarketingFooter
