import React from "react"
import _ from "lodash"
import moment from 'moment'

import { Session,postFixProfileIcons, markupMessage, postDelivered } from '../shared/globals'
import CalendarBadge from './CalendarBadge'
import TagsBadge from './admin/TagsBadge'
import NotesBadge from './admin/NotesBadge'
import PhoneBadge from './admin/PhoneBadge'
import {Attachments} from "./attachments"
import LikeButton from "./LikeButton"
import PostActions from './PostActions'
import ReactionList from './ReactionList'
import Check from '@material-ui/icons/Check'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'


const Comment = ({ user, comment }) => {
  // console.log('RENDER comment')
  const userName = _.get(comment,'createdById','invalidId') === Session.uid ? _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName','') : comment.createdByName
  // return (
  //   <div key={comment.id} style={{border:'solid 1px red',display:'flex',flexDirection:'row',overflow:'hidden'}}>
  //     <div style={{flex:0,border:'solid 1px blue'}}>
  //       avatar
  //     </div>
  //     <div style={{flex:1,maxWidth:400,border:'solid 1px blue',height:100,}}>
  //       <div style={{overflowWrap:'break-word'}}>
  //         bodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybodybody
  //       </div>
  //     </div>
  //     <div style={{flex:0,border:'solid 1px blue'}}>
  //       poster
  //     </div>
  //   </div>
  // )
  let styleCommentText = { flex:1,display:'flex',maxWidth:355,flexDirection:'column',padding: 8,position:'relative', marginBottom: '5px', backgroundColor: '#F5F6F7', border: '1px solid #DDDFE2', borderRadius: '17px', fontSize: '13px', letterSpacing: '0.53px', lineHeight: '17px', minHeight: '24px'}
  if(_.get(Session,'userClaims.isAdmin',false) && _.get(comment,'tags',[]).length === 0 && comment.createdById === user.uid){
    styleCommentText = { flex:1,display:'flex',maxWidth:355,flexDirection:'column',padding: 8,position:'relative', marginBottom: '5px', backgroundColor: '#F5F6F7', border: '2px solid red', borderRadius: '17px', fontSize: '13px', letterSpacing: '0.53px', lineHeight: '17px', minHeight: '24px'}
  }
  if(!_.get(Session,'userClaims.isAdmin',false) && !_.get(comment,'userDelivered',false)){
    postDelivered(comment,user.uid)
  }
  return (
    <div key={comment.id} className="commentListItem" style={{display: 'flex', padding: '5px 16px 0 10px', flexDirection: 'row'}}>
      <div className="commentAvatar">
        <img alt="" style={{ width: 30, height: 30, borderRadius: 99,border:'solid 1.5px black',padding:3}} onError={()=>postFixProfileIcons(user,comment)} src={_.get(comment,'createdByProfileIcon','')}/>
      </div>
      <div className="commentContent" style={{ display: 'flex', flexDirection: 'column', flex:1}}>
        <div id="commentTextAndOptionButton" style={{display: 'flex', flexDirection: 'row', position: 'relative'}}>
          <div style={{ display: 'flex', flexDirection: 'column', flex:1, marginLeft: '8px', marginRight: '8px' }}>
            {
              _.get(Session,'userClaims.isAdmin',false) && _.get(comment,'userDelivered',false) ?
              <CheckCircleOutline style={{position:'absolute',zIndex:1,bottom:'-5%',left:'0%',color: true?'rgb(86, 250, 22)':'grey'}} title={'asdfasdf'}/>
              : null
            }
            <div id="commentText" style={styleCommentText}>
              <div style={{paddingBottom:0,overflowWrap:'break-word'}}>
                <span style={{color:'#385898',fontWeight:'bold'}}>{userName}</span>
                <span style={{marginLeft: '6px'}}>{markupMessage(user.uid,comment.message)}</span>
              </div>
              <Attachments user={user} attachments={comment.attachments} />
              <div style={{top:-12,right:-4,borderRadius:16, backgroundColor: 'white', position: 'absolute', border: '1px solid #DDDFE2',display:'flex',flexDirection:'row'}}>
                <CalendarBadge user={user} post={comment} />
                <NotesBadge user={user} post={comment} />
                <PhoneBadge user={user} post={comment} />
                <TagsBadge user={user} post={comment} />
              </div>
              {
                _.filter(comment.reactions,(reaction)=>{
                  return !_.isArray(reaction)
                }).length===0?null:
                <div style={{maxHeight:10,padding:4,bottom:-12,right:-4,borderRadius:16, backgroundColor: 'white', position: 'absolute', border: '1px solid #DDDFE2'}}>
                  <ReactionList user={user} post={comment} size={12} />
                </div>
              }
            </div>
          </div>
          <PostActions user={user} post={comment} />
        </div>
        <div id="commentActions" style={{ display: 'flex', flexDirection: 'row',alignItems:'center', marginTop: '2px', marginLeft: '13px', fontSize: '13px', letterSpacing: '-0.07px', lineHeight: '17px' }}>
          <LikeButton user={user} thread={comment} size={'small'}/>
          <div id="commentActionsSeparator" style={{marginLeft: '3px', marginRight: '3px'}}>
            &middot;
          </div>
          <div id="threadTimeStamp" style={{color: '#616770', lineHeight: '17px', fontSize: 12}} title={`Created: ${moment(comment.createdDate).toString()}\nModified: ${moment(comment.modifiedDate).toString()}`}>
            {moment(comment.createdDate).fromNow()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comment
