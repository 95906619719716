import React, { useState } from 'react'
import _ from 'lodash'
import NotificationsActive from '@material-ui/icons/NotificationsActive'
import NotificationsOff from '@material-ui/icons/NotificationsOff'
import IconButton from '@material-ui/core/IconButton'

const NotificationToggle = ({ user, title, field,  updateUserField }) => {
  const [settingValue,updateSettingValue] = useState(_.get(user, field,true))

  const toggleItemValue = (buttonValue) => {
    if(buttonValue !== settingValue) {
      updateSettingValue(buttonValue)
      updateUserField(field, buttonValue)
    }
  }

  return (
    <div className='listItem' style={{marginTop: '8px', height: '26px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '2px', paddingRight: '5px', paddingTop: '4px', paddingBottom: '3px', backgroundColor: '#F7F7F7', borderRadius: '2px'}}>
      <div className='listItemTitle' style={{fontSize: '14px', letterSpacing: '0.65px', lineHeight: '16px', paddingTop: '2px', paddingBottom: '2px'}}>
        { title }
      </div>
      <div className='listItemButtons'>
        <IconButton size='small' style={{ color: settingValue === true ? '#13BB21' : '' }} onClick={() => toggleItemValue(true)}>
          <NotificationsActive fontSize="inherit"/>
        </IconButton>
        <IconButton size='small' style={{ color: settingValue === false ? 'red' : '' }} onClick={() => toggleItemValue(false)}>
          <NotificationsOff fontSize="inherit"/>
        </IconButton>
      </div>
    </div>
  )
}
export default NotificationToggle
