import React from 'react'

import AttachFile from '@material-ui/icons/AttachFile'

const AttachmentFile = ({user,attachment}) => {
  return (
    <div style={{flex:1,display:'flex',flexDirection:'column'}}>
      <div style={{flex:1,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
        <AttachFile/>
        {attachment.attachmentName}
      </div>
    </div>
  )
}
export default AttachmentFile
