import React, { useState } from 'react'
import _ from 'lodash'
import CreatableSelect from 'react-select/creatable'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Session, adminDocCreate } from '../../shared/globals'

export const DialogNotes = ({open,adminDoc,post,user,dialogParentHide,isDisabled}) => {
  const [tags, setTags]=useState(_.map(_.get(adminDoc,'tags',[]),(tag)=>{return {value:tag.value,label:tag.value}}))
  const [profile,] = useState(_.get(Session,`users.${user.uid}.profile`,{}))
  const [note, setNote] = useState(_.get(Session,`adminDocs.${adminDoc.id}.message`,''))
  const [disabled,setDisabled] = useState(isDisabled)

  const dialogHide = () => {
    setNote('')
    dialogParentHide()
  }
  const dialogUpdate = () => {
    adminDocCreate(user.uid,{
      id:adminDoc.id,
      postId:post.id,
      docType:'note',
      message: note,
      tags:tags,
    }).then(()=>{
      dialogHide()
    })
  }

  if(!_.get(Session,'userClaims.isAdmin',false)) return null
  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogHide} aria-labelledby="form-dialog-title">
      <DialogTitle>Sticky Note</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {`${profile.firstName} ${profile.lastName} (${profile.userCount})`}
        </DialogContentText>
        <TextField autoFocus fullWidth margin="normal" variant="outlined" multiline
          label="Note Details" rows={3} rowsMax={10} inputProps={{ maxLength: 999 }}
          onChange={(e)=>setNote(e.target.value)} disabled={disabled}
          value={note}
        />
        {
          (500-note.length > 400) ? null :
          <div style={{fontSize:12,fontColor:'grey',marginBottom:16}}>{500 - note.length} characters left</div>
        }
        <CreatableSelect isMulti isDisabled={disabled}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          placeholder={'Select Tags'}
          value={tags}
          onChange={(newValue)=>setTags(newValue)}
          options={Session.groupedOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogHide} color="primary">
          { disabled ? 'CLOSE' : 'CANCEL' }
        </Button>
        <Button onClick={disabled ? ()=>setDisabled(false) : dialogUpdate } color="primary" disabled={!disabled && note.length === 0}>
          { disabled ? 'EDIT' : 'UPDATE' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogNotes
