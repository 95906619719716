import React, { useState } from 'react'
import _ from 'lodash'
import Comment from './Comment'

import {Session} from '../shared/globals'

const CommentList = ({user,comments}) => {
  const [commentsSize,setCommentsSize] = useState(_.size(comments))
  const [commentsShowSize,setCommentsShowSize] = useState(3)

  if(commentsSize !== _.size(comments)){
    setCommentsSize(_.size(comments))
  }
  // useEffect(()=>{
  //   setCommentsSize(_.size(comments))
  //   console.log('UECL')
  // })
  const commentsShowSizeIncrement = () => {
    if(commentsShowSize===999 || commentsShowSize>commentsSize){
      return
    }
    if(commentsShowSize===3){
      setCommentsShowSize(10)
    }else if(commentsShowSize===10){
      setCommentsShowSize(25)
    }else if(commentsShowSize===25){
      setCommentsShowSize(50)
    }else if(commentsShowSize===50){
      setCommentsShowSize(100)
    }else if(commentsShowSize===100){
      setCommentsShowSize(200)
    }else if(commentsShowSize===200){
      setCommentsShowSize(500)
    }else if(commentsShowSize===500){
      setCommentsShowSize(999)
    }
  }
  const commentsShowSizeDecrement = () => {
    if(commentsShowSize===3){
      return
    }else if(commentsShowSize===10){
      setCommentsShowSize(3)
    }else if(commentsShowSize===25){
      setCommentsShowSize(10)
    }else if(commentsShowSize===50){
      setCommentsShowSize(25)
    }else if(commentsShowSize===100){
      setCommentsShowSize(50)
    }else if(commentsShowSize===200){
      setCommentsShowSize(100)
    }else if(commentsShowSize===500){
      setCommentsShowSize(200)
    }else if(commentsShowSize===999){
      setCommentsShowSize(500)
    }
  }
  // console.log('RENDER commentList')
  return (
    <div className="commentList" style={{ paddingTop: '4px'}}>
      {
        commentsSize===0?null:
        <div className="commentCount" style={{ display:'flex',flexDirection:'row',justifyContent:'space-between', height: '17px', fontSize: '13px', letterSpacing: '-0.07px', lineHeight: '17px', paddingRight: 12,paddingLeft: 12, color: '#616770' }}>
          <div style={{display:'flex',flexDirection:'row'}}>
            <div style={{color:'#385898',cursor:'pointer',paddingRight:12,display:commentsShowSize>=commentsSize?'none':''}} onClick={()=>commentsShowSizeIncrement()}>View more comments</div>
            <div style={{color:'#385898',cursor:'pointer',display:commentsShowSize===3?'none':''}} onClick={()=>commentsShowSizeDecrement()}>View less comments</div>
          </div>
          <div>{_.min([commentsShowSize,commentsSize])} of {commentsSize}</div>
        </div>
      }
      <div style={{display:'flex',flexDirection:'column-reverse'}}>
      {
        _.map(_.orderBy(_.filter(comments,(comment,key)=>{
          return _.get(comment,'isComment',false) && (_.indexOf(_.get(Session,`searchPostIdArray.${user.uid}`,[]),key) > -1 || _.get(Session,`searchStr.${user.uid}`,'').length === 0)
        }),['createdDate'],['desc']),(comment,index)=>{
          if(index < commentsShowSize){
            return <Comment key={comment.id} user={user} comment={comment} />
          }
        })
      }
      </div>
    </div>
  )
}

export default CommentList
