import React from 'react'
import _ from 'lodash'
import firebase from 'firebase'
// import moment from 'moment'
import axios from 'axios'
const firebaseConfigs = {
  apiKey: process.env.REACT_APP_FIREBASECONFIGS_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASECONFIGS_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASECONFIGS_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASECONFIGS_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASECONFIGS_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASECONFIGS_MESSAGINGSENDERID,
  // appId: process.env.REACT_APP_FIREBASECONFIGS_APPID
}
firebase.initializeApp(firebaseConfigs)
firebase.firestore().settings({})
// console.log('sessionStorage',sessionStorage)
export const allowedFileTypes = [
  'image/png',
  'video/mp4',
  'image/jpeg',
  'image/gif',
  'text/plain',
  'text/csv',
  'application/pdf',
  'video/quicktime',
  'video/webm'
]
export const resetSession = () => {
  Session.userClaims=null
  Session.uid=null
  Session.searchStr=''
  Session.searchPostIdArray={}
  Session.fbListenerBlog=null
  if(!sessionStorage.sid) sessionStorage.setItem('sid',Date.now())
  Session.blogThreadList = sessionStorage.blogThreadList && sessionStorage.blogThreadList!=='undefined' ? JSON.parse(sessionStorage.blogThreadList) : {}
  Session.fbUser=null
  Session.fbUserRef=null
  Session.auth=false
  Session.userList={}
  Session.adminDocs={}
  Session.userTabs=[]
  Session.users={}
  Session.posts={}
  Session.refs={}
  if(Session.fbListenerUser){
    _.map(Session.fbListenerUser,(tempListener)=>{
      tempListener()
    })
  }
  Session.fbListenerUser = null
  if(Session.fbListener){
    _.map(Session.fbListener,(tempListener)=>{
      tempListener()
    })
  }
  Session.fbListener = null
  if(Session.fbListenerUserList){
    Session.fbListenerUserList()
  }
  Session.fbListenerUserList=null
  if(Session.fbListenerAdminDocs){
    Session.fbListenerAdminDocs()
  }
  Session.fbListenerAdminDocs=null
  Session.userStatusDatabaseRef=null
  Session.refreshHome = null
  Session.refreshProfile = null
  Session.oauthProfile = null
  Session.refreshAdminNotes = null
  Session.refreshAdminCases = null
  Session.refreshAdminPhoneLogs = null
  Session.refreshAdminTasks = null
  Session.layouts = {
    generalDetails: [
      {fieldName:'firstName',label:'First Name',type:'text',options:[]},
      {fieldName:'lastName',label:'Last Name',type:'text',options:[]},
      {fieldName:'nickname',label:'Preferred Name',type:'text',options:[]},
      {fieldName:'birthdate',label:'Birthdate',type:'date',options:[]},
      {fieldName:'gender',label:'Gender',type:'select',options:['','Female','Male']},
    ],
    contactDetails: [
      {fieldName:'companyName',label:'Company Name',type:'readOnly',options:[]},
      {fieldName:'memberID',label:'Member ID',type:'readOnly',options:[]},
      {fieldName:'phoneNumber',label:'Phone Number',type:'text',options:[]},
      {fieldName:'email',label:'Personal Email',type:'readOnly',options:[]},
      {fieldName:'companyEmail',label:'Work Email',type:'readOnly',options:[]},
      {fieldName:'addressLine1',label:'Street Address',type:'text',options:[]},
      {fieldName:'addressLine2',label:'Apartment/Suite/PO Box',type:'text',options:[]},
      {fieldName:'addressCity',label:'City',type:'text',options:[]},
      {fieldName:'addressState',label:'State / Province',type:'text',options:[]},
      {fieldName:'addressZip',label:'Zip / Postal',type:'text',options:[]},
    ],
    patientDetails:[
      {fieldName:'patientFirstName',label:'First Name',type:'text',options:[]},
      {fieldName:'patientLastName',label:'Last Name',type:'text',options:[]},
      {fieldName:'patientNickname',label:'Preferred Name',type:'text',options:[]},
      {fieldName:'patientBirthdate',label:'Birthdate',type:'date',options:[]},
      {fieldName:'patientGender',label:'Gender',type:'select',options:['','Female','Male']},
      {fieldName:'patientRelationship',label:'Patient Relationship',type:'text',options:[]},
    ],
    cancerDetails:[
      {fieldName:'cancerType',label:'Type',type:'text',options:[]},
      {fieldName:'cancerStage',label:'Stage',type:'text',options:[]},
      {fieldName:'cancerDiagnosisDate',label:'Diagnosis Date',type:'date',options:[]},
      {fieldName:'cancerStatus',label:'Status',type:'select',options:['',
        'Newly Diagnosed' ,'Undergoing Treatment' ,'Active Surveillance' ,'Survivorship' ,
        'Maintenance or Metastatic Treatment' ,'End of Life' ,'Recurrent Disease' ,
      ]},
    ],
    adminDetails: [
      {fieldName:'companyName',label:'Company Name',type:'text',options:[]},
      {fieldName:'memberID',label:'Member ID',type:'text',options:[]},
      {fieldName:'companyEmail',label:'Work Email',type:'text',options:[]},
      {fieldName:'deactivatedStatus',label:'Excluded Reason',type:'select',options:['','Ineligible','Non-Customer','Not Interested','Test Account']},
      {fieldName:'type',label:'User Type',type:'select',options:['Patient','Caregiver','Admin','Information']},
      {fieldName:'billable',label:'Billable',type:'select',options:['','Yes','No']},
      {fieldName:'welcomePackage',label:'Welcome Package',type:'select',options:['','Yes','No']},
      {fieldName:'welcomePackageSent',label:'Welcome Package Sent Date',type:'date',options:[]},
      {fieldName:'welcomePackageTracking',label:'Welcome Package Tracking Number',type:'text',options:[]},
      {fieldName:'userEnteredCancerType',label:'User Entered Cancer Type',type:'text',options:[]},
      {fieldName:'notificationOnPresence',label:'Presence Alert',type:'booleanOpposite',options:[]},
    ],
  }
  Session.styles = {
    App:{
      flex:1,
      display:'flex',
      flexDirection:'column',
    },
    Header:{
      root:{padding:8,display:'flex',flexDirection:'column',backgroundColor: '#51B7C5',},
      c1:{flexDirection:'row',justifyContent:'space-between',marginBottom:8},
    },
    Login:{
      root: {
        flex:1,
        justifyContent:'center',
        display:'flex',
        flexDirection:'column',
        padding:8,
      },
      c0:{borderWidth:1,borderColor:'grey',borderRadius:4,backgroundColor:'rgb(150, 214, 223)'},
      c00:{alignItems:'center',marginTop:8,},
      c1:{padding:8},
      c11:{},
      c12:{marginTop:8},
      c2:{padding:8,backgroundColor:'white'},
      c21:{},
      c211:{borderWidth:1,borderColor:'grey',borderRadius:4,height:40,padding:8},
      c22:{},
      c221:{borderWidth:1,borderColor:'grey',borderRadius:4,height:40,marginTop:8,padding:8},
      c23:{},
      c231:{borderWidth:1,borderColor:'grey',borderRadius:4,height:40,marginTop:8,padding:8},
      c24a:{borderWidth:1,borderColor:'grey',borderRadius:4,height:40,backgroundColor:'rgba(81, 183, 197, .98)',marginTop:8,padding:8,alignItems:'center',justifyContent:'center'},
      c24b:{borderWidth:1,borderColor:'grey',borderRadius:4,height:40,backgroundColor:'rgba(81, 183, 197, .25)',marginTop:8,padding:8,alignItems:'center',justifyContent:'center'},
      c241:{color:'white',fontWeight:'bold',fontSize:16},
      c3:{padding:8},
      c31:{textAlign:'center'},
      c32:{backgroundColor:'#1877f2',marginTop:8,borderRadius:4,alignItems:'center',justifyContent:'center',flexDirection:'row',height:50,},
      c321:{width:45,height:45,},
      c322:{},
      c33:{backgroundColor:'white',marginTop:8,borderRadius:4,alignItems:'center',justifyContent:'center',flexDirection:'row',height:50,},
      c331:{width:45,height:45,},
      c332:{},
    },
    ThreadPoster:{
      root:{borderWidth:1,borderColor:'#DDDFE2',borderRadius:4},
      c1:{padding:8,backgroundColor:'#8CB2D1'},
      c2:{flexDirection:'row',padding:8,},
      c21:{marginRight:4},
      c211:{borderWidth:1,borderColor:'black',padding:2,borderRadius:99},
      c2111:{height:25,width:25},
      c22:{flex:1,justifyContent:'center',borderRadius:4,padding:4},
      c23:{position:'absolute',bottom:0,right:0},
      c231:{height:15,width:15},
      c3:{height:0,width:'95%',borderWidth:.5,borderColor:'#DDDFE2',},
      c4:{padding:8,},
      c41:{flexDirection:'row',paddingTop:8,justifyContent:'space-between'},
      c411:{flex:1,justifyContent:'center',borderWidth:1,borderColor:'black',backgroundColor:'#D1E2E4',alignItems:'center',padding:4,borderRadius:4,margin:4,},
      // c4111:{justifyContent:'center',alignItems:'center'},
      c5:{alignItems:'center',justifyContent:'center',padding:8,},
      c51:{flexDirection:'row',justifyContent:'center',alignItems:'center'},
      c511:{width:50,height:50},
      c6:{borderRadius:4,alignItems:'center',justifyContent:'center',margin:8,padding:4,backgroundColor:'rgb(81, 183, 197)'},
      c61:{color:'white',fontWeight:'bold',fontSize:16},
    },
    CommentPoster:{
      root:{padding:8},
      c1:{display: 'flex', flexDirection: 'row'},
      c11:{flex:1, display:'flex', flexDirection: 'column', backgroundColor: '#F5F6F7', borderRadius: 4, borderWidth:1, borderColor:'#DDDFE2'},
      c111:{marginHorizontal:4},
      c112:{position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'},
      c1121:{margin:2},

      c2:{},
      c21:{marginTop:8,flexDirection:'row',justifyContent:'space-between',alignItems:'center'},
      c211a:{},
      c211b:{height:50,width:50},
      c212:{alignItems:'center',justifyContent:'center'},
      // c3:{},
      c3:{marginTop:8,borderRadius:4,padding:4,alignItems:'center',backgroundColor:'rgb(81, 183, 197)'},
      c31:{color:'white',fontWeight:'bold',fontSize:14},
    },
    CommentList:{
      root:{ padding:8},
      c1:{display:'flex',flexDirection:'row',justifyContent:'space-between', fontSize: 12, color: '#616770' },
      c11:{flex:1,display:'flex',flexDirection:'row',justifyContent:'space-between',marginRight:8},
      c111a:{display:'none'},
      c111b:{flex:1,color:'#385898',cursor:'pointer',},
      c1111:{},
      c112a:{display:'none'},
      c112b:{flex:1,color:'#385898',cursor:'pointer',textAlign:'right',},
      c1121:{textAlign:'right'},
      c12:{},
      c2:{paddingTop:8,display:'flex',flexDirection:'column-reverse'},
    },
    Comment:{
      root:{display: 'flex',paddingTop:8, flexDirection: 'row'},
      c1:{flexDirection:'row',alignItems:'center'},
      c11:{borderWidth:1,borderColor:'black',padding:2,borderRadius:99,overflow:'hidden'},
      c111:{height:25,width:25},
      c12:{marginLeft:8,flex:1,fontSize: 14, color: '#385898', fontWeight: 'bold'},
      c2:{ display: 'flex', flexDirection: 'column', flex:1},
      c21:{display: 'flex', flexDirection: 'row', position: 'relative'},
      c211:{ display: 'flex', flexDirection: 'column', flex:1,marginHorizontal:8,padding: 4,position:'relative', backgroundColor: '#F5F6F7', borderWidth:1,borderColor:'#DDDFE2', borderRadius: 4, fontSize: 13},
      c2111:{fontSize: 12},
      c2112:{top:-12,right:-4,borderRadius:8,padding:2, backgroundColor: 'white', position: 'absolute', borderWidth:1,borderColor:'#DDDFE2',display:'flex',flexDirection:'row'},
      c22:{ display: 'flex', flexDirection: 'row',alignItems:'center'},
      c2211:{color: 'red', fontSize: 12},
      c2221:{color: '#616770', fontSize: 12},
    },
    Thread:{
      root:{borderWidth:1,borderColor:'#DDDFE2',  marginTop: 16, borderRadius: 4, backgroundColor: 'white'},
      c1:{padding:8,},
      c11:{display: 'flex', flexDirection: 'column',backgroundColor:'#FFFBFB'},
      c111:{zIndex:1,display: 'flex', flexDirection: 'row'},
      c112:{},
      c113:{display: 'flex', flexDirection: 'row',alignItems:'center',marginTop:8, justifyContent: 'space-between'},
      c1131:{display: 'flex', flexDirection: 'row',alignItems:'center',color: '#616770', fontSize: 13},
      c114:{width:'95%',alignSelf:'center',height:0,borderWidth:.5,borderColor:'#DDDFE2'},
      c115:{display: 'flex', flexDirection: 'row', justifyContent: 'space-around',marginTop:8},
      c116:{display: 'flex', flexDirection: 'row',alignItems:'center'},
      c1161:{marginLeft: 8, },
      c11611:{ fontSize: 13, color: '#616770', fontWeight: 'bold' },
      c2:{borderWidth:.5,borderColor:'#DADDE0'},
      c21:{marginRight:4},
      c211:{borderWidth:1,borderColor:'black',padding:2,borderRadius:99,overflow:'hidden'},
      c2111:{height:25,width:25},
      c22:{flex:1,display: 'flex', flexDirection: 'column'},
      c221:{fontSize: 14, color: '#385898', fontWeight: 'bold'},
      c2211:{fontSize: 14, color: '#385898', fontWeight: 'bold'},
      c222:{color: '#616770',  fontSize: 12},
      c2221:{color: '#616770',  fontSize: 12},
      c23:{position:'absolute',bottom:0,right:0},
      c231:{height:15,width:15},
      c3:{height:0,marginHorizontal:8,borderWidth:.5,borderColor:'black',},
      c4:{padding:8,},
      c41:{flexDirection:'row',padding:4,justifyContent:'space-between'},
      c411:{flex:1,justifyContent:'center',alignItems:'center',padding:4,borderRadius:4,margin:4,},
      // c4111:{justifyContent:'center',alignItems:'center'},
      c5:{alignItems:'center',justifyContent:'center',padding:8,},
      c51:{flexDirection:'row',justifyContent:'center',alignItems:'center'},
      c511:{width:50,height:50},
      c6:{alignItems:'center',justifyContent:'center',padding:8,},
    },
    Home:{
      root:{
        flex:1,
        display:'flex',
        flexDirection:'column',
      },
      c1:{
        flexDirection:'row',
        borderWidth:1,
        borderColor:'blue',
        justifyContent:'space-between',
        alignItems:'center',
      },
      c12:{
        borderWidth:1,
        borderColor:'grey',
        padding:4,
        backgroundColor:'pink',
      },
      c2:{padding:8,},
      c3:{flex:1,padding:8,},
    },
    profileStyles:{
      root:{padding:8},
      sectionContainer:{marginTop: 24,border:'solid 1px #51B7C5',borderRadius:4,overflow:'hidden'},
      sectionContainerName:{color: '#FFFFFF', padding:8,backgroundColor:'#51B7C5', fontSize: 18, letterSpacing: 0.65,fontWeight:'bold',display:'flex',flexDirection:'row',alignItems:'center'},
      sectionFieldContainer:{backgroundColor: '#FFFFFF', padding:8},
      sectionFieldContainerLine:{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'},
      inputField:{},
      inputFieldLabel:{fontSize:14},
      inputFieldValue:{borderWidth:1,borderColor:'#DDDFE2',padding:4,marginVertical:4},
      eventListDetails:{flex:1,textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}
    },
  }
  Session.categoryTags = [
    {label:'Scheduling',value:'Scheduling',group:'Care Coordination'},
    {label:'Appointment Prep',value:'Appointment Prep',group:'Care Coordination'},
    {label:'Medical Records',value:'Medical Records',group:'Care Coordination'},
    {label:'Care Team Management',value:'Care Team Management',group:'Care Coordination'},
    {label:'Provider Selection',value:'Provider Selection',group:'Care Coordination'},
    {label:'Resource Navigation',value:'Resource Navigation',group:'Care Coordination'},
    {label:'Care Coordination Other',value:'Care Coordination Other',group:'Care Coordination'},
    {label:'Diagnosis',value:'Diagnosis',group:'Medical Education & Symptom Management'},
    {label:'Treatment',value:'Treatment',group:'Medical Education & Symptom Management'},
    {label:'Medications',value:'Medications',group:'Medical Education & Symptom Management'},
    {label:'Appointment Interpretation',value:'Appointment Interpretation',group:'Medical Education & Symptom Management'},
    {label:'Comorbidity',value:'Comorbidity',group:'Medical Education & Symptom Management'},
    {label:'Symptoms',value:'Symptoms',group:'Medical Education & Symptom Management'},
    {label:'Update',value:'Update',group:'Medical Education & Symptom Management'},
    {label:'Medical Education & Symptom Management Other',value:'Medical Education & Symptom Management Other',group:'Medical Education & Symptom Management'},
    {label:'Meal Coordination',value:'Meal Coordination',group:'Finance and Logistics'},
    {label:'Financial Assistance',value:'Financial Assistance',group:'Finance and Logistics'},
    {label:'Bill Review',value:'Bill Review',group:'Finance and Logistics'},
    {label:'Grant ID and Application',value:'Grant ID and Application',group:'Finance and Logistics'},
    {label:'Transportation',value:'Transportation',group:'Finance and Logistics'},
    {label:'Child Care',value:'Child Care',group:'Finance and Logistics'},
    {label:'House Care',value:'House Care',group:'Finance and Logistics'},
    {label:'Clothing',value:'Clothing',group:'Finance and Logistics'},
    {label:'Wigs/Prostheses',value:'Wigs/Prostheses',group:'Finance and Logistics'},
    {label:'Finance and Logistics Other',value:'Finance and Logistics Other',group:'Finance and Logistics'},
    {label:'Nutrition',value:'Nutrition',group:'Wellbeing'},
    {label:'Sleep',value:'Sleep',group:'Wellbeing'},
    {label:'Weight',value:'Weight',group:'Wellbeing'},
    {label:'Relationship Management',value:'Relationship Management',group:'Wellbeing'},
    {label:'General',value:'General',group:'Wellbeing'},
    {label:'Identity',value:'Identity',group:'Wellbeing'},
    {label:'Wellbeing Other',value:'Wellbeing Other',group:'Wellbeing'},
    {label:'Alone',value:'Alone',group:'Emotional'},
    {label:'Anxious',value:'Anxious',group:'Emotional'},
    {label:'Death',value:'Death',group:'Emotional'},
    {label:'Depressed',value:'Depressed',group:'Emotional'},
    {label:'General',value:'General',group:'Emotional'},
    {label:'Lonely',value:'Lonely',group:'Emotional'},
    {label:'Sad',value:'Sad',group:'Emotional'},
    {label:'Therapy',value:'Therapy',group:'Emotional'},
    {label:'Emotional Support',value:'Emotional Support',group:'Emotional'},
    {label:'Body Image',value:'Body Image',group:'Emotional'},
    {label:'Emotional Other',value:'Emotional Other',group:'Emotional'},
    {label:'Benefits',value:'Benefits',group:'Benefits Navigation'},
    {label:'Leaves',value:'Leaves',group:'Benefits Navigation'},
    {label:'Return to Work',value:'Return to Work',group:'Benefits Navigation'},
    {label:'Employer Relations',value:'Employer Relations',group:'Benefits Navigation'},
    {label:'Workers Comp',value:'Workers Comp',group:'Benefits Navigation'},
    {label:'Second Opinion',value:'Second Opinion',group:'Benefits Navigation'},
    {label:'Benefits Navigation Other',value:'Benefits Navigation Other',group:'Benefits Navigation'},
    {label:'How-To',value:'How-To',group:'Administrative'},
    {label:'Explain Services',value:'Explain Services',group:'Administrative'},
    {label:'Feedback',value:'Feedback',group:'Administrative'},
    {label:'Complaints',value:'Complaints',group:'Administrative'},
    {label:'Gift Box',value:'Gift Box',group:'Administrative'},
    {label:'Thank You',value:'Thank You',group:'Administrative'},
    {label:'Billable',value:'Billable',group:'Administrative'},
    {label:'Administrative Other',value:'Administrative Other',group:'Administrative'},
    {label:'Technical Support',value:'Technical Support',group:'App/Tech'},
    {label:'Password',value:'Password',group:'App/Tech'},
    {label:'App/Tech Other',value:'App/Tech Other',group:'App/Tech'},
    {label:'TrueRX',value:'TrueRX',group:'Referrals'},
    {label:'Grand Rounds',value:'Grand Rounds',group:'Referrals'},
    {label:'Referrals Other',value:'Referrals Other',group:'Referrals'},

    {label:'ER Visit Avoided',value:'ER Visit Avoided',group:'Expected Outcomes'},
    {label:'Improved Appointment Compliance',value:'Improved Appointment Compliance',group:'Expected Outcomes'},
    {label:'Quality of Life Improved',value:'Quality of Life Improved',group:'Expected Outcomes'},
    {label:'Anxiety Improved',value:'Anxiety Improved',group:'Expected Outcomes'},
    {label:'Financial Barrier Resolved',value:'Financial Barrier Resolved',group:'Expected Outcomes'},
    {label:'Improved Clinical Outcomes',value:'Improved Clinical Outcomes',group:'Expected Outcomes'},
    {label:'Treatment Plan Compliant',value:'Treatment Plan Compliant',group:'Expected Outcomes'},
    {label:'Education for Informed Decision Making',value:'Education for Informed Decision Making',group:'Expected Outcomes'},
    {label:'Improved Timeliness to Treatment',value:'Improved Timeliness to Treatment',group:'Expected Outcomes'},
    {label:'Clinical - Low',value:'Clinical - Low',group:'Expected Outcomes'},
    {label:'Clinical - Medium',value:'Clinical - Medium',group:'Expected Outcomes'},
    {label:'Clinical - High',value:'Clinical - High',group:'Expected Outcomes'},
    {label:'Logisical - Low',value:'Logisical - Low',group:'Expected Outcomes'},
    {label:'Logisical - Medium',value:'Logisical - Medium',group:'Expected Outcomes'},
    {label:'Logisical - High',value:'Logisical - High',group:'Expected Outcomes'},
    {label:'Emotional - Low',value:'Emotional - Low',group:'Expected Outcomes'},
    {label:'Emotional - Medium',value:'Emotional - Medium',group:'Expected Outcomes'},
    {label:'Emotional - High',value:'Emotional - High',group:'Expected Outcomes'},
  ]
  Session.groupedOptions = [
    {
      label: 'Care Coordination',
      options: [
        { value: 'CARE-Scheduling', parentTag: 'CARE', label: 'Scheduling' },
        {
          value: 'CARE-AppointmentPrep',
          parentTag: 'CARE',
          label: 'Appointment Prep'
        },
        {
          value: 'CARE-MedicalRecords',
          parentTag: 'CARE',
          label: 'Medical Records'
        },
        {
          value: 'CARE-CareTeamManagement',
          parentTag: 'CARE',
          label: 'Care Team Management'
        },
        {
          value: 'CARE-ProviderSelection',
          parentTag: 'CARE',
          label: 'Provider Selection'
        },
        {
          value: 'CARE-ResourceNavigation',
          parentTag: 'CARE',
          label: 'Resource Navigation'
        },
        { value: 'CARE-Other', parentTag: 'CARE', label: 'Care Other' }
      ]
    },
    {
      label: 'Medical Education & Symptom Management',
      options: [
        { value: 'MEDICAL-Diagnosis', parentTag: 'MEDICAL', label: 'Diagnosis' },
        { value: 'MEDICAL-Treatment', parentTag: 'MEDICAL', label: 'Treatment' },
        {
          value: 'MEDICAL-Medications',
          parentTag: 'MEDICAL',
          label: 'Medications'
        },
        {
          value: 'MEDICAL-AppointmentInterpretation',
          parentTag: 'MEDICAL',
          label: 'Appointment Interpretation'
        },
        {
          value: 'MEDICAL-Comorbidity',
          parentTag: 'MEDICAL',
          label: 'Comorbidity'
        },
        { value: 'MEDICAL-Symptoms', parentTag: 'MEDICAL', label: 'Symptoms' },
        { value: 'MEDICAL-Update', parentTag: 'MEDICAL', label: 'Update' },
        { value: 'MEDICAL-Other', parentTag: 'MEDICAL', label: 'Meical Other' }
      ]
    },
    {
      label: 'Finance and Logistics',
      options: [
        {
          value: 'FINANCE-MealCoordination',
          parentTag: 'FINANCE',
          label: 'Meal Coordination'
        },
        {
          value: 'FINANCE-FinancialAssistance',
          parentTag: 'FINANCE',
          label: 'Financial Assistance'
        },
        {
          value: 'FINANCE-BillReview',
          parentTag: 'FINANCE',
          label: 'Bill Review'
        },
        {
          value: 'FINANCE-GrantIDAndApplication',
          parentTag: 'FINANCE',
          label: 'Grant ID and Application'
        },
        {
          value: 'FINANCE-Transportation',
          parentTag: 'FINANCE',
          label: 'Transportation'
        },
        { value: 'FINANCE-ChildCare', parentTag: 'FINANCE', label: 'Child Care' },
        { value: 'FINANCE-HouseCare', parentTag: 'FINANCE', label: 'House Care' },
        { value: 'FINANCE-Clothing', parentTag: 'FINANCE', label: 'Clothing' },
        {
          value: 'FINANCE-WigsProstheses',
          parentTag: 'FINANCE',
          label: 'Wigs/Prostheses'
        },
        { value: 'FINANCE-Other', parentTag: 'FINANCE', label: 'Finance Other' }
      ]
    },
    {
      label: 'Wellbeing',
      options: [
        {
          value: 'WELLBEING-Nutrition',
          parentTag: 'WELLBEING',
          label: 'Nutrition'
        },
        { value: 'WELLBEING-Sleep', parentTag: 'WELLBEING', label: 'Sleep' },
        { value: 'WELLBEING-Weight', parentTag: 'WELLBEING', label: 'Weight' },
        {
          value: 'WELLBEING-RelationshipManagement',
          parentTag: 'WELLBEING',
          label: 'Relationship Management'
        },
        { value: 'WELLBEING-General', parentTag: 'WELLBEING', label: 'General' },
        {
          value: 'WELLBEING-Identity',
          parentTag: 'WELLBEING',
          label: 'Identity'
        },
        {
          value: 'WELLBEING-Other',
          parentTag: 'WELLBEING',
          label: 'Wellbeing Other'
        }
      ]
    },
    {
      label: 'Emotional',
      options: [
        { value: 'EMOTIONAL-Alone', parentTag: 'EMOTIONAL', label: 'Alone' },
        { value: 'EMOTIONAL-Anxious', parentTag: 'EMOTIONAL', label: 'Anxious' },
        { value: 'EMOTIONAL-Death', parentTag: 'EMOTIONAL', label: 'Death' },
        {
          value: 'EMOTIONAL-Depressed',
          parentTag: 'EMOTIONAL',
          label: 'Depressed'
        },
        { value: 'EMOTIONAL-General', parentTag: 'EMOTIONAL', label: 'General' },
        { value: 'EMOTIONAL-Lonely', parentTag: 'EMOTIONAL', label: 'Lonely' },
        { value: 'EMOTIONAL-Sad', parentTag: 'EMOTIONAL', label: 'Sad' },
        { value: 'EMOTIONAL-Therapy', parentTag: 'EMOTIONAL', label: 'Therapy' },
        {
          value: 'EMOTIONAL-EmotionalSupport',
          parentTag: 'EMOTIONAL',
          label: 'Emotional Support'
        },
        {
          value: 'EMOTIONAL-BodyImage',
          parentTag: 'EMOTIONAL',
          label: 'Body Image'
        },
        {
          value: 'EMOTIONAL-Other',
          parentTag: 'EMOTIONAL',
          label: 'Emotional Other'
        }
      ]
    },
    {
      label: 'Benefits Navigation',
      options: [
        { value: 'BENEFITS-Benefits', parentTag: 'BENEFITS', label: 'Benefits' },
        { value: 'BENEFITS-Leaves', parentTag: 'BENEFITS', label: 'Leaves' },
        {
          value: 'BENEFITS-ReturnToWork',
          parentTag: 'BENEFITS',
          label: 'Return to Work'
        },
        {
          value: 'BENEFITS-EmployerRelations',
          parentTag: 'BENEFITS',
          label: 'Employer Relations'
        },
        {
          value: 'BENEFITS-WorkersComp',
          parentTag: 'BENEFITS',
          label: 'Workers Comp'
        },
        {
          value: 'BENEFITS-SecondOpinion',
          parentTag: 'BENEFITS',
          label: 'Second Opinion'
        },
        {
          value: 'BENEFITS-Other',
          parentTag: 'BENEFITS',
          label: 'Benefits Other'
        }
      ]
    },
    {
      label: 'Administrative',
      options: [
        {
          value: 'ADMINISTRATIVE-HowTo',
          parentTag: 'ADMINISTRATIVE',
          label: 'How-To'
        },
        {
          value: 'ADMINISTRATIVE-ExplainServices',
          parentTag: 'ADMINISTRATIVE',
          label: 'Explain Services'
        },
        {
          value: 'ADMINISTRATIVE-Feedback',
          parentTag: 'ADMINISTRATIVE',
          label: 'Feedback'
        },
        {
          value: 'ADMINISTRATIVE-Complaints',
          parentTag: 'ADMINISTRATIVE',
          label: 'Complaints'
        },
        {
          value: 'ADMINISTRATIVE-GiftBox',
          parentTag: 'ADMINISTRATIVE',
          label: 'Gift Box'
        },
        {
          value: 'ADMINISTRATIVE-ThankYou',
          parentTag: 'ADMINISTRATIVE',
          label: 'Thank You'
        },
        {
          value: 'ADMINISTRATIVE-Billable',
          parentTag: 'ADMINISTRATIVE',
          label: 'Billable'
        },
        {
          value: 'ADMINISTRATIVE-Other',
          parentTag: 'ADMINISTRATIVE',
          label: 'Administrative Other'
        }
      ]
    },
    {
      label: 'App/Tech',
      options: [
        { value: 'APPTECH-TechnicalSupport', parentTag: 'APPTECH', label: 'Technical Support' },
        { value: 'APPTECH-Password', parentTag: 'APPTECH', label: 'Password' },
        { value: 'APPTECH-Other', parentTag: 'APPTECH', label: 'App/Tech Other' }
      ]
    },
    {
      label: 'Referrals',
      options: [
        { value: 'REFERRALS-TrueRX', parentTag: 'REFERRALS', label: 'TrueRX' },
        { value: 'REFERRALS-GrandRounds', parentTag: 'REFERRALS', label: 'Grand Rounds' },
        { value: 'REFERRALS-Other', parentTag: 'REFERRALS', label: 'Referrals Other' }
      ]
    },
    {
      label: 'Expected Outcomes',
      options: [
        {label:'ER Visit Avoided',value:'ROI-ER Visit Avoided',parentTag:'ROI'},
        {label:'Improved Appointment Compliance',value:'ROI-Improved Appointment Compliance',parentTag:'ROI'},
        {label:'Quality of Life Improved',value:'ROI-Quality of Life Improved',parentTag:'ROI'},
        {label:'Anxiety Improved',value:'ROI-Anxiety Improved',parentTag:'ROI'},
        {label:'Financial Barrier Resolved',value:'ROI-Financial Barrier Resolved',parentTag:'ROI'},
        {label:'Improved Clinical Outcomes',value:'ROI-Improved Clinical Outcomes',parentTag:'ROI'},
        {label:'Treatment Plan Compliant',value:'ROI-Treatment Plan Compliant',parentTag:'ROI'},
        {label:'Education for Informed Decision Making',value:'ROI-Education for Informed Decision Making',parentTag:'ROI'},
        {label:'Improved Timeliness to Treatment',value:'ROI-Improved Timeliness to Treatment',parentTag:'ROI'},
        {label:'Clinical Low',value:'ROI-Clinical Low',parentTag:'ROI'},
        {label:'Clinical Medium',value:'ROI-Clinical Medium',parentTag:'ROI'},
        {label:'Clinical High',value:'ROI-Clinical High',parentTag:'ROI'},
        {label:'Logisical Low',value:'ROI-Logisical Low',parentTag:'ROI'},
        {label:'Logisical Medium',value:'ROI-Logisical Medium',parentTag:'ROI'},
        {label:'Logisical High',value:'ROI-Logisical High',parentTag:'ROI'},
        {label:'Emotional Low',value:'ROI-Emotional Low',parentTag:'ROI'},
        {label:'Emotional Medium',value:'ROI-Emotional Medium',parentTag:'ROI'},
        {label:'Emotional High',value:'ROI-Emotional High',parentTag:'ROI'},
      ]
    },
  ]
}
export const Session = {}
resetSession()
export const fbListenerBlog = () => {
  if(Session.fbListenerBlog && Session.fbListenerBlog !== 'init') return

  const listener = firebase.firestore().collection('/pswUsers/000000000PUBLICBLOG999999999/posts').where('isBlog','==',true).onSnapshot(
    (querySnapshot) => {
      let updateSize=0
      // let qsSize = querySnapshot.size
      const blogInit = _.size(Session.blogThreadList) === 0
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc.data()
        const docId = change.doc.id
        doc.id=docId
        doc.createdDate = _.get(doc,'createdDate.seconds',0)*1000 || Date.now()
        doc.modifiedDate= _.get(doc,'modifiedDate.seconds',0)*1000 || Date.now()
        doc.createdByName = _.get(doc,'createdByName','')
        doc.modifiedByName = _.get(doc,'modifiedByName','')
        if ((change.type === 'added' || change.type === 'modified') && doc.createdDate!==1000 && doc.modifiedDate!==1000) {
          updateSize++
          _.set(Session,`blogThreadList.posts.${docId}`,doc)
          if(doc.isThread){
            _.set(Session,`blogThreadList.threads.${docId}`, _.merge(doc,{comments:_.get(Session,`blogThreadList.threads.${docId}.comments`,{})},{attachments:_.get(Session,`blogThreadList.threads.${docId}.attachments`,{})}))
          }else if(doc.isComment && doc.threadId){
            _.set(Session,`blogThreadList.threads.${doc.threadId}.comments.${docId}`, _.merge(doc,{attachments:_.get(Session,`blogThreadList.threads.${doc.threadId}.comments.${docId}.attachments`,{})}))
          }else if(doc.isAttachment){
            if(doc.threadId===doc.commentId && doc.threadId){
              _.set(Session,`blogThreadList.threads.${doc.threadId}.attachments.${docId}`, _.merge(doc,{attachmentData:_.get(Session,`blogThreadList.threads.${doc.threadId}.attachments.${docId}.attachmentData`,{})}))
            }else if(doc.threadId && doc.commentId){
              _.set(Session,`blogThreadList.threads.${doc.threadId}.comments.${doc.commentId}.attachments.${docId}`, _.merge(doc,{attachmentData:_.get(Session,`blogThreadList.threads.${doc.threadId}.comments.${doc.commentId}.attachments.${docId}.attachmentData`,{})}))
            }
          }
        } else if (change.type === 'removed') {
          updateSize++
          _.unset(Session,`blogThreadList.posts.${docId}`)
          if(doc.isThread){
            _.unset(Session,`blogThreadList.threads.${docId}`)
          }else if(doc.isComment){
            _.unset(Session,`blogThreadList.threads.${doc.threadId}.comments.${docId}`)
          }else if(doc.isAttachment){
            if(doc.threadId===doc.commentId){
              _.unset(Session,`blogThreadList.threads.${doc.threadId}.attachments.${docId}`)
            }else{
              _.unset(Session,`blogThreadList.threads.${doc.threadId}.comments.${doc.commentId}.attachments.${docId}`)
            }
          }
        }
      })
      if(updateSize === 0) return
      if(blogInit && _.get(Session,`refreshBlog`)){
        _.get(Session,`refreshBlog`)()
      }else{
        refreshBlog()
      }
    }, (e) => {
      console.log('FB:ERROR:fbListenerBlogs', e)
      listener()
    }
  )
  Session.fbListenerBlog = listener
}
export const userPresenceListener = () => {
  // console.log('userPresenceListener',firebase.auth().currentUser.metadata.lastSignInTime)

  var uid = firebase.auth().currentUser.uid
  var userStatusDatabaseRef = firebase.database().ref('/pswUsersStatus/' + uid)

  var isOfflineForDatabase = {
    presenceState: 'offline',
    presenceLastChanged: firebase.database.ServerValue.TIMESTAMP,
  }
  var isOnlineForDatabase = {
    presenceState: 'online',
    presenceLastChanged: firebase.database.ServerValue.TIMESTAMP,
  }

  firebase.database().ref('.info/connected').on('value', (snapshot) => {
    if (snapshot && snapshot.val() === false) {
      return
    }

    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(() => {
      userStatusDatabaseRef.set(isOnlineForDatabase)
    })
  })
  _.set(Session,'userStatusDatabaseRef',userStatusDatabaseRef)
}
export const userLogin = (email, password) => {
  resetSession()
  return new Promise((resolve, reject) => {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(async (user) => {
        Session.uid = user.user.uid
        Session.auth = true
        resolve()
        userLogLoginLogout('Login')
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export const userFacebookLogin = () => {
  var provider = new firebase.auth.FacebookAuthProvider()
  provider.addScope('user_birthday')
  firebase.auth().signInWithPopup(provider).then((result) => {
    userLogLoginLogout('userFacebookLogin')
    // var token = result.credential.accessToken
    // var user = result.user
    // console.log('userFacebookLogin',user)
  })
}
export const userGoogleLogin = () => {
  var provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('profile')
  provider.addScope('email')
  firebase.auth().signInWithPopup(provider).then((result) => {
    userLogLoginLogout('userGoogleLogin')
  //  var token = result.credential.accessToken
  //  var user = result.user
  })
}
export const userRegister = (email, password) => {
  resetSession()
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .then(()=>{
        userLogLoginLogout('userRegister')
      })
      .catch((error) => {
        // var errorCode = error.code
        // var errorMessage = error.message
        // console.log('userRegister',error)
        reject(error)
      })
  })
}
export const userLogout = () => {
  userLogLoginLogout('Logout')
  return new Promise((resolve, reject) => {
    firebase.auth().signOut().then(() => {
      if(Session.userStatusDatabaseRef){
        Session.userStatusDatabaseRef.set({
          presenceState: 'offline',
          presenceLastChanged: firebase.database.ServerValue.TIMESTAMP,
        })
      }
      resetSession()
      resolve()
    })
    .catch((e) => {
      console.log('Firebase: Sign Out error', e)
      reject()
    })
  })
}
export const userSendPasswordResetEmail = (emailAddress) => {
  var userEmail = ''
  while(userEmail.length < 1){
    userEmail = prompt("Please enter your email address assigned to your account",userEmail)
    if(userEmail===null) break
  }
  if(userEmail!==null && userEmail.length > 0){
    firebase.auth().sendPasswordResetEmail(userEmail).then(function(a,b,c) {
      console.log('sendPasswordResetEmail')
    }).catch(function(error) {
      console.log('ERROR:sendPasswordResetEmail',error)
    })
  }
}
export const fbListenerUser = (uid) => {
  return new Promise((resolve, reject) => {
    if(_.get(Session,`fbListenerUser.${uid}`,null)) {
      return reject()
    }
    // if (Session.fbListenerUser) {
    //   console.log('fbListenerUser:Exists')
    //   return
    // }
    var db = firebase.firestore()

    const listener = db
      .collection('/pswUsers')
      .doc(uid)
      // .doc(Session.uid)
      .onSnapshot(
        (doc) => {
          const tempUserDoc = doc.data() || {} //DO AWAITS
          tempUserDoc.id = uid
          tempUserDoc.lastActivityDate = _.has(tempUserDoc,'lastActivityDate.seconds') ? _.get(tempUserDoc,'lastActivityDate.seconds',1)*1000 : ''
          tempUserDoc.lastActivityActionDate = _.has(tempUserDoc,'lastActivityActionDate.seconds') ? _.get(tempUserDoc,'lastActivityActionDate.seconds',1)*1000 : ''
          tempUserDoc.createdDate = _.get(tempUserDoc,'createdDate.seconds',0)*1000 || Date.now()
          tempUserDoc.modifiedDate= _.get(tempUserDoc,'modifiedDate.seconds',0)*1000 || Date.now()
          _.set(Session,`users.${uid}.profile`,tempUserDoc)
          _.set(Session,`filters`,_.get(doc.data(),'filters',{}))
          _.set(Session,`users.${uid}.profileRef`,doc)
          if(Session.uid===uid && !doc.metadata.hasPendingWrites){
            Session.fbUser = tempUserDoc
            Session.fbUserRef = doc
          }
          resolve()
          if(!_.get(Session,'userClaims.isAdmin',false) && (_.get(tempUserDoc,'welcomeScreen',0) === 99 || _.get(tempUserDoc,'welcomeScreen',0) === 0)){
            refreshApp()
          }
        },
        (e) => {
          console.log('FB:ERROR:user', e)
          listener()
        }
      )
    _.set(Session,`fbListenerUser.${uid}`,listener)
    userPresenceListener()
    // Session.fbListenerUser = listener
  })
}
export const fbListenerUserList = (refreshCallback) => {
  if(Session.fbListenerUserList || !_.get(Session,'userClaims.isAdmin',false)) {
    return
  }
  var db = firebase.firestore()

  const listener = db.collection(`/pswUsers`).onSnapshot(
    (querySnapshot) => {
      let updateSize=0
      // console.log('fbListenerUserList',querySnapshot.size)
      querySnapshot.docChanges().forEach((change) => {
        if(true || !change.doc.metadata.hasPendingWrites){
          const docId = change.doc.id
          const doc = change.doc.data() //DO AWAITS
          doc.id = change.doc.id
          doc.lastActivityDate = _.has(doc,'lastActivityDate.seconds') ? _.get(doc,'lastActivityDate.seconds',1)*1000 : ''
          doc.lastActivityActionDate = _.has(doc,'lastActivityActionDate.seconds') ? _.get(doc,'lastActivityActionDate.seconds',1)*1000 : ''
          doc.createdDate = _.get(doc,'createdDate.seconds',0)*1000 || Date.now()
          doc.modifiedDate= _.get(doc,'modifiedDate.seconds',0)*1000 || Date.now()
          if ((change.type === 'added' || change.type === 'modified')) {
            if(change.type === 'added' && _.get(doc,'profileIcon',null) === null && (Date.now() - doc.createdDate) > 300000){
              console.log('profileIcon',doc.userCount,doc.id)
              if(doc.profileIconPath !== 'pending'){
                userUpdateProfileIcon(doc.id)
              }else{
                // console.log('pending')
                // const userProfileIconCreate = firebase.functions().httpsCallable('userProfileIconCreate')
                // userProfileIconCreate({uid:doc.id}).then((result)=>{
                //   console.log('userProfileIconCreate',result)
                // }).catch((error)=>{
                //   console.log(error)
                // })
              }
            }
            updateSize++
            _.set(Session,`userCount.C${doc.userCount}`,doc.id)
            _.set(Session,`users.${docId}.profile`,doc)
            _.set(Session,`users.${docId}.profileRef`,change.doc)
          } else if (change.type === 'removed') {
            updateSize++
            _.unset(Session,`users.${docId}`)
          }

          if(_.get(Session,`refreshHome.${doc.id}`,null) && updateSize>0){
            // console.log('fbListenerUserList:refreshHome')
            // Session.refreshHome[doc.id]()
            refreshHome(doc.id)
          }else if(_.get(Session,`refreshProfile.${doc.id}`,null) && updateSize>0){
            // console.log('fbListenerUserList:refreshProfile')
            Session.refreshProfile[doc.id]()
          }else{
            // console.log('UNMOUNTED:fbListenerUserList')
          }
        }
      })

      if(Session.refreshUserList && updateSize>0){
        // console.log('refreshUserList')
        Session.refreshUserList()
      }
    },
    (e) => {
      console.log('FB:ERROR:fbListenerUserList', e)
      listener()
    }
  )
  Session.fbListenerUserList = listener
  // userPresenceListener()
  // const adminFullSync = firebase.functions().httpsCallable('adminFullSync')
  // adminFullSync({uid:Session.uid}).then((result)=>{
  //   console.log('adminFullSync')
  // }).catch((error)=>{
  //   console.log('ERROR:adminFullSync:',error)
  // })
  // syncOldUsers()
}
export const userUpdate = (uid,field) => {
  return new Promise((resolve, reject) => {
    if(!_.get(Session,`users.${uid}.profileRef`,null)){
      return reject()
    }
    field.modifiedById = Session.uid
    field.modifiedByName = _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName','')
    field.modifiedDate = firebase.firestore.FieldValue.serverTimestamp()

    _.get(Session,`users.${uid}.profileRef`,null).ref.set(field,{merge:true}).then(()=>{
      resolve()
      if(uid !== Session.uid && _.get(Session,'userClaims.isAdmin',false)){
        userLogLastAction(`Profile Update: ${uid}`)
      }else{
        userLogLastAction(`Profile Update`)
        // if(_.get(Session,'userClaims.isAdmin',false)){
        //   userLogLastAction(`Profile Update`)
        // }else{
        //   if(_.get(Session.fbUser,'welcomeScreen',0) === 99){
        //     userLogLastAction(`Profile Update`)
        //   }
        // }
      }
    }).catch((error)=>{
      reject()
    })
  })
}
export const userUpdateProfileIcon = (uid) => {
  if(!uid || _.get(Session,'fbUser.profileIconPath','pending') === 'pending'){
    console.log('userUpdateProfileIcon:pending',uid)
    // console.log('ERROR:userUpdateProfileIcon',uid,_.cloneDeep(Session.fbUser.profileIconPath))
    return
  }
  console.log('userUpdateProfileIcon',uid)
  firebase.storage().ref().child(`/attachments/${uid}/profileIcon.png`).getDownloadURL().then((url) => {
      userUpdate(uid,{profileIcon:url})
    }).catch((error)=>{
      console.log('ERROR:profileIconPath',error.code)
      if(error.code === 'storage/object-not-found'){
        const userProfileIconCreate = firebase.functions().httpsCallable('userProfileIconCreate')
        userProfileIconCreate({uid}).then((result)=>{
          console.log('userUpdateProfileIcon',result)
        }).catch((error)=>{
          console.log('ERROR:userUpdateProfileIcon',error)
        })
      }
    })
}
export const userLogLoginLogout = (action) => {
  const userLogDevice = firebase.functions().httpsCallable('userLogDevice')
  userLogDevice({screenHeight:window.innerHeight,screenWidth:window.innerWidth}).then((result)=>{
  }).catch((error)=>{
    console.log('userLogDevice:ERROR',error)
  })

  firebase.firestore().collection(`/pswUsers/${firebase.auth().currentUser.uid}/logs`).add({
    lastActivityAction: action,
    lastActivityActionDate: firebase.firestore.FieldValue.serverTimestamp(),
  })
}
export const userLogLastAction = (action) => {
  if(!_.get(Session,`users.${Session.uid}.profileRef`,null)){
    return
  }
  const tempUserRef = _.get(Session,`users.${Session.uid}.profileRef`,null)
  const userLog = {
    lastActivityAction: action,
    lastActivityActionDate: firebase.firestore.FieldValue.serverTimestamp(),
  }
  tempUserRef.ref.set(userLog,{merge:true}).then(()=>{
    tempUserRef.ref.collection(`logs`).add(userLog).then((logRef) => {
    }).catch((error)=>{
      console.log('LOGS:ERROR',error)
    })
  }).catch((error)=>{
    console.log('LOG:ERROR',error)
  })
}
export const fbListener = (uid) => {
  // console.log('STARTfbListener')
  if(_.get(Session,`fbListener.${uid}`,null)) {
    return
  }
  var db = firebase.firestore()

  const listener = db.collection(`/pswUsers/${uid}/posts`).onSnapshot(
    (querySnapshot) => {
      // console.log('querySnapshot')
      // let qsSize = querySnapshot.size
      // console.log('qsSize', qsSize,querySnapshot.metadata.hasPendingWrites,querySnapshot.metadata)
      // if(querySnapshot.metadata.hasPendingWrites) return
      let updateSize=0
      querySnapshot.docChanges().forEach((change) => {
        const docId = change.doc.id
        const doc = change.doc.data() //DO AWAITS
        doc.id = change.doc.id
        doc.createdDate = _.get(doc,'createdDate.seconds',1)*1000
        doc.modifiedDate= _.get(doc,'modifiedDate.seconds',1)*1000
        doc.createdByName = _.get(doc,'createdByName','')
        doc.modifiedByName = _.get(doc,'modifiedByName','')
        // console.log(doc.id,change.type,doc.modifiedDate)
        if ((change.type === 'added' || change.type === 'modified') && doc.createdDate!==1000 && doc.modifiedDate!==1000) {
          updateSize++
          _.set(Session,`posts.${uid}.${docId}`,doc)
          if(doc.isThread){
            _.set(Session,`users.${uid}.threads.${docId}`, _.merge(doc,{comments:_.get(Session,`users.${uid}.threads.${docId}.comments`,{})},{attachments:_.get(Session,`users.${uid}.threads.${docId}.attachments`,{})}))
          }else if(doc.isComment && doc.threadId){
            _.set(Session,`users.${uid}.threads.${doc.threadId}.comments.${docId}`, _.merge(doc,{attachments:_.get(Session,`users.${uid}.threads.${doc.threadId}.comments.${docId}.attachments`,{})}))
          }else if(doc.isAttachment){
            if(doc.threadId===doc.commentId && doc.threadId){
              _.set(Session,`users.${uid}.threads.${doc.threadId}.attachments.${docId}`, doc)
            }else if(doc.threadId && doc.commentId){
              _.set(Session,`users.${uid}.threads.${doc.threadId}.comments.${doc.commentId}.attachments.${docId}`, doc)
            }
          }
        } else if (change.type === 'removed') {
          updateSize++
          _.unset(Session,`posts.${uid}.${docId}`)
          if(doc.isThread){
            _.unset(Session,`users.${uid}.threads.${docId}`)
          }else if(doc.isComment){
            _.unset(Session,`users.${uid}.threads.${doc.threadId}.comments.${docId}`)
          }else if(doc.isAttachment){
            if(doc.threadId===doc.commentId){
              _.unset(Session,`users.${uid}.threads.${doc.threadId}.attachments.${docId}`)
            }else{
              _.unset(Session,`users.${uid}.threads.${doc.threadId}.comments.${doc.commentId}.attachments.${docId}`)
            }
          }
        }
      })
      // refreshCallback(qsSize)
      if(_.get(Session,`refreshHome.${uid}`,null) && updateSize>0){
        // console.log('refreshHome')
        // Session.refreshHome[uid]()
        refreshHome(uid)
      }else if(_.get(Session,`refreshProfile.${uid}`,null) && updateSize>0){
        // console.log('refreshProfile')
        Session.refreshProfile[uid]()
      }else if(updateSize===0){
      }else{
        // console.log('UNMOUNTED')
      }
    },
    (e) => {
      console.log('FB:ERROR:fbListener', e)
      listener()
    }
  )
  _.set(Session,`fbListener.${uid}`,listener)
}
const postPublicBlogComment = (publicUserEmail,comment,thread) => {
  const newComment = {
    message: comment,
    uid:'PUBLICBLOG',
    createdById: 'PUBLICBLOG',
    createdByName: publicUserEmail,
    createdByProfileIcon: 'https://firebasestorage.googleapis.com/v0/b/dev-exchanger-167423.appspot.com/o/attachments%2F000000000PUBLICBLOG999999999%2FRobinCareAvatar.png?alt=media&token=9005a26a-3aea-4efa-bf44-511c7aefb978',
    //'https://firebasestorage.googleapis.com/v0/b/psw-delete-fs2.appspot.com/o/attachments%2F000000000PUBLICBLOG999999999%2FCCLogo.png?alt=media&token=f91ad064-9c64-4520-af36-eabc920fccda',
    createdDate: firebase.firestore.FieldValue.serverTimestamp(),
    modifiedById: 'PUBLICBLOG',
    modifiedByName: publicUserEmail,
    modifiedByProfileIcon: 'https://firebasestorage.googleapis.com/v0/b/dev-exchanger-167423.appspot.com/o/attachments%2F000000000PUBLICBLOG999999999%2FRobinCareAvatar.png?alt=media&token=9005a26a-3aea-4efa-bf44-511c7aefb978',
    //'https://firebasestorage.googleapis.com/v0/b/psw-delete-fs2.appspot.com/o/attachments%2F000000000PUBLICBLOG999999999%2FCCLogo.png?alt=media&token=f91ad064-9c64-4520-af36-eabc920fccda',
    modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
    threadId: thread.id,
    isComment: true,
    isBlog: false,
    postType:'comment',
  }
  firebase.firestore().collection(`/pswUsers/000000000PUBLICBLOG999999999/posts`).add(newComment)
    .then((postRef) => {
      // console.log(postRef.id)
    })
    .catch((error) => {
      // console.log('public:error',error)
    })
}
export const postComment = (uid,comment,thread,attachments) => {
  return new Promise((resolve, reject) => {
    if(uid==='PUBLICBLOG'){
      let validPublicUserEmail = false
      let publicUserEmail = window.prompt('Please enter your email address and we will respond as soon as possible')
      validPublicUserEmail = publicUserEmail.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
      if(!validPublicUserEmail){
        publicUserEmail = window.prompt('Invalid email address, please try again')
        validPublicUserEmail = publicUserEmail.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
      }
      if(!validPublicUserEmail){
        window.alert('Sorry, please try again')
      }else{
        postPublicBlogComment(publicUserEmail,comment,thread)
      }
      return resolve()
    }
    const newComment = {
      message: comment,
      uid:Session.uid,
      createdById: Session.uid,
      createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
      createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
      createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      modifiedById: Session.uid,
      modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
      modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
      modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      localEpoc: Date.now(),
    }
    if (thread) {
      newComment.threadId = thread.id
      newComment.isComment = true
      newComment.postType='comment'
    } else {
      newComment.isThread = true
      newComment.postType='thread'
    }
    firebase.firestore().collection(`/pswUsers/${uid}/posts`).add(newComment)
      .then((postRef) => {
        if(uid === Session.uid){
          userLogLastAction(newComment.isThread ? 'Posted New Thread' : 'Posted New Comment')
          firebase.firestore().collection(`/pswUsers`).doc(uid)
            .set({
              lastActivityDate: firebase.firestore.FieldValue.serverTimestamp(),
              lastPostId:postRef.id,
            },{merge:true})
        }else{
          let tempUserCount = _.get(Session,`users.${uid}.profile.userCount`,-99)
          userLogLastAction(newComment.isThread ? `Posted New Thread User ${tempUserCount}` : `Posted New Comment User ${tempUserCount}`)
        }
        // console.log('Document written with ID: ', commentRef.id)
        const tempPromises = []
        const urls = newComment.message.match(/\bhttps?:\/\/\S+/gi)
        _.map(urls,(url,index)=>{
          tempPromises.push(postURL(uid,url,index,postRef.id,thread ? thread.id : postRef.id))
        })
        _.map(attachments.urlList,(url,index)=>{
          tempPromises.push(postURL(uid,url,index,postRef.id,thread ? thread.id : postRef.id))
        })
        _.map(attachments.pollList,(poll,index)=>{
          tempPromises.push(postPoll(uid,poll,(index+1)*10,postRef.id,thread ? thread.id : postRef.id))
        })
        _.map(attachments.trackerList,(tracker,index)=>{
          tempPromises.push(postTracker(uid,tracker,(index+1)*20,postRef.id,thread ? thread.id : postRef.id))
        })
        const validFiles = _.filter(attachments.tempFiles,{valid:true})
        _.map(validFiles, (file, index) => {
            tempPromises.push(postFile(uid,file,(index+1)*100,postRef.id,thread ? thread.id : postRef.id))
        })
        Promise.all(tempPromises).then((tempPromisesValues)=>{
          // console.log('tempPromises',tempPromises,tempPromisesValues)
          resolve(postRef)
        })
      })
      .catch((error) => {
        reject(error)
      })
    // resolve()
  })
}
export const postFixProfileIcons = async (user,post) => {
  if(!post.id || user.uid==='PUBLICBLOG'){
    return console.log('error with postFixProfileIcons',post)
  }
  const profileIcons = {createdByProfileIcon:user.profileIcon || ''}
  if(Session.uid === post.createdById){
    profileIcons.createdByProfileIcon = Session.fbUser.profileIcon || ''
    firebase.firestore().collection(`/pswUsers/${user.uid}/posts`).doc(post.id)
      .set(profileIcons,{merge:true})
      .then(()=>console.log('postFixProfileIcons:SUCCESS1',post.id))
      .catch((error)=>console.log('postFixProfileIcons:ERROR1',error))
  }else{
    profileIcons.createdByProfileIcon = await
    firebase.storage().ref().child(`/attachments/${post.createdById}/profileIcon.png`).getDownloadURL()
      .then((url) => {
        profileIcons.createdByProfileIcon = url
        firebase.firestore().collection(`/pswUsers/${user.uid}/posts`).doc(post.id)
          .set(profileIcons,{merge:true})
          .then(()=>console.log('postFixProfileIcons:SUCCESS2',post.id))
          .catch((error)=>console.log('postFixProfileIcons:ERROR2',error))
       })
      .catch((error)=>{
        console.log('postFixProfileIcons:ERROR3',post.createdById,error)
        if(error.code === 'storage/object-not-found'){
          const userProfileIconCreate = firebase.functions().httpsCallable('userProfileIconCreate')
          userProfileIconCreate({uid:post.createdById}).then((result)=>{
            console.log('userUpdateProfileIcon',result)
          }).catch((error)=>{
            console.log('ERROR:userUpdateProfileIcon',error)
          })
        }
        return ''
      })
  }
}
export const postUpdate = (uid,post,newMessage) => {
  return new Promise((resolve, reject) => {
    if(post.createdById !== Session.uid){
      return reject('invalid user')
    }
    firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.id)
      .set({
        message: _.trim(newMessage),
        previousMessage: post.message,
        uid: Session.uid,
        modifiedById: Session.uid,
        modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },{merge:true})
      .then(() => {
        if(post.isComment){
          firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.threadId)
            .set({
              uid: Session.uid,
              modifiedById: Session.uid,
              modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
              modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
              modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
            },{merge:true})
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject()
            })
        }else{
          resolve()
        }
      })
      .catch((error) => {
        reject()
      })
      userLogLastAction(`Post Update: ${uid} - ${post.id}`)
      resolve()
  })
}
export const postUpdateTags = (uid,post,tags) => {
  return new Promise((resolve, reject) => {
    if(!_.get(Session,'userClaims.isAdmin',false)){
      return reject('invalid user')
    }
    firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.id)
      .set({
        tags: _.map(tags,(tag)=>{return tag.value}),
        // uid: Session.uid,
        tagsModifiedById: Session.uid,
        tagsModifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        // tagsModifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
        tagsModifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },{merge:true})
      .then(() => {
        resolve()
        if(false && post.isComment){ // @TODO don't update modified date
          firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.threadId)
            .set({
              uid: Session.uid,
              modifiedById: Session.uid,
              modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
              modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
              modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
            },{merge:true})
            .then(() => {
              resolve()
            })
            .catch((error) => {
              reject()
            })
        }else{
          resolve()
        }
      })
      .catch((error) => {
        reject()
      })
      userLogLastAction(`Tagging: ${uid} - ${post.id}`)
      resolve()
  })
}
export const postUpdateTracker = (uid,post,attachmentData) => {
  return new Promise((resolve, reject) => {
    if(post.createdById === Session.uid && uid !== Session.uid){
      reject()
      return console.log('postUpdateTracker:invalid user')
    }
    firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.id)
      .set({
        attachmentData,
        uid: Session.uid,
        modifiedById: Session.uid,
        modifiedByName: _.get(Session.fbUser,'firstName','') || _.get(Session.fbUser,'firstName',''),
        modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },{merge:true})
      .then(() => {
        if(post.isAttachment){
          firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.commentId)
            .set({
              uid: Session.uid,
              modifiedById: Session.uid,
              modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
              modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
              modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
            },{merge:true})
            .then(() => {
            })
            .catch((error) => {
            })
          firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.threadId)
            .set({
              uid: Session.uid,
              modifiedById: Session.uid,
              modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
              modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
              modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
            },{merge:true})
            .then(() => {
            })
            .catch((error) => {
            })
            resolve()
        }else{
          resolve()
        }
      })
      .catch((error) => {
        reject()
      })
      userLogLastAction(`postUpdateTracker: ${uid} - ${post.id}`)
  })
}
export const postUpdatePoll = (uid,post,newValue) => {
  return new Promise((resolve, reject) => {
    if(uid==='PUBLICBLOG'){
      window.confirm('Thank you for your answer')
      post.attachmentData=_.merge(post.attachmentData,{
        response: newValue,
        isAnswered: newValue.length === 0?false:true,
      })
      refreshBlog()
      return resolve()
    }
    if(post.createdById === Session.uid && uid !== Session.uid){
      reject()
      return console.log('postUpdatePoll:invalid user')
    }
    firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.id)
      .set({
        attachmentData: {
          response: newValue,
          isAnswered: newValue.length === 0?false:true,
          previousValue: post.attachmentData.response || ''
        },
        uid: Session.uid,
        modifiedById: Session.uid,
        modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },{merge:true})
      .then(() => {
        if(post.isAttachment){
          firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.commentId)
            .set({
              uid: Session.uid,
              modifiedById: Session.uid,
              modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
              modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
              modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
            },{merge:true})
            .then(() => {
            })
            .catch((error) => {
            })
          firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.threadId)
            .set({
              uid: Session.uid,
              modifiedById: Session.uid,
              modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
              modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
              modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
            },{merge:true})
            .then(() => {
            })
            .catch((error) => {
            })
            resolve()
        }else{
          resolve()
        }
      })
      .catch((error) => {
        reject()
      })
      userLogLastAction(`postUpdatePoll: ${uid} - ${post.id}`)
  })
}
export const postDelete = (uid,post) => {
  if(post.isThread){
    firebase.firestore().collection(`/pswUsers/${uid}/posts`)
      .doc(post.id).set({
        isArchived: true,
        archivedById: Session.uid,
        archivedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        archivedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },{merge:true})
      .then(()=>{
        userLogLastAction(`postDelete:thread: ${uid} - ${post.id}`)
      }).catch((error)=>{
        console.log('Thread Archive Error',error)
      })
  }else{
    if(post.createdById !== Session.uid && post.createdById !== 'PUBLICBLOG'){
      return console.log('postDelete:invalid user')
    }
    firebase.firestore().collection(`/pswUsers/${uid}/deleted`)
      .add({...post,
        isDeleted: true,
        deletedById: Session.uid,
        deletedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        deletedDate: firebase.firestore.FieldValue.serverTimestamp(),
      }).then((postRef) => {
        firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.id).delete().then(() => {
          userLogLastAction(`postDelete:comment: ${uid} - ${post.id}`)
            // console.log("Document successfully deleted!")
        }).catch((error) => {
          console.log('postDelete1:ERROR',error)
        })
      })
      .catch((error) => {
        console.log('postDelete0:ERROR',error)
      })
  }
}
export const postUnarchive = (uid,post) => {
  if(post.isThread){
    firebase.firestore().collection(`/pswUsers/${uid}/posts`)
      .doc(post.id).set({
        isArchived: false,
        unarchivedById: Session.uid,
        unarchivedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        unarchivedDate: firebase.firestore.FieldValue.serverTimestamp(),
      },{merge:true})
      .then(()=>{
        userLogLastAction(`postUnarchive:thread: ${uid} - ${post.id}`)
      }).catch((error)=>{
        console.log('Thread Archive Error',error)
      })
  }
}

export const postAvatar = (uid, imageData) => {
  const attachmentRef = firebase.storage().ref().child(`/attachments/${uid}/profileIcon.png`)

  attachmentRef.putString(imageData, 'data_url').then((snapshot) => {
    userLogLastAction(`postAvatar: ${uid}`)
    attachmentRef.getDownloadURL().then((url) => {
      firebase.firestore().collection('/pswUsers').doc(uid).set({profileIcon: url}, {merge: true})
    })
  })
}

export const postFile = (uid,file,index,commentId,threadId) => {
  return new Promise((resolve, reject) => {
    if (file.valid) {
      file.ref = `/attachments/${uid}/${Date.now()}-${index}-${commentId}`
      const attachmentRef = firebase.storage().ref().child(file.ref)
      attachmentRef.put(file.file)
        .then((snapshot) => {
          attachmentRef.getDownloadURL().then((url) => {
            file.url = url
            firebase.firestore().collection(`/pswUsers/${uid}/posts`)
              .add({
                postType:'attachment_file',
                isAttachment: true,
                attachmentName: file.file.name,
                attachmentOrder: index,
                attachmentType: file.file.type,
                attachmentURL: file.url,
                commentId: commentId,
                threadId: threadId,
                uid:Session.uid,
                createdById: Session.uid,
                createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
                createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
                createdDate: firebase.firestore.FieldValue.serverTimestamp(),
                modifiedById: Session.uid,
                modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
                modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
                modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then((attachmentRef) => {
                userLogLastAction(`postFile: ${uid} - ${attachmentRef.id}`)
                resolve(true)
              })
              .catch((error)=>{
                // console.log('POSTFILE3:ERROR:postcomment')
                resolve(false)
              })
          })
          .catch((error)=>{
            // console.log('POSTFILE2:ERROR:getDownloadURL')
            resolve(false)
          })
        })
        .catch((error) => {
          // console.log('POSTFILE1:ERROR:putfile')
          resolve(false)
        })
    }
  })
}
export const postURL = (uid,url,index,commentId,threadId) => {
  return new Promise((resolve, reject) => {
    axios.get(`https://api.microlink.io?url=${url}`).then(({data,status})=>{
      if(status===200 && data.status==='success'){
        firebase.firestore().collection(`/pswUsers/${uid}/posts`)
          .add({
            postType:'attachment_url',
            isAttachment: true,
            attachmentName: 'url',
            attachmentOrder: index,
            attachmentType: 'url',
            attachmentURL: url,
            microLink: data,
            commentId: commentId,
            threadId: threadId,
            uid:Session.uid,
            createdById: Session.uid,
            createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
            createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
            createdDate: firebase.firestore.FieldValue.serverTimestamp(),
            modifiedById: Session.uid,
            modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
            modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
            modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then((attachmentRef) => {
            userLogLastAction(`postURL: ${uid} - ${attachmentRef.id}`)
            resolve(true)
          })
          .catch((error)=>{
            console.log('error',error)
            resolve(false)
          })
      }else{
        firebase
          .firestore()
          .collection(`/pswUsers/${uid}/posts`)
          .add({
            postType:'attachment_url',
            isAttachment: true,
            attachmentName: 'url',
            attachmentOrder: index,
            attachmentType: 'url',
            attachmentURL: url,
            commentId: commentId,
            threadId: threadId,
            uid:Session.uid,
            createdById: Session.uid,
            createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
            createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
            createdDate: firebase.firestore.FieldValue.serverTimestamp(),
            modifiedById: Session.uid,
            modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
            modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
            modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then((attachmentRef) => {
            resolve(true)
          })
          .catch((error)=>{
            console.log('error',error)
            resolve(false)
          })
      }
    }).catch((error)=>{
      firebase
        .firestore()
        .collection(`/pswUsers/${uid}/posts`)
        .add({
          postType:'attachment_url',
          isAttachment: true,
          attachmentName: 'url',
          attachmentOrder: index,
          attachmentType: 'url',
          attachmentURL: url,
          commentId: commentId,
          threadId: threadId,
          uid:Session.uid,
          createdById: Session.uid,
          createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
          createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
          createdDate: firebase.firestore.FieldValue.serverTimestamp(),
          modifiedById: Session.uid,
          modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
          modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
          modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((attachmentRef) => {
          resolve(true)
        })
        .catch((error)=>{
          console.log('error',error)
          resolve(false)
        })
      console.log('microlinkAxios error',error)
    })
  })
}
export const postPoll = (uid,poll,index,commentId,threadId) => {
  return new Promise((resolve, reject) => {
    firebase.firestore().collection(`/pswUsers/${uid}/posts`)
      .add({
        postType:'attachment_poll',
        isAttachment: true,
        attachmentName: poll.question,
        attachmentOrder: index,
        attachmentType: 'poll',
        attachmentURL: '',
        attachmentData: poll,
        commentId: commentId,
        threadId: threadId,
        uid:Session.uid,
        createdById: Session.uid,
        createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedById: Session.uid,
        modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((attachmentRef) => {
        userLogLastAction(`postPoll: ${uid} - ${attachmentRef.id}`)
        resolve(true)
      })
      .catch((error)=>{
        console.log('error',error)
        resolve(false)
      })
  })
}
export const postTracker = (uid,tracker,index,commentId,threadId) => {
  return new Promise((resolve, reject) => {
    firebase.firestore().collection(`/pswUsers/${uid}/posts`)
      .add({
        postType:'attachment_tracker',
        isAttachment: true,
        attachmentName: tracker,
        attachmentOrder: index,
        attachmentType: 'tracker',
        attachmentURL: '',
        attachmentData: {symptom:tracker,value:null},
        commentId: commentId,
        threadId: threadId,
        uid:Session.uid,
        createdById: Session.uid,
        createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedById: Session.uid,
        modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
        modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
        modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((attachmentRef) => {
        userLogLastAction(`postTracker: ${uid} - ${attachmentRef.id}`)
        resolve(true)
      })
      .catch((error)=>{
        console.log('error',error)
        resolve(false)
      })
  })
}
export const postEvent = (post, uid, eventDetails) => {
  firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.id)
    .set({eventDetails},{merge:true})
    .then(() => {
      userLogLastAction(`postEvent: ${uid} - ${post.id}`)
    })
    .catch((error) => {
    })
}
export const postReaction = (post, uid, reaction) => {
  if(uid==='PUBLICBLOG'){
    const blogPostReaction = firebase.functions().httpsCallable('blogPostReaction')
    blogPostReaction({postId:post.id,reaction,sid:sessionStorage.sid}).then((result)=>{
      // console.log(blogPostReaction,result)
    }).catch((error)=>{
      console.log(error)
    })
    return
  }

  const oldReaction = _.get(post,`reactions.${Session.uid}`,'')
  const turnOff = oldReaction===reaction?true:false
  firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.id)
    .set(oldReaction===''?{
      'reactions': reaction===''?{
        [Session.uid]:firebase.firestore.FieldValue.delete(),
      }:{
        [Session.uid]:reaction,
        [reaction]: firebase.firestore.FieldValue.arrayUnion(Session.uid)
      }
    }:{
      'reactions': reaction==='' || turnOff?{
        [Session.uid]:firebase.firestore.FieldValue.delete(),
        [oldReaction]:firebase.firestore.FieldValue.arrayRemove(Session.uid),
      }:{
        [Session.uid]:reaction,
        [oldReaction]:firebase.firestore.FieldValue.arrayRemove(Session.uid),
        [reaction]: firebase.firestore.FieldValue.arrayUnion(Session.uid)
      }
    },{merge:true})
    .then(() => {
      userLogLastAction(`postReaction: ${uid} - ${post.id}`)
    })
    .catch((error) => {
    })
}
export const postDelivered = (post,uid) => {
  firebase.firestore().collection(`/pswUsers/${uid}/posts`).doc(post.id)
    .set({
      userDelivered:true,
      userDeliveredDate: firebase.firestore.FieldValue.serverTimestamp(),
    },{merge:true})
    .then(() => {
      userLogLastAction(`postDelivered: Web - ${post.id}`)
    })
    .catch((error) => {
    })
}
export const fbListenerAdminDocs = () => {
  if(Session.fbListenerAdminDocs || !_.get(Session,'userClaims.isAdmin',false)) {
    return
  }
  Session.fbListenerAdminDocs={}
  var db = firebase.firestore()

  const listener = db.collection(`/pswAdminDocs`).onSnapshot(
    (querySnapshot) => {
      // console.log('querySnapshot')
      // let qsSize = querySnapshot.size
      // console.log('qsSize', qsSize,querySnapshot.metadata.hasPendingWrites,querySnapshot.metadata)
      // if(querySnapshot.metadata.hasPendingWrites) return
      let updateSize=0
      querySnapshot.docChanges().forEach((change) => {
        const docId = change.doc.id
        const doc = change.doc.data() //DO AWAITS
        doc.id = change.doc.id
        doc.createdDateEPOC = _.get(doc,'createdDate.seconds',1)*1000
        doc.modifiedDateEPOC = _.get(doc,'modifiedDate.seconds',1)*1000
        doc.createdByName = _.get(doc,'createdByName','')
        doc.modifiedByName = _.get(doc,'modifiedByName','')
        // console.log(doc.id,change.type,doc.modifiedDate)
        if ((change.type === 'added' || change.type === 'modified') && doc.createdDateEPOC!==1000 && doc.modifiedDateEPOC!==1000) {
          updateSize++
          _.set(Session,`adminDocs.${docId}`,doc)
        } else if (change.type === 'removed') {
          updateSize++
          _.unset(Session,`adminDocs.${docId}`)
        }
      })

      if(Session.refreshAdminSection && updateSize>0){
        Session.refreshAdminSection()
      }
      // if(Session.refreshAdminNotes && updateSize>0){
      //   Session.refreshAdminNotes()
      // }
      // if(Session.refreshAdminCases && updateSize>0){
      //   Session.refreshAdminCases()
      // }
      // if(Session.refreshAdminPhoneLogs && updateSize>0){
      //   Session.refreshAdminPhoneLogs()
      // }
    },
    (e) => {
      console.log('FB:ERROR:fbListenerAdminDocs', e)
      listener()
    }
  )
  _.set(Session,`fbListenerAdminDocs`,listener)
}
export const adminDocCreate = (uid,adminDoc) => {
  return new Promise((resolve, reject) => {
    if(adminDoc.id){ //UPDATE ADMINDOC
      firebase.firestore().collection(`/pswAdminDocs`).doc(adminDoc.id)
        .set(_.merge(adminDoc,{
          modifiedById: Session.uid,
          modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
          modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
          modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
          version: firebase.firestore.FieldValue.increment(1),
        }),{merge:true})
        .then(() => {
          userLogLastAction(`adminDocUpdate: ${uid} - ${adminDoc.id}`)
          resolve()
          firebase.firestore().collection(`/pswAdminDocs/${adminDoc.id}/versions`).add(adminDoc)
        }).catch((error)=>{
          console.log('adminDocCreate',error)
          reject(error)
        })
    }else{ //INSERT ADMINDOC
      firebase.firestore().collection(`/pswAdminDocs`)
        .add(_.merge(adminDoc,{
          uid: Session.uid,
          userUid: uid,
          createdById: Session.uid,
          createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
          createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
          createdDate: firebase.firestore.FieldValue.serverTimestamp(),
          modifiedById: Session.uid,
          modifiedByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
          modifiedByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
          modifiedDate: firebase.firestore.FieldValue.serverTimestamp(),
          version: firebase.firestore.FieldValue.increment(1),
        }))
        .then((adminDocRef) => {
          userLogLastAction(`adminDocCreate: ${uid} - ${adminDocRef.id}`)
          resolve()
          adminDoc.id=adminDocRef.id
          adminDocRef.set({id:adminDocRef.id},{merge:true}).then(()=>{
            adminDocRef.collection(`versions`).add(adminDoc)
          })
        }).catch((error)=>{
          console.log('adminDocCreate',error)
          reject(error)
        })
    }
  })
}
// export const adminNoteCreate = (uid,post,note) => {
//   return new Promise((resolve, reject) => {
//     if(!_.get(Session,'userClaims.isAdmin',false)) return reject('invalid user')
//
//     const adminNote = _.get(Session,`adminDocs.${post.id}`,{
//       postId:post.id,
//       docType:'note',
//       uid: Session.uid,
//       userUid: uid,
//       createdById: Session.uid,
//       createdByName: _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName',''),
//       createdByProfileIcon: _.get(Session.fbUser,'profileIcon',''),
//       createdDate: firebase.firestore.FieldValue.serverTimestamp(),
//     })
//     adminNote.message = note
//     adminNote.modifiedById = Session.uid
//     adminNote.modifiedByName = _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName','')
//     adminNote.modifiedByProfileIcon = _.get(Session.fbUser,'profileIcon','')
//     adminNote.modifiedDate = firebase.firestore.FieldValue.serverTimestamp()
//     adminNote.version = firebase.firestore.FieldValue.increment(1)
//
//     firebase.firestore().collection(`/pswAdminDocs`).doc(post.id)
//       .set(adminNote,{merge:true})
//       .then(() => {
//         userLogLastAction(`adminNoteCreate: ${uid} - ${post.id}`)
//         resolve()
//         firebase.firestore().collection(`/pswAdminDocs/${post.id}/versions`).add(adminNote)
//       }).catch((error)=>{
//         console.log('adminNoteCreate',error)
//         reject(error)
//       })
//   })
// }
export const adminOpenTab = async (uid) => {
  if(!_.has(Session,`users.${uid}`)) return
  const indexUid = _.indexOf(Session.userTabs,uid)
  if(indexUid !== 0){
    if(indexUid > 0){
      Session.userTabs.splice(indexUid,1)
    }
    Session.userTabs.unshift(uid)
    if(Session.refreshAdminPanel){
      userLogLastAction(`adminOpenTab: ${uid}`)
      return await Session.refreshAdminPanel()
    }
  }
}

export const fileSize = (size) => {
  const keys = ['B', 'KB', 'MB', 'GB']
  let key = 0
  while (true) {
    if (size >= 1024) {
      size = size / 1024
      key++
    } else {
      if (_.round(size) < 10) {
        return `${_.round(size, 1)}${keys[key]}`
      } else {
        return `${_.round(size)}${keys[key]}`
      }
    }
    if (key >= 3) {
      if (_.round(size) < 10) {
        return `${_.round(size, 1)}${keys[key]}`
      } else {
        return `${_.round(size)}${keys[key]}`
      }
    }
  }
}
export const fileDownload = (uid,attachment) => {
  const a = document.createElement('A')
  a.href = attachment.attachmentURL
  a.download = attachment.attachmentName
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
export const markupMessage = (uid,message) => {
  if(!message) return null
  const searchStr = _.get(Session,`searchStr.${uid}`,'')
  const messageSplitNL = message.split(new RegExp(`\n`))
  return (
    _.map(messageSplitNL,(messageParagraph,index) => {
      if(searchStr.length === 0) return <p key={`P-${index}`}>{messageParagraph}</p>
      const messageSplit = messageParagraph.split(new RegExp(`(${searchStr})`, 'gi'))
      return (
        <p key={`P-${index}`}>
          {
            _.map(messageSplit,(messagePart,index) => {
              if(messagePart.toLowerCase() === searchStr.toLowerCase()){
                return <mark key={`MPH-${index}`}>{messagePart}</mark>
              }else{
                return <span key={`MP-${index}`}>{messagePart}</span>
              }
            })
          }
        </p>
      )
    })
  )
}
export const refreshApp = _.debounce(()=>{
  if(_.get(Session,`appRefresh`)){
    _.get(Session,`appRefresh`)()
  }
}, 500,{leading:false,trailing:true})
export const refreshHome = _.debounce((uid)=>{
  // console.log('refreshHome',uid)
  if(_.get(Session,`refreshHome.${uid}`)){
    _.get(Session,`refreshHome.${uid}`)()
  }
}, 1250,{leading:false,trailing:true})
export const refreshBlog = _.debounce(()=>{
  if(_.get(Session,`refreshBlog`)){
    sessionStorage.setItem('blogThreadList',JSON.stringify(Session.blogThreadList))
    _.get(Session,`refreshBlog`)()
  }
}, 250,{leading:false,trailing:true})
export const debounceUserProfileIcon = (user)=>{
  if(!user.profileIconPath) return
  firebase.storage().ref().child(user.profileIconPath).getDownloadURL()
    .then((url) => {
      firebase.firestore().collection(`/pswUsers`).doc(user.uid)
        .set({profileIcon:url},{merge:true})
        .then(()=>{
          console.log('debounceUserProfileIcon:SUCCESS',user.uid)
          refreshApp()
        })
        .catch((error)=>console.log('debounceUserProfileIcon:ERROR',error))
    })
    .catch((error)=>{ return console.log('debounceUserProfileIcon:ERROR',user.uid,error) })
}

//FIX SECTION
const syncOldUsers = async () => {
  return
  const idtoken = await Session.currentUser.getIdToken()
  axios.get(`https://api.getrobincare.com/users`, {headers:{
    'Content-Type': 'application/json',
    suid: Session.uid,
    uid: Session.uid,
    idtoken: idtoken
  }})
    .then((response) => {
      _.map(response.data,(userSQLInfo)=>{
        axios.get(`https://api.getrobincare.com/cards/99999999`, {headers:{
          'Content-Type': 'application/json',
          suid: Session.uid,
          uid: userSQLInfo.uid,
          idtoken: idtoken
        }})
          .then((response) => {
            if(response.data.length>0){
              const userOldCards = response.data
              const userOldMyInfoCard = _.filter(userOldCards,{kind:'myinfo'}).length > 0 ? _.filter(userOldCards,{kind:'myinfo'})[0] : {}
              const userOldChatCards = _.filter(userOldCards,{kind:'chat'})
              userOldMyInfoCard.details = JSON.parse(userOldMyInfoCard.details)
              const userInfo = {
                old_active: _.get(userSQLInfo,'active',''),
                old_company: _.get(userSQLInfo,'company',''),
                old_companyEmail: _.get(userSQLInfo,'companyEmail',''),
                old_email: _.get(userSQLInfo,'email',''),
                old_exclude: _.get(userSQLInfo,'exclude',''),
                old_first_name: _.get(userSQLInfo,'first_name',''),
                old_id: _.get(userSQLInfo,'id',''),
                old_last_name: _.get(userSQLInfo,'last_name',''),
                old_last_signin: _.get(userSQLInfo,'last_signin',''),
                old_login_count: _.get(userSQLInfo,'login_count',''),
                old_phone: _.get(userSQLInfo,'phone',''),
                old_redemptioncode: _.get(userSQLInfo,'redemptioncode',''),
                old_status: _.get(userSQLInfo,'status',''),
                old_type: _.get(userSQLInfo,'type',''),
                old_user_created_date: _.get(userSQLInfo,'user_created_date',''),

                acceptTerms: true,
                welcomeScreen:99,
                userCount: _.get(userSQLInfo,'id',-99),
                uid:_.get(userSQLInfo,'uid',''),
                email:_.get(userSQLInfo,'email',''),
                firstName:_.get(userOldMyInfoCard,'details.first_name',''),
                lastName:_.get(userOldMyInfoCard,'details.last_name',''),
                displayName:_.get(userOldMyInfoCard,'details.nickname','') || _.get(userOldMyInfoCard,'details.first_name',''),
                phoneNumber:_.get(userOldMyInfoCard,'details.phone',''),
                nickname:_.get(userOldMyInfoCard,'details.nickname','') || _.get(userOldMyInfoCard,'details.first_name',''),
                employer:_.get(userSQLInfo,'company',''),
                companyName:_.get(userSQLInfo,'company',''),
                companyEmail:_.get(userSQLInfo,'companyEmail',''),
                employementStatus:'',
                occupation:'',
                gender:'',
                birthdate:_.get(userOldMyInfoCard,'details.birthdate',''),
                addressLine1:_.get(userOldMyInfoCard,'details.address_line_1',''),
                addressLine2:_.get(userOldMyInfoCard,'details.address_line_2',''),
                addressCity:_.get(userOldMyInfoCard,'details.city',''),
                addressState:_.get(userOldMyInfoCard,'details.state',''),
                addressZip:_.get(userOldMyInfoCard,'details.zip',''),
                userType: _.startCase(_.get(userOldMyInfoCard,'details.patientType',null)) || _.startCase(_.get(userSQLInfo,'type','')),
                type: _.startCase(_.get(userOldMyInfoCard,'details.patientType',null)) || _.startCase(_.get(userSQLInfo,'type','')),
                // profileIcon: null,
                // profileIconPath: null,
                symptoms: [
                  'Body Image Concerns',
                  'Constipation',
                  'Cough',
                  'Decreased Appetite',
                  'Diarrhea',
                  'Fatigue',
                  'Fear of Recurrence',
                  'Fear/Anxiety',
                  'Financial Stress',
                  'Hair Loss',
                  'Hot Flashes',
                  'Lacking Social Support',
                  'Memory/Concentration',
                  'Nausea/Vomiting',
                  'Numbness & Tingling',
                  'Pain',
                  'Sadness/Grief',
                  'Sexual Concerns',
                  'Shortness of Breath/Wheezing',
                  'Trouble Sleeping',
                  'Weight Gain',
                  'Weight Loss',
                ],

                patientEmail: '',
                patientFirstName:'',
                patientLastName:'',
                patientDisplayName:'',
                patientPhoneNumber:'',
                patientNickname:'',
                patientEmployer:'',
                patientEmployementStatus:'',
                patientOccupation:'',
                patientGender:'',
                patientBirthdate:'',
                patientAddressLine1:'',
                patientAddressLine2:'',
                patientAddressCity:'',
                patientAddressState:'',
                patientAddressZip:'',

                cancerType: _.get(userOldMyInfoCard,'details.cancer_type',''),
                cancerStage: _.get(userOldMyInfoCard,'details.cancer_stage',''),
                cancerDiagnosisDate: _.get(userOldMyInfoCard,'details.diagnosis_date',''),
                cancerStatus: _.startCase(_.get(userOldMyInfoCard,'details.cancer_journey_location','')),
                cancerInterests: _.get(userOldMyInfoCard,'details.cancer_concerns',''),
                cancerGoals: _.get(userOldMyInfoCard,'details.objective',''),

                old_cancer_concerns: _.get(userOldMyInfoCard,'details.cancer_concerns',''),
                old_cancer: _.get(userOldMyInfoCard,'details.cancer',''),
                old_cancer_coping: _.get(userOldMyInfoCard,'details.cancer_coping',''),
                old_cancer_journey_location: _.get(userOldMyInfoCard,'details.cancer_journey_location',''),
                old_cancer_journey_location_details: _.get(userOldMyInfoCard,'details.cancer_journey_location_details',''),
                old_cancer_stage: _.get(userOldMyInfoCard,'details.cancer_stage',''),
                old_cancer_type: _.get(userOldMyInfoCard,'details.cancer_type',''),
                old_caregiverPatient: _.get(userOldMyInfoCard,'details.caregiverPatient',''),
                old_authorization_level: _.get(userOldMyInfoCard,'details.authorization_level',''),
                old_diagnosis_date: _.get(userOldMyInfoCard,'details.diagnosis_date',''),

                insurance:'',
                hostpital:'',
                medications:[],

                createdDate: firebase.firestore.Timestamp.fromDate(new Date(_.get(userSQLInfo,'user_created_date',new Date()))),
                modifiedDate: firebase.firestore.Timestamp.fromDate(new Date(_.get(userSQLInfo,'user_modified_date',new Date()))),
              }
              if(false){
              // if(true || userSQLInfo.uid === '0FZXnTg2NwTxotyebwQj5fgdQ1G2'){
                console.log('USERSYNC:',userInfo.uid,userOldChatCards.length)
                firebase.firestore().collection(`pswUsers`).doc(userInfo.uid).set(userInfo,{merge:true})
                  .then(()=>{
                    // _.map(userOldChatCards,(oldChat)=>{
                    //   oldChat.details = JSON.parse(_.get(oldChat,'details','{}'))
                    //   const newComment = {
                    //     message: _.get(oldChat,'details.message',''),
                    //     uid: _.get(oldChat,'createdBy_uid',userInfo.uid),
                    //     createdById: _.get(oldChat,'createdBy_uid','unknown'),
                    //     createdByName: _.get(oldChat,'created_by_first_name','unknown'),
                    //     createdByProfileIcon: '',
                    //     createdDate: firebase.firestore.Timestamp.fromDate(new Date(_.get(oldChat,'created_date',new Date()))),
                    //     modifiedById: _.get(oldChat,'modifiedBy_uid','unknown'),
                    //     modifiedByName: _.get(oldChat,'modified_by_first_name','unknown'),
                    //     modifiedByProfileIcon: '',
                    //     modifiedDate: firebase.firestore.Timestamp.fromDate(new Date(_.get(oldChat,'modified_date',new Date()))),
                    //     localEpoc: Date.now(),
                    //     threadId:'xxxMASTERxxx',
                    //     isComment:true,
                    //     postType:'comment',
                    //     oldMySQLId:_.get(oldChat,'id',-99),
                    //   }
                    //   firebase.firestore().collection(`/pswUsers/${userInfo.uid}/posts`).doc(`OLDCARD${_.get(oldChat,'id','ZZZ')}`).set(newComment,{merge:true})
                    //     .then(() => {
                    //       // console.log('newComment',`OLDCARD${_.get(oldChat,'id','ZZZ')}`)
                    //     })
                    //     .catch((error) => {
                    //       console.log('ERROR:userSyncOldCards:FS',error)
                    //     })
                    // })
                  })
              }
            }
          })
          .catch((error) => {
            console.log('ERROR:USERCARDS:', error)
          })
      })
    })
    .catch((error) => {
      console.log('ERROR:USERSQLINFO:', error)
    })
}
