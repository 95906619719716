import React, { useState,useEffect } from 'react'
import { withRouter } from 'react-router'
import { Switch, Route, Redirect } from 'react-router-dom'
import firebase from 'firebase'
import _ from 'lodash'
import LuxonUtils from '@date-io/luxon'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

//SRC IMPORTS
import { Session, resetSession, fbListenerUser, fbListener, userUpdateProfileIcon, userLogLoginLogout, userUpdate, syncOldUserActivities } from './shared/globals'
import Header from './components/Header'
import LeftNav from './components/LeftNav'
import Login from './components/Login'
import Blog from './components/Blog'
import Profile from './components/Profile'
import Home from './components/Home'
import AdminPanel from './components/admin/AdminPanel'
import Settings from './components/Settings'
import MarketingPage from './components/MarketingPage'
import MarketingPrivacy from './components/MarketingPrivacy'
import MarketingTerms from './components/MarketingTerms'
import EmployerPage from './components/PublicEmployer'
import BrokerPage from './components/PublicBroker'
import { DialogWelcome, DialogAccept } from './components/dialogs'

const useAuth = () => {
  const [state, setState] = useState(() => {
    const user = firebase.auth().currentUser
    return { initializing: !user, user }
  })
  const onChange = async (user) => {
    resetSession()
    if(!user){
    }else{
      Session.userClaims=await user.getIdTokenResult().then((idTokenResult)=>{ return idTokenResult.claims})
    }
    setState({ initializing: false, user })
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange)
    return () => unsubscribe()
  }, [])

  return state
}

const App = ({ history }) => {
  const { initializing, user } = useAuth()
  const [, refreshState] = useState()
  const [posterModal,setPosterModal] = useState(false)

  const refresh = () => {
    // console.log('APP RENDER:refresh')
    refreshState(Date.now())
  }

  useEffect(()=>{
    // console.log('APP RENDER:useEffect')
    Session.appRefresh=refresh
    if(Session.fbUser && !_.get(Session.fbUser,'profileIcon',null) && _.get(Session.fbUser,'profileIconPath',null) !== null){
      _.set(Session.fbUser,'profileIcon','loading...')
      userUpdateProfileIcon(Session.fbUser.uid)
    }
    return () => {
      // console.log('APP RENDER:useEffect:exit')
      Session.appRefresh=null
    }
  })

  if (initializing) {
    return null
  }

  if(user && !Session.auth){
    Session.currentUser = user
    Session.uid = user.uid
    Session.user = 'autologin'
    Session.auth = true
    Session.posterModal = setPosterModal
    userLogLoginLogout(`autoLogin${performance.navigation.type}`)
  }
  if (Session.auth && !_.get(Session,`fbListenerUser.${user.uid}`,null)) {
    // console.log('APP RENDER:fbListenerUser')
    fbListenerUser(Session.uid).then(refresh).catch((error)=>console.log(error))
  } else if (!Session.auth) {
  }
  if (Session.auth && !_.get(Session,`fbListener.${user.uid}`,null)) {
    // console.log('APP RENDER:fbListener')
    fbListener(Session.uid)
  }
  // if (Session.auth && _.get(Session,'userClaims.isAdmin',false) && !Session.fbListenerUserList) {
  //  fbListenerUserList(refresh)
  // }

  if (Session.auth && (!_.get(Session,`users.${user.uid}.profile`,null) || _.get(Session,`users.${user.uid}.profile.userCount`,-1) === -1)) {
    return (
      <div style={{ height: '100vh', display: 'flex', backgroundColor:'white', flexDirection: 'column',alignItems:'center',justifyContent:'center' }}>
        <div style={{backgroundColor:'#6BC1F6',padding:50,borderRadius:99}}>
          <img
            src={require('./shared/assets/rc/RobinCare-Logo_Animation-blueBckground.gif')}
            alt=""
            style={{}}
            />
        </div>
        <div style={{marginTop:16,color:'black',fontSize:36,fontWeight:200,lineHeight:'initial'}}>Please wait while we prepare</div>
      </div>
    )
  }
  if(Session.auth && _.get(Session,`users.${user.uid}.profile`,null) && !_.get(Session,'userClaims.isAdmin',false) && _.get(Session,`users.${user.uid}.profile.welcomeScreen`,0) < 99){
    return (
      <div style={{ height: '100vh', display: 'flex', backgroundColor:'white', flexDirection: 'column',alignItems:'center',justifyContent:'center' }}>
        <DialogWelcome open={true} dialogParentHide={()=>{console.log('close')}} user={_.get(Session,`users.${user.uid}.profile`,null)}/>
      </div>
    )
  }
  if(Session.auth && _.get(Session,`users.${user.uid}.profile`,null) && !_.get(Session,'userClaims.isAdmin',false) && !_.get(Session,`users.${user.uid}.profile.acceptTerms`,false)){
    if(_.get(history,`location.pathname`,'/').split('/')[1] === 'terms'){
      return (
        <Route path="/terms" component={MarketingTerms} />
      )
    }else if(_.get(history,`location.pathname`,'/').split('/')[1] === 'privacy-policy'){
      return (
        <Route path="/privacy-policy" component={MarketingPrivacy} />
      )
    }else if(_.get(history,`location.pathname`,'/').split('/')[1] === 'privacy'){
      return (
        <Route path="/privacy" component={MarketingPrivacy} />
      )
    }else{
      return (
        <div style={{ height: '100vh', display: 'flex', backgroundColor:'white', flexDirection: 'column',alignItems:'center',justifyContent:'center' }}>
          <DialogAccept open={true} dialogParentHide={()=>{console.log('close')}} user={_.get(Session,`users.${user.uid}.profile`,null)}/>
        </div>
      )
    }
  }
  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header history={history} />
      <div style={{display:posterModal?'':'none',cursor:'pointer',height:'100%',width:'100%',position:'absolute',zIndex:1,backgroundColor:'black',opacity:0.3}}></div>
      {
        _.get(Session,'userClaims.isAdmin',false) ?
        <div style={{backgroundColor: 'white', display: 'flex', flexDirection: 'row', flex: 1, overflow: 'hidden'}}>
          <Switch>
            <Route exact path="/" render={() => Session.auth ? (<AdminPanel />) : (<Redirect to={{pathname: '/public/0'}}/>)}/>
            <Route path="/profile" render={() => Session.auth && _.get(Session,`users.${user.uid}.profile`,null) ? (<Profile user={_.get(Session,`users.${user.uid}.profile`,null)} />) : (<Redirect to={{pathname: '/login'}}/>)}/>
            <Redirect to="/" />
          </Switch>
        </div>
         :
        <div style={{backgroundColor: 'white', display: 'flex', flexDirection: 'row', flex: 1, overflow: 'hidden'}}>
          {
            Session.auth ? <LeftNav user={_.get(Session,`users.${Session.uid}.profile`,null)} history={history} /> : null
          }
          <div style={{display: 'flex', flexDirection: 'row', flex: 1, overflow: 'scroll'}}>
            <Route exact path="/" render={() => Session.auth ? (<Home uid={user.uid} />) : (<Redirect to={{pathname: '/login'}}/>)}/>
            <Route path="/profile" render={() => Session.auth && _.get(Session,`users.${user.uid}.profile`,null) ? (<Profile user={_.get(Session,`users.${user.uid}.profile`,null)} />) : (<Redirect to={{pathname: '/login'}}/>)}/>
            <Route exact path="/login" render={() => Session.auth && _.get(Session,`users.${user.uid}.profile`,null) ? (<Redirect to={{pathname: '/'}}/>):(<Login history={history} login={true} />)}/>
            <Route exact path="/signup" render={() => Session.auth && _.get(Session,`users.${user.uid}.profile`,null) ? (<Redirect to={{pathname: '/'}}/>):(<Login history={history} login={false} />)}/>
            <Route path="/public/:id" component={MarketingPage} />
            <Route path="/employer" component={EmployerPage} />
            <Route path="/broker" component={BrokerPage} />
            <Route path="/privacy" component={MarketingPrivacy} />
            <Route path="/privacy-policy" component={MarketingPrivacy} />
            <Route path="/terms" component={MarketingTerms} />
            <Route path="/blog" component={Blog} />
            <Route path="/settings" render={() => <Settings user={_.get(Session,`users.${user.uid}.profile`,null)}/>} />
          </div>
        </div>
      }
    </div>
    </MuiPickersUtilsProvider>
  )
}

export default withRouter(App)
