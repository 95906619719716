import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateTimePicker } from '@material-ui/pickers';

import { Session, postEvent } from '../../shared/globals'

export const DialogEventDetails = ({open,post,user,dialogParentHide}) => {
  const [,refreshState] = useState()
  const [isNew,] = useState(!_.has(post,'eventDetails.epoc'))
  const [eventDetails,setEventDetails] = useState(_.get(post,'eventDetails.details',''))
  const [eventDateTime,setEventDateTime] = useState(moment(_.get(post,'eventDetails.epoc',new Date())).toDate())

  const dialogHide = () => {
    setEventDetails('')
    dialogParentHide()
  }
  const dialogCancel = () => {
    if(!isNew){ //not new cancel = remove
      postEvent(post,user.uid,{})
    }
    dialogHide()
  }
  const dialogUpdate = () => {
    postEvent(post,user.uid,{
      modifiedById:Session.uid,
      details:eventDetails,
      dateString:moment(eventDateTime.isLuxonDateTime?eventDateTime.ts:eventDateTime).toString(),
      epoc:moment(eventDateTime.isLuxonDateTime?eventDateTime.ts:eventDateTime).valueOf()
    })
    dialogHide()
  }

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogHide} aria-labelledby="form-dialog-title">
      <DialogTitle>Event Details</DialogTitle>
      <DialogContent>
        <TextField autoFocus fullWidth margin="normal" variant="outlined"
          label="Description" inputProps={{ maxLength: 50 }}
          onChange={(e)=>setEventDetails(e.target.value)}
          value={eventDetails}
        />
        <DateTimePicker fullWidth inputVariant="outlined" showTodayButton
          label="Event Date/Time"
          onClose={()=>{refreshState(Date.now())}}
          value={eventDateTime}
          onChange={setEventDateTime}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogCancel} color="primary">
          {isNew?'Cancel':'Remove Event'}
        </Button>
        <Button onClick={dialogUpdate} color="primary" disabled={eventDetails.length === 0}>
          {isNew?'Save Event':'Update Event'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogEventDetails
