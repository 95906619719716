import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Session,userUpdate } from '../../shared/globals'

export const DialogTracker = ({open,trackerList,dialogParentHide}) => {
  const [symptomPickList, ] = useState(_.map(_.get(Session,'fbUser.symptoms',[]),(symptom)=>{
    return {label:symptom, value:symptom}
  }) || [])
  const [selectedSymptoms,setSelectedSymptoms] = useState(_.map(trackerList,(tracker)=>{
    return {label:tracker,value:tracker}
  }) || [])

  const dialogUpdate = () => {
    trackerList.length=0
    trackerList.push(..._.map(selectedSymptoms,(symptom)=>{return _.startCase(symptom.value)}))
    const tempNewPickList = _.map(symptomPickList,({value})=>{
      return _.startCase(value)
    }).sort()
    if(_.difference(tempNewPickList,_.get(Session,'fbUser.symptoms',[])).length > 0){
      userUpdate(Session.uid,{
        symptoms:_.map(symptomPickList,({value})=>{
          return _.startCase(value)
        }).sort(),
      })
    }
    dialogParentHide()
  }
  const handleCSChange = (newValue: any, actionMeta: any) => {
    if( actionMeta.action === 'create-option'){
      const tempSingleNewValue = newValue[newValue.length-1]
      if(_.indexOf(symptomPickList,tempSingleNewValue) === -1){
        symptomPickList.push({label:_.startCase(tempSingleNewValue.value),value:_.startCase(tempSingleNewValue.value)})
      }
      //
      // if(_.indexOf(_.get(Session,'fbUser.symptoms',[]),tempSingleNewValue) === -1){
      //   if(!_.has(Session,'fbUser.symptoms')){
      //     _.set(Session,'fbUser.symptoms',[])
      //   }
      //   _.get(Session,'fbUser.symptoms',[]).push(_.startCase(tempSingleNewValue.value))
      // }
    }
    setSelectedSymptoms(newValue)
  }

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogParentHide} aria-labelledby="form-dialog-title">
      <DialogTitle>Add a Symptom to Track</DialogTitle>
        <DialogContent>
          <CreatableSelect isMulti autoFocus
            menuPortalTarget={document.body}
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            placeholder={'Select Symptoms to Track'}
            value={selectedSymptoms}
            onChange={handleCSChange}
            options={symptomPickList}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogParentHide} color="primary">
            CANCEL
          </Button>
          <Button onClick={dialogUpdate} color="primary" disabled={!selectedSymptoms || selectedSymptoms.length === 0}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
  )
}
export default DialogTracker
