import React, { useState } from 'react';
import _ from 'lodash'

import IconButton from '@material-ui/core/IconButton';
import CollectionsBookmarkOutlined from '@material-ui/icons/CollectionsBookmarkOutlined'

import { Session } from '../../shared/globals'
import { DialogNotes } from '../dialogs'

const NotesBadge = ({ user, post }) => {
  const [dialogOpen,setDialogOpen] = useState('')
  const dialogHide = () => {
    setDialogOpen('')
  }
  if(_.filter(_.get(Session,'adminDocs',{}),{postId:post.id,docType:'note'}).length===0 || !_.get(Session,'userClaims.isAdmin',false)){
    return null
  }else{
    return (
        <div id="feedbackButton" style={{cursor: 'pointer', verticalAlign: 'center', fontSize: 'inherit'}}>
          <IconButton size='small' onClick={()=>setDialogOpen('DialogNotes')}>
            <CollectionsBookmarkOutlined style={{fontSize:16,cursor:'pointer'}} />
          </IconButton>
          {dialogOpen!=='DialogNotes'?null:<DialogNotes open={dialogOpen==='DialogNotes'} dialogParentHide={dialogHide} post={post} adminDoc={_.get(_.filter(Session.adminDocs,{postId:post.id,docType:'note'}),'[0]',{id:null})} user={user} isDisabled={true}/>}
        </div>
      )
  }
}

export default NotesBadge
