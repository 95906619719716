import React from "react"
import _ from "lodash"
import { Emoji } from "emoji-mart"

import ThumbUpOutlined from '@material-ui/icons/ThumbUpOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles, Theme } from '@material-ui/core/styles';

import { Session, postReaction } from '../shared/globals'


const LikeButton = ({ user, size, thread }) => {
  let currentReaction = _.get(thread,`reactions.${Session.uid}`,'')
  let liked = currentReaction==='thumbsup'?true:false
  let emojiSize = size === 'small' ? 18 : 24

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      borderRadius: 13,
      '& .emoji-mart-emoji': {
        padding: '2px 4px 4px',
        '&:hover': {
          background: "rgb(221, 223, 226)",
          borderRadius: '5px',
        }
      }
    },
  }))(Tooltip);

  return (
    <div id="likeContent" style={{cursor: 'pointer'}}>
      <LightTooltip placement='top'  leaveTouchDelay={0} interactive title={
        <React.Fragment>
          <div id="reactionItems" style={{display: 'flex', flexWrap: 'wrap', height: '28px', justifyContent: 'space-around', width: size === 'small' ? 170 : 230}}>
            {
              _.map(['thumbsup','heart','laughing','astonished','disappointed','angry'],(reaction)=>{
                return <Emoji key={`BADGE-${thread.id}-${reaction}`} emoji={reaction} set='messenger' size={emojiSize} onClick={()=>postReaction(thread,user.uid,reaction)}/>
              })
            }
          </div>
        </React.Fragment>
      }>
        <div className="likeContent" style={{display: 'flex', flexDirection: 'row',alignItems:'center'}} onClick={()=>{
            postReaction(thread,user.uid,'thumbsup')
          }}>
          <div id="likeButtonIcon" style={{display: size === 'small' ? 'none' : ''}}>
            <ThumbUpOutlined style={{color: liked?'blue':''}}/>
          </div>
          <div className="likeButtonText" style={{ paddingLeft: 4, fontSize: '13px', lineHeight: '15px', color: liked ? 'blue' : '#616770', fontWeight: 'bold', letterSpacing: '-0.07px', height: '15px' }}>
            Like
          </div>
        </div>
      </LightTooltip>
    </div>
  )
}

export default LikeButton
