import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import CreatableSelect from 'react-select/creatable'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DateTimePicker } from '@material-ui/pickers';

import { Session, adminDocCreate } from '../../shared/globals'

export const DialogPhoneLog = ({open,adminDoc,post,user,dialogParentHide,isDisabled}) => {
  const [tags, setTags]=useState(_.map(_.get(adminDoc,'tags',[]),(tag)=>{return {value:tag.value,label:tag.value}}))
  const [,refreshState] = useState()
  const [profile,] = useState(_.get(Session,`users.${user.uid}.profile`,{}))
  const [message, setMessage] = useState(_.get(Session,`adminDocs.${adminDoc.id}.message`,''))
  const [communicationType,setCommunicationType] = useState(_.get(adminDoc,'communicationType','Phone'))
  const [eventDateTime,setEventDateTime] = useState(moment(_.get(Session,`adminDocs.${adminDoc.id}.epoc`,new Date())).toDate())
  const [disabled,setDisabled] = useState(isDisabled)

  const dialogHide = () => {
    setMessage('')
    dialogParentHide()
  }
  const dialogUpdate = () => {
    adminDocCreate(user.uid,{
      id:adminDoc.id,
      postId:post.id,
      docType:'phone',
      message: message,
      tags: tags,
      communicationType:communicationType,
      dateString:moment(eventDateTime.isLuxonDateTime?eventDateTime.ts:eventDateTime).toString(),
      epoc:moment(eventDateTime.isLuxonDateTime?eventDateTime.ts:eventDateTime).valueOf()
    }).then(()=>{
      dialogHide()
    })
  }

  if(!_.get(Session,'userClaims.isAdmin',false)) return null
  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogHide} aria-labelledby="form-dialog-title">
      <DialogTitle>Communication Log</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {`${profile.firstName} ${profile.lastName} (${profile.userCount})`}
        </DialogContentText>
        <TextField fullWidth margin="normal" variant="outlined" label="Communication Type" select disabled={disabled} value={communicationType || 'Phone'} onChange={(e) => setCommunicationType(e.target.value)} >
          {
            _.map(['Phone','Email','Other'],(commType)=>{
              return <MenuItem key={commType} value={commType}>{commType}</MenuItem>
            })
          }
        </TextField>
        <TextField autoFocus fullWidth margin="normal" variant="outlined" multiline
          label="Communication Details" rows={3} rowsMax={10} inputProps={{ maxLength: 999 }}
          onChange={(e)=>setMessage(e.target.value)} disabled={disabled}
          value={message}
        />
        {
          (500-message.length > 400) ? null :
          <div style={{fontSize:12,fontColor:'grey',marginBottom:16}}>{500 - message.length} characters left</div>
        }
        <DateTimePicker fullWidth inputVariant="outlined" showTodayButton
          label="Communication Date/Time" disabled={disabled}
          onClose={()=>{refreshState(Date.now())}}
          value={eventDateTime}
          onChange={setEventDateTime}
        />
        <CreatableSelect isMulti isDisabled={disabled}
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          placeholder={'Select Tags'}
          value={tags}
          onChange={(newValue)=>setTags(newValue)}
          options={Session.groupedOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogHide} color="primary">
          { disabled ? 'CLOSE' : 'CANCEL' }
        </Button>
        <Button onClick={disabled ? ()=>setDisabled(false) : dialogUpdate } color="primary"  disabled={!disabled && message.length === 0}>
          { disabled ? 'EDIT' : 'UPDATE' }
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogPhoneLog
