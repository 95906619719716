import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import moment from 'moment'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import Today from '@material-ui/icons/Today'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox'
import { KeyboardDatePicker } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import ArchiveOutlined from '@material-ui/icons/ArchiveOutlined'
import UnarchiveOutlined from '@material-ui/icons/UnarchiveOutlined'
import PollOutlined from '@material-ui/icons/PollOutlined'
import List from '@material-ui/icons/List'
import Public from '@material-ui/icons/Public'

import {Attachments} from './attachments'
import { Session, userUpdate, postAvatar, postUnarchive } from '../shared/globals.js'
import { DialogPhoneLog, DialogNotes, DialogCases, DialogTasks, DialogEventDetails } from './dialogs'

const RenderProfileField = ({user, profileField}) => {
  const [,refreshState] = useState()
  const [fieldValue, setFieldValue] = useState(_.get(user,profileField.fieldName,'') || '')
  const updateUserField = (fieldName,value) => {
    // _.set(user,fieldName,value)
    // console.log('updateUserField',fieldName,_.get(user,fieldName,''),fieldValue,value)
    setFieldValue(value)
    // refreshState(Date.now())
  }
  const saveUserField = (fieldName) => {
    if(_.get(user,fieldName,'')===fieldValue){
      return
    }
    userUpdate(user.uid,{[fieldName]:typeof fieldValue === 'string'?_.trim(fieldValue):fieldValue}).then(()=>{
      refreshState(Date.now())
    })
  }
  const saveUserFieldDirect = (fieldName,tempValue) => {
    if(_.get(user,fieldName,'')===tempValue){
      return
    }
    userUpdate(user.uid,{[fieldName]:typeof tempValue === 'string'?_.trim(tempValue):tempValue}).then(()=>{
      setFieldValue(tempValue)
    })
  }
  const {label,fieldName,type,options} = profileField
  if(type !== 'boolean' && type !== 'booleanOpposite' && fieldValue !== _.get(user,profileField.fieldName,'') && (Date.now()-user.modifiedDate) < 3000){
    if((fieldValue === null || fieldValue === '') && (_.get(user,profileField.fieldName,'') === null || _.get(user,profileField.fieldName,'') === '')){
    }else{
      // console.log('profileField.fieldName',profileField.fieldName,`z${fieldValue}zy${_.get(user,profileField.fieldName,'')}y`)
      // console.log('asdf',Date.now()-user.modifiedDate)
      setFieldValue(_.get(user,profileField.fieldName,'') || '')
    }
  }
  switch (type){
    case 'readOnly':
      return (
        <TextField style={{marginTop: '15px'}} inputProps={{ maxLength: 100 }} fullWidth variant="outlined" margin="dense" InputLabelProps={{ shrink: true }}
          label={label} value={_.get(user,fieldName,'') || ''}
        />
      )
    case 'booleanOpposite':
      return (
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <div>{label}</div>
          <Checkbox
            checked={_.get(user,fieldName,true)}
            onChange={(e) => {
              userUpdate(user.uid,{[fieldName]:e.target.checked})
            }}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      )
    case 'text':
      return (
        <TextField style={{marginTop: '15px'}} inputProps={{ maxLength: 100 }} fullWidth variant="outlined" margin="dense" InputLabelProps={{ shrink: true }}
          label={label} value={fieldValue || ''}
          onChange={(e) => updateUserField(fieldName,e.target.value)}
          onBlur={() => saveUserField(fieldName)}
        />
      )
    case 'date':
      let tempDateValue = moment(fieldValue).format('MM/DD/YYYY')
      if(!fieldValue){
        tempDateValue=null
      }
      if(fieldName === 'birthdate' && tempDateValue === null && _.get(user,'birthYear',0) > 1900 && _.get(user,'birthYear',0) < 2022){
        tempDateValue = moment(`01/01/${_.get(user,'birthYear',0)}`).format('MM/DD/YYYY')
        userUpdate(user.uid,{birthdate:tempDateValue}).then(()=>{
          console.log('bdUpdate')
        })
      }
      if(fieldName === 'patientBirthdate' && tempDateValue === null && _.get(user,'patientBirthYear',0) > 1900 && _.get(user,'patientBirthYear',0) < 2022){
        tempDateValue = moment(`01/01/${_.get(user,'patientBirthYear',0)}`).format('MM/DD/YYYY')
        userUpdate(user.uid,{patientBirthdate:tempDateValue}).then(()=>{
          console.log('pbdUpdate')
        })
      }
      if(fieldName === 'cancerDiagnosisDate' && tempDateValue === null && _.get(user,'cancerDiagnosisDateYear',0) > 1900 && _.get(user,'cancerDiagnosisDateYear',0) < 2022){
        tempDateValue = moment(`01/01/${_.get(user,'cancerDiagnosisDateYear',0)}`).format('MM/DD/YYYY')
        if(_.get(user,'cancerDiagnosisDateMonth',0) > 0 && _.get(user,'cancerDiagnosisDateMonth',0) < 13){
          tempDateValue = moment(`${_.get(user,'cancerDiagnosisDateMonth',0)}/01/${_.get(user,'cancerDiagnosisDateYear',0)}`).format('MM/DD/YYYY')
        }
        userUpdate(user.uid,{cancerDiagnosisDate:tempDateValue}).then(()=>{
          console.log('cddUpdate')
        })
      }
      return (
        <KeyboardDatePicker fullWidth inputVariant="outlined" label={label} margin="dense" style={{marginTop: '15px'}}
          format="MM/dd/yyyy" clearable placeholder='MM/DD/YYYY'
          value={moment(tempDateValue).isValid() ? tempDateValue || null : null}
          onChange={(date,value) => {
            if(moment(value).isValid()){
              saveUserFieldDirect(fieldName,value)
            }else if(tempDateValue.length === 0){
              saveUserFieldDirect(fieldName,'')
            }
          }}
          />
      )
    case 'select':
      return (
        <TextField style={{marginTop: '15px'}} fullWidth variant="outlined" margin="dense" select InputLabelProps={{ shrink: true }}
          label={label} value={fieldValue || ''}
          onChange={(e) => updateUserField(fieldName,e.target.value)}
          onBlur={() => saveUserField(fieldName)}
          >
          {
            _.map(options,(option)=>{
              return <MenuItem key={option} value={option}>{option}</MenuItem>
            })
          }
        </TextField>
      )
    default:
      return (
        <TextField style={{marginTop: '15px'}} inputProps={{ maxLength: 100 }} fullWidth variant="outlined" margin="dense" InputLabelProps={{ shrink: true }}
          label={label} value={fieldValue || ''}
          onChange={(e) => updateUserField(fieldName,e.target.value)}
          onBlur={() => saveUserField(fieldName)}
        />
      )
  }
}

const Profile = ({ user, refs }) => {
  const [, refreshState] = useState(Date.now())
  const [dialogOpen,setDialogOpen] = useState('')
  const [adminDoc,setAdminDoc] = useState({})
  let cropperRef = useRef('null')
  const [attachedImage, setAttachedImage] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const dialogShow = (dialog,post) => {
    setAdminDoc(post)
    setDialogOpen(dialog)
  }
  const dialogHide = () => {
    setAdminDoc({})
    setDialogOpen('')
  }

  useEffect(()=>{
    _.set(Session,`refreshProfile.${user.uid}`,refresh)
    return ()=>_.unset(Session,`refreshProfile.${user.uid}`)
  })

  const refresh = () => {
    // console.log('refreshProfile')
    refreshState(Date.now())
  }
  const userTypeUpdate = (type) => {
    userUpdate(user.uid,{type:type})
  }

  const handleFileSelect = event => {
    // event.preventDefault()
    if(event.target.files && event.target.files.length > 0) {
      let currentFile = event.target.files[0]
      let fileReader = new FileReader()

      fileReader.addEventListener('load', () => {
        let loadedFile = fileReader.result
        setAttachedImage(loadedFile)
      }, false)

      fileReader.readAsDataURL(currentFile)
    }
  }

  const cropImage = () => {
    if(cropperRef.getCroppedCanvas() === 'undefined') {
      return;
    }
    let croppedImageData = cropperRef.getCroppedCanvas().toDataURL()
    setCroppedImage(croppedImageData)

    postAvatar(user.uid, croppedImageData)
    setAttachedImage(null)
  }

  const adminTasks = _.filter(_.get(Session,'adminDocs',{}),{docType:'task',userUid:user.uid})
  const adminPhoneLogs = _.filter(_.get(Session,'adminDocs',{}),{docType:'phone',userUid:user.uid})
  const adminNotes = _.filter(_.get(Session,'adminDocs',{}),{docType:'note',userUid:user.uid})
  const adminCases = _.filter(_.get(Session,'adminDocs',{}),{docType:'case',userUid:user.uid})
  const userEventList = _.orderBy(_.filter(_.get(Session,`posts.${user.uid}`,{}),(post)=>{return _.has(post,'eventDetails.epoc')}),['createdDate'],['desc'])
  const userArchivedThreadList = _.filter(_.get(Session,`posts.${user.uid}`,{}),{isThread:true,isArchived:true})
  const userPollsList = _.filter(_.get(Session,`posts.${user.uid}`,{}),{attachmentType:'poll'})
  const userTrackers = _.filter(_.get(Session,`posts.${user.uid}`,{}),{attachmentType:'tracker'})
  const userFiles = _.filter(_.get(Session,`posts.${user.uid}`,{}),{postType:'attachment_file'})
  const userWebsites = _.filter(_.get(Session,`posts.${user.uid}`,{}),{isAttachment:true,attachmentType:'url'})

  const {profileStyles} = Session.styles
  const renderSection = (sectionName,hideHeader) => {
    const fields = _.get(Session,`layouts.${_.camelCase(sectionName)}`,[])
    return (
      <div ref={(ref) => (Session.refs[_.camelCase(sectionName)] = ref)} style={profileStyles.sectionContainer}>
        {
          hideHeader ? null :
          <div style={profileStyles.sectionContainerName}>{sectionName}</div>
        }
        <div style={profileStyles.sectionFieldContainer}>
          {
            _.map(fields,(field,index)=>{
              return <RenderProfileField key={`FIELD-${index}`} user={user} profileField={field} />
            })
          }
        </div>
      </div>
    )
  }
  return (
    <div style={{minWidth: '500px', maxWidth: '500px', marginTop: '15px'}}>
      <div className="profilePicture" ref={(ref) => (Session.refs['profile0'] = ref)} >
        <div id='imageCropper' style={{display: attachedImage !== null ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <Cropper style={{ height: '200px', width: '70%' }} ref={ref => cropperRef = ref} aspectRatio={1/1} src={attachedImage}/>
          <div style={{display:'flex',flexDirection:'row'}}>
            <Button onClick={() => setAttachedImage(null)} variant="outlined" color="primary" component='label' style={{marginTop:8,marginRight:8}}>
              Cancel
            </Button>
            <Button onClick={() => cropImage()} variant="outlined" color="primary" component='label' id='cropImage' style={{marginTop: 8,marginLeft:8}}>
              Crop & Save
            </Button>
          </div>
        </div>
        <div id='profilePicAndUploadButton' style={{display: attachedImage !== null ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <img style={{ maxHeight: 85, backgroundColor: 'white', border: 'solid 2px black', padding: 4, borderRadius: 99 }} src={croppedImage || _.get(user,'profileIcon','')} alt=""/>
          <Button variant="outlined" color="primary" component='label' id='uploadPicture' style={{marginTop: '8px'}}>
            <input type='file' style={{ display: 'none' }} multiple={false} onChange={ event => handleFileSelect(event) }/>
            Change profile picture
          </Button>
        </div>
      </div>
      {renderSection('General Details')}
      {renderSection('Contact Details')}
      {_.get(Session,'userClaims.isAdmin',false) ? renderSection('Admin Details') : null}
      {
        true ? null :
        <div ref={(ref) => (Session.refs[_.camelCase('Random Questions')] = ref)} style={profileStyles.sectionContainer}>
          <div style={profileStyles.sectionFieldContainer}>
            <div style={{display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
              <div style={{border:'1px solid #DDDFE2',borderRadius:8,padding:8,margin:4,cursor:'pointer',backgroundColor:_.get(user,'type','')==='Patient'?'#5CD1F5':''}} onClick={()=>userTypeUpdate('Patient')}>Do you have cancer?</div>
              <div style={{border:'1px solid #DDDFE2',borderRadius:8,padding:8,margin:4,cursor:'pointer',backgroundColor:_.get(user,'type','')==='Caregiver'?'#5CD1F5':''}} onClick={()=>userTypeUpdate('Caregiver')}>Helping someone with cancer?</div>
              <div style={{border:'1px solid #DDDFE2',borderRadius:8,padding:8,margin:4,cursor:'pointer',backgroundColor:_.get(user,'type','')==='Information'?'#5CD1F5':''}} onClick={()=>userTypeUpdate('Information')}>Want information about cancer?</div>
            </div>
          </div>
        </div>
      }
      { _.get(user,'type','')!=='Caregiver'? null : renderSection('Patient Details') }
      { _.get(user,'type','')!=='Patient'  && _.get(user,'type','')!=='Caregiver'? null : renderSection('Cancer Details') }
      { //USER EVENT LIST
        userEventList.length === 0 ? null :
        <div ref={(ref) => (Session.refs[_.camelCase('Event List')] = ref)} style={profileStyles.sectionContainer}>
          <div style={profileStyles.sectionContainerName}>
            <Today />
            <div>Event List</div>
          </div>
          <div style={profileStyles.sectionFieldContainer}>
            {
              _.map(userEventList,(post,index)=>{
                return (
                  <div key={`EVENT-${post.id}`} style={profileStyles.sectionFieldContainerLine} onClick={()=>dialogShow('DialogEventDetails',post)}>
                    <div style={profileStyles.eventListDetails}>{post.eventDetails.details}</div>
                    <div style={profileStyles.eventListDetails}>{` `}{moment(post.eventDetails.epoc).toString()}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      { //COMMUNICATION LOGS
        !_.get(Session,'userClaims.isAdmin',false) || adminPhoneLogs.length === 0 ? null :
        <div>
          <div ref={(ref) => (Session.refs['profilePhoneLog'] = ref)} style={{marginTop: '25px', border: '1px solid #51B7C5', height: '37px', backgroundColor: '#51B7C5', borderRadius: '5px 5px 0 0', paddingLeft: '8px'}}>
            <div style={{color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '18px', letterSpacing: '0.65px'}}>Communication Log</div>
          </div>
          <div className='sectionBody' style={{backgroundColor: '#FFFFFF', borderLeft: '1px solid #E9E9E9', borderRight: '1px solid #E9E9E9', paddingTop: '10px', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '0 0 5px 5px'}}>
            {
              _.map(adminPhoneLogs,(phone)=>{
                return (
                  <div key={`PHONE-${phone.id}`} onClick={()=>{dialogShow('DialogPhoneLog',phone)}} style={{cursor:'pointer',backgroundColor:'white',border:'solid 1px black',borderRadius:4,margin:4,padding:4,display:'flex',flexDirection:'column'}}>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {phone.createdByName}
                    </div>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {phone.dateString}
                    </div>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {phone.message}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      { //ADMIN NOTES
        !_.get(Session,'userClaims.isAdmin',false) || adminNotes.length === 0 ? null :
        <div>
          <div ref={(ref) => (Session.refs['profileAdminNotes'] = ref)} style={{marginTop: '25px', border: '1px solid #51B7C5', height: '37px', backgroundColor: '#51B7C5', borderRadius: '5px 5px 0 0', paddingLeft: '8px'}}>
            <div style={{color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '18px', letterSpacing: '0.65px'}}>Admin Notes</div>
          </div>
          <div className='sectionBody' style={{backgroundColor: '#FFFFFF', borderLeft: '1px solid #E9E9E9', borderRight: '1px solid #E9E9E9', paddingTop: '10px', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '0 0 5px 5px'}}>
            {
              _.map(adminNotes,(note)=>{
                return (
                  <div key={`NOTE-${note.id}`} onClick={()=>{dialogShow('DialogNotes',note)}} style={{cursor:'pointer',backgroundColor:'white',border:'solid 1px black',borderRadius:4,margin:4,padding:4,display:'flex',flexDirection:'column'}}>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {note.createdByName}
                    </div>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {note.createdByDate}
                    </div>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {note.message}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      { //ADMIN CASES
        !_.get(Session,'userClaims.isAdmin',false) || adminCases.length === 0 ? null :
        <div>
          <div ref={(ref) => (Session.refs['profileAdminCases'] = ref)} style={{marginTop: '25px', border: '1px solid #51B7C5', height: '37px', backgroundColor: '#51B7C5', borderRadius: '5px 5px 0 0', paddingLeft: '8px'}}>
            <div style={{color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '18px', letterSpacing: '0.65px'}}>Admin Cases</div>
          </div>
          <div className='sectionBody' style={{backgroundColor: '#FFFFFF', borderLeft: '1px solid #E9E9E9', borderRight: '1px solid #E9E9E9', paddingTop: '10px', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '0 0 5px 5px'}}>
            {
              _.map(adminCases,(adminCase)=>{
                return (
                  <div key={`CASE-${adminCase.id}`} onClick={()=>{dialogShow('DialogCases',adminCase)}} style={{cursor:'pointer',backgroundColor:'white',border:'solid 1px black',borderRadius:4,margin:4,padding:4,display:'flex',flexDirection:'column'}}>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {adminCase.createdByName}
                    </div>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {adminCase.createdByDate}
                    </div>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {adminCase.message}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      { //ADMIN TASKS
        !_.get(Session,'userClaims.isAdmin',false) || adminTasks.length === 0 ? null :
        <div>
          <div ref={(ref) => (Session.refs['profileTasks'] = ref)} style={{marginTop: '25px', border: '1px solid #51B7C5', height: '37px', backgroundColor: '#51B7C5', borderRadius: '5px 5px 0 0', paddingLeft: '8px'}}>
            <div style={{color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '18px', letterSpacing: '0.65px'}}>Task List</div>
          </div>
          <div className='sectionBody' style={{backgroundColor: '#FFFFFF', borderLeft: '1px solid #E9E9E9', borderRight: '1px solid #E9E9E9', paddingTop: '10px', paddingBottom: '15px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '0 0 5px 5px'}}>
            {
              _.map(adminTasks,(task)=>{
                return (
                  <div key={`TASK-${task.id}`} onClick={()=>{dialogShow('DialogTasks',task)}} style={{cursor:'pointer',backgroundColor:'white',border:'solid 1px black',borderRadius:4,margin:4,padding:4,display:'flex',flexDirection:'column'}}>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {task.createdByName}
                    </div>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {task.dateString}
                    </div>
                    <div style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
                      {task.message}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      { //USER ARCHIVED LIST
        userArchivedThreadList.length === 0 ? null :
        <div ref={(ref) => (Session.refs[_.camelCase('Archived Threads')] = ref)} style={profileStyles.sectionContainer}>
          <div style={profileStyles.sectionContainerName}>
            <ArchiveOutlined />
            <div>Archived Threads</div>
          </div>
          <div style={profileStyles.sectionFieldContainer}>
            {
              _.map(userArchivedThreadList,(post,index)=>{
                return (
                  <div key={`ARCHIVE-${post.id}`} style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div id="userDetails" style={{display: 'flex', flexDirection: 'row',alignItems:'center',paddingRight:8}}>
                      <div id="userAvatar" style={{paddingRight:8}}>
                        <img style={{ width: 12, height: 12, borderRadius: 99,border:'solid 1px black',padding:2}} src={post.createdById===Session.uid?_.get(user,'profileIcon',''):_.get(post,'createdByProfileIcon','')} alt=""/>
                      </div>
                      <div id="userName" style={{fontSize: 12, color: '#385898', fontWeight: 'bold'}} title={moment(post.createdDate).isValid?moment(post.createdDate).fromNow():null}>
                        {post.createdByName}
                      </div>
                    </div>
                    <div style={{ flex:1,textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow:'hidden',marginRight:8}}>
                      {post.message}
                    </div>
                    <UnarchiveOutlined style={{cursor:'pointer'}} onClick={()=>postUnarchive(user.uid,post)}/>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
      { //USER POLLS LIST
        userPollsList.length === 0 ? null :
        <div ref={(ref) => (Session.refs[_.camelCase('Polls List')] = ref)} style={profileStyles.sectionContainer}>
          <div style={profileStyles.sectionContainerName}>
            <PollOutlined />
            <div>Polls List</div>
          </div>
          <div style={profileStyles.sectionFieldContainer}>
            <Attachments user={user} attachments={userPollsList} />
          </div>
        </div>
      }
      { //USER TRACKERS
        userTrackers.length === 0 ? null :
        <div ref={(ref) => (Session.refs[_.camelCase('Tracker List')] = ref)} style={profileStyles.sectionContainer}>
          <div style={profileStyles.sectionContainerName}>
            <List />
            <div>Tracker List</div>
          </div>
          <div style={profileStyles.sectionFieldContainer}>
            <Attachments user={user} attachments={userTrackers} />
          </div>
        </div>
      }
      { //USER FILES
        userFiles.length === 0 ? null :
        <div ref={(ref) => (Session.refs[_.camelCase('File List')] = ref)} style={profileStyles.sectionContainer}>
          <div style={profileStyles.sectionContainerName}>
            <div>File List</div>
          </div>
          <div style={profileStyles.sectionFieldContainer}>
            <Attachments user={user} attachments={userFiles} />
          </div>
        </div>
      }
      { //USER WEBSITES
        userWebsites.length === 0 ? null :
        <div ref={(ref) => (Session.refs[_.camelCase('Saved Websites')] = ref)} style={profileStyles.sectionContainer}>
          <div style={profileStyles.sectionContainerName}>
            <Public />
            <div>Saved Websites</div>
          </div>
          <div style={profileStyles.sectionFieldContainer}>
            <Attachments user={user} attachments={userWebsites} />
          </div>
        </div>
      }
      {dialogOpen!=='DialogTasks'?null:<DialogTasks open={dialogOpen==='DialogTasks'} dialogParentHide={dialogHide} post={{id:adminDoc.postId}} adminDoc={adminDoc} user={{uid:adminDoc.userUid}} isDisabled={true}/>}
      {dialogOpen!=='DialogPhoneLog'?null:<DialogPhoneLog open={dialogOpen==='DialogPhoneLog'} dialogParentHide={dialogHide} post={{id:adminDoc.postId}} adminDoc={adminDoc} user={{uid:adminDoc.userUid}} isDisabled={true}/>}
      {dialogOpen!=='DialogNotes'?null:<DialogNotes open={dialogOpen==='DialogNotes'} dialogParentHide={dialogHide} post={{id:adminDoc.postId}} adminDoc={adminDoc} user={{uid:adminDoc.userUid}} isDisabled={true}/>}
      {dialogOpen!=='DialogCases'?null:<DialogCases open={dialogOpen==='DialogCases'} dialogParentHide={dialogHide} post={{id:adminDoc.postId}} adminDoc={adminDoc} user={{uid:adminDoc.userUid}} isDisabled={true}/>}
      {dialogOpen!=='DialogEventDetails'?null:<DialogEventDetails open={dialogOpen==='DialogEventDetails'} dialogParentHide={dialogHide} post={adminDoc} user={user}/>}
    </div>
  )
}

export default Profile
