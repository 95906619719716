import React from 'react'
import _ from 'lodash'

import { userLogLastAction } from '../../shared/globals.js'

const URL = ({user,attachment}) => {
  if(attachment.microLink && _.get(attachment,'microLink.status','')==='success'){
    const {data} = attachment.microLink
    return (
        <a href={_.get(data,'url','')} onClick={()=> userLogLastAction(`URL Click: ${attachment.id}`)} rel="noopener noreferrer" target="_blank" title={_.get(data,'title','')} media="image,logo" direction="ltr" style={{textDecoration:'none'}}>
          <div style={localStyles.box0}>
            <div autoPlay="" controls="" loop="" playsInline="" style={{...localStyles.box1,backgroundImage: `url("${_.get(data,'image.url','')}")`}}></div>
            <div style={localStyles.box2}>
              <header style={localStyles.box3}>
                <p title={_.get(data,'title','')} style={localStyles.box4}>{_.get(data,'title','')}</p>
              </header>
              <div style={localStyles.box5}>
                <p style={localStyles.box6} title={_.get(data,'description','')}>
                  {_.get(data,'description','')}
                </p>
              </div>
              <footer style={localStyles.box7}>
                <p title={_.get(data,'publisher','')} style={localStyles.box8}>{_.get(data,'publisher','')}</p>
              </footer>
            </div>
          </div>
        </a>
    )
  }else{
    // console.log('Microlink Used')
    return (
      <div className='microLinkUrl' style={{textOverflow: 'ellipsis',whiteSpace: 'nowrap',overflow:'hidden'}}>
        <a href={attachment.attachmentURL} onClick={()=> userLogLastAction(`URL Click: ${attachment.id}`)} rel="noopener noreferrer" target="_blank" media="image,logo" direction="ltr" style={{textDecoration:'none'}}>
          {attachment.attachmentURL}
        </a>
      </div>
    )
  }
}
 const localStyles={
   box0:{
     maxWidth: '350px',
     minHeight: '125px',
     maxHeight: '125px',
     backgroundColor: '#fff',
     border:'solid 1px #e1e8ed',
     overflow: 'hidden',
     color: '#181919',
     fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
     display: 'flex',
     textDecoration: 'none',
     opacity: 1,
     position: 'relative',
     transitionDuration: '0.15s',
     transitionTimingFunction: 'ease-in-out',
     transitionProperty: 'background,border-color',
   },
   box1:{
     background: 'transparent no-repeat center center / cover',
     display: 'block',
     overflow: 'hidden',
     minHeight: '125px',
     maxHeight: '125px',
     minWidth:'125px',
     maxWidth:'125px',
     position: 'relative',
     transition: 'flex-basis 0.25s ease-in-out',
    //  backgroundImage: 'url(https://img.youtube.com/vi/2TQ-pOvI6Xo/maxresdefault.jpg)',
   },
   box2:{
     flex:1,
     padding: '10px 15px',
     display:'flex',
     flexDirection:'column',
     justifyContent:'space-between',
     boxSizing:'border-box',
     overflow:'hidden',
   },
   box3:{
     display:'flex',
     flex:1,
   },
   box4:{
     flex:1,
     textAlign:'left',
     fontSize:16,
     fontWeight:'bold',
     textOverflow: 'ellipsis',
     whiteSpace: 'nowrap',
     overflow:'hidden',
     margin:0,
   },
   box5:{
     display:'flex',
     flex:2,
     overflow:'hidden',
   },
   box6:{
     flex:1,
     textAlign:'left',
     fontSize:14,
     lineHeight:'18px',
     fontWeight:'normal',
    //  textOverflow: 'ellipsis',
    //  whiteSpace: 'nowrap',
     overflow:'hidden',
     margin:'10px 0px',
   },
   box7:{
     display:'flex',
     flex:1,
     flexGrow:0,
   },
   box8:{
     flex:1,
     textAlign:'left',
     fontSize:12,
     fontWeight:'normal',
    //  textOverflow: 'ellipsis',
    //  whiteSpace: 'nowrap',
     overflow:'hidden',
     margin:0,
   }
 }
export default URL
