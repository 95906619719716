import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Session } from '../shared/globals'
import { Link } from 'react-router-dom'
import '../shared/css/cancer-chat-ui.webflow.css'
import '../shared/css/normalize.css'
import '../shared/css/webflow.css'

const Public = () => {
  return (
    <div className="body">
      <div className="background-block-top"></div>
      <div className="center-content">
        <img src="images/Asset-14.png" srcSet="images/Asset-14.png 500w, images/Asset-14.png 800w, images/Asset-14.png 1080w, images/Asset-14.png 1819w" sizes="(max-width: 479px) 80vw, 400px" alt="" className="image logo headline" />
        <div className="title-block">
          <h1 className="heading top-title">Fight cancer&#x27;s impact in your conpany</h1>
          <h1 className="heading top-title line-2">Support employees fighting cancer</h1>
          <div className="subhead">This is some text inside of a div block</div>
          <div className="div-block-3"><a href="brokers.html" className="button-2 w-button">Click here if you are a broker</a></div>
        </div>
        <div className="div-block-2">
          <div className="highlight-box big-box-shadow">
            <div className="highlight-box-head">For your company</div>
            <div className="highlight-box-block">
              <h2 className="heading-3">Unbelievable ROI</h2>
              <div className="highlight-box-paragraph">
                <div className="highlight-block-title">
                  <div className="higlight-point point-red"></div>
                  <h2 className="point-title">Most affordable benefit in existance</h2>
                </div>
                <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing</div>
              </div>
            </div>
            <div className="highlight-box-block">
              <div className="highlight-box-paragraph">
                <div className="highlight-block-title">
                  <div className="higlight-point point-red"></div>
                  <h2 className="point-title">this is a second title</h2>
                </div>
                <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing</div>
              </div>
              <div className="highlight-box-paragraph">
                <div className="highlight-block-title">
                  <div className="higlight-point point-red"></div>
                  <h2 className="point-title">And that a third</h2>
                </div>
                <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing, more and more and more amd more</div>
              </div>
            </div>
          </div>
          <div className="highlight-box big-box-shadow">
            <div className="highlight-box-head">For your employees</div>
            <div className="highlight-box-block">
              <h2 className="heading-3">Unprecedented support</h2>
              <div className="highlight-box-paragraph">
                <div className="highlight-block-title">
                  <div className="higlight-point point-blue"></div>
                  <h2 className="point-title">Most affordable benefit in existance</h2>
                </div>
                <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing</div>
              </div>
            </div>
            <div className="highlight-box-block">
              <div className="highlight-box-paragraph">
                <div className="highlight-block-title">
                  <div className="higlight-point point-blue"></div>
                  <h2 className="point-title">this is a second title</h2>
                </div>
                <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing and more on this paragraph to see how it goes</div>
              </div>
              <div className="highlight-box-paragraph">
                <div className="highlight-block-title">
                  <div className="higlight-point point-blue"></div>
                  <h2 className="point-title">And that a third</h2>
                </div>
                <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing and this is mucg longer to test what&#x27;s hoing on</div>
              </div>
            </div>
          </div>
        </div>
        <div className="multi-points">
          <div className="paragraph-title">$ .25 per member per month it&#x27;s a steal</div>
          <div className="highlight-box-paragraph inline">
            <div className="highlight-block-title">
              <div className="higlight-point point-green"></div>
              <h2 className="point-title">And that a third</h2>
            </div>
            <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing and this is mucg longer to test what&#x27;s hoing on</div>
          </div>
          <div className="highlight-box-paragraph inline">
            <div className="highlight-block-title">
              <div className="higlight-point point-green"></div>
              <h2 className="point-title">And that a third</h2>
            </div>
            <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing and this is mucg longer to test what&#x27;s hoing on</div>
          </div>
          <div className="highlight-box-paragraph inline">
            <div className="highlight-block-title">
              <div className="higlight-point point-green"></div>
              <h2 className="point-title">And that a third</h2>
            </div>
            <div className="point-paragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing and this is mucg longer to test what&#x27;s hoing on</div>
          </div>
        </div>
        <div className="social-validation-block">
          <div className="social-validation-quote">
            <img src="https://d3e54v103j8qbb.cloudfront.net/plugins/Image/assets/placeholder.60f9b1840c.svg" alt="" className="profile-pic" />
            <div className="social-validation-text-block">
              <div className="social-validation-statement">This what I have to say about Cancer.chat, it&#x27;s a good thing. Now another line to see if it works. And another lines to see where the image goes. Nw duplicate it I have to say about Cancer.chat, it&#x27;s a good thing. Now another line to see if it works. And another lines to see where the image goes</div>
              <div className="social-validation-name">John Doe, patient</div>
            </div>
          </div>
          <div className="social-validation-quote">
            <img src="https://d3e54v103j8qbb.cloudfront.net/plugins/Image/assets/placeholder.60f9b1840c.svg" alt="" className="profile-pic" />
            <div className="social-validation-text-block">
              <div className="social-validation-statement">This is another important quote, and this quote is way longer than we initailally though, maybe two lines please. Or more, maybe three lines just to see how it looks</div>
              <div className="social-validation-name">Gina pie, know it all</div>
            </div>
          </div>
        </div>
        <div className="multi-points">
          <div className="paragraph-title">How it works</div>
          <div className="highlights-all w-clearfix">
            <img src="https://d3e54v103j8qbb.cloudfront.net/plugins/Image/assets/placeholder.60f9b1840c.svg" alt="" className="image-2 image-3" />
            <div className="how-works-text-block">
              <div className="highlight-box-paragraph inline">
                <div className="highlight-block-title">
                  <h2 className="straight-paragraph-title">And that a third</h2>
                </div>
                <div className="straight-praragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing and this is mucg longer to test what&#x27;s hoing on</div>
              </div>
              <div className="highlight-box-paragraph inline">
                <div className="highlight-block-title">
                  <h2 className="straight-paragraph-title">And that a third</h2>
                </div>
                <div className="straight-praragraph">yes, that&#x27;s absolutely true, we&#x27;re not shitting you, you can get it for almost nothing and this is mucg longer to test what&#x27;s hoing on</div>
              </div>
            </div>
          </div>
        </div>
        <div className="client-info-form">
          <div className="paragraph-title">Countact us now for more information</div>
          <div className="straight-praragraph">This is some text inside of a div block.</div>
          <div className="w-form">
            {
              /*
              <form id="email-form" name="email-form" data-name="Email Form"><label for="name" className="field-label">Name</label><input type="text" className="w-input" maxlength="256" name="name" data-name="Name" id="name"><label for="email">role</label><input type="email" className="w-input" maxlength="256" name="email" data-name="Email" id="email" required=""><label for="email-3">company</label><input type="email" className="w-input" maxlength="256" name="email-2" data-name="Email 2" id="email-2" required=""><label for="email-3">zip</label><input type="email" className="text-field w-input" maxlength="256" name="email-2" data-name="Email 2" id="email-2" required=""><input type="submit" value="Submit" data-wait="Please wait..." className="submit-button w-button"></form>
              */
            }
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <div className="background-block-bottom"></div>
        </div>
        <div>
          <div className="company-info">company info here</div>
        </div>
      </div>
    </div>
  )
  return (
    <div style={{ flex:1 ,display:'flex',flexDirection:'column',alignItems:'center' }}>
      <div ref={(ref) => (Session.refs['public1'] = ref)} style={{ width: '100%', height: '450px'}}>
        <div style={{display: 'flex', flexDirection: 'row', padding: '70px 90px'}}>
          <div id='textContents' style={{display: 'flex', flexDirection: 'column', marginRight: '30px'}}>
            <div id='itemHeader' style={{ color: '#4E6CB4', fontSize: '43px', letterSpacing: '1.37px', lineHeight: '50px' }}>
              For everything your doctors can't help you with
            </div>
            <div id='itemBody' style={{minWidth: '363px', color: '#4E6CB4', marginTop: '20px', fontSize: '20px', letterSpacing: '1.03px', lineHeight: '24px'}}>
              And this is a short paragraph that explains what we do with a few details and that's it.
            </div>
            <div id='itemButton'>
            </div>
            <Link to='/login'>
              <button style={{marginTop: '99px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '116px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
                Join Now
              </button>
            </Link>
          </div>
          <div id='itemPhoto' style={{marginTop: '10px', zIndex: '200'}}>
            <img src={require('../shared/assets/lightbulb.png')} alt="" style={{ height: '440px', width: '440px' }} />
          </div>
        </div>
      </div>
      <div ref={(ref) => (Session.refs['public2'] = ref)} style={{ height: 'auto', backgroundColor: '#FDAAAA' }}>
        <div style={{display: 'flex', flexDirection: 'row', padding: '70px 90px'}}>
          <div id='itemPhoto'>
            <img src={require('../shared/assets/market_chat.png')} alt="" style={{ height: '350px', width: '200px' }} />
          </div>
          <div id='textContents' style={{display: 'flex', flexDirection: 'column', marginLeft: '50px', marginTop: '70px'}}>
            <div id='itemHeader' style={{ color: '#4E6CB4', fontSize: '43px', letterSpacing: '1.37px', lineHeight: '50px' }}>
              A familiar way to share your experience
            </div>
            <div id='itemBody' style={{color: '#4E6CB4', marginTop: '23px', fontSize: '20px', letterSpacing: '1.03px', lineHeight: '24px'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
            <div id='actionButtons' style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '20px'}}>
              <Link to='/blog'>
                <button style={{marginRight: '20px', marginTop: '99px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '161px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
                  SEE THE BLOG
                </button>
              </Link>
              <button style={{marginTop: '99px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '116px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
                Join Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref={(ref) => (Session.refs['public3'] = ref)} style={{ height: '510px', padding: '70px' }}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <div id='valueProp1' style={{display: 'flex', flexDirection: 'column', padding: '10px', minWidth: '300px', maxWidth: '300px', marginRight: '100px'}}>
            <div id='valueImage' style={{padding: '5px', alignSelf: 'center'}}>
              <img src={require('../shared/assets/customer_support.png')} alt="" style={{ height: '270px', width: '250px' }} />
            </div>
            <div id='valueHeader' style={{marginTop: '8px', marginBottom: '8px', height: '50px', color: '#4E6CB4', fontSize: '38px', letterSpacing: '1.21px', lineHeight: '44px', textAlign: 'center'}}>
              Life Coaching
            </div>
            <div id='valueBody' style={{color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px', textAlign: 'center'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
          </div>
          <div id='valueProp2' style={{display: 'flex', flexDirection: 'column', padding: '10px', minWidth: '300px', maxWidth: '300px'}}>
            <div id='valueImage' style={{padding: '5px', alignSelf: 'center'}}>
              <img src={require('../shared/assets/zenpicture.png')} alt="" style={{ height: '270px', width: '250px' }} />
            </div>
            <div id='valueHeader' style={{marginTop: '8px', marginBottom: '8px', height: '50px', color: '#4E6CB4', fontSize: '38px', letterSpacing: '1.21px', lineHeight: '44px', textAlign: 'center'}}>
              No Cost
            </div>
            <div id='valueBody' style={{color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px', textAlign: 'center'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
          </div>
        </div>
        <div id='actionButtonCentered' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <button style={{marginTop: '30px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '116px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
            Join Now
          </button>
        </div>
      </div>
      <div ref={(ref) => (Session.refs['public4'] = ref)} style={{ height: '427px', backgroundColor: '#F0E3F6', padding: '70px' }}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <blockquote style={{fontSize: '26px', fontStyle: 'italic', letterSpacing: '1.34px', lineHeight: '30px', textAlign: 'center', color: '#4E6CB4'}}>
            "Social confirmation quote from a RC Patient. It really helped me a lot and I discovered new ways to deal with my cancer"
          </blockquote>
          <div id='quoteAuthor' style={{display: 'flex', justifyContent: 'flex-end', height: '33px', color: '#4E6CB4', fontSize: '25px', letterSpacing: '1.34px', lineHeight: '29px', fontWeight: 'bold'}}>
            Robin Patient
          </div>
        </div>
      </div>
      <div id='stockPeopleSection' style={{width: '100%', height: '250px'}}>
        <img src={require('../shared/assets/stock_people.png')} alt="" style={{ width: '100%' }} />
      </div>
      <div ref={(ref) => (Session.refs['public5'] = ref)} style={{backgroundColor: '#F5F6F7', height: '427px', width: '100%', padding: '50px' }}>
        <div id='sectionHeader' style={{textAlign: 'center', color: '#4E6CB4', justifyContent: 'center', fontSize: '38px', letterSpacing: '1.21px', lineHeight: '44px', height: '50px'}}>
          First value prop
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <div id='valueBody' style={{padding: '10px', minWidth: '300px', maxWidth: '300px', color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px', textAlign: 'center'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
            <div id='valueBody' style={{padding: '10px', minWidth: '300px', maxWidth: '300px', color: '#4E6CB4', fontSize: '16px', letterSpacing: '0.83px', lineHeight: '19px', textAlign: 'center'}}>
              And this is a short paragraph that explains what we do with a few details and that's it. This is a little longer because we've got space.
            </div>
        </div>
        <div id='actionButtons' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <button style={{marginTop: '99px', color: '#1293BF', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0.83px', letterHeight: '19px', boxSizing: 'border-box', height: '46px', width: '116px', border: '3px solid #1293BF', borderRadius: '4px', backgroundColor: '#FFFFFF'}}>
            Join Now
          </button>
        </div>
      </div>
      <div id='footer' style={{ height: '600px'}}>
        <div id='footerContents'>
          Footer
        </div>
      </div>
    </div>
  )
}
export default Public
