import React, { useState,useEffect } from 'react'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'

//MATERIAL DESIGN IMPORTS
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'
import Close from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import CheckBox from '@material-ui/icons/CheckBox'
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import Phone from '@material-ui/icons/Phone'
import CollectionsBookmark from '@material-ui/icons/CollectionsBookmark'

import { Session, fbListener } from '../shared/globals'
import {ThreadPoster} from './Posters'
import Thread from './Thread'
import Comment from './Comment'
import {CommentPoster} from './Posters'
import Profile from './Profile'
import SearchBox from './SearchBox'
import { DialogPhoneLog, DialogNotes, DialogCases, DialogTasks } from './dialogs'
import {withStyles} from "@material-ui/core";

const StyledButton = withStyles({
  root: {
    height: '48px',
    width: '38px',
  },
  label:{
    overflow:'hidden',
  }
})(Button)


const Home = ({uid, index, closeTab}) => {
  const [, refreshState] = useState()
  const [adminPage,setAdminPage] = useState('home')
  const [scrollParentRef,] = useState({})
  const [threadCount,setThreadCount] = useState(5)
  const [postCount,setPostCount] = useState(5)
  const [dialogOpen,setDialogOpen] = useState('')
  const dialogHide = () => {
    setDialogOpen('')
    refreshState(Date.now())
  }

  const refresh = () => {
    refreshState(Date.now())
  }

  useEffect(()=>{
    _.set(Session,`refreshHome.${uid}`,refresh)
    return ()=>{
      _.unset(Session,`refreshHome.${uid}`)
    }
  })
  const user = _.get(Session,`users.${uid}.profile`,{})
  const localStyles = {
    userRoot:{
      display: 'flex',
      flexDirection: 'column',
      margin:0,
      backgroundColor:'#6BC1F6',
      // border:'solid 1px black',
      // borderRadius:8,
      // padding:8,
      maxWidth:500,
      minWidth:400,
      width:500,
    },
    adminRoot:{
      display: 'flex',
      flexDirection: 'column',
      border: '3px solid #DDDDDD',
      backgroundColor:'#fbfbfc',
      marginLeft: index>0?16:0,
      borderRadius:8,
      // padding:8,
      maxWidth:500,
      minWidth:400,
      width:500,
    },
    root: {
      maxWidth:500,
      minWidth:400,
      width:500,
      // padding: 8,
    },
    root2: {
      // borderTop: '0.5px solid #DADDE0',
      // borderRadius: '2px 2px 0 0',
      padding:8,
      // marginTop: 10,
      overflow: 'auto'
    },
    root3: {
      flex:1,
      display:'flex',
      flexDirection:'column',
      overflow:'hidden',
    },
    root4: {
      padding:8,
      backgroundColor:'white',
      overflow: 'auto',
      flex:1,
    }
  }

  let backgroundColor = 'white'
  switch (_.indexOf(Session.userTabs,uid)){
    case 0:
      backgroundColor = '#ffe7b8'//'#C7DFFF'
      break
    case 1:
      backgroundColor = '#ffe7b8'//'#E9C7FF'
      break
    default:
      backgroundColor = 'white'
      break
  }

  const userInitials = () => {
    if(user.firstName && user.lastName) {
      return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
    } else if(user.firstName) {
      return `${user.firstName[0]}`.toUpperCase()
    } else if(user.lastName) {
      return `${user.lastName[0]}`.toUpperCase()
    } else {
      return ''
    }
  }

  const renderTogglePage = () => {
    // if(user.uid===Session.uid) return null
    if(!_.get(Session,'userClaims.isAdmin',false)){
      return (
        <div style={{paddingLeft:8,paddingRight:8,paddingBottom:8}}>
          <SearchBox uid={uid} />
        </div>
      )
    }
    return (
      <div style={{display:'flex',flexDirection:'column',padding:8,borderBottom:'solid 1px #DDDDDD'}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <div id='homeAndProfileToggle' style={{flex: 1, display:'flex',flexDirection:'row', justifyContent: 'space-between', alignItems:'center',padding:'4px',boxShadow: '0 2px 4px 0 rgba(80,80,80,0.5)',backgroundColor:backgroundColor,borderRadius: '4px',cursor:'pointer', height: '48px'}}>
            <Tooltip title={adminPage==='home'?'Goto Profile':'Goto Feed'} placement="top">
              <div id='userAvatarAndToggleButton' onClick={()=>setAdminPage(adminPage==='home'?'profile':'home')} style={{display: 'flex', flexDirection: 'row'}}>
                  {
                    userInitials().length>0?
                    <div id='userAvatar' style={{minWidth: '37px', maxWidth: '37px', minHeight: '37px', maxHeight: '37px', border: '1px solid #979797', borderRadius: '50%', alignItems: 'center', backgroundColor: '#FFFFFF'}}>
                      <div id='userInitials' style={{lineHeight: '37px', fontSize: '16px', textAlign: 'center', fontColor: 'black'}}>
                        { userInitials() }
                      </div>
                    </div> :
                    <div style={{minWidth: '37px', maxWidth: '37px', minHeight: '37px', maxHeight: '37px', border: '1px solid #979797', borderRadius: '50%', backgroundColor: '#FFFFFF',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                      <img style={{ width: 36, height: 36, borderRadius: 99}} alt={''} src={_.get(user,'profileIcon','')}/>
                    </div>
                  }
                <div id='userIdFullNameAndEmail' style={{marginLeft: 6, display: 'flex', flexDirection: 'column'}}>
                  <div id='userIdAndFullName' style={{display: 'flex', flexDirection: 'row',  textOverflow: 'ellipsis', width: '250px', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                    <div id='userId' style={{fontSize: 14, color: '#504D4D'}}>
                      {user.userCount}
                    </div>
                    <div id='userFullName' style={{marginLeft: 5, fontSize: 14, fontWeight: 'bold',}}>
                      {_.startCase(_.get(user,`firstName`,''))} {
                        _.trim(_.get(user,`nickname`,'')).length > 0 && _.trim(_.get(user,`nickname`,'')) !== _.trim(_.get(user,`firstName`,'')) ?
                        `[${_.startCase(_.get(user,`nickname`,''))}]` : null
                      } {_.startCase(_.get(user,`lastName`,''))} {!_.get(user,'companyName',null)?null:`(${user.companyName})`}
                    </div>
                  </div>
                  <div id='userEmail' style={{fontSize: 14, color: '#504D4D'}}>
                    {`${user.email}`}
                  </div>
                </div>
              </div>
            </Tooltip>

            <Tooltip title='Close Tab' id='closeButton' placement="top">
              <IconButton size='small' style={{marginLeft:8}} onClick={() => closeTab(index)}>
                <Close style={{cursor:'pointer'}} />
              </IconButton>
            </Tooltip>
          </div>
          <Tooltip title="Create Communication Log" placement="top">
            <StyledButton variant='contained' size='small' style={{marginLeft:8}} onClick={()=>setDialogOpen('DialogPhoneLog')}>
              <Phone style={{cursor:'pointer'}} />
            </StyledButton>
          </Tooltip>
          <Tooltip title="Create Admin Note" placement="top">
            <StyledButton variant='contained' size='small' style={{marginLeft:8}} onClick={()=>setDialogOpen('DialogNotes')}>
              <CollectionsBookmark style={{cursor:'pointer'}} />
            </StyledButton>
          </Tooltip>
        </div>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingTop:8}}>
          <SearchBox uid={uid} />
          <Tooltip title="Create Task" placement="top">
            <StyledButton variant='contained' size='small' style={{marginLeft:8}} onClick={()=>setDialogOpen('DialogTasks')}>
              <CheckBox style={{cursor:'pointer'}} />
            </StyledButton>
          </Tooltip>
          <Tooltip title="Create Case Request" placement="top">
            <StyledButton variant='contained' size='small' style={{marginLeft:8}} onClick={()=>setDialogOpen('DialogCases')}>
              <AssignmentInd style={{cursor:'pointer'}} />
            </StyledButton>
          </Tooltip>
        </div>
        {dialogOpen!=='DialogPhoneLog'?null:<DialogPhoneLog open={dialogOpen==='DialogPhoneLog'} dialogParentHide={dialogHide} post={{id:null}} adminDoc={{id:null}} user={user}/>}
        {dialogOpen!=='DialogNotes'?null:<DialogNotes open={dialogOpen==='DialogNotes'} dialogParentHide={dialogHide} post={{id:null}} adminDoc={{id:null}} user={user}/>}
        {dialogOpen!=='DialogTasks'?null:<DialogTasks open={dialogOpen==='DialogTasks'} dialogParentHide={dialogHide} post={{id:null}} adminDoc={{id:null}} user={user}/>}
        {dialogOpen!=='DialogCases'?null:<DialogCases open={dialogOpen==='DialogCases'} dialogParentHide={dialogHide} post={{id:null}} adminDoc={{id:null}} user={user}/>}
      </div>
    )
  }
  const renderPage = () => {
    switch (adminPage){
      case 'profile':
        return (
          <div key="home" style={localStyles.root2} ref={(ref) => scrollParentRef.ref=ref }>
            <Profile user={user} />
          </div>
        )
      case 'home':
        const orderedThreadList = _.orderBy(
          _.filter(_.get(Session,`users.${user.uid}.threads`,{}), (thread,key) => {
            return (!thread.isArchived && (_.indexOf(_.get(Session,`searchPostIdArray.${user.uid}`,[]),key) > -1 || _.get(Session,`searchStr.${user.uid}`,'').length === 0))
          }),['modifiedDate'],['desc'])
        const orderedPostList = _.orderBy(
          _.filter(_.get(Session,`posts.${user.uid}`,{}), (post,key) => {
            return (!post.isArchived && (post.isThread || post.isComment) && (_.indexOf(_.get(Session,`searchPostIdArray.${user.uid}`,[]),key) > -1 || _.get(Session,`searchStr.${user.uid}`,'').length === 0))
          }),['createdDate'],['asc'])
        if(scrollParentRef.lastComment){
          setTimeout(()=>{
            if(scrollParentRef.lastComment && (scrollParentRef.ref.scrollHeight - scrollParentRef.ref.scrollTop - scrollParentRef.ref.offsetHeight*1.1) < 100){
              scrollParentRef.lastComment.scrollIntoView({ behavior: "smooth" })
            }
          }, 100);
        }
        if(true){
          return (
            <div key="home" style={localStyles.root3}>
              <div key="postList" style={localStyles.root4} ref={(ref) => scrollParentRef.ref=ref }>
                <InfiniteScroll
                    pageStart={0}
                    isReverse={true}
                    loadMore={()=>{
                      if(postCount < _.size(orderedPostList)){
                        setPostCount(postCount+5)
                      }
                    }}
                    hasMore={postCount < _.size(orderedPostList)}
                    loader={<div key={0}>Loading ...</div>}
                    useWindow={false}
                    getScrollParent={() => scrollParentRef.ref}
                >
                  {
                    _.map(orderedPostList, (post,index) => {
                      if(index >= _.size(orderedPostList)-postCount && !post.isArchived && (post.isThread || post.isComment) && post.id){
                        return <div key={`${post.id}`}ref={(ref) => scrollParentRef.lastComment=ref }>
                        <Comment key={`${post.id}`} user={user} comment={post} />
                        </div>
                      }
                    })
                  }
                </InfiniteScroll>
              </div>
              <CommentPoster user={user} thread={{id:'xxxMASTERxxx'}} />
            </div>
          )
        }else{
          return (
            <div key="home" style={localStyles.root2} ref={(ref) => scrollParentRef.ref=ref }>
              <ThreadPoster user={user}/>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={()=>{
                      if(threadCount < _.size(orderedThreadList)){
                        setThreadCount(threadCount+5)
                      }
                    }}
                    hasMore={threadCount < _.size(orderedThreadList)}
                    loader={<div key={0}>Loading ...</div>}
                    useWindow={false}
                    getScrollParent={() => scrollParentRef.ref}
                >
                  {
                    _.map(orderedThreadList, (thread,index) => {
                      if(index < threadCount && !thread.isArchived && thread.id){
                        return <Thread key={`${thread.id}`} user={{uid:user.uid}} thread={thread} />
                      }
                    })
                  }
                </InfiniteScroll>
            </div>
          )
        }
      default:
        return null
    }
  }

  if (Session.auth && !_.get(Session,`fbListener.${user.uid}`,null)) {
    fbListener(user.uid)
  }

  // console.log('HOME RENDER',user.welcomeScreen)
  return (
    <div style={_.get(Session,'userClaims.isAdmin',false)?localStyles.adminRoot:localStyles.userRoot}>
      {renderTogglePage()}
      {renderPage()}
    </div>
  )
}
//PSW TEST SECTION
// const recordRTC = () => {
//   return
//       // <div onClick={recordRTC}>record</div>
//       // let stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
//       // let recorder = new RecordRTCPromisesHandler(stream, {
//       //     type: 'video'
//       // });
//       // recorder.startRecording();
//       //
//       // const sleep = m => new Promise(r => setTimeout(r, m));
//       // await sleep(3000);
//       //
//       // await recorder.stopRecording();
//       // let blob = await recorder.getBlob();
//       // invokeSaveAsDialog(blob);
//
//     //easy
//       // navigator.mediaDevices.getUserMedia({
//       //     video: true,
//       //     audio: true
//       // }).then(async function(stream) {
//       //   var RecordRTC = require('recordrtc')
//       //     let recorder = RecordRTC(stream, {
//       //         type: 'video'
//       //     });
//       //     recorder.startRecording();
//       //
//       //     const sleep = m => new Promise(r => setTimeout(r, m));
//       //     await sleep(3000);
//       //
//       //     recorder.stopRecording(function() {
//       //         let blob = recorder.getBlob();
//       //         console.log('blob',blob);
//       //         let videoPath = `/attachments/${Session.uid}/testvideo.webm`
//       //         const attachmentRef = firebase
//       //           .storage()
//       //           .ref()
//       //           .child(videoPath)
//       //         attachmentRef
//       //           .put(blob)
//       //           .then((snapshot) => {
//       //             attachmentRef.getDownloadURL().then((url) => {
//       //               console.log('url',url)
//       //             }).catch((error)=>{
//       //               console.log('error1',error)
//       //             })
//       //           }).catch((error)=>{
//       //             console.log('error2',error)
//       //           })
//       //     });
//       // });
// }
export default Home
