import React, { useState } from 'react'
import _ from 'lodash'
import firebase from 'firebase'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Slider from '@material-ui/lab/Slider'
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'

import { Session,userUpdate } from '../../shared/globals'

const useStyles = makeStyles({
  root: {
    flex:1,
    // width: 300,
    // maxWidth:'90%',
    padding: 24,
    // paddingRight:48,
    minHeight:30,
    display:'flex',
    flexDirection:'row',
    alignItems:'flex-end',
    position:'relative',
    marginRight:16,
  },
  slider: {
    padding: '22px 0px',
  },
  thumbIcon: {
    borderRadius: '50%',
  },
})
const StyledSlider = withStyles({
  thumbIconWrapper: {
    backgroundColor: '#fff',
  },
  thumb: {
    height: '50px',
    width: '50px',
    boxSizing:'border-box',
    backgroundColor: 'transparent',
    '&$focused, &:hover': {
      boxShadow: `inset 0px 0px 0px ${25}px ${fade('#2B8CA6', 0.16)}`,
      boxSizing:'border-box',
      backgroundColor: 'transparent',
    },
    '&$activated': {
      boxShadow: `0px 0px 0px ${8 * 1.5}px ${fade('#2B8CA6', 0.16)}`,
    },
    '&$jumped': {
      boxShadow: `0px 0px 0px ${8 * 1.5}px ${fade('#2B8CA6', 0.16)}`,
    },
  },
  track: {
    backgroundColor: '#2B8CA6',
    height: 8,
  },
  trackAfter: {
    backgroundColor: '#d0d7dc',
  },
  focused: {},
  activated: {},
  jumped: {},
})(Slider)
const localStyles = {
  dialogHolder:{
    backgroundColor:'#FF74D8',
    padding:16,
    borderRadius:8,
    minWidth:400,
    maxWidth:600,
    minHeight:400,
    maxHeight:700,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
  },
  dialogContent:{
    flex:1,
    backgroundColor:'white',
    borderRadius:8,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,0.5)',
    fontWeight:200,
    display:'flex',
    padding:32,
    flexDirection:'column',
  },
}

export const DialogWelcome = ({open,user,dialogParentHide}) => {
  const dialogHide = () => {
    _.set(user,'welcomeScreen',99)
    dialogParentHide()
  }
  return (
    <Dialog key={'WS'} fullWidth={true} maxWidth={'xs'} open={true} disableBackdropClick={true} disableEscapeKeyDown={true} onClose={dialogHide} PaperProps={{ style: localStyles.dialogHolder }}>
      <WelcomeScreen key={'WS00'} user={user}/>
    </Dialog>
  )
}
const WelcomeScreen = ({user}) => {
  const [,refreshState] = useState()
  const classes = useStyles()
  const [welcomeScreen,setWelcomeScreen] = useState(_.get(user,'welcomeScreen',0))
  const [practiceTracker,setPracticeTracker] = useState(null)
  const [practiceTrackerState,setPracticeTrackerState] = useState('init')

  const nextScreen = (screenNumber) => {
    setWelcomeScreen(screenNumber)
  }
  const previousScreen = (screenNumber) => {
    setWelcomeScreen(screenNumber)
  }
  const userSetPresence = (status) => {
    if(Session.userStatusDatabaseRef && _.get(user,'presenceState','') !== status){
      _.set(user,'presenceState',status)
      Session.userStatusDatabaseRef.set({
        presenceState: status,
        presenceLastChanged: firebase.database.ServerValue.TIMESTAMP,
      })
    }
  }
  let fieldValid = false

  switch (welcomeScreen){
    case 0: // HELLO
      userSetPresence('online')
      return (
        <div style={localStyles.dialogContent}>
          <div style={{flex:1}}></div>
          <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
            <div style={{color:'#007A8A',fontSize:36,fontWeight:200}}>Thanks for waiting,</div>
            <div>Let's get you set up now.</div>
            <div>This will only take a moment.</div>
          </div>
          <div style={{flex:1}}></div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <Button style={{visibility:'hidden',marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}}>
              Back
            </Button>
            <Button style={{marginLeft:8,backgroundColor:'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}} onClick={()=>{
              nextScreen(welcomeScreen+1)
              userUpdate(user.uid,{
                welcomeScreen:welcomeScreen+1,
                lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                lastActivityAction:`Welcome Screen ${welcomeScreen}`
              })}}>
              Let's Go
            </Button>
          </div>
        </div>
      )
    case 1: // REQUEST NAME
      if(_.get(user,'email',null) === null){
        console.log('missing email',_.cloneDeep(user))
        fieldValid = _.get(user,'emailFacebook','').match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null
        return (
          <div key={'WS1-1'} style={localStyles.dialogContent}>
            <div style={{flex:1}}></div>
            <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64}}>
              <div style={{paddingBottom:8,lineHeight:'initial'}}>Please provide us with your email address</div>
              <TextField autoFocus fullWidth margin="normal" variant="outlined"
                label="Email" placeholder="Email Address" inputProps={{ maxLength: 50 }}
                value={_.get(user,'emailFacebook','') || ''} onChange={(e)=>{
                  _.set(user,'emailFacebook',e.target.value)
                  refreshState(Date.now())
                }}
              />
            </div>
            <div style={{flex:1}}></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Button style={{visibility:'hidden',marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}}>
                Back
              </Button>
              <Button style={{marginLeft:8,backgroundColor:!fieldValid?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                disabled={!fieldValid}
                onClick={()=>{
                  userUpdate(user.uid,{
                    emailFacebook: _.get(user,'emailFacebook',''),
                    email: _.get(user,'emailFacebook',''),
                  })
                  refreshState(Date.now())
                }}>
                Next
              </Button>
            </div>
          </div>
        )
      }
      return (
        <div key={'WS1'} style={localStyles.dialogContent}>
          <div style={{flex:1}}></div>
          <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64}}>
            <div style={{paddingBottom:8,lineHeight:'initial'}}>First things first, what would you like us to call you</div>
            <TextField required autoFocus fullWidth margin="normal" variant="outlined"
              label="Name" placeholder="First Name or Preferred Name" inputProps={{ maxLength: 50 }}
              value={_.get(user,'firstName','')} onChange={(e)=>{
                _.set(user,'firstName',_.trimStart(e.target.value))
                refreshState(Date.now())
              }}
            />
          <TextField required fullWidth margin="normal" variant="outlined"
              label="Last Name" placeholder="Last Name" inputProps={{ maxLength: 50 }}
              value={_.get(user,'lastName','')} onChange={(e)=>{
                _.set(user,'lastName',_.trimStart(e.target.value))
                refreshState(Date.now())
              }}
            />
          </div>
          <div style={{flex:1}}></div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <Button style={{visibility:'hidden',marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}}>
              Back
            </Button>
            <Button style={{marginLeft:8,backgroundColor:_.trim(_.get(user,'firstName','')).length===0 || _.trim(_.get(user,'lastName','')).length===0?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
              disabled={_.trim(_.get(user,'firstName','')).length===0 || _.trim(_.get(user,'lastName','')).length===0}
              onClick={()=>{
              nextScreen(welcomeScreen+1)
              userUpdate(user.uid,{
                firstName: _.startCase(_.get(user,'firstName','')),
                nickname: _.startCase(_.get(user,'firstName','')),
                lastName: _.startCase(_.get(user,'lastName','')),
                welcomeScreen:welcomeScreen+1,
                lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                lastActivityAction:`Welcome Screen ${welcomeScreen}`
              })}}>
              Next
            </Button>
          </div>
        </div>
      )
    case 2: // Company Info
      let ws2Valid = true
      if(
        _.trim(_.get(user,'companyName','')).length < 1
        || _.trim(_.get(user,'memberID','')).length < 1
      ){
        ws2Valid=false
      }
      return (
        <div key={'WS4'} style={localStyles.dialogContent}>
          <div style={{flex:1}}></div>
          <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64}}>
            <div style={{paddingBottom:8,lineHeight:'initial'}}>Please provide company information</div>
            <TextField required autoFocus fullWidth margin="normal" variant="outlined"
              label="Company Name" placeholder="Your Company Name" inputProps={{ maxLength: 50 }}
              value={_.get(user,'companyName','')} onChange={(e)=>{
                _.set(user,'companyName',_.trimStart(e.target.value))
                refreshState(Date.now())
              }}
            />
            <TextField fullWidth margin="normal" variant="outlined"
              label="Company Email Address" placeholder="Your Company Email" inputProps={{ maxLength: 50 }}
              value={_.get(user,'companyEmail','')} onChange={(e)=>{
                _.set(user,'companyEmail',_.trimStart(e.target.value))
                refreshState(Date.now())
              }}
            />
            <TextField fullWidth margin="normal" variant="outlined"
              label="Phone Number" placeholder="Your Phone Number" inputProps={{ maxLength: 50 }}
              value={_.get(user,'phoneNumber','')} onChange={(e)=>{
                _.set(user,'phoneNumber',_.trimStart(e.target.value))
                refreshState(Date.now())
              }}
            />
            <TextField required fullWidth margin="normal" variant="outlined"
              label="Member ID" placeholder="Employee ID / Member ID / Access or Redemption Code" inputProps={{ maxLength: 50 }}
              value={_.get(user,'memberID','')} onChange={(e)=>{
                _.set(user,'memberID',_.trimStart(e.target.value))
                refreshState(Date.now())
              }}
            />
          </div>
          <div style={{flex:1}}></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                previousScreen(welcomeScreen-1)
                userUpdate(user.uid,{
                  welcomeScreen:welcomeScreen-1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Back
              </Button>
              <Button style={{marginLeft:8,backgroundColor:!ws2Valid?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                disabled={!ws2Valid}
                onClick={()=>{
                nextScreen(welcomeScreen+1)
                userUpdate(user.uid,{
                  companyName: _.startCase(_.trim(_.get(user,'companyName',''))),
                  companyEmail: _.trim(_.get(user,'companyEmail','')),
                  phoneNumber: _.trim(_.get(user,'phoneNumber','')),
                  memberID: _.trim(_.get(user,'memberID','')),
                  welcomeScreen:welcomeScreen+1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Next
              </Button>
            </div>
        </div>
      )
    case 3: // REQUEST CANCER
      return (
        <div key={'WS2'} style={localStyles.dialogContent}>
          <div style={{flex:1}}></div>
          <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,display:'flex',flexDirection:'column',lineHeight:'initial'}}>
            <div style={{paddingBottom:16}}>Welcome {_.get(user,'firstName','')}, tell us what brings you to Robin Care</div>
            <div style={{paddingBottom:16}}>
              <Button fullWidth style={{backgroundColor:_.get(user,'type','')==='Patient'?'#6090CB':'#EBFDFF',color:_.get(user,'type','')==='Patient'?'white':'#51B7C4',fontWeight:_.get(user,'type','')==='Patient'?400:200,fontSize:20}} onClick={()=>{
                _.set(user,'type','Patient')
                nextScreen(welcomeScreen+1)
                userUpdate(user.uid,{
                  type: 'Patient',
                  welcomeScreen:welcomeScreen+1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                I have cancer myself
              </Button>
            </div>
            <div style={{paddingBottom:16}}>
              <Button fullWidth style={{backgroundColor:_.get(user,'type','')==='Caregiver'?'#6090CB':'#EBFDFF',color:_.get(user,'type','')==='Caregiver'?'white':'#51B7C4',fontWeight:_.get(user,'type','')==='Caregiver'?400:200,fontSize:20}} onClick={()=>{
                _.set(user,'type','Caregiver')
                nextScreen(welcomeScreen+1)
                userUpdate(user.uid,{
                  type: 'Caregiver',
                  welcomeScreen:welcomeScreen+1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                I’m caring for a person who has cancer
              </Button>
            </div>
            <div style={{}}>
              <Button fullWidth style={{backgroundColor:_.get(user,'type','')==='Information'?'#6090CB':'#EBFDFF',color:_.get(user,'type','')==='Information'?'white':'#51B7C4',fontWeight:_.get(user,'type','')==='Information'?400:200,fontSize:20}} onClick={()=>{
                _.set(user,'type','Information')
                nextScreen(welcomeScreen+1)
                userUpdate(user.uid,{
                  type: 'Information',
                  welcomeScreen:welcomeScreen+1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                I don’t have cancer but I want to learn about cancer risks
              </Button>
            </div>
          </div>
          <div style={{flex:1}}></div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
              previousScreen(welcomeScreen-1)
              userUpdate(user.uid,{
                welcomeScreen:welcomeScreen-1,
                lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                lastActivityAction:`Welcome Screen ${welcomeScreen}`
              })}}>
              Back
            </Button>
            <Button style={{visibility:'hidden',marginLeft:8,backgroundColor:'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}>
              Next
            </Button>
          </div>
        </div>
      )
    case 4: // REQUEST YEAR
      return (
        <div key={'WS3'} style={localStyles.dialogContent}>
          <div style={{flex:1}}></div>
          <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64}}>
            <div style={{paddingBottom:8,lineHeight:'initial'}}>What year were you born</div>
            <TextField autoFocus fullWidth margin="normal" variant="outlined"
              label="Year of Birth" placeholder="YYYY" inputProps={{ maxLength: 4 }}
              value={_.get(user,'birthYear','')} onChange={(e)=>{
                _.set(user,'birthYear',parseInt(e.target.value.replace(/[^0-9]/gi, ''))||'')
                refreshState(Date.now())
              }}
            />
          </div>
          <div style={{flex:1}}></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                previousScreen(welcomeScreen-1)
                userUpdate(user.uid,{
                  welcomeScreen:welcomeScreen-1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Back
              </Button>
              <Button style={{marginLeft:8,backgroundColor:_.get(user,'birthYear',0) < 1900 || _.get(user,'birthYear',0) > 2022?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                disabled={_.get(user,'birthYear',0) < 1900 || _.get(user,'birthYear',0) > 2022}
                onClick={()=>{
                nextScreen(welcomeScreen+1)
                userUpdate(user.uid,{
                  birthYear:_.get(user,'birthYear',0),
                  birthdate:_.get(user,'birthYear',0) > 1900 && _.get(user,'birthYear',0) < 2022 ? `${_.get(user,'birthYear',0)}-01-01` : '',
                  welcomeScreen:welcomeScreen+1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Next
              </Button>
            </div>
        </div>
      )
    case 5: // REQUEST ZIP
      return (
        <div key={'WS4'} style={localStyles.dialogContent}>
          <div style={{flex:1}}></div>
          <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64}}>
            <div style={{paddingBottom:8,lineHeight:'initial'}}>What is your zip code</div>
            <TextField autoFocus fullWidth margin="normal" variant="outlined"
              label="Zip Code" placeholder="XXXXX" inputProps={{ maxLength: 5 }}
              value={_.get(user,'addressZip','')} onChange={(e)=>{
                _.set(user,'addressZip',_.trim(e.target.value.replace(/[^0-9]/gi, '')))
                refreshState(Date.now())
              }}
            />
          </div>
          <div style={{flex:1}}></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                previousScreen(welcomeScreen-1)
                userUpdate(user.uid,{
                  welcomeScreen:welcomeScreen-1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Back
              </Button>
              <Button style={{marginLeft:8,backgroundColor:_.trim(_.get(user,'addressZip','')).length!==5?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                disabled={_.trim(_.get(user,'addressZip','')).length!==5}
                onClick={()=>{
                nextScreen(7)
                userUpdate(user.uid,{
                  addressZip: _.trim(_.get(user,'addressZip','')),
                  patientAddressZip: _.trim(_.get(user,'addressZip','')),
                  welcomeScreen:7,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Next
              </Button>
            </div>
        </div>
      )
    case 6: // SAMPLE TRACKER
      const sliderOnChange = (e, newValue) => {
        if(practiceTrackerState === 'reset') return
        setPracticeTracker(newValue)
        if(practiceTrackerState === 'init'){
          setPracticeTrackerState('save')
        }
        if(practiceTrackerState === 'init2'){
          setPracticeTrackerState('done')
        }
      }
      return (
        <div style={localStyles.dialogContent}>
          <div style={{flex:1}}></div>
          <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64}}>
            <div style={{paddingBottom:16}}>Trackers are really important, they help put everyday life into perspective. Please try the tracker below and rate how you're feeling today</div>
              <div style={{paddingBottom:8,fontSize:16,color:'#757d8a',fontWeight:500}}>How are you feeling</div>
              <Paper className={classes.root}>
              <StyledSlider
                min={0} max={10} step={1} aria-labelledby="label"
                classes={{ thumbIconWrapper: classes.thumbIconWrapper }}
                value={practiceTrackerState === 'init' || practiceTrackerState === 'init2' ? 5: practiceTracker} onChange={sliderOnChange}
                thumb={
                  <div style={{ position:'relative',display:'flex' }}>
                    <LocationOnOutlined style={{ color: '#2B8CA6',fontSize:'50px',marginTop:'-50%' }} />
                    <div style={{minWidth:18,position:'absolute',backgroundColor:'white',borderRadius:8,color:'black',fontWeight:'bold',fontSize:16,left: '50%',top: '-10%', transform: 'translate(-50%,-50%)'}}>
                      {practiceTrackerState === 'init' || practiceTrackerState === 'init2' ? '' : practiceTracker}
                    </div>
                  </div>
                }
               />
             <div style={{}}>
               <div style={{width:60,marginLeft:16}}>
                 {
                   practiceTrackerState === 'init' ? null :
                   practiceTrackerState === 'save' ?
                   <Button style={{border:'solid 1px #DDDFE2',padding:2,backgroundColor:'white',color:'#757d8a',fontWeight:500,fontSize:12}} onClick={()=>{
                     _.set(user,'welcomeScreenPractice1',practiceTracker)
                     setPracticeTrackerState('reset')
                     userUpdate(user.uid,{
                       welcomeScreenPractice1: _.get(user,'welcomeScreenPractice1',''),
                       lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                       lastActivityAction:`Welcome Screen ${welcomeScreen}`
                     })}}>
                     Save
                   </Button> :
                   practiceTrackerState === 'reset' ?
                   <Button style={{border:'solid 1px #DDDFE2',padding:2,backgroundColor:'white',color:'#757d8a',fontWeight:500,fontSize:12}} onClick={()=>setPracticeTrackerState('init2')}>
                     Reset
                   </Button> :
                   practiceTrackerState === 'init2' ? null :
                   <Button style={{border:'solid 1px #DDDFE2',padding:2,backgroundColor:'white',color:'#757d8a',fontWeight:500,fontSize:12}} onClick={()=>{
                     _.set(user,'welcomeScreenPractice2',practiceTracker)
                     nextScreen(welcomeScreen+1)
                     userUpdate(user.uid,{
                       welcomeScreenPractice2: _.get(user,'welcomeScreenPractice2',''),
                       welcomeScreen:welcomeScreen+1,
                       lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                       lastActivityAction:`Welcome Screen ${welcomeScreen}`
                     })}}>
                     Save
                   </Button>
                 }
               </div>
             </div>
            </Paper>
            {
              practiceTrackerState === 'init' ?
              <div style={{paddingTop:8,fontSize:18,fontWeight:200,color:'#757d8a'}}>Move the slider to your desired level (0-bad 10-great) <div style={{color:'#FF74D8',textAlign:'center'}}>(Step 1 of 5)</div></div> :
              practiceTrackerState === 'save' ?
              <div style={{paddingTop:8,fontSize:18,fontWeight:600,color:'#757d8a'}}>Save your results by clicking on the save button <div style={{color:'#FF74D8',textAlign:'center'}}>(Step 2 of 5)</div></div> :
              practiceTrackerState === 'reset' ?
              <div style={{paddingTop:8,fontSize:18,fontWeight:700,color:'#757d8a'}}>Reset your results by clicking on the reset button <div style={{color:'#FF74D8',textAlign:'center'}}>(Step 3 of 5)</div></div> :
              practiceTrackerState === 'init2' ?
              <div style={{paddingTop:8,fontSize:18,fontWeight:800,color:'#757d8a'}}>Move the slider to a new desired level <div style={{color:'#FF74D8',textAlign:'center'}}>(Step 4 of 5)</div></div> :
              practiceTrackerState === 'done' ?
              <div style={{paddingTop:8,fontSize:18,fontWeight:900,color:'#757d8a'}}>Save your results again to go to the next screen <div style={{color:'#FF74D8',textAlign:'center'}}>(Step 5 of 5)</div></div> : null
            }
          </div>
          <div style={{flex:1}}></div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
              previousScreen(welcomeScreen-1)
              userUpdate(user.uid,{
                welcomeScreen:welcomeScreen-1,
                lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                lastActivityAction:`Welcome Screen ${welcomeScreen}`
              })}}>
              Back
            </Button>
            <Button style={{visibility:practiceTrackerState === 'reset' || practiceTrackerState === 'init2' || practiceTrackerState === 'done' ? 'visible':'hidden',marginLeft:8,backgroundColor:'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}} onClick={()=>{
              _.set(user,'welcomeScreenPractice2',practiceTracker)
              nextScreen(welcomeScreen+1)
              userUpdate(user.uid,{
                welcomeScreenPractice2: _.get(user,'welcomeScreenPractice2',''),
                welcomeScreen:welcomeScreen+1,
                lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                lastActivityAction:`Welcome Screen ${welcomeScreen}`
              })}}>
              SKIP
            </Button>
          </div>
        </div>
      )
    case 7: // DONE BRANCH CANCER ANSWER
      if(_.get(user,'type','') === 'Patient' || _.get(user,'type','') === 'Caregiver'){
        return (
          <div style={localStyles.dialogContent}>
              <div style={{flex:1}}></div>
              <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
                <div style={{paddingBottom:16}}>
                  {
                    _.get(user,'type','') === 'Caregiver' ?
                    <div>
                      We noticed you indicated that you are a caregiver, please proceed to the next screens to fill out the patients cancer details.
                    </div> :
                    <div>
                      We noticed you indicated that you are a patient, please proceed to the next screen to fill out your cancer details.
                    </div>
                  }
                </div>
              </div>
              <div style={{flex:1}}></div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                  previousScreen(5)
                  userUpdate(user.uid,{
                    welcomeScreen:5,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })}}>
                  Back
                </Button>
                <Button style={{marginLeft:8,backgroundColor:'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                  onClick={()=>{
                    nextScreen(_.get(user,'type','') === 'Caregiver' ? 10 : _.get(user,'type','') === 'Patient' ? 20 : 99)
                    userUpdate(user.uid,{
                      welcomeScreenMoreDetails: true,
                      welcomeScreen: _.get(user,'type','') === 'Caregiver' ? 10 : _.get(user,'type','') === 'Patient' ? 20 : 99,
                      lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                      lastActivityAction:`Welcome Screen ${welcomeScreen}`
                    })
                  }}>
                  Next
                </Button>
              </div>
            </div>
        )
      }else{
        return (
          <div style={localStyles.dialogContent}>
              <div style={{flex:1}}></div>
              <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
                <div style={{}}>You are all set {_.get(user,'firstName','')}, we look forward to talking with you soon.</div>
              </div>
              <div style={{flex:1}}></div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                  previousScreen(5)
                  userUpdate(user.uid,{
                    welcomeScreen:5,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })}}>
                  Back
                </Button>
                <Button style={{marginLeft:8,backgroundColor:'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                  onClick={()=>{
                    userUpdate(user.uid,{
                      welcomeScreen:99,
                      lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                      lastActivityAction:`Welcome Screen ${welcomeScreen}`
                    })
                    userSetPresence('online')
                  }}>
                  Finish
                </Button>
              </div>
            </div>
        )
      }
      return (
        <div style={localStyles.dialogContent}>
            <div style={{flex:1}}></div>
            <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
              <div style={{}}>You are all set {_.get(user,'firstName','')}, we look forward to talking with you soon.</div>
            </div>
            {
              _.get(user,'type','') === 'Patient' || _.get(user,'type','') === 'Caregiver' ?
              <div style={{color:'#007A8A',fontSize:20,fontWeight:200,padding:64,paddingTop:0}}>
                <div style={{paddingBottom:16}}>
                  <div>By the way, we noticed you indicated that you were a {_.get(user,'type','')}, did you want to fill out the basic details now?
                    <Button style={{margin:4,border:'solid 1px #DDDFE2',padding:2,backgroundColor:'white',color:'#757d8a',fontWeight:600,fontSize:16}} onClick={()=>{
                      nextScreen(_.get(user,'type','') === 'Caregiver' ? 10 : _.get(user,'type','') === 'Patient' ? 20 : 99)
                      userUpdate(user.uid,{
                        welcomeScreenMoreDetails: true,
                        welcomeScreen: _.get(user,'type','') === 'Caregiver' ? 10 : _.get(user,'type','') === 'Patient' ? 20 : 99,
                        lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                        lastActivityAction:`Welcome Screen ${welcomeScreen}`
                      })
                    }}>
                      Yes
                    </Button>
                  </div>
                </div>
              </div> : null
            }
            <div style={{flex:2}}></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                previousScreen(welcomeScreen-1)
                userUpdate(user.uid,{
                  welcomeScreen:welcomeScreen-1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Back
              </Button>
              <Button style={{marginLeft:8,backgroundColor:'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                onClick={()=>{
                  userUpdate(user.uid,{
                    welcomeScreen:99,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })
                  userSetPresence('online')
                }}>
                Finish
              </Button>
            </div>
          </div>
      )
    case 10: // CAREGIVER BRANCH REQUEST NAME
      return (
        <div style={localStyles.dialogContent}>
            <div style={{flex:1}}></div>
            <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64}}>
              <div style={{paddingBottom:8,lineHeight:'initial'}}>What can we call the person you're caring for?</div>
              <TextField required autoFocus fullWidth margin="normal" variant="outlined"
                label="Name" placeholder="First Name or Preferred Name" inputProps={{ maxLength: 50 }}
                value={_.get(user,'patientFirstName','')} onChange={(e)=>{
                  _.set(user,'patientFirstName',_.trimStart(e.target.value))
                  refreshState(Date.now())
                }}
              />
              <TextField required fullWidth margin="normal" variant="outlined"
                label="Last Name" placeholder="Last Name" inputProps={{ maxLength: 50 }}
                value={_.get(user,'patientLastName','')} onChange={(e)=>{
                  _.set(user,'patientLastName',_.trimStart(e.target.value))
                  refreshState(Date.now())
                }}
              />
              <TextField required fullWidth margin="normal" variant="outlined"
                label="Relationship" placeholder="Relationship (ie Spouse)" inputProps={{ maxLength: 50 }}
                value={_.get(user,'patientRelationship','')} onChange={(e)=>{
                  _.set(user,'patientRelationship',_.trimStart(e.target.value))
                  refreshState(Date.now())
                }}
              />
            </div>
            <div style={{flex:1}}></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                previousScreen(7)
                userUpdate(user.uid,{
                  welcomeScreen:7,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Back
              </Button>
              <Button style={{marginLeft:8,backgroundColor:_.trim(_.get(user,'patientFirstName','')).length===0 || _.trim(_.get(user,'patientLastName','')).length===0 || _.trim(_.get(user,'patientRelationship','')).length===0?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                disabled={_.trim(_.get(user,'patientFirstName','')).length===0 || _.trim(_.get(user,'patientLastName','')).length===0 || _.trim(_.get(user,'patientRelationship','')).length===0}
                onClick={()=>{
                nextScreen(welcomeScreen+1)
                userUpdate(user.uid,{
                  patientFirstName: _.startCase(_.get(user,'patientFirstName','')),
                  patientNickname: _.startCase(_.get(user,'patientFirstName','')),
                  patientLastName: _.startCase(_.get(user,'patientLastName','')),
                  patientRelationship: _.startCase(_.get(user,'patientRelationship','')),
                  welcomeScreen:welcomeScreen+1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Next
              </Button>
            </div>
          </div>
      )
    case 11: // CAREGIVER BRANCH REQUEST YEAR
      // _.set(user,'patientBirthYear',_.get(user,'birthYear',''))
      return (
        <div style={localStyles.dialogContent}>
            <div style={{flex:1}}></div>
            <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
              <div>What year were they born</div>
              <div style={{paddingBottom:8,fontSize:16}}>approximation is fine</div>
              <TextField autoFocus fullWidth margin="normal" variant="outlined"
                label="Year of Birth" placeholder="YYYY" inputProps={{ maxLength: 4 }}
                value={_.get(user,'patientBirthYear','')} onChange={(e)=>{
                  _.set(user,'patientBirthYear',parseInt(e.target.value.replace(/[^0-9]/gi, ''))||'')
                  refreshState(Date.now())
                }}
              />
            </div>
            <div style={{flex:1}}></div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                  previousScreen(welcomeScreen-1)
                  userUpdate(user.uid,{
                    welcomeScreen:welcomeScreen-1,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })}}>
                  Back
                </Button>
                <Button style={{marginLeft:8,backgroundColor:_.get(user,'patientBirthYear',0) < 1900 || _.get(user,'patientBirthYear',0) > 2022?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                  disabled={_.get(user,'patientBirthYear',0) < 1900 || _.get(user,'patientBirthYear',0) > 2022}
                  onClick={()=>{
                  nextScreen(welcomeScreen+1)
                  userUpdate(user.uid,{
                    patientBirthYear:_.get(user,'patientBirthYear',0),
                    patientBirthdate:_.get(user,'patientBirthYear',0) > 1900 && _.get(user,'patientBirthYear',0) < 2022 ? `${_.get(user,'patientBirthYear',0)}-01-01` : '',
                    welcomeScreen:welcomeScreen+1,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })}}>
                  Next
                </Button>
              </div>
          </div>
      )
    case 12: // CAREGIVER BRANCH REQUEST ZIP
      return (
        <div style={localStyles.dialogContent}>
            <div style={{flex:1}}></div>
            <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
              <div>What is there zip code</div>
              <div style={{paddingBottom:8,fontSize:16}}>leave it the same if you're not sure</div>
              <TextField autoFocus fullWidth margin="normal" variant="outlined"
                label="Zip Code" placeholder="XXXXX" inputProps={{ maxLength: 5 }}
                value={_.get(user,'patientAddressZip','')} onChange={(e)=>{
                  _.set(user,'patientAddressZip',_.trim(e.target.value.replace(/[^0-9]/gi, '')))
                  refreshState(Date.now())
                }}
              />
            </div>
            <div style={{flex:1}}></div>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                  previousScreen(welcomeScreen-1)
                  userUpdate(user.uid,{
                    welcomeScreen:welcomeScreen-1,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })}}>
                  Back
                </Button>
                <Button style={{marginLeft:8,backgroundColor:_.trim(_.get(user,'patientAddressZip','')).length!==5?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                  disabled={_.trim(_.get(user,'patientAddressZip','')).length!==5}
                  onClick={()=>{
                  nextScreen(20)
                  userUpdate(user.uid,{
                    patientAddressZip: _.trim(_.get(user,'patientAddressZip','')),
                    welcomeScreen:20,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })}}>
                  Next
                </Button>
              </div>
          </div>
      )
    case 20: // PATIENT BRANCH REQUEST CANCER DETAILS
      let nextButtonDisabled = _.get(user,'cancerType','').length === 0 || _.get(user,'cancerStatus','').length === 0
      return (
        <div style={localStyles.dialogContent}>
            <div style={{flex:1}}></div>
            <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:8,paddingLeft:64,paddingRight:64}}>
              <div style={{paddingBottom:8,lineHeight:'initial'}}>Please fill out the following details</div>
              <TextField autoFocus fullWidth margin="normal" variant="outlined"
                label="Cancer Type" placeholder="Type of cancer" inputProps={{ maxLength: 100 }}
                value={_.get(user,'cancerType','')} onChange={(e)=>{
                  _.set(user,'cancerType',e.target.value)
                  refreshState(Date.now())
                }}
                />
                <TextField fullWidth margin="normal" variant="outlined"
                  label="Cancer Stage" placeholder="Stage of cancer" inputProps={{ maxLength: 50 }}
                  value={_.get(user,'cancerStage','')} onChange={(e)=>{
                    _.set(user,'cancerStage',e.target.value)
                    refreshState(Date.now())
                  }}
                  />
                  <TextField fullWidth margin="normal" variant="outlined"
                    label="Year of Diagnosis" placeholder="YYYY" inputProps={{ maxLength: 4 }}
                    value={_.get(user,'cancerDiagnosisDateYear','')} onChange={(e)=>{
                      _.set(user,'cancerDiagnosisDateYear',parseInt(e.target.value.replace(/[^0-9]/gi, ''))||'')
                      refreshState(Date.now())
                    }}
                  />
                  <TextField fullWidth margin="normal" variant="outlined" select
                    label="Month of Diagnosis" placeholder="Month"
                    value={_.get(user,'cancerDiagnosisDateMonth',0)} onChange={(e)=>{
                      _.set(user,'cancerDiagnosisDateMonth',e.target.value||0)
                      refreshState(Date.now())
                    }}
                  >
                    <MenuItem key={'Blank'} value={0}></MenuItem>
                    <MenuItem key={'January'} value={1}>January</MenuItem>
                    <MenuItem key={'February'} value={2}>February</MenuItem>
                    <MenuItem key={'March'} value={3}>March</MenuItem>
                    <MenuItem key={'April'} value={4}>April</MenuItem>
                    <MenuItem key={'May'} value={5}>May</MenuItem>
                    <MenuItem key={'June'} value={6}>June</MenuItem>
                    <MenuItem key={'July'} value={7}>July</MenuItem>
                    <MenuItem key={'August'} value={8}>August</MenuItem>
                    <MenuItem key={'September'} value={9}>September</MenuItem>
                    <MenuItem key={'October'} value={10}>October</MenuItem>
                    <MenuItem key={'November'} value={11}>November</MenuItem>
                    <MenuItem key={'December'} value={12}>December</MenuItem>
                  </TextField>
                  <TextField fullWidth margin="normal" variant="outlined" select
                    label="Cancer Status" placeholder="Current status" inputProps={{ maxLength: 50 }}
                    value={_.get(user,'cancerStatus','')} onChange={(e)=>{
                      _.set(user,'cancerStatus',e.target.value)
                      refreshState(Date.now())
                    }}>
                    <MenuItem key={'Blank'} value={''}></MenuItem>
                    <MenuItem key={'Newly Diagnosed'} value={'Newly Diagnosed'}>Newly Diagnosed</MenuItem>
                    <MenuItem key={'Undergoing Treatment'} value={'Undergoing Treatment'}>Undergoing Treatment</MenuItem>
                    <MenuItem key={'Active Surveillance'} value={'Active Surveillance'}>Active Surveillance</MenuItem>
                    <MenuItem key={'Survivorship'} value={'Survivorship'}>Survivorship</MenuItem>
                    <MenuItem key={'Maintenance or Metastatic Treatment'} value={'Maintenance or Metastatic Treatment'}>Maintenance or Metastatic  Treatment</MenuItem>
                    <MenuItem key={'End of Life'} value={'End of Life'}>End of Life</MenuItem>
                    <MenuItem key={'Recurrent Disease'} value={'Recurrent Disease'}>Recurrent Disease</MenuItem>
                  </TextField>
            </div>
            <div style={{flex:1}}></div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1}
                onClick={()=>{
                  previousScreen(_.get(user,'type','') === 'Caregiver' ? 12 : 7)
                  userUpdate(user.uid,{
                    welcomeScreen: _.get(user,'type','') === 'Caregiver' ? 12 : 7,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })
                }}>
                Back
              </Button>
              <Button style={{marginLeft:8,backgroundColor:nextButtonDisabled?'RGBA(81,183,196,0.35)':'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                disabled={nextButtonDisabled}
                onClick={()=>{
                  let tempDiagnosisDate = ''
                  if(_.get(user,'cancerDiagnosisDateYear',0) > 1900 && _.get(user,'cancerDiagnosisDateYear',0) < 2022){
                    if(_.get(user,'cancerDiagnosisDateMonth',0) > 0 && _.get(user,'cancerDiagnosisDateMonth',0) < 13){
                      tempDiagnosisDate = `${_.get(user,'cancerDiagnosisDateYear',0)}-${_.get(user,'cancerDiagnosisDateMonth',0)}-01`
                    }else{
                      tempDiagnosisDate = `${_.get(user,'cancerDiagnosisDateYear',0)}-01-01`
                    }
                  }
                  nextScreen(welcomeScreen+1)
                  userUpdate(user.uid,{
                    cancerType: _.startCase(_.get(user,'cancerType','')),
                    cancerStage: _.startCase(_.get(user,'cancerStage','')),
                    cancerDiagnosisDateYear: _.get(user,'cancerDiagnosisDateYear',0),
                    cancerDiagnosisDateMonth: _.get(user,'cancerDiagnosisDateMonth',0),
                    cancerDiagnosisDate: tempDiagnosisDate,
                    cancerStatus: _.get(user,'cancerStatus',''),
                    welcomeScreen:welcomeScreen+1,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })
                }}>
                Done
              </Button>
            </div>
          </div>
      )
    case 21:
      return (
        <div style={localStyles.dialogContent}>
            <div style={{color:'#007A8A',fontSize:24,fontWeight:200,padding:64,lineHeight:'initial'}}>
              <div>Thanks for the extra information, this will help us assist you faster.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <Button style={{marginRight:8,width:160,height:60,color:'#51B7C4',fontWeight:300,fontSize:20}} tabIndex={-1} onClick={()=>{
                previousScreen(welcomeScreen-1)
                userUpdate(user.uid,{
                  welcomeScreen:welcomeScreen-1,
                  lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                  lastActivityAction:`Welcome Screen ${welcomeScreen}`
                })}}>
                Back
              </Button>
              <Button style={{marginLeft:8,backgroundColor:'#51B7C4',width:160,height:60,color:'white',fontWeight:600,fontSize:20}}
                onClick={()=>{
                  userUpdate(user.uid,{
                    welcomeScreen:99,
                    lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityAction:`Welcome Screen ${welcomeScreen}`
                  })
                  userSetPresence('online')
                }}>
                Finish
              </Button>
            </div>
          </div>
      )
    default:
      userUpdate(user.uid,{
        welcomeScreen:99,
        lastActivityDate:firebase.firestore.FieldValue.serverTimestamp(),
        lastActivityAction:`Welcome Screen ${welcomeScreen} (error)`
      })
      userSetPresence('online')
      return null
  }
}
export default DialogWelcome
