import React, { useState } from 'react'
import _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import InputAdornment from '@material-ui/core/InputAdornment'
const StyledIconButton = withStyles({
  root: {
    fontSize: 12,
    boxShadow: 'none',
    color: '#DDDFE2',
    '&:hover': {
      color: 'grey'
    }
  }
})(IconButton)

export const DialogPoll = ({ open, pollList, dialogParentHide }) => {
  const [poll, setPoll] = useState({ question: '', options: [] })
  const [, refreshState] = useState()
  const dialogHide = () => {
    setPoll({ question: '', options: [] })
    dialogParentHide()
  }
  const dialogUpdate = () => {
    pollList.push(poll)
    dialogHide()
  }

  return (
    <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={dialogHide} aria-labelledby="form-dialog-title" >
      <DialogTitle>Add a Poll to Post</DialogTitle>
      {
        // <DialogContentText>This is a great way to ask a quick select a choice question</DialogContentText>
      }
      <DialogContent>
        <TextField autoFocus fullWidth margin="normal" variant="outlined"
          label="Poll Question" placeholder="Whats your favorite color?" inputProps={{ maxLength: 50 }} InputLabelProps={{ shrink: true }}
          value={poll.question}
          onChange={(e) => {
            _.set(poll, 'question', e.target.value)
            refreshState(Date.now())
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
          <div>
            {_.map(poll.options, (option, index) => {
              return (
                <TextField key={`OPTION-${index}`} autoFocus fullWidth margin="normal" variant="outlined"
                  label={`Option #${index + 1}`} placeholder={`enter value for option #${index + 1}`} InputLabelProps={{ shrink: true }}
                  value={option} inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    _.set(poll, `options[${index}]`, e.target.value)
                    refreshState(Date.now())
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <StyledIconButton edge="end" aria-label="Remove Option" onClick={() => { poll.options.splice(index, 1); refreshState(Date.now()) }} >
                          <DeleteOutline />
                        </StyledIconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )
            })}
          </div>
        </div>
      </DialogContent>
      <DialogContent>
        <Button onClick={() => { poll.options.push(''); refreshState(Date.now()) }} color="primary" disabled={poll.options.length >= 10} >
          Add Option (limit 10)
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={dialogHide} color="primary">
          CANCEL
        </Button>
        <Button disabled={ _.trim(poll.question).length === 0 || _.indexOf(poll.options, '') >= 0 || poll.options.length === 0 } onClick={dialogUpdate} color="primary" >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogPoll
