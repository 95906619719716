import React, { useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'

//MATERIAL DESIGN IMPORTS
import Message from '@material-ui/icons/MessageOutlined'
import LikeButton from './LikeButton'
import { Session, postFixProfileIcons, markupMessage } from '../shared/globals'
import CommentList from './CommentList.js'
import {Attachments} from './attachments'
import {CommentPoster} from './Posters'
import CalendarBadge from './CalendarBadge'
import TagsBadge from './admin/TagsBadge'
import NotesBadge from './admin/NotesBadge'
import PhoneBadge from './admin/PhoneBadge'

import PostActions from './PostActions'
import ReactionList from './ReactionList'

const Thread = ({ user, thread }) => {

  useEffect(() => {
    //if i'm focused on this thread post and new comment comes in scroll down to it?
    // refs[`THREADCOMMENT-${thread.id}`].scrollIntoView({behavior: 'smooth',block: 'end',inline: 'nearest'})
  })
// console.log('THREAD RENDER',thread)
  const userName = _.get(thread,'createdById','invalidId') === Session.uid ? _.get(Session.fbUser,'nickname','') || _.get(Session.fbUser,'firstName','') : thread.createdByName
  let styleThreadRoot = {border: '1px solid #DDDFE2', boxShadow: '0 0 3px #ccc', marginTop: 27, borderRadius: '4px', backgroundColor: 'white'}
  if(_.get(Session,'userClaims.isAdmin',false) && _.filter(thread.comments,(comment)=>{
    return _.get(comment,'tags',[]).length === 0 && comment.createdById === user.uid
  }).length>0){
    styleThreadRoot = {border: '2px solid red', boxShadow: '0 0 3px #ccc', marginTop: 27, borderRadius: '4px', backgroundColor: 'white'}
  }
  if(_.get(Session,'userClaims.isAdmin',false) && _.get(thread,'tags',[]).length === 0 && thread.createdById === user.uid){
    styleThreadRoot = {border: '2px solid red', boxShadow: '0 0 3px #ccc', marginTop: 27, borderRadius: '4px', backgroundColor: 'white'}
  }
  return (
    <div id="threadRoot" style={styleThreadRoot} ref={(ref) => (Session.refs[`THREAD-${thread.id}`] = ref)}>
      <div id="threadBody" style={{}}>
        <div id="threadContents" style={{display: 'flex', flexDirection: 'column', padding: '16px 16px 8px 16px', borderRadius: '4px 4px 0px 0px',backgroundColor:'#fef8f8'}}>
          <div id="userDetails" style={{display: 'flex', flexDirection: 'row'}}>
            <div id="userAvatar" style={{paddingRight:8}}>
              <img style={{ width: 36, height: 36, borderRadius: 99,border:'solid 2px black',padding:4}} onError={()=>postFixProfileIcons(user,thread)} src={_.get(thread,'createdByProfileIcon','')} alt=""/>
            </div>
            <div id="userNameAndTimeStamp" style={{display: 'flex', flexDirection: 'column', width: '82%'}}>
              <div id="userName" style={{fontSize: '14px', color: '#385898', fontWeight: 'bold'}}>
                {userName}
              </div>
              <div id="threadTimeStamp" style={{color: '#616770', lineHeight: '17px', fontSize: 12}} title={`Created: ${moment(thread.createdDate).toString()}\nModified: ${moment(thread.modifiedDate).toString()}`}>
                {moment(thread.createdDate).isValid?moment(thread.createdDate).fromNow():null}
              </div>
            </div>
            <div id="threadActions" style={{cursor: 'pointer', marginRight: '5px', height: '24px'}}>
              <PostActions user={user} post={thread}/>
            </div>
          </div>
          <div className="userContent" style={{fontSize: '14px',overflowWrap:'break-word' }}>
            {markupMessage(user.uid,thread.message)}
          </div>
          <Attachments user={user} attachments={thread.attachments} />
          <div style={{display: 'flex', flexDirection: 'row',alignItems:'center', marginLeft: 12, marginTop: 10, marginRight:12, justifyContent: 'space-between'}}>
            <ReactionList user={user} post={thread} size={18} />
            <div id="commentCount" style={{display: 'flex', flexDirection: 'row',alignItems:'center',color: '#616770', letterSpacing: '-0.07px', fontSize: '13px'}}>
              {
                !thread.commentsCount || thread.commentsCount === 0 ? null : `${thread.commentsCount} Comments`
              }
              <CalendarBadge user={user} post={thread} />
              <NotesBadge user={user} post={thread} />
              <PhoneBadge user={user} post={thread} />
              <TagsBadge user={user} post={thread} />
            </div>
          </div>
          <div style={{}}>
            <hr style={{marginLeft: 12, marginRight:12, borderTop: '0.1px solid #DADDE0'}} />
          </div>
          <div className="threadActions" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', color: 'rgba(0,0,0,0.54)', paddingTop: '3px'}}>
            <LikeButton key={`LB-${thread.id}-${thread.modifiedDate}`} user={user} thread={thread}/>
            <div className="commentContent" style={{display: 'flex', flexDirection: 'row', cursor:'pointer'}} onClick={()=>Session.refs[`THREADCOMMENT-${thread.id}`].scrollIntoView({behavior: 'smooth',block: 'end',inline: 'nearest'})}>
              <Message/>
              <div className="commentButton" style={{ paddingTop: '3px', paddingLeft: '4px', fontSize: '13px', lineHeight: '15px', color: '#616770', fontWeight: 'bold', letterSpacing: '-0.07px', height: '15px' }}>
                Comment
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{border:'solid 1px #DADDE0'}}></div>
      <CommentList user={user} comments={thread.comments} />
      <CommentPoster user={user} thread={thread} />
    </div>
  )
}

export default Thread
